import React, { useContext, useState, useEffect } from "react";
import "./ItemIdentities.scss";
import { TableCell } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Paginator from "../../../components/paginator/Paginator";
import TableBodyComponent from "../../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../../components/table/tableBody/TableRowComponent";
import TableHeader from "../../../components/table/tableHeader/TableHeader";
import { useGet } from "../../../hooks/useFetch";
import DialogComponent from "../../../components/dialogs/dialog/Dialog";
import { FormInput } from "../../../components/formComponents/FormComponents";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  createItemIdentity,
  deleteItemIdentity,
  updateItemIdentity,
} from "../../../core/apis/item";
import { useGlobalValues } from "../../../context/GlobalContext";

import { AlertContext } from "../../../context/AlertContext";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import withH1 from "../../../assests/HOC/withH1";
import { useTranslation } from "react-i18next";
import BasicTabs from "../../../components/Tabs/Tabs";
import { translateByGoogleApi } from "../../../core/apis/newlanguage";

const itemIdentitySchema = yup.object({
  name: yup.string("Enter item identity").required("Field is required"),
});

const ItemIdentities = () => {
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const tableHeaders = [{ title: t("name") }];
  const [value, setValue] = useState("en");
  const { enabledLanguages } = useGlobalValues();
  const [searchParams] = useSearchParams();

  let url = {
    getAll: `admin/get-all-item-identities`,
    create: `admin/create-item-identity`,
    edit: `admin/update-item-identity/${id}`,
    getId: `admin/get-item-identity-by-id/${id}`,
  };
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };
  const {
    data: itemIdentities,
    loading: loadingPage,
    refetch,
  } = useGet({ url: url.getAll, payload: payload });

  let data = itemIdentities?.data?.data;

  const handleDialog = (type) => () => {
    setOpen({ ...open, [type]: !open?.[type] });
    setId(null);
  };
  const handleEdit = (id) => () => {
    setOpen({ ...open, update: true });
    setId(id);
  };
  const formik = useFormik({
    initialValues: {
      name: data?.data?.find((i) => i?.id === id)?.name?.[value] || "",
      id: data?.data?.find((i) => i?.id === id)?.id || null,
      language: value,
    },
    enableReinitialize: true,
    validationSchema: itemIdentitySchema,
    onSubmit: () => {
      handleSubmitForm();
    },
  });
  const handleSubmitForm = () => {
    setLoading(true);
    if (open.add) {
      createItemIdentity({ ...formik.values }).then((res) => {
        if (res.data?.success) {
          enabledLanguages.map((item) => {
            if (item.code != "en") {
              handleTranslateByGoogle(res.data.data, item.code);
            }
          });
          refetch();
          formik.values.name = "";
          setOpen({ ...open, add: false });
        }
        setLoading(false);
      });
    } else {
      updateItemIdentity({ ...formik.values }).then((res) => {
        setAlert({
          visible: true,
          text: res.data ? res.data.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
        if (res.data?.success) {
          refetch();
          setOpen({ ...open, update: false });
          setId(null);
        }
        setLoading(false);
      });
    }
  };

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteItemIdentity(responseData).then((res) => {
        if (res?.data?.success) {
          setId(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  const handleChange = (event, newValue) => {
    i18n.changeLanguage(newValue);
    setValue(newValue);
  };

  const handleTranslateByGoogle = async (values, targetLanguage) => {
    let arrayofdata = [values.name.en];
    await translateByGoogleApi(arrayofdata, targetLanguage).then((res) => {
      let payload = {
        id: values.id,
        language: targetLanguage,
        name: res[0]?.translatedText,
      };
      updateItemIdentity(payload).then((res) => {
        if (res.data?.success) {
          refetch();
          setAlert({
            visible: true,
            text: res.data ? res.data.message : res.message,
            type: res.data?.success ? "success" : "error",
          });
        }
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  return (
    <>
      <DialogComponent
        open={open.add || open.update || false}
        className="cardDialog addressDialog"
        handleOpenClose={handleDialog(open.add ? "add" : "update")}
        buttonLabel={open.add ? "Create" : "Save"}
        title={`${open.add ? "Add" : "Update"} Item Identity`}
        loading={loading}
        disabled={!formik.dirty || loading}
        onSubmit={formik.handleSubmit}
      >
        <div className="w-500">
          <FormInput
            required
            label="Item Identity"
            placeholder="Enter item identity"
            name="name"
            id="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </div>
      </DialogComponent>

      <BasicTabs
        handleChange={handleChange}
        tabsList={enabledLanguages}
        value={value}
        children={
          <div className={value != "ar" ? "" : "arabic-component"}>
            <TableHeader
              placeholder={t("Search by item identity name")}
              add={() => setOpen({ ...open, add: true })}
              filterButton={false}
            />
            <TableBodyComponent
              isArabic={value == "ar" ? true : false}
              header={tableHeaders}
              loading={loadingPage}
              total={data?.total}
            >
              {data?.data?.map((element) => (
                <TableRowComponent
                  className="BrandDetails"
                  handleDelete={() => {
                    setId(element?.id);
                    setOpenDelete(true);
                  }}
                  handleEdit={handleEdit(element?.id)}
                  row={element}
                  key={element?.id}
                >
                  <TableCell className={value != "ar" ? " " : "textAlighRight"}>
                    {element?.name?.[value]
                      ? element?.name?.[value]
                      : element?.name?.en}
                  </TableCell>
                </TableRowComponent>
              ))}
            </TableBodyComponent>
          </div>
        }
      />

      <Paginator count={data?.last_page} disabled={loadingPage} />
      {openDelete && (
        <NoticeConfirmation data={id} handleResponse={handleDeleteResponse} />
      )}
    </>
  );
};

export default withH1(ItemIdentities, "Item Identities");
