// EXTERNAL LIBRARIES
import React, { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TableCell } from "@mui/material";
import { AlertContext } from "../../context/AlertContext";
// TABLE
import TableHeader from "../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import Paginator from "../../components/paginator/Paginator";

// CUSTOM HOOKS
import { useGet } from "../../hooks/useFetch";

// DATA NOT FOUND
import DataNotFound from "../../components/notFound/DataNotFound";

// STYLING
import "./CountrySetting.scss";

// MODAL COMPONENT
import { FormDropdownList } from "../../components/formComponents/FormComponents";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import withH1 from "../../assests/HOC/withH1";
import { updateCountryStatus } from "../../core/apis/country";

const tableHeaders = [{ title: "Country Name" }, { title: "Country Status" }];

const CountrySetting = () => {
  // DATA AND HOOKS
  const [searchParams] = useSearchParams();
  const { setAlert } = useContext(AlertContext);

  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
    is_hidden: searchParams.get("is_hidden")
      ? searchParams.get("is_hidden") == "true"
        ? true
        : false
      : false,
  };

  const hiddenStatusOption = [
    { name: "Hidden", id: 1 },
    { name: "Not Hidden", id: 2 },
  ];
  const [countryDetail, setCountryDetail] = useState(null);
  const [openStatusToggle, setOpenStatusToggle] = useState(false);

  let url = `admin/get-all-countries`;

  // API CALL (fetching data to be displayed)
  const {
    data: countries,
    loading,
    refetch,
  } = useGet({ url: url, payload: payload });
  let data = countries?.data.data;

  const handleChangeStatus = (e, element) => {
    setCountryDetail(element);
    setOpenStatusToggle(true);
  };

  const handleResponse = (type, data) => {
    if (type === "yes") {
      updateCountryStatus(data?.id).then((res) => {
        console.log("res===>", res.data.success, res.data.message);
        if (res?.data?.success) {
          refetch();
        }
        setAlert({
          visible: true,
          type: res?.data?.success ? "success" : "error",
          text: res.data.message,
        });
      });
    }
    setOpenStatusToggle(false);
  };

  return (
    <div className="countryComponent">
      <TableHeader
        placeholder="Search by country name"
        filterByHideStatus
        withAll={false}
      />
      <TableBodyComponent
        header={tableHeaders}
        loading={loading}
        total={data?.total}
        actions={false}
      >
        {data?.data?.length ? (
          data?.data?.map((element) => (
            <TableRowComponent row={element} key={element?.id} actions={false}>
              <TableCell>{element?.name}</TableCell>

              <TableCell>
                <FormDropdownList
                  required
                  formComponent={false}
                  data={hiddenStatusOption}
                  value={
                    element?.is_hidden
                      ? { name: "Hidden", id: 1 }
                      : { name: "Not Hidden", id: 2 }
                  }
                  onChange={(e) => handleChangeStatus(e, element)}
                />
              </TableCell>
            </TableRowComponent>
          ))
        ) : (
          <DataNotFound />
        )}
      </TableBodyComponent>
      {data?.data && <Paginator count={data?.last_page} disabled={loading} />}

      {openStatusToggle && (
        <NoticeConfirmation
          message={`Are you sure you want to ${
            countryDetail?.is_hidden ? " unhide " : " hide "
          }this country`}
          handleResponse={handleResponse}
          data={countryDetail}
        />
      )}
    </div>
  );
};
export default withH1(CountrySetting, "Country Setting");
