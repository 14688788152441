import { api } from "./main";

export const createDiscount = async (data) => {
  return await api
    .post("admin/create-discount", data)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};

export const deleteDiscount = async (id) => {
  return await api
    .delete(`/admin/delete-discount/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};

export const updateDiscount = async (data) => {
  return await api
    .put(`admin/update-discount/${data?.id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};
