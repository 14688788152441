import axios from "axios";

export const REACT_APP_IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

export let api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_WEB_URL,
  headers: {
    "X-Authorization":
      "AoKeVdwXUfTBe8kOTPsSJ0vZYWY4qFGmEqtJYs1M7osYXBSiCsXeqXEOAfgzC7oL",
  },
});

// Set the AUTH token for any request
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      await axios
        .get("auth/refresh-token", {
          withCredentials: true,
          baseURL: process.env.REACT_APP_WEB_URL,
          headers: {
            "X-Authorization":
              "AoKeVdwXUfTBe8kOTPsSJ0vZYWY4qFGmEqtJYs1M7osYXBSiCsXeqXEOAfgzC7oL",
          },
        })
        .catch(() => {
          localStorage.clear();
          window.location.replace("/login");
        });
      return axios(error.config);
    } else {
      return Promise.reject(error);
    }
  }
);
