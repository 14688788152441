import React, { useEffect, useState, refetch } from "react";
import { getallheadlineinfo } from "../../core/apis/retailerinfopagedata";
import { TableCell } from "@mui/material";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import BasicTabs from "../../components/Tabs/Tabs";
import "./ManageRetailer.scss";
import RetailerinfoHandle from "./RetailerInfoHandle";
import { useQuery } from "@tanstack/react-query";
import withH1 from "../../assests/HOC/withH1";
import { useGlobalValues } from "../../context/GlobalContext";
import { useGet } from "../../hooks/useFetch";

const RetailerInfoPageData = (props) => {
  const tableHeaders = [{ title: "Title" }, { title: "Type" }];
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState(null);
  const [value, setValue] = useState("en");
  const { enabledLanguages } = useGlobalValues();

  const {
    data: response,
    loading,
    refetch,
  } = useGet({
    url: "/get-all-headline-info",
  });

  const Retailerdata = response?.data?.data;

  const handleEdit = (value) => {
    setDetail(value);
    setOpen(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <BasicTabs
        handleChange={handleChange}
        tabsList={enabledLanguages}
        value={value}
        children={
          <div className={value != "ar" ? "" : "arabic-component"}>
            <TableBodyComponent
              isArabic={value == "ar" ? true : false}
              header={tableHeaders}
              loading={loading}
              total={Retailerdata?.total}
            >
              {Retailerdata?.map((element) => (
                <TableRowComponent
                  handleEdit={() => handleEdit(element)}
                  row={element}
                  key={element?.id}
                >
                  <TableCell className={value != "ar" ? " " : "textAlighRight"}>
                    {element?.information?.[value]
                      ? element?.information?.[value]
                      : element?.information?.en}
                  </TableCell>
                  <TableCell className={value != "ar" ? " " : "textAlighRight"}>
                    {element?.head_line_info_type?.type
                      ? element?.head_line_info_type?.type
                      : ""}
                  </TableCell>
                </TableRowComponent>
              ))}
            </TableBodyComponent>
            {open && (
              <RetailerinfoHandle
                data={detail}
                languageValue={value}
                onClose={() => {
                  setDetail(null);
                  setOpen(false);
                  refetch()
                }}
              />
            )}
          </div>
        }
      />
    </div>
  );
};

export default withH1(RetailerInfoPageData, "Info Head-Lines Management");
