//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

//COMPONENT
import Button from "../../../components/button/Button";
import { useForm, Controller } from "react-hook-form";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { FormInput } from "../../../components/formComponents/FormComponents";
import { useTranslation } from "react-i18next";
import { CloseSVG } from "../../../assests/icons/SVG";
import { AlertContext } from "../../../context/AlertContext";
import {
  createProductUsageType,
  updateProductUsageType,
} from "../../../core/apis/productUsageType";
//CSS
import "./UsageTypes.scss";
import { translateByGoogleApi } from "../../../core/apis/newlanguage";
import { useGlobalValues } from "../../../context/GlobalContext";

const HandleSchema = yup.object({
  name: yup.string("Invalid").nullable().required("Field is required"),
  abbreviation: yup.string("").required("Field is Required"),
});

export function UsageTypeHandle(props) {
  const { data, onClose, refetch, languageValue } = props;

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  const { setAlert } = useContext(AlertContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const { enabledLanguages } = useGlobalValues();

  const { t, i18n } = useTranslation();

  const handleSubmitForm = (formData) => {
    setSaveLoading(true);
    if (data) {
      updateProductUsageType(formData).then((res) => {
        if (res?.data.success) {
          refetch();
          onClose();
        }
        setAlert({
          visible: true,
          text: res.data ? res.data?.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
        setSaveLoading(false);
      });
    } else {
      createProductUsageType(formData).then((res) => {
        if (res?.data.success) {
          onClose();
          enabledLanguages.map((item) => {
            if (item.code != "en") {
              handleTranslateByGoogle(res.data.data, formData, item.code);
            }
          });
        }
        setAlert({
          visible: true,
          text: res.data ? res.data?.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
        setSaveLoading(false);
      });
    }
  };

  const handleTranslateByGoogle = async (values, formData, targetLanguage) => {
    let arrayofdata = [values.name.en];
    await translateByGoogleApi(arrayofdata, targetLanguage).then((res) => {
      formData.id = values.id;
      formData.language = targetLanguage;
      formData.name = res[0]?.translatedText;
      formData.abbreviation = values.abbreviation;
      updateProductUsageType(formData).then((res) => {
        refetch();
      });
    });
  };

  useEffect(() => {
    i18n.changeLanguage(languageValue);

    if (data) {
      reset({
        id: data?.id,
        name: data?.name?.[languageValue],
        abbreviation: data?.abbreviation || "",
        language: languageValue,
      });
    }
  }, [data]);

  return (
    <Dialog disablebackdropclick="true" fullWidth open={true}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {data ? "Update Usage Type" : "Add usage Type"}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            "&:hover": {
              backgroundColor: "transparent", // Set the hover background color
            },
          }}
          style={{ justifyContent: "end" }}
        >
          <CloseSVG />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={handleSubmit(handleSubmitForm)}
          className={languageValue == "ar" ? "arabic-component" : "  "}
        >
          <div className="product-usage-type-handle">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormInput
                  required
                  label="Usage Type"
                  placeholder="Enter usage Type"
                  name="name"
                  value={value}
                  onChange={(e) => onChange(e)}
                  helperText={error?.message}
                />
              )}
              name={`name`}
              control={control}
            />
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormInput
                  required
                  label="Abbreviation"
                  placeholder="Enter abbreviation"
                  name="abbreviation"
                  value={value}
                  onChange={(e) => onChange(e)}
                  helperText={error?.message}
                />
              )}
              name={`abbreviation`}
              control={control}
            />

            <div className="action-footer">
              <Button
                name="Save"
                selected
                loading={saveLoading}
                width={"30%"}
                type="submit"
              />
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default UsageTypeHandle;
