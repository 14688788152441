import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Typography,
  Divider,
  Grid,
  Paper,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Skeleton,
} from "@mui/material";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import { getExceptionsByID } from "../../core/apis/exception";
import { useParams } from "react-router-dom";
import TitleComponent from "../../components/titleComponent/TitleComponent";
import "./Exceptions.scss";

const ViewExceptions = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getExceptionsByID(params.id).then((res) => {
      setData(res.data.data);
      setLoading(false);
    });
  }, [params.id]);

  return (
    <div className="exception-details">
      <Paper elevation={24} className="paper-div">
        <div className="header">
          {loading ? (
            <Skeleton variant="rectangular" width={210} height={40} />
          ) : (
            <TitleComponent
              className="title-details"
              title={data?.function_name}
            />
          )}
        </div>

        <Card variant="elevation">
          <Box sx={{ p: 2 }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} sm={4} md={4}>
                <ListItem>
                  {loading ? (
                    <Skeleton variant="circular" width={40} height={40} />
                  ) : (
                    <ListItemAvatar>
                      <Avatar className="avatarBg">
                        <TurnedInIcon />
                      </Avatar>
                    </ListItemAvatar>
                  )}

                  <ListItemText
                    primary={
                      loading ? (
                        <Skeleton variant="text" width={80} />
                      ) : (
                        "Case No"
                      )
                    }
                    secondary={
                      loading ? (
                        <Skeleton variant="text" width={150} />
                      ) : (
                        data?.case_number
                      )
                    }
                  />
                </ListItem>
              </Grid>
            </Grid>
          </Box>
          <Divider />
        </Card>

        <Box sx={{ p: 2 }} className="payload-section">
          <Typography variant="h6" sx={{ mb: 2 }}>
            Payload
          </Typography>
          {loading ? (
            <Skeleton variant="rectangular" width="100%" height={150} />
          ) : (
            <Typography variant="body2" sx={{ mb: 2 }}>
              {data?.payload}
            </Typography>
          )}
        </Box>

        <Box sx={{ p: 2 }} className="exception-section">
          <Typography variant="h6" sx={{ mb: 2 }}>
            Exception
          </Typography>
          {loading ? (
            <Skeleton variant="rectangular" width="100%" height={150} />
          ) : (
            <Typography variant="body2" sx={{ mb: 2 }}>
              {data?.exception}
            </Typography>
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default ViewExceptions;
