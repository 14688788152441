import { Box, Modal, Button, Slider } from "@mui/material";
import { borderRadius, padding } from "@mui/system";
import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";

const boxStyle = {
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center",
};
const modalStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const buttonStyle = {
  padding: "10px",
  background: "#041c3f",
  width: "100%",
  justifyContent: "end",
  alignItems: "center",
  display: "flex",
  flexFlow: "row",
};

const cancelButton = {
  marginRight: "10px",
  color: "white",
  borderColor: "white",
  width: "100px",
};

const saveButton = {
  background: "#4ba4f0",
  borderColor: "#fff",
  color: "#fff",
  width: "100px",
};

const headerStyle = {
  color: "#fff",
  background: "#041c3f",
  width: "100%",
  padding: "10px 10px",
};

// Modal
const CropperModal = (props) => {
  const {
    src,
    modalOpen,
    setModalOpen,
    setPreview,
    max_image_height,
    max_image_width,
    onCropComplete,
    multiple,
    iwebp,
  } = props;

  const cropRef = useRef(null);

  const [slideValue, setSlideValue] = useState(10);

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < length; i++) {
      randomString += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return randomString;
  };

  // Example usage to generate a random filename
  const randomFileName = generateRandomString(5); //

  //handle save
  const handleSave = async () => {
    if (cropRef && iwebp) {
      const dataUrl = cropRef.current.getImage().toDataURL("image/webp"); // Use WebP format
      const result = await fetch(dataUrl);
      const blob = await result.blob();

      const croppedFile = new File([blob], `${randomFileName}.webp`, {
        type: "image/webp", // Use WebP format for transparency
      });

      onCropComplete(croppedFile);
      if (!multiple) {
        setPreview(URL.createObjectURL(blob));
      }
      setModalOpen(false);
    }

    if (cropRef && iwebp === false) {
      const dataUrl = cropRef.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();

      const croppedFile = new File([blob], `${randomFileName}.png`, {
        type: "image/png", // Use PNG format for transparency
      });

      onCropComplete(croppedFile);
      if (!multiple) {
        setPreview(URL.createObjectURL(blob));
      }
      setModalOpen(false);
    }
  };

  return (
    <Modal sx={modalStyle} open={modalOpen}>
      <Box sx={boxStyle}>
        <Box sx={headerStyle}>
          <h5>Crop Image</h5>
        </Box>
        <AvatarEditor
          ref={cropRef}
          image={src}
          width={max_image_width}
          height={max_image_height}
          color={[0, 0, 0, 0.5]}
          border={50}
          borderRadius={10}
          scale={slideValue / 10}
          rotate={0}
          style={{
            maxWidth: "1000px", // Limit max width of editor
            width: "100%",
          }}
        />
        <Slider
          min={10}
          max={20}
          sx={{
            margin: "0 auto",
            width: "80%",
            color: "cyan",
          }}
          size="medium"
          defaultValue={slideValue}
          value={slideValue}
          onChange={(e, newValue) => setSlideValue(newValue)}
        />

        <Box sx={buttonStyle}>
          <Button
            size="small"
            sx={cancelButton}
            variant="outlined"
            onClick={(e) => setModalOpen(false)}
          >
            cancel
          </Button>
          <Button
            sx={saveButton}
            size="small"
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
CropperModal.defaultProps = {
  max_image_height: 400,
  max_image_width: 200,
  multiple: false,
  iwebp: false,
};

export default CropperModal;
