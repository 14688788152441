//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

//COMPONENT
import Skeleton from "../../components/MUI-skeleton/Skeleton";
import { Button, IconButton } from "@mui/material";
import { AlertContext } from "../../context/AlertContext";
import Paginator from "../../components/paginator/Paginator";
import TableHeader from "../../components/table/tableHeader/TableHeader";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

//API
import { useGet } from "../../hooks/useFetch";
//CSS
import "./HomeGroup.scss";
import HomePageGroupItem from "./HomePageGroupItem";
import {
  deleteHomeGroup,
  getAllHomePageGroup,
  reOrderHomePageGroup,
} from "../../core/apis/homePageGroup";
import { DragBlueSVG } from "../../assests/icons/SVG";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import withH1 from "../../assests/HOC/withH1";

export function HomeGroup(props) {
  //VARIABLES
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); //pagination and filter
  const [homePageDetail, setHomePageDetail] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [canDrag, setCanDrag] = useState(false);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [allFilters, setAllFilters] = useState({
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
    country_ids: searchParams.get("country_ids"),
  });

  // Drag sensors from external library
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  //FUNCTIONS

  // Handle drag movement and setting array values
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const dragId = data.find((item) => item.id === active.id);
      const dropId = data.find((item) => item.id === over.id);

      if (dragId && dropId) {
        const oldIndex = data.indexOf(dragId);
        const newIndex = data.indexOf(dropId);
        setData((items) => {
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    }
  };

  const getHomePageGroupData = (newFilters) => {
    setLoading(true);
    getAllHomePageGroup({ ...newFilters }).then((res) => {
      if (res?.data?.success) {
        setAllData(res?.data?.data);
        setData(res?.data.data?.data || []);
      }
      setLoading(false);
    });
  };
  useEffect(() => {
    getHomePageGroupData(allFilters);
  }, [allFilters]);

  useEffect(() => {
    setAllFilters({
      per_page: searchParams.get("per_page") || 10,
      page: searchParams.get("page") || 1,
      name: searchParams.get("search"),
      country_ids: searchParams.get("country_ids"),
    });
  }, [searchParams]);

  const handleSubmit = () => {
    setSaveLoading(true);
    reOrderHomePageGroup({
      home_page_group_ids: data?.map((el) => el?.id),
    }).then((res) => {
      if (res?.data?.success) {
        // refetch();
        setCanDrag(false);
      }
      setAlert({
        text: res.data ? res.data.message : res.message,
        type: res.data.success ? "success" : "error",
      });
      setSaveLoading(false);
    });
  };

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteHomeGroup(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setHomePageDetail(null);
          setSearchParams({
            per_page: 10,
            page: 1,
          });
          getHomePageGroupData(allFilters);
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  const handleDelete = (element) => {
    setHomePageDetail(element);
    setOpenDelete(true);
  };

  return (
    <div className="home-group-component">
      <TableHeader
        placeholder="Search by name"
        to="/homepage-groups/new-group"
        filterButton={false}
      />
      {!loading && !saveLoading ? (
        <>
          {" "}
          <div className="home-header">
            <div className="title">Name</div>
            <div className="actions">
              <div>
                {canDrag ? (
                  <Button
                    variant="text"
                    onClick={() => {
                      setCanDrag(false);
                      setSearchParams({
                        per_page: 10,
                        page: 1,
                      });
                    }}
                  >
                    Cancel
                  </Button>
                ) : (
                  <IconButton
                    onClick={() => {
                      setCanDrag(true);
                      setSearchParams({
                        per_page: allData?.total,
                        page: 1,
                      });
                    }}
                  >
                    <DragBlueSVG />
                  </IconButton>
                )}
              </div>
              {canDrag && (
                <div>
                  <Button variant="text" onClick={() => handleSubmit()}>
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="home-page-listing">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={data}
                strategy={verticalListSortingStrategy}
              >
                {data?.map((element, index) => (
                  <div className="home-page-list-element" key={element?.id}>
                    <HomePageGroupItem
                      element={element}
                      canDrag={canDrag}
                      handleDelete={handleDelete}
                    />
                  </div>
                ))}
              </SortableContext>
            </DndContext>
          </div>
        </>
      ) : (
        <Skeleton count={10} />
      )}

      <Paginator count={allData?.last_page} disabled={loading} />
      {openDelete && (
        <NoticeConfirmation
          data={homePageDetail}
          handleResponse={handleDeleteResponse}
        />
      )}
    </div>
  );
}

export default withH1(HomeGroup, "Homepage Groups");
