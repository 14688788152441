import React, { useContext, useState, useEffect } from "react";
import "./PiceaWorkFlow.scss";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { TableCell } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Paginator from "../../../components/paginator/Paginator";
import TableBodyComponent from "../../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../../components/table/tableBody/TableRowComponent";
import TableHeader from "../../../components/table/tableHeader/TableHeader";
import { useGet } from "../../../hooks/useFetch";
import DialogComponent from "../../../components/dialogs/dialog/Dialog";
import {
  FormInput,
  FormDropdownList,
} from "../../../components/formComponents/FormComponents";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  getAllPiceaWorkFlowTypes,
  getAllPiceaWorkFlow,
  createPiceaWorkFlow,
  deletePiceaWorkFlow,
  getAllPiceaUsers,
} from "../../../core/apis/Picea";
import { AlertContext } from "../../../context/AlertContext";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import withH1 from "../../../assests/HOC/withH1";

const piceaWorkFlowSchema = yup.object({
  picea_workflow_id: yup
    .string("Enter Workflow ID")
    .required("Field is required"),
  picea_workflow_type_id: yup.object().nullable().required("Field is required"),
  picea_user_id: yup.object().nullable().required("Field is required"),
});

const PiceaWorkFlow = () => {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [workflowTypes, setworkflowTypes] = useState([]);
  const [piceaUsers, setpiceaUsers] = useState([]);

  const tableHeaders = [
    { title: "Id" },
    { title: "name" },
    { title: "Type" },
    { title: "Assigned User" },
    { title: "Code" },
  ];
  const [searchParams] = useSearchParams();

  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };

  const {
    data: piceaWorkFlow,
    loading: loadingPage,
    refetch,
  } = useGet({ url: `admin/picea/get-all-picea-workflows`, payload: payload });

  let data = piceaWorkFlow?.data?.data;

  useEffect(() => {
    getAllPiceaWorkFlowTypes().then((res) => setworkflowTypes(res.data.data));
    getAllPiceaUsers().then((res) => setpiceaUsers(res.data.data));
  }, []);

  const toggleDialog = (value) => () => {
    setOpen(value);
    setId(null);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      picea_workflow_id: "",
      picea_workflow_type_id: "",
      picea_user_id: "",
    },
    enableReinitialize: true,
    validationSchema: piceaWorkFlowSchema,
    onSubmit: () => {
      handleSubmitForm();
    },
  });

  const handleSubmitForm = () => {
    setLoading(true);
    let payload = {
      name: formik.values.name,
      picea_user_id: formik.values.picea_user_id.id,
      picea_workflow_id: formik.values.picea_workflow_id,
      picea_workflow_type_id: formik.values.picea_workflow_type_id.id,
    };
    createPiceaWorkFlow(payload).then((res) => {
      console.log(payload);
      setAlert({
        visible: true,
        text: res.data ? res.data.message : res.message,
        type: res.data?.success ? "success" : "error",
      });
      if (res.data?.success) {
        refetch();
        toggleDialog(!open)();
        formik.resetForm(); // Reset the form values
      }
      setLoading(false);
    });
  };

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deletePiceaWorkFlow(responseData).then((res) => {
        if (res?.data?.success) {
          setId(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  return (
    <div className="PiceaWorkFlow">
      <DialogComponent
        open={open.add || open.update || false}
        handleOpenClose={toggleDialog(!open)}
        buttonLabel="Create"
        title="Create WorkFlow "
        loading={loading}
        disabled={!formik.dirty || loading}
        onSubmit={formik.handleSubmit}
        className="min-220"
      >
        <div className="w-500">
          <FormInput
            required
            label="WorkFlow Name"
            placeholder="Enter WorkFlow Name"
            name="name"
            id="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <FormInput
            required
            label="WorkFlow ID"
            placeholder="Enter WorkFlow ID"
            name="picea_workflow_id"
            id="picea_workflow_id"
            value={formik.values.picea_workflow_id}
            onChange={formik.handleChange}
            error={
              formik.touched.picea_workflow_id &&
              Boolean(formik.errors.picea_workflow_id)
            }
            helperText={
              formik.touched.picea_workflow_id &&
              formik.errors.picea_workflow_id
            }
          />

          <FormDropdownList
            required
            data={workflowTypes || []}
            label="Work Flow Type"
            name="picea_workflow_type_id"
            id="picea_workflow_type_id"
            value={formik?.values.picea_workflow_type_id}
            onChange={(value) =>
              formik.setFieldValue("picea_workflow_type_id", value)
            }
            helperText={
              formik.touched?.picea_workflow_type_id &&
              formik.errors?.picea_workflow_type_id
            }
          />

          <FormDropdownList
            required
            data={piceaUsers || []}
            label="Assign User"
            name="picea_user_id"
            id="picea_user_id"
            accessValue="username"
            value={formik?.values?.picea_user_id}
            onChange={(value) => formik.setFieldValue("picea_user_id", value)}
            helperText={
              formik.touched?.picea_user_id && formik.errors?.picea_user_id
            }
          />
        </div>
      </DialogComponent>

      <TableHeader
        placeholder="Search by name"
        add={() => setOpen({ ...open, add: true })}
        filterButton={false}
      />
      <TableBodyComponent
        header={tableHeaders}
        loading={loadingPage}
        total={data?.total}
      >
        {data?.data?.map((element) => (
          <TableRowComponent
            className="BrandDetails"
            handleDelete={() => {
              setId(element?.id);
              setOpenDelete(true);
            }}
            row={element}
            key={element?.id}
          >
            <TableCell>{element.id}</TableCell>
            <TableCell>{element?.name}</TableCell>
            <TableCell>{element?.picea_workflow_type.name}</TableCell>
            <TableCell>{element.picea_user.username}</TableCell>
            <TableCell>{element?.picea_workflow_id}</TableCell>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
      <Paginator count={data?.last_page} disabled={loadingPage} />
      {openDelete && (
        <NoticeConfirmation data={id} handleResponse={handleDeleteResponse} />
      )}
    </div>
  );
};

export default withH1(PiceaWorkFlow, "WorkFlows");
