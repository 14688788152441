import { api } from "./main";

export const getAllRibbons = async (payload) => {
  return await api
    .get("admin/get-all-ribbons", { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const getRibbonsById = async (ribbon_id) => {
  return await api
    .get(`admin/get-ribbon-by-id/${ribbon_id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const createRibbon = async (payload) => {
  return await api
    .post("admin/create-ribbon", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const updateRibbon = async (payload) => {
  return await api
    .put(`admin/update-ribbon/${payload?.ribbon_id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const deleteRibbon = async (ribbon_id) => {
  return await api
    .delete(`admin/delete-ribbon/${ribbon_id}`, { params: { id: ribbon_id } })
    .then((res) => res)
    .catch((err) => console.error(err));
};
