import React, { useEffect, useState } from "react";
import { Skeleton, Box } from "@mui/material";

export default function Animations(props) {
  const [array, setArray] = useState([]);

  useEffect(() => {
    if (props.count) {
      const arrays = [];
      for (var i = 0; i < props.count; i++) {
        arrays.push(i);
      }
      setArray(arrays);
    }
  }, []);
  return (
    <Box sx={{ width: "100%" }}>
      {array.length !== 0
        ? array.map((val) => (
            <Skeleton
              key={val}
              sx={{
                width: "100%",
                marginTop: val === 0 ? "3px" : "-15px",
                height: props.height ? props.height : "77px",
              }}
              animation={val % 2 === 0 ? "wave" : "wave"}
            />
          ))
        : ""}
    </Box>
  );
}
