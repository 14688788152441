import { api } from "./main";

export const UpdateNotificationGroup = async (payload) => {
  return await api
    .put(`admin/update-notification-group/${payload?.id}`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};

export const CreateNotificationGroup = async (data) => {
  return await api
    .post("admin/create-notification-group", data)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};

export const getAllNotificationTypes = async (data) => {
  return await api
    .get("get-all-notification-types", { params: data })
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};

export const markAllNotificationsRead = async (payload) => {
  return await api
    .post("mark-all-notifications-read", payload)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};
export const getUserNotification = async (payload) => {
  return await api
    .get("get-user-notifications", { params: payload })
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};

export const getNotificationById = async (payload) => {
  return await api
    .get(`get-notification-by-id/${payload}`, { params: payload })
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};
