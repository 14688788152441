// EXTERNAL LIBRARIES
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TableCell } from "@mui/material";

// STYLING
import "./Vat.scss";

// TABLE
import TableHeader from "../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import Paginator from "../../components/paginator/Paginator";

// MODAL CONTENT
import VatDetails from "./vatDetails/VatDetails";
import withH1 from "../../assests/HOC/withH1";
import { useQuery } from "@tanstack/react-query";
//API
import { getAllCountriesAdmin } from "../../core/apis/country";

const Vat = () => {
  // DATA AND HOOKS
  const [searchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState(false);
  const [element, setElement] = useState(null);

  const tableHeaders = [{ title: "Country" }, { title: "Value" }];

  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["countries", payload],
    queryFn: () => {
      return getAllCountriesAdmin(payload).then((res) => res?.data?.data);
    },
  });

  return (
    <>
      <TableHeader placeholder="Search by country name" filterButton={false} />
      <TableBodyComponent
        loading={isLoading}
        header={tableHeaders}
        total={data?.total}
      >
        {data?.data?.map((element) => (
          <TableRowComponent
            handleEdit={() => {
              setElement(element);
              setOpenModal(true);
            }}
            row={element}
            key={element?.id}
          >
            <TableCell>{element?.name}</TableCell>
            <TableCell>{element?.vat}</TableCell>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
      <Paginator count={data?.last_page} disabled={isLoading} />

      {openModal && (
        <VatDetails
          element={element}
          refetch={refetch}
          onClose={() => {
            setElement(null);
            setOpenModal(false);
          }}
        />
      )}
    </>
  );
};

export default withH1(Vat, "VAT");
