import { api } from "./main";

export const getQuestionByID = async (id) => {
  return await api
    .get(`/admin/get-question-by-id/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      alert("error while fetching data");
    });
};

export const updateQuestions = async (id, payload) => {
  return await api
    .post(`admin/update-question/${id}?_method=PUT`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};

export const createQuestions = async (payload) => {
  return await api
    .post(`admin/create-question`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};

export const deleteAnswer = async (id) => {
  return await api
    .delete(`/admin/delete-answer/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};
