export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const groupParamsByKey = (params) =>
  [...params.entries()].reduce((acc, tuple) => {
    // getting the key and value from each tuple
    const [key, val] = tuple;
    if (acc.hasOwnProperty(key)) {
      // if the current key is already an array, we'll add the value to it
      if (Array.isArray(acc[key])) {
        acc[key] = [...acc[key], val];
      } else {
        // if it's not an array, but contains a value, we'll convert it into an array
        // and add the current value to it
        acc[key] = [acc[key], val];
      }
    } else {
      // plain assignment if no special case is present
      acc[key] = val;
    }

    return acc;
  }, {});
export const objectCleaner = (obj) => {
  obj = Object.fromEntries(
    Object.entries(obj).filter(
      ([_, v]) => v != "null" && v != null && v !== "" && v?.length !== 0
    )
  );
  return obj;
};
export const urlToTitle = (urlString) => {
  return urlString.split("-").join(" ");
};
export const getFormData = (
  obj,
  formData = new FormData(),
  parentKey = null
) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      const formattedKey = parentKey ? `${parentKey}[${key}]` : key;

      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          const itemKey = `${formattedKey}[${index}]`;
          if (typeof item === "object" && item instanceof File) {
            formData.append(itemKey, item);
          } else {
            getFormData({ [itemKey]: item }, formData);
          }
        });
      } else if (
        typeof value === "object" &&
        value !== null &&
        !(value instanceof File)
      ) {
        getFormData(value, formData, formattedKey);
      } else {
        formData.append(formattedKey, value);
      }
    }
  }

  return formData;
};

export const removeBlob = (obj, fieldArray) => {
  let newobj = { ...obj };
  fieldArray.map((el) => {
    typeof newobj?.[el] === "string" && delete newobj?.[el];
  });

  return newobj;
};

export const removeBlobs = (obj) => {
  let newobj = { ...obj };
  typeof newobj?.image === "string" && delete newobj?.image;
  return newobj;
};

export const separateValuesByAttributeId = (data) => {
  const attributeGroups = [];
  const otherItems = [];

  // Iterate over each item in the data array
  data.forEach((item) => {
    const {
      id,
      factor,
      model_factor_type,
      model_factor_id,
      product_factorable,
    } = item;

    if (model_factor_type === "App\\Models\\AttributeValue") {
      const attributeId = product_factorable?.attribute_id;

      // Find the existing group for this attribute_id or create a new one
      let group = attributeGroups.find(
        (group) => group?.attribute_id === attributeId
      );
      if (!group) {
        group = {
          attribute_id: attributeId,
          items: [],
        };
        attributeGroups.push(group);
      }

      // Push the item to the corresponding attribute_id group
      group.items.push({
        id,
        factor,
        attribute_id: attributeId,
        product_factorable,
      });
    } else if (model_factor_type === "App\\Models\\Condition") {
      // For Condition items, push them to the otherItems array directly
      otherItems.push({
        id,
        factor,
        model_factor_type,
        model_factor_id,
        product_factorable,
      });
    }
  });

  // Add a special group for Condition items if there are any
  if (otherItems.length > 0) {
    attributeGroups.push({
      attribute_id: null, // You can use null or a specific value to represent Condition items
      items: otherItems,
    });
  }

  return attributeGroups;
};
export const findValue = (key, data, storeLanguage) => {
  // Try to find the object using the storeLanguage
  const foundObject = data?.find(
    (item) => item.language === storeLanguage && item[key] !== undefined
  );

  // If found, return the value
  if (foundObject) {
    return foundObject[key];
  }
  // Fallback: Try to find the object using the default language "Arabic"
  const defaultObject = data?.find(
    (item) => item.language === "en" && item[key] !== undefined
  );
  // Return the value from the default object, or an empty string if not found
  return defaultObject ? defaultObject[key] : "";
};

//count en and ar for about us and meetus
export const checkFirstValueLanguage = (data) => {
  let englishCount = 0;
  let arabicCount = 0;

  data?.forEach((section) => {
    const firstColumn = section?.columns[0]; // Get the first item in the columns array

    if (firstColumn) {
      if (firstColumn?.language === "en") {
        englishCount++;
      } else if (firstColumn?.language === "ar") {
        arabicCount++;
      }
    }
  });

  return { englishCount, arabicCount };
};

// Function to combine objects by language

export const combineByLanguage = (data) => {
  const combined = {};

  data?.forEach((item) => {
    const language = item?.columns[0]?.language; // Check the first language in the columns array

    if (language) {
      if (!combined[language]) {
        combined[language] = [];
      }
      combined[language].push(item);
    }
  });

  return combined;
};
