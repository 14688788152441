import React, { useEffect, useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AlertContext } from "../../../../context/AlertContext";
import * as yup from "yup";
import { useGet } from "../../../../hooks/useFetch";
import "./privacyPolicy.scss";
import NewEditor from "../../../../components/editor/NewEditor";
import { FormInput } from "../../../../components/formComponents/FormComponents";
import { useGlobalValues } from "../../../../context/GlobalContext";
import { updateExistingDocument } from "../../../../core/apis/store";
import Button from "../../../../components/button/Button";
import Cropper from "../../../../components/singleUpload/imageCropper";
import { Skeleton } from "@mui/material";

const PrivacyPolicy = (props) => {
  const { doctype } = props;
  // Validation Schema
  const privacyPolicySchema = yup.object({
    title: yup.string().nullable().required("Field is required"),
    description: yup.string().nullable().required("Field is required"),
    Image: yup.mixed(),
  });

  const { storeLanguage } = useGlobalValues();
  const [loading, setLoading] = useState(false);
  const [fetchingdata, setfetchingdata] = useState(true);
  const [imageVal, setImageValue] = useState(null);

  const { setAlert } = useContext(AlertContext);

  // // Get Details by Document Types
  const {
    data: detailsbyTypes,
    loading: loadingAlldocumenttypes,
    refetch,
  } = useGet({
    url: `admin/document/${doctype}`,
  });

  let detailsbyTypesArray = detailsbyTypes?.data?.data;

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(privacyPolicySchema) });

  const handleImage = (croppedImage) => {
    setImageValue(croppedImage);
    setValue("Image", croppedImage);
  };

  console.log(getValues("description"));

  // Fetch data and populate form if ID is provided
  useEffect(() => {
    if (detailsbyTypesArray) {
      setValue("title", detailsbyTypesArray?.title?.[storeLanguage]);
      setValue("description", detailsbyTypesArray?.paragraph_content);
      setValue("Image", detailsbyTypesArray?.image);
      setfetchingdata(false);
    }
  }, [detailsbyTypesArray]);

  // Fetch data and populate form if ID is provided
  useEffect(() => {
    setfetchingdata(true);
    refetch();
  }, [storeLanguage]);

  const handleSubmitForm = async (values) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("title", values.title);
    formData.append("paragraph_content", values.description);
    formData.append("language", storeLanguage);

    if (
      doctype === "SHIPPING_INFORMATION" ||
      doctype === "PAYMENT_INFORMATION"
    ) {
      formData.append("image", values.Image);
    }

    if (!(values?.Image instanceof File)) {
      formData.delete("image");
    }

    updateExistingDocument(doctype, formData).then((res) => {
      setAlert({
        visible: true,
        text: res?.data?.success ? "Successfully updated" : res?.data?.message,
        type: res?.data?.success ? "success" : "error",
      });
      refetch();
      setLoading(false);
    });
  };
  const handleCancel = () => {
    setfetchingdata(true);
    refetch();
  };

  return (
    <div className="PrivacyPolicy">
      <div className="form-section">
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          {/* Skeleton Loader for Title */}
          {fetchingdata ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={56}
              style={{ marginBottom: "16px" }}
            />
          ) : (
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormInput
                  required
                  name="title"
                  label="Page Title"
                  hideVariant
                  placeholder={"Privacy Policy"}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  helperText={error?.message}
                />
              )}
              name="title"
              control={control}
            />
          )}

          <div className="form-editor">
            {/* Skeleton Loader for Description */}
            {fetchingdata ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={200}
                style={{ marginBottom: "16px" }}
              />
            ) : (
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <NewEditor
                    label={"Description"}
                    name="description"
                    labelclassname="label-editor-title"
                    value={value}
                    lang={storeLanguage}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                )}
                name="description"
                control={control}
              />
            )}
          </div>

          {doctype === "SHIPPING_INFORMATION" ||
          doctype === "PAYMENT_INFORMATION" ? (
            fetchingdata ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={300}
                style={{ marginBottom: "16px" }}
              />
            ) : (
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Cropper
                    required
                    sendCroppedFile={handleImage}
                    MAX_IMAGE_WIDTH={500}
                    MAX_IMAGE_HEIGHT={500}
                    title={"Drag your Image here"}
                    className="width100"
                    isTrue={true}
                    iwebp={true}
                    imageSrc={getValues("Image")}
                  />
                )}
                name="Image"
                control={control}
              />
            )
          ) : null}

          <div className="action-footer">
            {fetchingdata ? (
              <>
                <Skeleton
                  variant="rectangular"
                  width={120}
                  height={40}
                  style={{ marginRight: "16px" }}
                />
                <Skeleton variant="rectangular" width={120} height={40} />
              </>
            ) : (
              <>
                <Button
                  selected
                  name={"Save"}
                  type="submit"
                  loading={loading}
                  className="mx-1"
                />
                <Button
                  onClick={() => handleCancel()}
                  name={"Cancel"}
                  type="button"
                  className="mx-1"
                />
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
