import React, { useContext, useState } from "react";

//router
import { useNavigate, useSearchParams } from "react-router-dom";

//reusable components
import Paginator from "../../components/paginator/Paginator";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import TableHeader from "../../components/table/tableHeader/TableHeader";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { TableCell } from "@mui/material";
//custom hook
import { useGet } from "../../hooks/useFetch";

//styling
import "./LogisticCompanies.scss";

//apis
import { deleteLogisticCompany } from "../../core/apis/logisticCompany";

//higher order component
import withH1 from "../../assests/HOC/withH1";
//context
import { AlertContext } from "../../context/AlertContext";

const tableHeaders = [{ title: "Name" }, { title: "Description" }];
const LogisticCompanies = () => {
  //context
  const { setAlert } = useContext(AlertContext);

  const [searchParams] = useSearchParams();

  const [selectedLogistic, setSelectedLogistic] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  let navigate = useNavigate();
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };
  let url = `admin/get-all-logistic-companies`;

  //get all logistic companies
  const {
    data: logisticCompanies,
    loading,
    refetch,
  } = useGet({ url: url, payload: payload });
  let data = logisticCompanies?.data?.data;

  //delete an existed logistic company
  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteLogisticCompany(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setSelectedLogistic(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  return (
    <>
      <TableHeader
        placeholder="Search by Logistic Company Name"
        // to="/logistic-companies/new-logistic-company"
        filterButton={false}
      />
      <TableBodyComponent
        header={tableHeaders}
        loading={loading}
        total={data?.total}
      >
        {data?.data?.map((element) => (
          <TableRowComponent
            className="BrandDetails"
            // handleDelete={() => {
            //   setSelectedLogistic(element);
            //   setOpenDelete(true);
            // }}
            handleEdit={() =>
              navigate(`/logistic-companies/logistic-company/${element?.id}`)
            }
            row={element}
            key={element?.id}
          >
            <TableCell>{element?.name}</TableCell>
            <TableCell>{element?.description}</TableCell>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
      <Paginator count={data?.last_page} disabled={loading} />
      {openDelete && (
        <NoticeConfirmation
          data={selectedLogistic}
          handleResponse={handleDeleteResponse}
        />
      )}
    </>
  );
};

export default withH1(LogisticCompanies, "Logistic Companies");
