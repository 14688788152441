// EXTERNAL LIBRARIES
import React, { useState, useContext, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TableCell, Paper, Button } from "@mui/material";
import { AlertContext } from "../../context/AlertContext";
// TABLE
import TableHeader from "../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import Paginator from "../../components/paginator/Paginator";

// CUSTOM HOOKS
import { useGet } from "../../hooks/useFetch";

// DATA NOT FOUND
import DataNotFound from "../../components/notFound/DataNotFound";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import {
  deleteProductSku,
  UploadProductsSKUItems,
} from "../../core/apis/productSkus";
import withH1 from "../../assests/HOC/withH1";
import UploadFile from "@mui/icons-material/UploadFile";
import Buttoncomponent from "../../components/button/Button";
import DownloadIcon from "@mui/icons-material/Download";

const ProductSKU = () => {
  // HOOKS AND DATA
  const { setAlert } = useContext(AlertContext);
  const [searchParams] = useSearchParams();
  const [detail, setDetail] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  let navigate = useNavigate();

  const tableHeaders = [
    { title: "SKU-Details" },
    { title: "SKU" },
    { title: "Quantity" },
    { title: "Status" },
  ];

  let payload = {
    per_page: 30,
    page: searchParams.get("page") || 1,
    sku_code: searchParams.get("search"),
  };

  // Fetching the data to be displayed
  let url = `/admin/get-all-product-skus`;
  const {
    data: productSKU,
    loading,
    refetch,
  } = useGet({ url: url, payload: payload });
  let data = productSKU?.data?.data;

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteProductSku(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setDetail(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const handleDownload = () => {
    setDownloading(true);
    const link = document.createElement("a");
    link.href = "/items-import-template.xlsx"; // Path relative to the public folder
    link.download = "items-import-template.xlsx"; // The file name for the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloading(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    handleUpload(event?.target?.files[0]);
  };

  const handleUpload = (selectedFile) => {
    setUploading(true);
    if (!selectedFile) {
      console.error("No file selected for upload");
      return;
    }
    let valuesData = new FormData();
    valuesData.append("file", selectedFile);
    // Call handleUpload function with selectedFile
    UploadProductsSKUItems(valuesData).then((response) => {
      setAlert({
        visible: true,
        text: response?.data?.message,
        type: response?.data?.success ? "success" : "error",
      });
      refetch();
      setUploading(false);
      console.log("File uploaded successfully:", response);
      // Handle successful upload response as needed
    });
  };

  return (
    <div className="productskuList">
      <Paper elevation={24} className="paper-div">
        <TableHeader
          to="/product-sku/new-product-sku"
          placeholder="Search by product SKU or by product name"
          filterButton={false}
        />
        <div className="flexbutton">
          <Buttoncomponent
            name={"Download Template"}
            type="button"
            className="mx-1"
            startIcon={<DownloadIcon />}
            onClick={handleDownload}
            loading={downloading}
          />
          <>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
              style={{ display: "none" }}
              ref={fileInputRef} // Use fileInputRef here
            />
            <Buttoncomponent
              selected
              name={"Bulk Upload"}
              type="button"
              className="mx-1"
              startIcon={<UploadFile />}
              loading={uploading}
              onClick={() => fileInputRef.current.click()} // Call click method on fileInputRef
            />
          </>
        </div>
        <TableBodyComponent
          loading={loading}
          header={tableHeaders}
          total={data?.total}
        >
          {data?.data?.length ? (
            data?.data?.map((element) => (
              <TableRowComponent
                internal
                // handleDelete={() => {
                //   setDetail(element);
                //   setOpenDelete(true);
                // }}
                handleEdit={() =>
                  navigate("/product-sku/edit-product-sku/" + element?.id)
                }
                handleEye={() =>
                  navigate("/product-sku/view-product-sku/" + element?.id)
                }
                row={element}
                key={element?.id}
              >
                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_IMAGE_URL +
                        element?.product?.category?.image_path
                      }
                      style={{ height: "50px", width: "50px" }}
                    />
                    <p
                      title={element?.name}
                      style={{
                        cursor: "pointer",
                        marginBottom: "0px",
                        maxWidth: "100%",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        lineHeight: "1.2",
                        padding: "8px",
                      }}
                    >
                      {element?.name}
                    </p>
                  </div>
                </TableCell>
                <TableCell>{element?.sku_code}</TableCell>
                <TableCell>{element?.item_count}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color={element.active ? "success" : "error"}
                  >
                    {element?.active ? "LIVE" : "NOT LIVE"}
                  </Button>
                </TableCell>
              </TableRowComponent>
            ))
          ) : (
            <DataNotFound />
          )}
        </TableBodyComponent>
      </Paper>
      {data && <Paginator count={data?.last_page} disabled={loading} />}
      {openDelete && (
        <NoticeConfirmation
          data={detail}
          handleResponse={handleDeleteResponse}
        />
      )}
    </div>
  );
};
export default withH1(ProductSKU, "Product SKU");
