//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

//COMPONENT
import Button from "../../components/button/Button";
import { useForm, Controller } from "react-hook-form";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
} from "@mui/material";
import { useGet } from "../../hooks/useFetch";
import { CloseSVG } from "../../assests/icons/SVG";
import { AlertContext } from "../../context/AlertContext";
import { FormInput } from "../../components/formComponents/FormComponents";
//CSS
import "./ItemHandle.scss";
import { updateItem } from "../../core/apis/item";

const HandleSchema = yup.object({
  cost: yup.number().nullable().required("Field is required"),
  item_identities: yup.array().of(
    yup.object().shape({
      item_identity_id: yup.object(),
      value: yup.string().required("Field is required"),
    })
  ),
});

const ProductItemHandle = (props) => {
  const { data, onClose, refetch } = props;

  //GET THE DATA
  const { data: idendities, identityLoading } = useGet({
    url: `/admin/get-item-item-identities/${data?.id}`,
  });

  const { data: itemdetail, detailLoading } = useGet({
    url: `/admin/get-item-by-id/${data?.id}`,
  });

  const idenditiesArray = idendities?.data?.data;
  const itemdetailArray = itemdetail?.data?.data;
  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  const { setAlert } = useContext(AlertContext);

  const [saveLoading, setSaveLoading] = useState(false);

  const handleSubmitForm = (formData) => {
    setSaveLoading(true);
    updateItem({
      id: data?.id,
      cost: formData?.cost,
      item_identities: formData?.item_identities?.map((val) => ({
        item_identity_id: val?.item_identity_id?.id,
        value: val?.value,
      })),
    }).then((response) => {
      if (response?.data.success) {
        refetch();
        onClose();
      }
      setAlert({
        text: response.data ? response.data?.message : response.message,
        type: response.data?.success ? "success" : "error",
      });

      setSaveLoading(false);
    });
  };

  useEffect(() => {
    let array = [];
    if (itemdetailArray && idenditiesArray) {
      itemdetailArray?.item_details?.identity?.map((val) => {
        array.push({
          item_identity_id: val,
          value: val?.pivot?.value,
        });
      });
      idenditiesArray.map((el) => {
        let check = array?.find((val) => val?.item_identity_id?.id === el?.id);
        if (!check) {
          array.push({
            item_identity_id: el,
            value: "",
          });
        }
      });
    }

    reset({
      item_identities: array,
      cost: itemdetailArray?.item_details?.item_cost || "",
    });
  }, [idenditiesArray, itemdetailArray]);

  return (
    <Dialog disablebackdropclick="true" fullWidth open={true}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {"Update Item"}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseSVG />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="product-questions">
          {!identityLoading && !detailLoading ? (
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormInput
                    label="Cost"
                    placeholder={"Enter cost"}
                    name="cost"
                    value={value}
                    onChange={(e) =>
                      onChange(e.target.value === "" ? null : e.target.value)
                    }
                    helperText={error?.message}
                  />
                )}
                name={`cost`}
                control={control}
              />
              {getValues("item_identities")?.map((element, index) => (
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      placeholder={`Enter ${element?.item_identity_id?.name?.en}`}
                      label={element?.item_identity_id?.name?.en}
                      value={value}
                      name={`item_identities.${index}.value`}
                      onChange={(e) => onChange(e.target.value)}
                      helperText={error?.message}
                    />
                  )}
                  name={`item_identities.${index}.value`}
                  control={control}
                />
              ))}
              <div className="action-footer Buttons">
                <Button
                  name="See Items Details"
                  href={`/items-details/${data?.id}`}
                />

                <Button
                  name="Save"
                  selected
                  loading={saveLoading}
                  disabled={detailLoading || saveLoading}
                  width={"30%"}
                  type="submit"
                />
              </div>
            </form>
          ) : (
            <Skeleton count={5} />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProductItemHandle;
