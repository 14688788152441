import { api } from "./main";

export const getAllCurrency = async (page, payload) => {
  return await api
    .post(`/get-all-currencies?page=${page}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const changeCurrencyStatus = async (payload) => {
  return await api
    .patch(`/admin/hide-unhide-currency/${payload?.id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateCurrencySetting = async (payload) => {
  return await api
    .patch(`/admin/update-currency-countries/${payload?.id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
