import { api } from "./main";

export const getAllExceptions = (payload) => {
  return api
    .get("admin/exceptions", { params: payload })
    .then((res) => res.data.data)
    .catch((err) => console.error(err));
};

export const deleteException = async (payload) => {
  return await api
    .delete(`admin/exceptions/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getExceptionsByID = async (id) => {
  return await api
    .get(`admin/exceptions/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
