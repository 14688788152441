import React, { forwardRef, Fragment } from "react";
import { LogoSVG } from "../../assests/icons/SVG";
import background from "../../assests/Images/2useBackground.png";
import "./Invoice.scss";
import moment from "moment";

const Invoice = forwardRef((props, ref) => {
  const { data } = props;
  const marginTop = "25px";
  const marginBottom = "25px";
  const marginLeft = "0";
  const marginRight = "25px";
  const getPageMargins = () => {
    return `@page { 
      margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important;
      size: A4;

   }`;
  };
  return (
    <div ref={ref} className="Invoice">
      <table>
        <thead>
          <tr>
            <td>
              <div className="header-space" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>
                <style>{getPageMargins()}</style>
                <main>
                  <div className="invoice-details">
                    <span>
                      <b>Invoice Number</b>
                      <p>{data?.barcode}</p>
                    </span>
                    <span>
                      <b>Date of issue</b>
                      <p>{moment().format("DD-MM-YYYY")}</p>
                    </span>
                    {/* <span>
                      <b>Due Date</b>
                      <p>12345667</p>
                    </span> */}
                  </div>
                  <div className="invoice-addresses">
                    <span>
                      <h5>
                        <b>2USE GENERAL TRADING L.L.C</b>
                      </h5>
                      <p>+961 71 664 469</p>
                      <p>Address: Beirut - Lebanon</p>
                      <p>Commercial License: 3830035</p>
                    </span>
                    <span>
                      <h5>
                        <b>Bill to </b>
                      </h5>
                      <p>{data?.user?.full_name}</p>
                      <p>{data?.user?.email}</p>
                      <p>{data?.user?.phone_number}</p>
                      <p>
                        {`${data?.address?.address_line}, ${data?.address?.city_name}, ${data?.address?.country?.name}`}
                      </p>
                    </span>
                  </div>
                  <div className="invoice-table">
                    <div>
                      <b>Description</b>
                      <b>Qty</b>
                      <b>Unit Price</b>
                      <b>Amount</b>
                    </div>
                    <hr />

                    {data?.invoice?.invoice_item?.map((item) => (
                      <Fragment key={item?.id}>
                        <div>
                          <p>
                            <b>{item?.item?.item_code}</b>
                            <p
                              style={{ margin: "0" }}
                            >{`${item?.item?.product_sku?.product?.brand?.name?.en}-${item?.item?.product_sku?.product?.name?.en}`}</p>
                            {item?.item?.product_sku?.product_variation
                              ?.length ? (
                              <p style={{ margin: "0" }}>
                                {item?.item?.product_sku?.product_variation.map(
                                  (el, index) =>
                                    `${el?.attribute_value?.name?.en} ${
                                      index ===
                                      item?.item?.product_sku?.product_variation
                                        ?.length -
                                        1
                                        ? ""
                                        : "-"
                                    }`
                                )}
                              </p>
                            ) : null}
                          </p>
                          <p>1</p>
                          <p>{`${item?.unit_price} ${data?.currency?.code}`}</p>
                          <p>{`${item?.unit_price} ${data?.currency?.code}`}</p>
                        </div>
                        <hr />
                      </Fragment>
                    ))}
                  </div>
                  <div className="total">
                    <span>
                      <b>Subtotal</b>
                      <p>{`${(
                        data?.invoice?.sub_total - data?.invoice?.shipping_cost
                      )?.toFixed(2)} ${data?.currency?.code}`}</p>
                    </span>
                    <span>
                      <b>shipping Fees</b>
                      <p>{`${data?.invoice?.shipping_cost} ${data?.currency?.code}`}</p>
                    </span>
                    <span>
                      <b>Total Excluding VAT</b>
                      <p>{`${data?.invoice?.sub_total} ${data?.currency?.code}`}</p>
                    </span>
                    <span>
                      <b>VAT</b>
                      <p>{`${data?.invoice?.tax}%`}</p>
                    </span>
                    <span>
                      <b>Total</b>
                      <p>
                        {`${Number(data?.amount)?.toFixed(2)} ${
                          data?.currency?.code
                        }`}
                      </p>
                    </span>
                  </div>
                </main>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className="footer-space"> </div>
            </td>
          </tr>
        </tfoot>
      </table>
      <header>
        <h1>Invoice</h1>
        <LogoSVG className="LogoSVG" />
      </header>
      <footer>
        Call Center: +961 71 664 469, Email:Info@2use.com, Return Policy: 7 Days
      </footer>
      <img alt="" src={background} className="watermark" />
    </div>
  );
});

export default Invoice;
