import React, { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Skeleton } from "@mui/material";

const CustomCard = ({
  onDelete = () => {},
  onEdit = () => {},
  data = [],
  maxDescriptionLength = 200,
  language = "en",
  teams = false,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [loading, setloading] = useState("");

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) return text;
    return `${text?.substring(0, maxLength)}...`;
  };

  useEffect(() => {
    if (data) {
      setloading(true);
      const titleObj = data?.columns?.find(
        (item) => item.title && item.language === language
      );
      const descriptionObj = data?.columns?.find(
        (item) => item.description && item.language === language
      );
      const imageObj = data?.columns?.find(
        (item) => item.image && item.language === language
      );
      setTitle(titleObj?.title);
      setDescription(descriptionObj?.description);
      setImage(imageObj?.image);
      setloading(false);
    }
  }, [data]);

  return (
    <Card>
      {/* Conditional rendering: show skeleton if loading, otherwise show image */}
      {loading ? (
        <Skeleton variant="rectangular" width="100%" height={200} />
      ) : (
        <CardMedia
          component="img"
          alt="sectionImage"
          height="200"
          style={{ backgroundPosition: "top" }}
          image={process.env.REACT_APP_IMAGE_URL + image}
        />
      )}

      <CardContent>
        {/* Conditional rendering for the title */}
        {loading ? (
          <Skeleton variant="text" width="60%" />
        ) : (
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
        )}

        {/* Conditional rendering for the description */}
        {loading ? (
          <Skeleton variant="text" width="90%" />
        ) : (
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {truncateText(description, maxDescriptionLength)}
          </Typography>
        )}
      </CardContent>

      <CardActions>
        <Button size="small" onClick={onEdit} disabled={loading}>
          Edit
        </Button>
        <Button
          size="small"
          onClick={onDelete}
          disabled={loading}
          style={{ color: "#ff0000" }}
        >
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

export default CustomCard;
