import React, { useState, useEffect, useContext } from "react";
import Button from "../../../components/button/Button";
import { useGet } from "../../../hooks/useFetch";
import { useGlobalValues } from "../../../context/GlobalContext";
import { AlertContext } from "../../../context/AlertContext";
import { TableCell } from "@mui/material";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";

import { deleteFooterBlock } from "../../../core/apis/store";
import TableBodyComponent from "../../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../../components/table/tableBody/TableRowComponent";

import "./footer.scss";
import QuickLinksForm from "./quickLinksForm";

const QuickLinks = () => {
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();
  const [addsections, setAddSections] = useState(false);
  const [blockId, setblockId] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const tableHeaders = [{ title: "Block Title" }];
  const [editstate, seteditstate] = useState(false);
  const [element, setelement] = useState(null);

  const {
    data: footerBlocks,
    loading: loading,
    refetch,
  } = useGet({
    url: `admin/footer?language=${storeLanguage}`,
  });

  const footerblocks = footerBlocks?.data?.data;

  const handleAddNewSection = () => {
    setAddSections(!addsections);
  };

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteFooterBlock(responseData).then((res) => {
        if (res?.data?.success) {
          setblockId(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res?.data?.success
            ? "Deleted Successfully"
            : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  const handleToggleEditBlock = (value) => {
    setelement(value);
    setAddSections(true);
    seteditstate(true);
  };

  const handleOnFinish = () => {
    setblockId(null);
    setAddSections(false);
    seteditstate(false);
    refetch();
  };

  return (
    <div className="footerquickLink">
      <div className="viewModeHeader">
        {!addsections && (
          <Button
            selected
            name="Add Footer New Block"
            onClick={(e) => handleAddNewSection()}
          />
        )}
      </div>
      {!addsections && (
        <div className="exportList">
          <TableBodyComponent
            loading={loading}
            total={footerblocks?.length}
            header={tableHeaders}
          >
            {footerblocks?.map((element, index) => (
              <TableRowComponent
                internal
                key={element?.id}
                handleEdit={() => {
                  handleToggleEditBlock(element);
                }}
                handleDelete={() => {
                  setblockId(element.id);
                  setOpenDelete(true);
                }}
              >
                <TableCell>{element?.title}</TableCell>
              </TableRowComponent>
            ))}
          </TableBodyComponent>
        </div>
      )}
      {addsections && (
        <QuickLinksForm
          data={element}
          edit={editstate}
          onFinish={handleOnFinish}
        />
      )}

      {openDelete && (
        <NoticeConfirmation
          data={blockId}
          handleResponse={handleDeleteResponse}
        />
      )}
    </div>
  );
};

export default QuickLinks;
