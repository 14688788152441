//UTILITIES
import { useSortable } from "@dnd-kit/sortable";
import { useNavigate } from "react-router";

//COMPONENT
import { CSS } from "@dnd-kit/utilities";
import { Card, CardContent, IconButton } from "@mui/material";
import { DeleteSVG, DragBlueSVG, EditSVG } from "../../assests/icons/SVG";

export function HomePageGroupItem(props) {
  const { canDrag, element, handleDelete } = props;
  let navigate = useNavigate();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: element?.id });

  //TRANSITION
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return canDrag ? (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Card className="home-group-list-card">
        <CardContent>
          <div className="element-container">
            <div>{element?.name?.en}</div>
            <div>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() =>
                  canDrag
                    ? null
                    : navigate(`/homepage-groups/edit-group/${element?.id}`)
                }
              >
                <DragBlueSVG />
              </IconButton>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  ) : (
    <Card className="home-group-list-card">
      <CardContent className="home-group-content">
        <div className="element-container">
          <div className="name">{element?.name?.en}</div>
          <div>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() =>
                canDrag
                  ? null
                  : navigate(`/homepage-groups/edit-group/${element?.id}`)
              }
            >
              <EditSVG />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => handleDelete(element)}
            >
              <DeleteSVG fill="#EE1818" />
            </IconButton>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default HomePageGroupItem;
