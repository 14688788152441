// EXTERNAL LIBRARIES
import React, { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TableCell } from "@mui/material";

// STYLING
import "./Discounts.scss";

// TABLE
import TableHeader from "../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import Paginator from "../../components/paginator/Paginator";

// CUSTOM HOOKS
import { useGet } from "../../hooks/useFetch";
import { AlertContext } from "../../context/AlertContext";

// FORM
import { api } from "../../core/apis/main";

// DATA NOT FOUND
import DataNotFound from "../../components/notFound/DataNotFound";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { deleteDiscount } from "../../core/apis/discount";
import withH1 from "../../assests/HOC/withH1";

const Discounts = () => {
  // DATA AND HOOKS
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const [openDelete, setOpenDelete] = useState(false);
  const [discountDetail, setDiscountDetail] = useState(null);
  const tableHeaders = [
    { title: "Name" },
    { title: "Discount" },
    { title: "Status" },
  ];
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };

  let url = `admin/get-all-discounts`;

  // API CALL
  const {
    data: discounts,
    loading,
    refetch,
  } = useGet({ url: url, payload: payload });
  let data = discounts?.data.data;

  //FUNCTIONS
  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteDiscount(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setDiscountDetail(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  return (
    <>
      <TableHeader
        placeholder="Search by name"
        to="/discounts/add-new-discount"
        filterButton={false}
      />
      <TableBodyComponent
        header={tableHeaders}
        loading={loading}
        total={data?.total}
      >
        {data?.data ? (
          data?.data?.map((element) => (
            <TableRowComponent
              handleEdit={() => navigate(`/discounts/${element?.id}`)}
              handleDelete={() => {
                setDiscountDetail(element);
                setOpenDelete(true);
              }}
              row={element}
              key={element?.id}
            >
              <TableCell>{element?.name.en}</TableCell>
              <TableCell>{element?.value}</TableCell>
              {/* <TableCell>{element?.brand.name.en}</TableCell> */}
              <TableCell>
                {element?.is_active ? "Active" : "Not active"}
              </TableCell>
            </TableRowComponent>
          ))
        ) : (
          <DataNotFound />
        )}
      </TableBodyComponent>
      {data?.data && <Paginator count={data?.last_page} disabled={loading} />}
      {openDelete && (
        <NoticeConfirmation
          data={discountDetail}
          handleResponse={handleDeleteResponse}
        />
      )}
    </>
  );
};

export default withH1(Discounts, "Discounts");
