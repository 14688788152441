//MARILYNN

//CSS
import "./TitleComponent.scss";
export function TitleComponent(props) {
  const { title, color, isArabic, arabicLabel, className } = props;

  return (
    <div
      style={{ color: color }}
      className={className ? className : "title-component"}
    >
      {isArabic ? arabicLabel : title}
    </div>
  );
}

export default TitleComponent;
