import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import moment from "moment";
import { useNavigate } from "react-router";
import { Bar } from "react-chartjs-2";
import { Card, CardContent, TableCell } from "@mui/material";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";

import Spinner from "../../components/MUI-skeleton/Spinner";
import "./Dashboard.scss";
import CountUp from "react-countup";
import { Chart, registerables } from "chart.js";
import { useGet } from "../../hooks/useFetch";
import { FormCalendarInput } from "../../components/formComponents/FormComponents";
import { DoughnutNoDataFound } from "../../assests/icons/SVG";
import {
  BarSkeleton,
  CircularDashboardSkeleton,
} from "../../components/MUI-skeleton/DashboardSkeleton";
import { useSearchParams } from "react-router-dom";
import withH1 from "../../assests/HOC/withH1";

const isMobile = window.innerWidth < 600;
const responsiveSizeHack = isMobile
  ? window.innerWidth + 600
  : window.innerWidth;

const Dashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let payload = {
    end_date: searchParams.get("end_date"),
    start_date: searchParams.get("start_date"),
  };
  //API
  const { data: users, loading: usersLoading } = useGet({
    url: "admin/all-users-count",
  });
  const { data: orders, loading: ordersLoading } = useGet({
    url: "admin/all-orders-count",
    payload: payload,
  });

  const { data: transactions, loading: transactionsLoading } = useGet({
    url: "admin/get-latest-unreviewed-transactions",
    payload: payload,
  });

  let usersData = users?.data?.data;
  let ordersData = orders?.data?.data;
  let transactionData = transactions?.data?.data;
  const [ordersDashboard, setOrdersDashboard] = useState(null);
  const [usersDashboard, setUsersDashboard] = useState(null);

  const [loading, setloading] = useState(false);
  let navigate = useNavigate();
  const tableHeaders = [
    { title: "Barcode" },
    { title: "User" },
    { title: "User Type" },
    { title: "Third Party" },
    { title: "Price" },
    { title: "Date" },
    { title: "Status" },
    { title: "Type" },
  ];

  Chart.register(...registerables);

  const items = ["1", "2", "3", "14", "10", "8", "9"];
  const [chartData, setChartData] = useState({
    labels: ["2023-01-25", "2023-01-26"],
    datasets: [
      {
        label: "Dataset 1",
        data: items.map((i) => i),
        backgroundColor: [
          "#68B9FF",
          "#4BA4F0",
          "#B7DEFF",
          "#8ECBFF",
          "#68B3FE",
        ],
        hoverOffset: 1,
        radius: "70%",
      },
    ],
  });

  useEffect(() => {
    if (usersData) {
      setUsersDashboard({
        labels: Object.keys(usersData),
        datasets: [
          {
            label: "Count",
            data: Object.values(usersData),
            backgroundColor: ["#B7DEFF", "#8ECBFF", "#68B9FF", "#4BA4F0"],
          },
        ],
      });
    }
    if (ordersData) {
      setOrdersDashboard({
        labels: ordersData?.map(function (d) {
          return d.date;
        }),
        datasets: [
          {
            borderWidth: 2,
            borderRadius: Number.MAX_VALUE,
            borderSkipped: false,
            label: "Orders",
            data: ordersData?.map(function (d) {
              return d.number_of_orders;
            }),
            backgroundColor: ["#4BA4F0"],
            hoverOffset: 10,
            radius: "20%",
          },
        ],
      });
    }
  }, [usersData, ordersData, transactionData]);

  if (loading) {
    return <Spinner />;
  } else {
    return (
      <div className="dashboard">
        <div className="dashboard-charts-container ">
          <div className="bar-chart">
            <Card style={{ height: "100%" }}>
              <CardContent>
                <h6 className="chart-title-bar"> Number of Orders</h6>

                <div className="bar">
                  {!ordersLoading ? (
                    <>
                      <div className="bar-filter-date">
                        <FormCalendarInput
                          disabledAfter
                          value={
                            searchParams.get("end_date")
                              ? new Date(searchParams.get("end_date"))
                              : new Date()
                          }
                          onChange={(value) => {
                            if (value) {
                              setSearchParams({
                                end_date: moment(value).format("YYYY-MM-DD"),

                                start_date: moment(
                                  new Date(value - 7 * 24 * 60 * 60 * 1000)
                                ).format("YYYY-MM-DD"),
                              });
                            } else {
                              setSearchParams({
                                end_date: moment(new Date()).format(
                                  "YYYY-MM-DD"
                                ),
                                start_date: moment(
                                  new Date(new Date() - 7 * 24 * 60 * 60 * 1000)
                                ).format("YYYY-MM-DD"),
                              });
                            }
                          }}
                        />
                      </div>
                      {ordersDashboard && (
                        <Bar
                          data={ordersDashboard}
                          options={{ plugins: { legend: { display: false } } }}
                        />
                      )}
                      {ordersDashboard && (
                        <div className="range-date-chosen">
                          {ordersData?.[0]?.date} -
                          {ordersData?.[ordersData.length - 1]?.date}
                        </div>
                      )}
                    </>
                  ) : (
                    <BarSkeleton />
                  )}
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="doughnut-chart pd-top">
            <Card style={{ height: "100%" }}>
              <CardContent>
                <h6 className="chart-title-doughnut">Total Number of Users</h6>
                <div className="doughnut" style={{ marginTop: "40px" }}>
                  {!usersLoading ? (
                    usersDashboard ? (
                      <Doughnut
                        style={{ display: "flex" }}
                        height={responsiveSizeHack}
                        width={responsiveSizeHack}
                        data={usersDashboard}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                          },
                        }}
                      />
                    ) : (
                      <DoughnutNoDataFound />
                    )
                  ) : (
                    <CircularDashboardSkeleton />
                  )}
                </div>
              </CardContent>
            </Card>
          </div>
        </div>

        <div className="transaction-dashboard">
          <div className="dashboard-sub-title">Latest Transactions</div>
          <TableBodyComponent
            header={tableHeaders}
            actions={false}
            loading={transactionsLoading}
            total={transactionData?.length || 0}
          >
            {transactionData?.map((element) => (
              <TableRowComponent
                row={element}
                actions={false}
                handleClick={() =>
                  navigate(
                    `/open-${
                      element?.is_buy ? "buying" : "selling"
                    }-transaction/${element?.id}`
                  )
                }
              >
                <TableCell>{element?.barcode}</TableCell>
                <TableCell>
                  {element?.user?.full_name || "Deleted User"}
                </TableCell>
                <TableCell>{element?.user?.user_type?.name}</TableCell>
                <TableCell>
                  {element?.third_party?.[0]?.third_party?.name?.en ||
                    "No third party"}
                </TableCell>
                <TableCell>
                  AED{" "}
                  <CountUp
                    end={element?.transaction_amount}
                    duration={0.9}
                    decimals={2}
                  />{" "}
                  {element?.currency?.code}
                </TableCell>
                <TableCell>
                  {moment(element?.created_at).calendar() || "---"}
                </TableCell>
                <TableCell>{element?.transaction_status?.name?.en}</TableCell>
                <TableCell>{element?.is_buy ? "Buying" : "Selling"}</TableCell>
              </TableRowComponent>
            ))}
          </TableBodyComponent>
        </div>
      </div>
    );
  }
};

export default withH1(Dashboard, "Dashboard");
