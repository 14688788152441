// EXTERNAL LIBRARIES
import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useNavigate } from "react-router-dom";

// STYLING
// import "./Attributes.scss";
import { EditSVG, DragBlueSVG } from "../../../assests/icons/SVG";

// Draggable item for external library
const CategoryItem = (props) => {
  const { id, element } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className="attributeItem"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <p>{element[0]?.question?.en}</p>
      <span>
        <DragBlueSVG />
      </span>
    </div>
  );
};
export default CategoryItem;
