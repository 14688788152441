//UTILITIES
import React from "react";
import PropTypes from "prop-types";

//COMPONENT

import {
  Table,
  TableBody,
  TableContainer,
  Skeleton,
  Paper,
} from "@mui/material";
import CollapsedTableHeader from "./CollapsedTableHeader";
import NoData from "../no-data/NoData";

//CSS
import "./CollapsedTable.scss";

const CollapsedTable = (props) => {
  const { header, children, handleExport, loading, internal, total } = props;
  const { actions, prefixAction } = props;

  return (
    <TableContainer className="collapse-table-component-container">
      {!loading ? (
        total !== 0 ? (
          <Table className={`collapse-table-component-table`}>
            {header.length > 0 && (
              <CollapsedTableHeader
                actions={actions}
                prefixAction={prefixAction}
                header={header}
                handleExport={handleExport}
                internal={internal}
              />
            )}
            <TableBody>{children}</TableBody>
          </Table>
        ) : (
          <NoData />
        )
      ) : (
        <Skeleton count={10} />
      )}
    </TableContainer>
  );
};

CollapsedTable.defaultProps = {
  actions: true,
  prefixAction: false,
  internal: false,
  header: [],
};
CollapsedTable.propTypes = {
  actions: PropTypes.bool,
  requestSort: PropTypes.func,
  headers: PropTypes.any,
  handleExport: PropTypes.func,
  loading: PropTypes.bool,
  internal: PropTypes.bool,
  prefixAction: PropTypes.bool,
  total: PropTypes.number,
};

export default CollapsedTable;
