import { Alert, Slide, Snackbar } from "@mui/material";
import React, { createContext, useEffect, useState } from "react";

const AlertContext = createContext();

const AlertContextProvider = (props) => {
  const { children } = props;
  const [alert, setAlert] = useState({ visible: false });
  useEffect(() => {
    alert.visible &&
      setTimeout(() => {
        setAlert({ visible: false });
      }, 3000);
  }, [alert]);
  return (
    <AlertContext.Provider value={{ alert, setAlert }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={alert.visible}
        autoHideDuration={6000}
        onClose={() => setAlert({})}
        TransitionComponent={(props) => <Slide {...props} direction="left" />}
      >
        <Alert
          onClick={() => setAlert({ ...alert, visible: false })}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.text}
        </Alert>
      </Snackbar>

      {children}
    </AlertContext.Provider>
  );
};
export { AlertContextProvider, AlertContext };
