import React from "react";
import "./TransactionDetails.scss";
import Barcode from "react-barcode";

const TransactionDetails = (props) => {
  const { data } = props;

  const summary = [
    {
      title: "Customer Details",
      items: [
        { label: "Customer Name", value: data?.user?.full_name },
        { label: "Phone Number", value: data?.user?.phone_number },
        { label: "Email", value: data?.user?.email },
      ],
    },
    {
      title: "Address Details",
      items: [
        { label: "Contact Name", value: data?.shipping?.address?.contact_name },
        {
          label: "Contact Number",
          value: data?.shipping?.address?.contact_number,
        },
        {
          label: "Address",
          value: `${data?.shipping?.address?.address_line}, ${data?.shipping?.address?.city_name}, ${data?.shipping?.address?.country?.name}`,
        },
      ],
    },
    {
      title: "Order Summary",
      items: [
        { label: "Order Date", value: data?.date },
        {
          label: "Payment Method",
          value: data?.payment_gateway?.name?.en || "N/A",
        },
        {
          label: "Shipping Cost",
          value: data?.shipping_cost + "AED" || "Free Delivery",
        },
        {
          label: "Total",
          value: Number(data?.amount)?.toFixed(2) + " " + data?.currency?.code,
        },
      ],
    },
  ];
  return (
    <div className="Order_detailsDisclaimer marginButton">
      <div className="Items_summary">
        {data?.items?.map((item, index) => (
          <table
            className={!index ? "tableDisclaimer" : "tableDisclaimer printHide"}
            key={index}
          >
            <thead className="thead">
              <tr>
                <td className="h6">Items Summary</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>item ID</td>
                <td>{item?.id}</td>
              </tr>
              <tr>
                <td>Barcode</td>
                <td className="barcode">
                  <Barcode
                    value={item?.item_code}
                    textPosition={"left"}
                    fontSize={10}
                    height={20}
                    margin={0}
                  />
                </td>
              </tr>

              <tr>
                <td>Estimated Value</td>
                <td>
                  {`${data?.currency?.code} ${Number(
                    item?.inspection_value,
                  )?.toFixed(2)}`}
                </td>
              </tr>
              <tr>
                <td>Brand</td>
                <td> {item?.product_sku?.product?.brand?.name?.en}</td>
              </tr>
              <tr>
                <td>Category</td>
                <td> {item?.product_sku?.product?.category?.name?.en}</td>
              </tr>
              <tr>
                <td>Product</td>
                <td> {item?.product_sku?.product?.name?.en}</td>
              </tr>
              {item?.product_sku?.product_variation?.map((attribute) => (
                <tr key={attribute?.id}>
                  <td> {attribute?.attribute_value?.attribute?.name?.en}</td>
                  <td> {attribute?.attribute_value?.name?.en}</td>
                </tr>
              ))}

              {item?.question_answer?.map((question) => (
                <tr key={question.id}>
                  <td> {question?.question?.question?.en}</td>
                  <td> {question?.answer?.en}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </div>
      <div className="Details_row">
        {summary?.map((item, index) => (
          <div className="Summary" key={index}>
            <h4>{item?.title}</h4>
            <div className="Info">
              {item?.items?.map((body, index) => (
                <div className="Sub_row" key={index}>
                  <p className="Left">{body?.label}</p>
                  <p className={body?.className || null}>{body?.value}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransactionDetails;
