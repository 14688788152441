import { Box, Grid, Typography, Skeleton, Button } from "@mui/material";
import React, { useState } from "react";
import "./styles.scss";

// Colors Array for ColorPicker
const colors = [
  "#FF5722",
  "#FFC107",
  "#00BCD4",
  "#2196F3",
  "#9E9E9E",
  "#F44336",
  "#3F51B5",
  "#000000",
  "#041C3F",
];

export const ColorPicker = ({
  label,
  selectedColor,
  onColorChange,
  isLoading,
}) => {
  const [showColors, setShowColors] = useState(true);

  const handleToggleColors = () => {
    setShowColors((prevShowColors) => !prevShowColors);
  };

  const handleColorClick = (color) => {
    if (color !== selectedColor) {
      onColorChange(color);
    }
  };

  return (
    <Box className="color-picker-container">
      <Typography variant="subtitle1" className="color-picker-label">
        {isLoading ? <Skeleton width="100%" /> : label}
      </Typography>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2, // Space between the elements
            marginBottom: 2,
          }}
        >
          <Button
            onClick={handleToggleColors}
            variant="contained"
            size="small"
            style={{ backgroundColor: "#c7d7fe", color: "#3538cd" }}
          >
            Pick a Color
          </Button>
          
          {/* Selected Color Box */}
          <Box
            sx={{
              width: 32,
              height: 32,
              backgroundColor: selectedColor,
              border: "2px solid",
              borderColor: selectedColor ? "black" : "transparent",
            }}
          />
        </Box>
      </Grid>

      {showColors && (
        <Grid container spacing={2} className="color-grid">
          {isLoading
            ? Array.from(new Array(10)).map((_, index) => (
                <Grid item xs={1} key={index}>
                  <Skeleton variant="rectangular" width={32} height={32} />
                </Grid>
              ))
            : colors.map((color) => (
                <Grid item xs={1} key={color}>
                  <Box
                    className={`color-box ${
                      selectedColor === color ? "selected" : ""
                    }`}
                    sx={{
                      backgroundColor: color,
                      border: selectedColor === color ? "2px solid black" : "",
                      cursor: 'pointer', // Add cursor for better UX
                    }}
                    onClick={() => handleColorClick(color)}
                  />
                </Grid>
              ))}
          <Grid item xs={3}>
            {isLoading ? (
              <Skeleton width="100%" height={32} />
            ) : (
              <input
                type="text"
                value={selectedColor}
                onChange={(e) => onColorChange(e.target.value)}
                className="color-input"
                style={{ width: '100%', padding: '8px' }} // Adjust width and padding for better styling
              />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
