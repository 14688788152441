import React, { createContext, useState } from "react";

const SideMenuContext = createContext();

const SideMenuContextProvider = (props) => {
  const { children } = props;
  // the value that will be given to the context
  const [sideMenu, setSideMenu] = useState(false);

  return (
    // the Provider gives access to the context to its children
    <SideMenuContext.Provider value={{ sideMenu, setSideMenu }}>
      {children}
    </SideMenuContext.Provider>
  );
};

export { SideMenuContext, SideMenuContextProvider };
