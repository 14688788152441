import { api } from "./main";
import axios from "axios";

const sourceLanguage = "en"; // Source language

export const getalllanguages = async (payload) => {
  return await api
    .get("get-all-languages", { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getalllanguagesfilterBystatus = async () => {
  return await api
    .get("get-all-languages", { params: { is_enabled: 1 } })
    .then((response) => response.data.data)
    .catch((err) => console.error(err));
};

export const translateByGoogleApi = async (payload, targetLanguage) => {
  return await axios
    .post(
      `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_TRANSLATION_KEY}`,
      {
        q: payload,
        source: sourceLanguage,
        target: targetLanguage,
      }
    )
    .then((response) => response.data.data.translations)
    .catch((err) => console.error(err));
};

export const enableLanguages = async (id, payload) => {
  return await api
    .put(`admin/update-enable-disable-languages/${id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
