import React from "react";
import GoogleMapReact from "google-map-react";
import "./Maps.scss";
import { FormHelperText } from "@mui/material";
import { PinSVG } from "../../assests/icons/SVG";
const AnyReactComponent = () => <PinSVG className="pinImage" />;

const SimpleMap = (props) => {
  const defaultMapOptions = (maps) => ({
    fullscreenControl: false,
    mapTypeId: maps.MapTypeId.ROADMAP,
    styles: [
      {
        featureType: "poi.business",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
    ],
  });
  const { center, zoom, onClick, coordination, defaultZoom, helperText } =
    props;
  return (
    // Important! Always set the container height explicitly
    <div className="map">
      <GoogleMapReact
        draggable={props.draggable}
        bootstrapURLKeys={{
          key: "AIzaSyDYRAQnEwtZOhGCqSIum-Xj18AYIqokYFU",
          libraries: ["places", "geometry", "drawing", "visualization"],
        }}
        defaultZoom={defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onClick={onClick}
        options={defaultMapOptions}
        zoom={zoom}
        center={center}
      >
        {coordination && Object.values(coordination)?.length !== 0 && (
          <AnyReactComponent
            lat={coordination?.lat}
            lng={coordination?.lng}
            text="Pin"
          />
        )}
      </GoogleMapReact>
      {helperText !== "" && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  );
};
SimpleMap.defaultProps = {
  center: {
    lat: 33.8547,
    lng: 35.8623,
  },
  zoom: 8,
};
export default SimpleMap;
