// EXTERNAL LIBRARIES
import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// STYLING
import "./ProductDetails.scss";

// CUSTOM HOOKS
import { useGet } from "../../../../hooks/useFetch";
import { AlertContext } from "../../../../context/AlertContext";
// FORM
import {
  FormDropdownList,
  FormInput,
  FormTextArea,
  FormMultiSelect,
} from "../../../../components/formComponents/FormComponents";
import Button from "../../../../components/button/Button";
import { api } from "../../../../core/apis/main";
import {
  getProductByID,
  updateProduct,
  createProduct,
  ValidatePrice,
} from "../../../../core/apis/product";
import EditableRow from "../../../../components/editableRow/editableRow";

import { useGlobalValues } from "../../../../context/GlobalContext";
import { translateByGoogleApi } from "../../../../core/apis/newlanguage";
import Paper from "@mui/material/Paper";
import TitleComponent from "../../../../components/titleComponent/TitleComponent";
import Chip from "@mui/material/Chip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import AttributeDetails from "../../../attributes/attributeDetails/AttributeDetails";
import { separateValuesByAttributeId } from "../../../../core/functions/Functions";
import MultipleCropper from "../../../../components/singleUpload/multipleImageCropper";
import ProductTable from "../../../../components/pricesTable/pricesTable";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { Switch } from "@mui/material";

const ProductDetails = (props) => {
  // DATA AND HOOKS
  const { create } = props;

  const HandleSchema = yup.object({
    name: yup.string("Invalid").required("Field is required"),
    base_price: yup.number("Invalid").nullable().required("Field is required"),
    brand_id: yup.object().nullable().required("Field is required"),
    category_id: yup.object().nullable().required("Field is required"),

    sub_category_id: yup.array().of(
      yup.object().shape({
        category_id: yup.object(),
        category_value_id: yup
          .object()
          .nullable()
          .required("Field is required"),
      })
    ),
    attribute_id: yup
      .array()
      .min(1, "Field is required")
      .required("Field is required"),

    attribute_values_id: yup.array(),
    description: yup.string("Invalid").required("Field is required"),
    abbreviation: yup.string("").required("Field is required"),

    imageList: yup
      .array()
      .min(1, "Field is required")
      .required("Field is required"),

    // Conditional validation for conditionList
    conditionList: yup.array().when(["create", "enablefactors"], {
      is: (create, enablefactors) => create && enablefactors,
      then: (schema) => schema.required("Field is required"),
      otherwise: (schema) => schema,
    }),

    // Conditional validation for attributeTableList
    attributeTableList: yup.array().when("enablefactors", {
      is: true,
      then: (schema) => schema.required("attributeTableList is required"),
      otherwise: (schema) => schema,
    }),

    attribute_values_estimation_false: yup.array(),

    enablefactors: yup.bool().nullable().required("Field is Required"),
    productfactoravailable: yup.bool().nullable(),
  });

  const params = useParams();
  let navigate = useNavigate();

  const { enabledLanguages } = useGlobalValues();

  const { setAlert } = useContext(AlertContext);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [existingImages, setExistingImages] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [validateValues, setvalidateValues] = useState(false);

  //Tab Value
  const [tabvalue, setTabValue] = useState("en");

  //Modal
  const [open, setOpen] = useState(false);
  const [openValidatePrices, setopenValidatePrices] = useState(false);
  const [attributeList, setAttributeList] = useState([]);
  const [attributeListValues, setAttributeListValues] = useState([]);
  const [conditionfactorsArray, setconditionfactorsArray] = useState();
  const [receivedPrices, setreceivedPrices] = useState();

  // Editable Row Edit Mode Condition
  const [conditioneditMode, setconditioneditMode] = useState(false);
  const [editModes, setEditModes] = useState({});

  // API CALLS
  const { data: brands, brandsLoading } = useGet({
    url: "admin/get-all-brands",
  });
  const { data: categories, categoriesLoading } = useGet({
    url: "admin/get-all-categories",
  });

  const { data: conditions, conditionsLoading } = useGet({
    url: "/user/get-all-conditions",
  });

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(HandleSchema),
    reValidateMode: "onChange" | "onBlur",
  });

  const {
    data: attributes,
    attributesLoading,
    refetch,
  } = useGet({
    url: `admin/get-all-attributes?category_id=${getValues("category_id")?.id}`,
  });

  useEffect(() => {
    const categoryId = getValues("category_id")?.id;
    if (categoryId && create) {
      refetch();
      setAttributeList([]);
      setValue("attribute_id", []);
      setValue("attribute_values_id", []);
      setValue("attribute_values_estimation_false", []);
    }
  }, [getValues("category_id")]); // Dependency array

  const handlecreateConditionsVal = () => {
    const conditionarray = conditions.data.data
      .filter((item) => item.id < 8)
      .map((item) => ({
        attributeName: "Condition",
        attribute_id: null,
        outterid: null,
        id: item.id,
        factor: 0,
        name: item?.name,
      }));
    setconditionfactorsArray(conditionarray);
  };

  useEffect(() => {
    if (create && conditions) {
      handlecreateConditionsVal();
    }
  }, [conditions]);

  const {
    fields: subCategoryFields,
    remove: subCategoryRemove,
    append: subCategoryAppend,
  } = useFieldArray({ control, name: "sub_category_id" });

  const handleRefetecvalues = () => {
    refetch();
    setOpen(false);
  };

  const toggleEditMode = (index) => {
    setEditModes((prevEditModes) => ({
      ...prevEditModes,
      [index]: !prevEditModes[index],
    }));
  };

  //FUNCTIONS
  const handleChangeMain = (value) => {
    subCategoryRemove();
    if (value !== null) {
      getData(value, null);
    }
  };

  // Handle Sub Category
  const handleSelectChild = (value, ChildIndex) => {
    if (value === null) {
      getValues("sub_category_id")?.map((el, index) => {
        if (index > ChildIndex) {
          subCategoryRemove(ChildIndex);
        }
      });
    } else {
      if (value?.has_child) {
        getData(value, ChildIndex);
      }
    }
  };
  // Gets called everytime we need to display the dropdown select and appends the element to the state "subcat"
  const getData = async (categoryValue, editValue) => {
    setLoadingData(true);
    return await api
      .get(`admin/get-category-by-id/${categoryValue?.id}`)
      .then((res) => {
        if (res?.data?.success) {
          subCategoryAppend({
            category_id: res?.data?.data?.category,
            category_value_id: editValue ? editValue : null,
          });
        }

        setLoadingData(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSubmitForm = (formValues) => {
    const flattenedCurrentValues = getValues("attribute_values_id")?.flat();
    const updatedAttributeListValues = attributeListValues?.filter((item) =>
      flattenedCurrentValues.some((val) => val.id === item.id)
    );

    if (
      (create && fileList?.length !== 0) ||
      (!create && (existingImages?.length !== 0 || fileList?.length !== 0))
    ) {
      setSaveLoading(true);
      let formData = new FormData();
      let arrayOfAttribute = [];
      formData.append("name", formValues?.name);
      formData.append("abbreviation", formValues?.abbreviation);
      formData.append("description", formValues?.description);
      formData.append("base_price", formValues?.base_price);
      formData.append("brand_id", formValues?.brand_id?.id);
      formData.append("language", tabvalue);
      formData.append("is_buy_sell", formValues.enablefactors ? 1 : 0);

      if (formValues.enablefactors) {
        conditionfactorsArray.forEach((el, index) => {
          formData.append(`conditions_factor[${index}][condition_id]`, el.id);
          formData.append(`conditions_factor[${index}][factor]`, el.factor);
        });
      }

      if (formValues.enablefactors) {
        if (create) {
          const groupedData = attributeListValues.reduce((acc, obj) => {
            const { attribute_id } = obj;
            if (!acc[attribute_id]) {
              acc[attribute_id] = [];
            }
            acc[attribute_id].push(obj);
            return acc;
          }, {});

          Object.keys(groupedData)?.map((item, originalindex) => {
            return (
              formData.append(
                `attributes_factor[${originalindex}][attribute_id]`,
                item
              ),
              groupedData[item].forEach((el, index) => {
                formData.append(
                  `attributes_factor[${originalindex}][attribute_value_ids][${index}][id]`,
                  el.id
                );
                formData.append(
                  `attributes_factor[${originalindex}][attribute_value_ids][${index}][factor]`,
                  el.factor
                );
              })
            );
          });
        } else {
          updatedAttributeListValues?.forEach((el, index) => {
            formData.append(
              `attributes_factor[${index}][attribute_value_ids]`,
              el.id
            );
            formData.append(`attributes_factor[${index}][factor]`, el.factor);
          });
        }
      }

      if (create) {
        formData.append(
          "category_id",
          formValues?.sub_category_id?.length !== 0
            ? formValues?.sub_category_id[
                formValues?.sub_category_id?.length - 1
              ]?.category_value_id?.id
            : formValues?.category_id?.id
        );
      } else {
        formData.append("category_id", formValues?.category_ids);
      }

      formValues?.attribute_values_estimation_false?.map((el) => {
        el?.map((item) => {
          arrayOfAttribute.push(item?.id);
        });
      });

      formValues?.attribute_values_id?.map((el) => {
        el?.map((item) => {
          arrayOfAttribute.push(item?.id);
        });
      });

      fileList.map((image) => {
        formData.append("image[]", image);
      });

      formData.append("deleted_image", deletedImages);

      formData.append("attribute_value_ids", arrayOfAttribute?.toString());

      console.log(formValues);

      for (var pair of formData.entries()) {
        console.log(pair);
      }

      if (create) {
        createProduct(formData).then((res) => {
          setAlert({
            text: res.data.message,
            type: res.data.success ? "success" : "error",
          });
          if (res?.data?.success) {
            enabledLanguages.map((item) => {
              if (item.code != "en") {
                handleTranslateByGoogle(
                  res.data.data,
                  item.code,
                  arrayOfAttribute,
                  formValues?.abbreviation,
                  conditionfactorsArray,
                  attributeListValues
                );
                navigate("/products");
              }
            });
            navigate("/products");
          }
          setSaveLoading(false);
        });
      } else {
        formData.append("id", params?.id);
        formData.append("language", tabvalue);
        updateProduct({ id: params?.id, data: formData }).then((res) => {
          setAlert({
            text: res.data.message,
            type: res.data.success ? "success" : "error",
          });
          if (res?.data?.success) {
            navigate("/products");
          }
          setSaveLoading(false);
        });
      }
    }
  };

  // Removing file from list state (currently selected by user)
  const removeFile = (file) => {
    let filtered = fileList.filter(function (e) {
      return e != file;
    });
    setFileList(filtered);
    setValue("imageList", filtered);
  };

  // Setting the files to be deleted from the existing files (fetched data for edit)
  const removeImage = (id) => {
    deletedImages.push(id);
    setDeletedImages(deletedImages);

    let filtered = existingImages.filter(function (e) {
      return e.id != id;
    });
    setExistingImages(filtered);
  };

  // HandleImages
  const handleImages = (imagesList) => {
    let existing = fileList ? [...fileList] : [];
    existing.push(imagesList);
    setFileList(existing);
    setValue("imageList", existing);
  };

  // HandleConditions
  const handleapiConditionsValue = async (data) => {
    const values = separateValuesByAttributeId(data).filter(
      (item) => item?.attribute_id == null
    );

    // Iterate over the 'values' array
    await values.forEach((item) => {
      // Extract items and map them to include 'product_factorable' contents
      const conditionarray = item?.items.map((innerItem) => ({
        outterid: innerItem?.id,
        id: innerItem?.product_factorable?.id,
        factor: innerItem?.factor,
        name: innerItem?.product_factorable?.name,
      }));
      setconditionfactorsArray(conditionarray);
    });
  };

  // HANDLE ATTRIBUTES
  const handleRecievedAttValues = async (data, attribute) => {
    const values = separateValuesByAttributeId(data).filter(
      (item) => item?.attribute_id != null
    );

    // Map attributes to set 'attributeName' for matching values
    attribute.forEach((item) => {
      const matchedValue = values.find(
        (KeyValue) => KeyValue?.attribute_id === item?.id
      );
      if (matchedValue) {
        // Set 'attributeName' if a match is found
        matchedValue.attributeName = item?.name?.en;
      }
    });
    // Initialize an empty array to hold the extracted items
    let storageArray = [];

    // Iterate over the 'values' array
    await values
      .sort((a, b) => a?.attribute_id - b?.attribute_id)
      .forEach((item) => {
        // Extract items and map them to include 'product_factorable' contents
        const storageItems = item.items.map((innerItem) => ({
          outterid: innerItem?.id,
          id: innerItem?.product_factorable?.id,
          attribute_id: item?.attribute_id,
          factor: innerItem?.factor,
          name: innerItem?.product_factorable?.name,
          abbreviation: innerItem?.product_factorable?.abbreviation,
          // Add more properties from 'product_factorable' as needed
        }));

        handleAttributeValue(storageItems);
        // Push the filtered object into the 'storageArray'
        storageArray.push(storageItems);
      });

    return storageArray;
  };

  // Calling fetch data api on edit
  useEffect(() => {
    if (!create) {
      setLoadingForm(true);
      getProductByID(params?.id).then(async (res) => {
        if (res?.data?.success) {
          let responseData = res?.data?.data;

          let attValues = await handleRecievedAttValues(
            responseData?.product_factor,
            responseData?.attribute
          );
          if (responseData.is_buy_sell) {
            handleapiConditionsValue(responseData?.product_factor);
          }
          // Process attribute_values_estimation_false
          const attributeValuesEstimationFalse = responseData?.attribute
            ?.filter((attr) => attr?.is_estimation_factor === false)
            .map((attr) => attr.values.map((value) => value));

          const attributeValuesEstimationFalseISBUY =
            responseData?.attribute.map((attr) =>
              attr.values.map((value) => value)
            );
          reset({
            description: responseData?.description?.[tabvalue]
              ? responseData?.description?.[tabvalue]
              : responseData?.description?.en,
            name: responseData?.name?.[tabvalue]
              ? responseData?.name?.[tabvalue]
              : responseData?.name?.en,
            base_price: responseData?.base_price,
            brand_id: responseData?.brand,
            category_id: responseData?.category.parents,
            attribute_id: responseData?.attribute || [],
            attribute_values_id: attValues || [],
            attributeTableList: attValues || [],
            attribute_values_estimation_false: responseData.is_buy_sell
              ? attributeValuesEstimationFalse
              : attributeValuesEstimationFalseISBUY,
            category_ids: responseData?.category?.id,
            imageList: responseData?.product_image,
            abbreviation: responseData?.abbreviation,
            enablefactors: responseData?.is_buy_sell,
            productfactoravailable:
              responseData?.product_factor.length > 0 ? true : false,
          });
          getData(responseData?.category.parents, responseData?.category);
          setAttributeList(responseData?.attribute);
          setExistingImages(responseData?.product_image);
        }
        setLoadingForm(false);
      });
    } else {
      reset({
        description: "",
        name: "",
        base_price: null,
        brand_id: null,
        category_id: null,
        attribute_id: [],
        abbreviation: "",
        language: tabvalue,
        enablefactors: false,
      });
    }
  }, [tabvalue]);

  const handleTranslateByGoogle = async (
    values,
    targetLanguage,
    arrayOfAttribute,
    abbreviation,
    conditionfactorsArray,
    attributeListValues
  ) => {
    let arrayofdata = [values.name.en, values.description.en];

    await translateByGoogleApi(arrayofdata, targetLanguage).then((res) => {
      let formData = new FormData();
      formData.append("name", res[0]?.translatedText);
      formData.append("description", res[1]?.translatedText);
      formData.append("language", targetLanguage);
      formData.append("base_price", values.base_price);
      formData.append("brand_id", values.brand_id);
      formData.append("category_id", values.category_id);
      formData.append("attribute_value_ids", arrayOfAttribute?.toString());
      formData.append("abbreviation", abbreviation);
      conditionfactorsArray.forEach((el, index) => {
        formData.append(`conditions_factor[${index}][condition_id]`, el.id);
        formData.append(`conditions_factor[${index}][factor]`, el.factor);
      });
      attributeListValues.forEach((el, index) => {
        formData.append(
          `attributes_factor[${index}][attribute_value_ids]`,
          el.id
        );
        formData.append(`attributes_factor[${index}][factor]`, el.factor);
      });

      updateProduct({ id: values.id, data: formData }).then((res) => {
        setAlert({
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
        setSaveLoading(false);
      });
    });
  };
  // add onclear
  const handleOnClear = (val) => {
    setAttributeList(val);
  };

  const handleAttributeValue = (val) => {
    console.log(val);
    addNewValues(val);
  };

  const addNewValues = (newValues) => {
    setAttributeListValues((prevValues) => {
      const filteredValues = prevValues.filter(
        (item) =>
          !newValues.some(
            (newItem) =>
              newItem.id === item.id &&
              newItem.attribute_id === item.attribute_id
          )
      );

      // Add the new values to the filtered list
      return [...filteredValues, ...newValues];
    });
  };

  const handleConditionValues = (val) => {
    setconditionfactorsArray(val);
    setValue("conditionList", val);
  };

  const handleClosePrices = () => {
    setopenValidatePrices(false);
    setreceivedPrices(null);
  };

  const handleValidate = () => {
    let price = getValues("base_price");
    let ValueData = new FormData();

    attributeList?.forEach((item) => {
      attributeListValues?.forEach((KeyValue) => {
        if (KeyValue?.attribute_id === item?.id) {
          // Set 'attributeName' if a match is found
          KeyValue.attributeName = item?.name?.en;
        }
      });
    });

    const groupedData = attributeListValues?.reduce((acc, obj) => {
      const { attribute_id } = obj;
      if (!acc[attribute_id]) {
        acc[attribute_id] = [];
      }
      acc[attribute_id].push(obj);
      return acc;
    }, {});

    Object.keys(groupedData)?.map((item, originalindex) =>
      groupedData[item].forEach((el, index) => {
        ValueData.append(
          `attributes[${originalindex}][name]`,
          el?.attributeName
        );
        ValueData.append(
          `attributes[${originalindex}][attribute_values][${index}][name]`,
          el.name?.en
        );
        ValueData.append(
          `attributes[${originalindex}][attribute_values][${index}][factor]`,
          el?.factor
        );
      })
    );

    ValueData.append(`base_price`, price);
    conditionfactorsArray.forEach((el, index) => {
      ValueData.append(`conditions[${index}][name]`, el.name?.en);
      ValueData.append(`conditions[${index}][factor]`, el.factor);
    });

    ValidatePrice(ValueData).then((res) => {
      if (res.data) {
        let sortedArray = res?.data?.sort((a, b) => a - b);
        setreceivedPrices(sortedArray);
        setAlert({
          text: res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
        setopenValidatePrices(true);
      } else {
        setAlert({
          text: res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
      }
    });
  };

  const returnValueName = (id) => {
    let attributes = getValues("attribute_id");
    let Value = attributes?.filter((item) => item?.id == id);
    return Value[0]?.name?.en;
  };

  const returnValueOptions = (id) => {
    const Value = attributes?.data?.data?.filter((item) => item?.id == id);
    return Value?.length > 0 ? Value[0]?.attribute_value : [];
  };

  const enableBlock = watch("enablefactors", true);
  const productfactor = watch("productfactoravailable", true);

  useEffect(() => {
    if (enableBlock && conditions && !productfactor) {
      handlecreateConditionsVal();
    }
  }, [enableBlock, conditions]);

  return (
    <div className="products-details">
      {!loadingForm ? (
        <Paper elevation={24} className="paper-div">
          <div className="header">
            <TitleComponent
              className="title-details"
              title={create ? "New Product" : getValues("name") || ""}
            />
            <div className="chip-wrapper">
              {enabledLanguages.map((item, index) =>
                create && item.code === "en" ? (
                  <Chip
                    label={item.name}
                    value={item.code}
                    className="en-chip color-white"
                    color="primary"
                    key={index}
                  />
                ) : (
                  !create && (
                    <Chip
                      key={index}
                      label={item.name}
                      value={item.code}
                      className={
                        tabvalue != item.code
                          ? "en-chip"
                          : "en-chip color-white"
                      }
                      color="primary"
                      variant={tabvalue != item.code ? "outlined" : ""}
                      onClick={() => setTabValue(item.code)}
                    />
                  )
                )
              )}
            </div>
          </div>

          <div className="body-wrapper">
            <div className={tabvalue != "ar" ? "body" : "  width-100"}>
              <form
                className={
                  tabvalue != "ar"
                    ? "products-details-form"
                    : " products-details-form "
                }
                onSubmit={handleSubmit(handleSubmitForm)}
              >
                <div className="form-body">
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="name"
                        label="Product Name"
                        placeholder={"Enter name"}
                        mainClassName="mx-2"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                          clearErrors("name");
                        }}
                        helperText={error?.message}
                        hideVariant={true}
                      />
                    )}
                    name="name"
                    control={control}
                  />
                  {tabvalue != "ar" && (
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormInput
                          required
                          name="abbreviation"
                          label="Product Abbreviation"
                          placeholder={"Enter abbreviation"}
                          mainClassName="mx-2"
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                            clearErrors("abbreviation");
                          }}
                          helperText={error?.message}
                          hideVariant={true}
                        />
                      )}
                      name="abbreviation"
                      control={control}
                    />
                  )}
                </div>

                {tabvalue != "ar" && (
                  <>
                    <div className="form-body margin-top-5">
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormDropdownList
                            required
                            data={brands?.data?.data || []}
                            loading={brandsLoading}
                            label="Brand"
                            name="brand_id"
                            clearErrors={clearErrors}
                            mainClassName="mx-2 width-100"
                            dependencyError={["brand_id"]}
                            placeholder={"Select brand"}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            helperText={error?.message}
                            hideVariant={true}
                          />
                        )}
                        name="brand_id"
                        control={control}
                      />

                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormInput
                            required
                            type="number"
                            name="base_price"
                            label="Base Price"
                            mainClassName="mx-2"
                            placeholder={"Enter base price"}
                            value={value}
                            onChange={(e) => {
                              onChange(
                                e.target.value === "" ? null : e.target.value
                              );
                              clearErrors("base_price");
                            }}
                            helperText={error?.message}
                            hideVariant={true}
                          />
                        )}
                        name="base_price"
                        control={control}
                      />
                    </div>

                    <div className="form-body margin-top-5">
                      <div
                        className={
                          attributeList?.length > 0
                            ? "inputs-wrapper  mt-17"
                            : "inputs-wrapper "
                        }
                      >
                        <>
                          <Controller
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <FormDropdownList
                                required
                                data={categories?.data?.data?.categories || []}
                                loading={categoriesLoading}
                                label="Category"
                                name="category_id"
                                mainClassName="mx-2 width-100"
                                clearErrors={clearErrors}
                                dependencyError={[
                                  "category_id",
                                  "sub_category_id",
                                ]}
                                placeholder={"Select category"}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  handleChangeMain(e);
                                }}
                                disabled={create ? false : true}
                                helperText={error?.message}
                                hideVariant={true}
                              />
                            )}
                            name="category_id"
                            control={control}
                          />
                          {getValues("sub_category_id")?.map((el, index) => (
                            <Controller
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <FormDropdownList
                                  data={el?.category_id?.child || []}
                                  loading={categoriesLoading}
                                  label={el?.category_id?.name?.en}
                                  mainClassName="mx-2 width-100"
                                  name={`sub_category_id.${index}.category_value_id`}
                                  placeholder={"Select sub category"}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    handleSelectChild(e, index);
                                  }}
                                  disabled={create ? false : true}
                                  helperText={error?.message}
                                  hideVariant={true}
                                />
                              )}
                              name={`sub_category_id.${index}.category_value_id`}
                              control={control}
                            />
                          ))}
                        </>
                      </div>
                      <div className="inputs-wrapper mx-8">
                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormMultiSelect
                              required
                              data={attributes?.data?.data || []}
                              loading={attributesLoading}
                              label="Attribute"
                              name="attribute_id"
                              clearErrors={clearErrors}
                              dependencyError={["attribute_id"]}
                              placeholder={"Select attribute"}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                setAttributeList(e);
                              }}
                              helperText={error?.message}
                              hideVariant={true}
                              mainClassName="mx-2 width-100"
                              linkTitle="Add New Variant"
                              msg="Kindly Choose a Category to activate the attributes."
                              handleAddLink={() => setOpen(true)}
                            />
                          )}
                          name="attribute_id"
                          control={control}
                        />
                        {attributeList &&
                          !enableBlock &&
                          attributeList
                            ?.sort((a, b) => a.id - b.id)
                            .map((attValues, index) => (
                              <Controller
                                key={attValues.id}
                                control={control}
                                name={`attribute_values_estimation_false.${index}`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <FormMultiSelect
                                    required
                                    data={
                                      returnValueOptions(attValues.id) || []
                                    }
                                    loading={attributesLoading}
                                    label={`${attValues?.name?.en} Attribute Values`}
                                    name={`attribute_values_estimation_false.${index}`}
                                    clearErrors={clearErrors}
                                    dependencyError={[
                                      `attribute_values_estimation_false.${index}`,
                                    ]}
                                    placeholder="Select Attribute Values"
                                    value={value}
                                    onChange={(selectedValues) => {
                                      onChange(selectedValues);
                                    }}
                                    helperText={error?.message}
                                    hideVariant={true}
                                    mainClassName="mx-2 width-100"
                                  />
                                )}
                              />
                            ))}

                        {attributeList &&
                          enableBlock &&
                          attributeList
                            ?.filter((val) => val.is_estimation_factor == false)
                            ?.sort((a, b) => a.id - b.id)
                            .map((attValues, index) => (
                              <Controller
                                key={attValues.id}
                                control={control}
                                name={`attribute_values_estimation_false.${index}`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <FormMultiSelect
                                    required
                                    data={
                                      returnValueOptions(attValues.id) || []
                                    }
                                    loading={attributesLoading}
                                    label={`${attValues?.name?.en} Attribute Values`}
                                    name={`attribute_values_estimation_false.${index}`}
                                    clearErrors={clearErrors}
                                    dependencyError={[
                                      `attribute_values_estimation_false.${index}`,
                                    ]}
                                    placeholder="Select Attribute Values"
                                    value={value}
                                    onChange={(selectedValues) => {
                                      onChange(selectedValues);
                                    }}
                                    helperText={error?.message}
                                    hideVariant={true}
                                    mainClassName="mx-2 width-100"
                                  />
                                )}
                              />
                            ))}
                        {/* false/true */}
                        {attributeList &&
                          enableBlock &&
                          attributeList
                            ?.filter((val) => val.is_estimation_factor == true)
                            ?.sort((a, b) => a.id - b.id)
                            .map((attValues, index) => (
                              <Controller
                                key={attValues.id}
                                control={control}
                                name={`attribute_values_id.${index}`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <FormMultiSelect
                                    required
                                    data={
                                      returnValueOptions(attValues?.id) || []
                                    }
                                    loading={attributesLoading}
                                    label={`${attValues?.name?.en} Attribute Values`}
                                    name={`attribute_values_id.${index}`}
                                    clearErrors={clearErrors}
                                    dependencyError={[
                                      `attribute_values_id.${index}`,
                                    ]}
                                    placeholder="Select Attribute Values"
                                    value={value}
                                    onChange={(selectedValues) => {
                                      onChange(selectedValues);
                                    }}
                                    helperText={error?.message}
                                    hideVariant={true}
                                    mainClassName="mx-2 width-100"
                                  />
                                )}
                              />
                            ))}
                      </div>
                    </div>
                  </>
                )}
                <div className="form-body">
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormTextArea
                        required
                        name="description"
                        label="Description"
                        placeholder={"Enter description"}
                        mainClassName="mx-2 width-100"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                          clearErrors("description");
                        }}
                        helperText={error?.message}
                        hideVariant={true}
                      />
                    )}
                    name="description"
                    control={control}
                  />
                </div>
                {tabvalue != "ar" && (
                  <div className="form-body">
                    <div className="form-input-wrapper mx-2 width-100">
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <div className="switch">
                            <label>Enable factors</label>
                            <Switch
                              checked={value}
                              onChange={(e) => onChange(e.target.checked)}
                            />
                          </div>
                        )}
                        name="enablefactors"
                        control={control}
                      />
                      <p style={{ fontSize: "11px" }}>
                        Enable this component only for products related to
                        buying or selling. For all other products, ensure it
                        stays disabled.
                      </p>
                    </div>
                  </div>
                )}
                {tabvalue == "ar" && (
                  <div className="action-footer">
                    <Button
                      name={create ? "Create" : "Save"}
                      selected
                      onClick={handleSubmit(handleSubmitForm)}
                      type="submit"
                      startIcon={<CollectionsBookmarkIcon />}
                      loading={saveLoading}
                      disabled={saveLoading || loadingData}
                    />
                  </div>
                )}
              </form>
            </div>
            {tabvalue != "ar" && (
              <div className="upload-body">
                <div className="filesSection">
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <MultipleCropper
                        sendCroppedFile={handleImages}
                        MAX_IMAGE_WIDTH={500}
                        MAX_IMAGE_HEIGHT={500}
                        sizeMsg={"500*500"}
                        message={error?.message}
                        error={error}
                        iwebp={false}
                      />
                    )}
                    name="imageList"
                    control={control}
                  />
                  <div className="files">
                    <ul className="fileList">
                      {existingImages &&
                        existingImages.map((image) => (
                          <li className="image" key={image.id}>
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                image.image_path
                              }
                            />
                            <button
                              type="button"
                              onClick={(e) => {
                                removeImage(image.id);
                                e.preventDefault();
                              }}
                            >
                              x
                            </button>
                          </li>
                        ))}
                    </ul>
                    <ul className="fileList">
                      {fileList &&
                        fileList.map((file, i) => (
                          <li className="image" key={i}>
                            <img src={URL.createObjectURL(file)} />
                            <button
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                removeFile(file);
                              }}
                            >
                              x
                            </button>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>

          {tabvalue != "ar" && (
            <div className="factors-div">
              {conditions && enableBlock && (
                <div className="factorsDiv">
                  <div className="mt-1">
                    <h5>
                      {"Condition Factors"}
                      <Tooltip
                        title="The condition factor will be multiplied by The sum of( base factor + attributes Factor) "
                        placement="right"
                      >
                        <InfoIcon
                          style={{ color: "#ff0000", margin: "0px 5px" }}
                        />
                      </Tooltip>
                    </h5>
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <EditableRow
                            data={conditionfactorsArray?.sort(
                              (a, b) => a.id - b.id
                            )}
                            create={create ? true : false}
                            title={"Condition"}
                            // add onclear
                            onClear={(val) => handleOnClear(val)}
                            onsave={(values) => {
                              handleConditionValues(values);
                              clearErrors("conditionList");
                            }}
                            editMode={conditioneditMode}
                            setEditMode={() =>
                              setconditioneditMode(!conditioneditMode)
                            }
                            hide={false}
                          />
                          {error && (
                            <p className="error-message">
                              All fields are required!
                            </p>
                          )}
                        </>
                      )}
                      name="conditionList"
                      control={control}
                    />
                  </div>

                  {getValues("attribute_values_id")?.map(
                    (selectedValue, index) => {
                      if (!selectedValue) {
                        return null;
                      }
                      return (
                        <div key={index}>
                          <div
                            className={
                              selectedValue?.length > 0 &&
                              selectedValue?.length < 3
                                ? "width-25 mt-1"
                                : selectedValue?.length > 2 &&
                                  selectedValue?.length < 4
                                ? "width-50 mt-1"
                                : "width-100 mt-1"
                            }
                          >
                            {selectedValue && (
                              <h5>
                                {returnValueName(
                                  selectedValue[0]?.attribute_id
                                ) +
                                  " " +
                                  "Factors"}
                                <Tooltip
                                  title={
                                    "The" +
                                    " " +
                                    returnValueName(
                                      selectedValue[0]?.attribute_id
                                    ) +
                                    " " +
                                    " factor will be added to the base Price. "
                                  }
                                  placement="right"
                                >
                                  <InfoIcon
                                    style={{
                                      color: "#ff0000",
                                      margin: "0px 5px",
                                    }}
                                  />
                                </Tooltip>
                              </h5>
                            )}
                            <Controller
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <>
                                  <EditableRow
                                    data={selectedValue}
                                    create={create ? true : false}
                                    title={
                                      selectedValue
                                        ? returnValueName(
                                            selectedValue[0]?.attribute_id
                                          )
                                        : ""
                                    }
                                    //add onclear
                                    onClear={(val) =>
                                      setconditionfactorsArray(val)
                                    }
                                    onsave={(values) => {
                                      handleAttributeValue(values);
                                      clearErrors(
                                        `attributeTableList.${index}`
                                      );
                                    }}
                                    editMode={!!editModes[index]}
                                    setEditMode={() => toggleEditMode(index)}
                                    hide={false}
                                  />
                                  {error && (
                                    <p className="error-message">
                                      All fields are required!
                                    </p>
                                  )}
                                </>
                              )}
                              name={`attributeTableList.${index}`}
                              control={control}
                            />
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              )}

              <div className="action-footer">
                {enableBlock && (
                  <Button
                    name={"Validate"}
                    type="submit"
                    className="mx-1"
                    onClick={handleValidate}
                    startIcon={<CheckCircleIcon />}
                    loading={validateValues}
                    disabled={validateValues}
                  />
                )}
                <Button
                  name={create ? "Create" : "Save"}
                  selected
                  onClick={handleSubmit(handleSubmitForm)}
                  type="submit"
                  startIcon={<CollectionsBookmarkIcon />}
                  loading={saveLoading}
                  disabled={saveLoading || loadingData}
                />
              </div>
            </div>
          )}
        </Paper>
      ) : (
        <Paper elevation={24} className="paper-div"></Paper>
      )}

      <Dialog
        fdivlWidth="true"
        maxWidth={"lg"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Add New Variant</DialogTitle>
        <DialogContent>
          <AttributeDetails
            onsuccess={handleRefetecvalues}
            onModelPage={true}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      <Dialog
        fdivlWidth="true"
        maxWidth={"lg"}
        open={openValidatePrices}
        onClose={handleClosePrices}
      >
        <DialogTitle>{getValues("name") || "Prices"}</DialogTitle>
        <Divider />
        <DialogContent>
          <ProductTable productData={receivedPrices} />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            name={"Close"}
            className="mx-1"
            onClick={handleClosePrices}
            endIcon={<CheckCircleIcon />}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProductDetails;
