//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../context/AlertContext";
import { useNavigate, useParams } from "react-router";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import palette from "../../theme/color.scss";
//COMPONENT
import SimpleMap from "../../components/maps/Maps";
import Button from "../../components/button/Button";
import { Card, CardContent, Skeleton, IconButton } from "@mui/material";
import {
  FormDropdownList,
  FormInput,
  FormMultiSelect,
  FormSwitch,
  UploadComponent,
} from "../../components/formComponents/FormComponents";
import OrganizationsBranches from "./OrganizationsBranches";
import TitleComponent from "../../components/titleComponent/TitleComponent";
import { useGet } from "../../hooks/useFetch";
import {
  CreateOrganization,
  EditOrganization,
  getOrganizationById,
} from "../../core/apis/organization";
import { CloseSVG } from "../../assests/icons/SVG";
import { defaultLat, defaultLong } from "../../core/constants/constant";
//CSS
import "./Organizations.scss";
import OrganizationsCountries from "./OrganizationsCountries";
import {
  getAllPiceaWorkFlow,
  updatePiceaOraganization,
} from "../../core/apis/Picea";
import { getFormData } from "../../core/functions/Functions";

const HandleSchema = yup.object({
  name: yup.string().required("Field is required"),
  description: yup.string(),
  factor: yup
    .number()
    .nullable()
    .required("Field is required")
    .positive("Field should be positive"),

  address: yup.object().shape({
    address_line: yup.string().required("Field is required"),
    country_id: yup.object().nullable().required("Field is required"),
    city_name: yup.string().required("Field is required"),
    latitude: yup.number().nullable(),
    longitude: yup.number().nullable(),
    postal_code: yup.string(),
    location_message: yup
      .string()
      .nullable()
      .when(["latitude", "longitude"], {
        is: (latitudeValue, longitudeValue) =>
          latitudeValue === null || longitudeValue === null,
        then: yup.string().nullable().required("Field is required"),
        otherwise: yup.string().nullable(),
      }),
  }),
  countries_factor: yup
    .array()
    .min(1, "Field is required")
    .required("Field is required"),
  country: yup.array().of(
    yup.object().shape({
      country_name: yup.string(),
      country_id: yup.number().nullable().required("Field is required"),
      exception_factor: yup
        .number()
        .nullable()
        .positive("Field should be positive")
        .required("Field is required"),
    })
  ),
  picea_enabled: yup.bool(),
  two_use_enabled: yup.bool(),
  employee_enabled: yup.bool(),
  piceaBuyBack_workflow_id: yup.object().nullable(),
  piceaWipe_workflow_id: yup.object().nullable(),
  twoUseBuyBack_workflow_id: yup.object().nullable(),
  twoUseWipe_workflow_id: yup.object().nullable(),
  twouseVerify_workflow_id: yup.object().nullable(),
  image: yup.mixed().required("Field is Required"),
  backgroundImage: yup.mixed().required("Field is Required"),
});

const OrganizationHandle = (props) => {
  const { id } = useParams();
  let navigate = useNavigate();
  const { type } = props;
  const { setAlert } = useContext(AlertContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const [countriesArray, setCountriesArray] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [organizationData, setOrganizationData] = useState(null);
  const [workflow, setworkflow] = useState([]);
  const [wipeWorkflow, setwipeWorkflow] = useState([]);
  const [VerifyWorkflow, setVerifyWorkflow] = useState([]);
  const [showWorkFlow, setshowWorkFlow] = useState(false);
  const [showTwouseWorkFlow, setshowTwouseWorkFlow] = useState(false);
  const [PrePicea, setPrePicea] = useState();
  const [PreWorkFlow, setPreWorkFlow] = useState();

  //API
  const { data: countries, countryloading } = useGet({
    url: "get-all-countries",
    payload: null,
  });

  let countryArray = countries?.data?.data;

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });
  const {
    fields: countryFields,
    remove: countryRemove,
    append: countryAppend,
    prepend: countryPrepend,
  } = useFieldArray({ control, name: "country" });

  //FUNCTIONS

  const buildFormData = (formData) => {
    const valuesData = new FormData();

    valuesData.append("name", formData.name);
    valuesData.append("description", formData.description);
    valuesData.append("factor", formData.factor);
    valuesData.append("picea_enabled", formData.picea_enabled ? 1 : 0);
    valuesData.append("two_use_enabled", formData.two_use_enabled ? 1 : 0);
    valuesData.append("logo", formData.image);
    valuesData.append("background", formData.backgroundImage);
    valuesData.append(
      "enable_fill_form_employee",
      formData.employee_enabled ? 1 : 0
    );

    const workflowsArray = [
      {
        picea_workflow_id: formData.twoUseWipe_workflow_id?.id,
        workflow_type_id: 1,
      },
      {
        picea_workflow_id: formData.twouseVerify_workflow_id?.id,
        workflow_type_id: 1,
      },
      {
        picea_workflow_id: formData.piceaBuyBack_workflow_id?.id,
        workflow_type_id: 2,
      },
      {
        picea_workflow_id: formData.piceaWipe_workflow_id?.id,
        workflow_type_id: 2,
      },
    ];

    workflowsArray.forEach((workflow, index) => {
      if (
        workflow.picea_workflow_id !== null &&
        workflow.picea_workflow_id !== undefined
      ) {
        valuesData.append(
          `workflows[${index}][picea_workflow_id]`,
          workflow.picea_workflow_id
        );
        valuesData.append(
          `workflows[${index}][workflow_type_id]`,
          workflow.workflow_type_id
        );
      }
    });

    appendAddressData(valuesData, formData.address);

    formData.country?.forEach((country, index) => {
      appendCountryData(valuesData, country, index);
    });

    return valuesData;
  };

  const appendAddressData = (formData, address) => {
    Object.keys(address).forEach((key) => {
      const value = address[key];
      if (value !== null && value !== undefined) {
        formData.append(`address[${key}]`, value);
      }
    });

    const countryId = address.country_id?.id;
    if (countryId !== null && countryId !== undefined) {
      formData.append("address[country_id]", countryId);
    }
  };

  const appendCountryData = (formData, country, index) => {
    Object.keys(country).forEach((key) => {
      const value = country[key];
      if (value !== null && value !== undefined && key !== "country_name") {
        formData.append(`country[${index}][${key}]`, value);
      }
    });
  };

  const handleSubmitForm = (formData) => {
    const valuesData = buildFormData(formData);

    setSaveLoading(true);
    if (type === "edit") {
      handleUpdatePicea(formData);

      if (formData?.image instanceof File === false) {
        valuesData.delete("logo");
      }
      if (formData?.backgroundImage instanceof File === false) {
        valuesData.delete("background");
      }

      EditOrganization(id, valuesData).then((res) => {
        setAlert({
          visible: true,
          text: res.data ? res.data.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
        if (res.data?.success) {
          navigate("/organizations");
        }
        setSaveLoading(false);
      });
    } else {
      CreateOrganization(valuesData).then((res) => {
        setAlert({
          visible: true,
          text: res.data ? res.data.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
        if (res.data?.success) {
          navigate("/organizations");
        }
        setSaveLoading(false);
      });
    }
  };

  const handleAddCountryFactor = (value) => {
    let array = [];
    value.reverse().map((item) => {
      let checkAvailable = getValues("country").find(
        (val) => val.country_id === item.id
      );

      if (checkAvailable) {
        array.push(checkAvailable);
      } else {
        array.push({
          country_id: item?.id,
          country_name: item?.name,
          exception_factor: null,
        });
      }
    });

    countryRemove();

    array.map((val) => {
      countryAppend(val);
    });
  };

  const handleRemoveCountryFactor = (element, elementIndex) => {
    countryRemove(elementIndex);
    let oldCountryArray = [...getValues("countries_factor")];

    let newArray = oldCountryArray.filter(
      (el) => el?.id !== element?.country_id
    );

    setValue("countries_factor", newArray, { shouldValidate: true });
  };

  const getOrganizationData = () => {
    setLoadingData(true);
    getOrganizationById(id).then((res) => {
      if (res?.data?.success) {
        setOrganizationData(res?.data?.data || null);
        setPrePicea(res?.data?.data?.picea_enabled);
        setPreWorkFlow(res?.data?.data?.workflows);
        if (res.data.data.picea_enabled) {
          setshowWorkFlow(true);
        }
        if (res.data.data.two_use_enabled) {
          setshowTwouseWorkFlow(true);
        }
        const flows = res.data.data.workflows;

        const filterByWorkflowType = (workflowTypeId, flowType) =>
          flows.filter(
            (item) =>
              item.picea_workflow_type.id === workflowTypeId &&
              item.pivot.workflow_type_id == flowType
          );

        const twoUsefilteredWipeData = filterByWorkflowType(1, 1);
        const twoUsefilteredVerifyData = filterByWorkflowType(6, 1);
        const piceafilteredWipeData = filterByWorkflowType(1, 2);
        const piceafilteredRetailerData = filterByWorkflowType(2, 2);

        reset({
          display_name: res?.data?.data?.name?.en || "",
          name: res?.data?.data?.name?.en || "",
          description: res?.data?.data?.description?.en || "",
          factor: res?.data?.data?.factor || null,
          picea_enabled: res?.data?.data?.picea_enabled,
          picea_workflow_id: res.data.data.workflows,
          two_use_enabled: res?.data?.data.two_use_enabled,
          piceaBuyBack_workflow_id: piceafilteredRetailerData[0],
          piceaWipe_workflow_id: piceafilteredWipeData[0],
          twoUseWipe_workflow_id: twoUsefilteredWipeData[0],
          twouseVerify_workflow_id: twoUsefilteredVerifyData[0],
          employee_enabled: res.data.data.enable_fill_form_employee,
          address: {
            address_id: res?.data?.data?.address?.[0]?.id || null,
            address_line: res?.data?.data?.address?.[0]?.address_line || "",
            country_id: res?.data?.data?.address?.[0]?.country || null,
            city_name: res?.data?.data?.address?.[0]?.city_name || "",
            latitude: res?.data?.data?.address?.[0]?.latitude || defaultLat,
            longitude: res?.data?.data?.address?.[0]?.longitude || defaultLong,
            postal_code: res?.data?.data?.address?.[0]?.postal_code || "",
            location_message: "",
          },
          image: res?.data?.data?.logo || null,
          backgroundImage: res?.data?.data?.background || null,

          countries_factor: [],
          country: [],
        });
      } else {
        setAlert({
          visible: true,
          text: res.data ? res.data.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
      }
      setLoadingData(false);
    });
  };

  useEffect(() => {
    if (countryArray) {
      setCountriesArray(
        organizationData?.country
          ? countryArray?.filter(
              (ar) => !organizationData?.country?.find((rm) => rm.id === ar.id)
            )
          : countryArray
      );
    }
    getAllPiceaWorkFlow().then((res) => {
      const data = res.data?.data || [];

      const filterByWorkflowType = (workflowTypeId) =>
        data.filter((item) => item.picea_workflow_type.id === workflowTypeId);

      const filteredWipeData = filterByWorkflowType(1);
      const filteredRetailerData = filterByWorkflowType(2);
      const filteredVerifyData = filterByWorkflowType(6);

      setVerifyWorkflow(filteredVerifyData);
      setwipeWorkflow(filteredWipeData);
      setworkflow(filteredRetailerData);
    });

    if (type === "edit") {
      getOrganizationData();
    } else {
      reset({
        name: "",
        description: "",
        factor: null,

        address: {
          address_id: null,
          address_line: "",
          country_id: null,
          city_name: "",
          latitude: null,
          longitude: null,
          postal_code: "",
          location_message: "",
        },
        two_use_enabled: false,
        picea_enabled: false,
        employee_enabled: false,
        countries_factor: [],
        country: [],
        image: "",
        backgroundImage: "",
      });
    }
  }, [countries]);

  const handleUpdatePicea = (formData) => {
    let workflowsArrays = [];
    if (formData.two_use_enabled) {
      if (formData.twoUseWipe_workflow_id) {
        workflowsArrays.push({
          picea_workflow_id: formData.twoUseWipe_workflow_id?.id,
          workflow_type_id: 1,
        });
      }
      if (formData.twouseVerify_workflow_id) {
        workflowsArrays.push({
          picea_workflow_id: formData.twouseVerify_workflow_id?.id,
          workflow_type_id: 1,
        });
      }
    }
    if (formData.picea_enabled) {
      if (formData.piceaBuyBack_workflow_id) {
        workflowsArrays.push({
          picea_workflow_id: formData.piceaBuyBack_workflow_id?.id,
          workflow_type_id: 2,
        });
      }
      if (formData.piceaWipe_workflow_id) {
        workflowsArrays.push({
          picea_workflow_id: formData.piceaWipe_workflow_id?.id,
          workflow_type_id: 2,
        });
      }
    }
    let workflows = workflowsArrays;
    updatePiceaOraganization(id, { workflows }).then((res) =>
      console.log(workflowsArrays)
    );
  };
  return (
    <>
      <h1>
        {type === "edit" ? getValues("display_name") : "New Organization"}
      </h1>
      <div className="organization-handle-component">
        <div
          className={`organization-handle-form-section ${
            type === "new" ? "full-view" : "mid-view"
          }`}
        >
          <Card>
            <CardContent>
              {!loadingData ? (
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                  <TitleComponent
                    title="General Information"
                    color={palette.primaryColor}
                  />
                  <div
                    className={
                      type === "new"
                        ? "form-double-children"
                        : "form-single-children"
                    }
                  >
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <FormInput
                          required
                          name="name"
                          label="Organization"
                          placeholder={"Enter name"}
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          helperText={errors?.name?.message}
                        />
                      )}
                      name="name"
                      control={control}
                    />
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <FormInput
                          label="Description"
                          placeholder={"Enter description"}
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          helperText={errors?.description?.message}
                        />
                      )}
                      name="description"
                      control={control}
                    />
                    <div className="upload-section mb-4">
                      <TitleComponent
                        title="Upload Logo"
                        color={palette.primaryColor}
                      />
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <UploadComponent
                            value={value}
                            name="image"
                            setFieldValue={(field, value) => onChange(value)}
                            helperText={error?.message}
                          />
                        )}
                        name="image"
                        control={control}
                      />
                    </div>
                    <div className="upload-section mb-4">
                      <TitleComponent
                        title="Upload Background Image"
                        color={palette.primaryColor}
                      />
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <UploadComponent
                            value={value}
                            name="backgroundImage"
                            setFieldValue={(field, value) => onChange(value)}
                            helperText={error?.message}
                          />
                        )}
                        name="backgroundImage"
                        control={control}
                      />
                    </div>

                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <FormInput
                          required
                          type="number"
                          label="Factor"
                          name="factor"
                          placeholder={"Enter Factor"}
                          value={value}
                          onChange={(e) =>
                            onChange(
                              e.target.value === "" ? null : e.target.value
                            )
                          }
                          helperText={errors?.factor?.message}
                        />
                      )}
                      name="factor"
                      control={control}
                    />
                    <div className="mt-4">
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormSwitch
                            label="Enabled Employee Details on checkout form"
                            name="employee_enabled"
                            value={value}
                            onChange={(e) => {
                              onChange(e?.target?.checked);
                            }}
                          />
                        )}
                        name="employee_enabled"
                        control={control}
                      />
                    </div>
                  </div>

                  <TitleComponent
                    title="Manage all WorkFlows"
                    color={palette.primaryColor}
                  />
                  <div
                    className={
                      type === "new"
                        ? "form-double-children"
                        : "form-single-children"
                    }
                  >
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "space-between",
                        flexDirection: "column",
                      }}
                    >
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormSwitch
                            label="Enable 2use WorkFlow"
                            name="two_use_enabled"
                            value={value}
                            onChange={(e) => {
                              onChange(e?.target?.checked);
                              setshowTwouseWorkFlow(!showTwouseWorkFlow);
                            }}
                          />
                        )}
                        name="two_use_enabled"
                        control={control}
                      />

                      {showTwouseWorkFlow ? (
                        <>
                          <Controller
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <FormDropdownList
                                label="2use Verify WorkFlow"
                                name="twouseVerify_workflow_id"
                                placeholder={"Select 2use WorkFlow"}
                                data={VerifyWorkflow || []}
                                loading={countryloading}
                                value={value}
                                onChange={(value) => {
                                  onChange(value);
                                }}
                                helperText={
                                  errors?.twouseVerify_workflow_id?.message
                                }
                              />
                            )}
                            name="twouseVerify_workflow_id"
                            control={control}
                          />

                          <Controller
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <FormDropdownList
                                label="2use Wipe WorkFlow"
                                name="twoUseWipe_workflow_id"
                                placeholder={"Select 2use WorkFlow"}
                                data={wipeWorkflow || []}
                                loading={countryloading}
                                value={value}
                                onChange={(value) => {
                                  onChange(value);
                                }}
                                helperText={
                                  errors?.twoUseWipe_workflow_id?.message
                                }
                              />
                            )}
                            name="twoUseWipe_workflow_id"
                            control={control}
                          />
                        </>
                      ) : (
                        " "
                      )}
                    </div>

                    <div>
                      <div
                        className="d-flex"
                        style={{
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormSwitch
                              label="Enable Picea Flow"
                              name="picea_enabled"
                              value={value}
                              onChange={(e) => {
                                onChange(e?.target?.checked);
                                setshowWorkFlow(!showWorkFlow);
                              }}
                            />
                          )}
                          name="picea_enabled"
                          control={control}
                        />
                        {showWorkFlow ? (
                          <>
                            <Controller
                              render={({ field: { onChange, value } }) => (
                                <FormDropdownList
                                  required
                                  data={workflow || []}
                                  label="Picea BuyBack Work Flow"
                                  name="piceaBuyBack_workflow_id"
                                  placeholder={"Select WorkFlow"}
                                  value={value}
                                  onChange={(value) => {
                                    onChange(value);
                                  }}
                                  helperText={
                                    errors?.piceaBuyBack_workflow_id?.message
                                  }
                                />
                              )}
                              name={"piceaBuyBack_workflow_id"}
                              control={control}
                            />

                            <Controller
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <FormDropdownList
                                  label="Picea Wipe WorkFlow"
                                  name="piceaWipe_workflow_id"
                                  placeholder={"Select WorkFlow"}
                                  data={wipeWorkflow || []}
                                  loading={countryloading}
                                  value={value}
                                  onChange={(value) => {
                                    onChange(value);
                                  }}
                                  helperText={
                                    errors?.piceaWipe_workflow_id?.message
                                  }
                                />
                              )}
                              name="piceaWipe_workflow_id"
                              control={control}
                            />
                          </>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </div>

                  <TitleComponent
                    title="Address Information"
                    color={palette.primaryColor}
                  />
                  <div
                    className={
                      type === "new"
                        ? "form-double-children"
                        : "form-single-children"
                    }
                  >
                    <div>
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormInput
                            required
                            label="Address"
                            placeholder={"Enter address"}
                            name="address.address_line"
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            helperText={error?.message}
                          />
                        )}
                        name="address.address_line"
                        control={control}
                      />
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormDropdownList
                            required
                            data={countryArray || []}
                            loading={countryloading}
                            label="Country"
                            name="address.country_id"
                            placeholder={"Select country"}
                            value={value}
                            onChange={(e) => onChange(e)}
                            helperText={error?.message}
                          />
                        )}
                        name="address.country_id"
                        control={control}
                      />
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormInput
                            required
                            label="City"
                            name="address.city_name"
                            placeholder={"Enter city"}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            helperText={error?.message}
                          />
                        )}
                        name="address.city_name"
                        control={control}
                      />
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormInput
                            name="address.postal_code"
                            label="Postal Code"
                            placeholder={"Enter code"}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            helperText={error?.message}
                          />
                        )}
                        name="address.postal_code"
                        control={control}
                      />
                    </div>
                    <div className={"map-height"}>
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <SimpleMap
                            coordination={{
                              lat: getValues("address.latitude"),
                              lng: getValues("address.longitude"),
                            }}
                            draggable={true}
                            zoom={8}
                            onClick={(e) => {
                              setValue("address.latitude", e.lat, {
                                shouldValidate: true,
                              });
                              setValue("address.longitude", e.lng, {
                                shouldValidate: true,
                              });
                              clearErrors(["address.location_message"]);
                            }}
                            helperText={
                              errors?.address?.location_message?.message
                            }
                          />
                        )}
                        name="address.latitude"
                        control={control}
                      />
                    </div>
                  </div>

                  <TitleComponent
                    title="Exceptions Factor"
                    color={palette.primaryColor}
                  />
                  <div
                    className={
                      type === "new"
                        ? "form-double-children"
                        : "form-single-children"
                    }
                  >
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <FormMultiSelect
                          required
                          data={countriesArray || []} //remove duplicate from merging two arrays : because we want countries that are not fixed}
                          label="Country"
                          name="countries_factor"
                          placeholder={"Select country"}
                          value={value}
                          onChange={(value) => {
                            onChange(value);
                            handleAddCountryFactor(value);
                          }}
                          helperText={errors?.countries_factor?.message}
                        />
                      )}
                      name={"countries_factor"}
                      control={control}
                    />
                  </div>
                  <div
                    className={`scrollable-ui-country-factor ${
                      type === "edit" ? "full-width" : ""
                    }`}
                  >
                    {getValues("country")?.map((element, index) => (
                      <div className="country-factor-dynamic-form">
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <FormInput
                              type="number"
                              name={`country.${index}.exception_factor`}
                              label={`${element.country_name} Factor`}
                              placeholder={"Enter factor"}
                              value={value}
                              onChange={(e) =>
                                onChange(
                                  e.target.value === "" ? null : e.target.value
                                )
                              }
                              helperText={
                                errors?.country?.[index]?.exception_factor
                                  ?.message
                              }
                            />
                          )}
                          name={`country.${index}.exception_factor`}
                          control={control}
                        />

                        <div className="remove-section">
                          <IconButton
                            aria-label="close"
                            onClick={() =>
                              handleRemoveCountryFactor(element, index)
                            }
                          >
                            <CloseSVG fill="#A1ADBD" />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="action-footer">
                    <Button
                      name={type === "edit" ? "Save" : "Create"}
                      selected
                      width={"30%"}
                      loading={saveLoading}
                      type="submit"
                    />
                  </div>
                </form>
              ) : (
                <Skeleton count={5} />
              )}
            </CardContent>
          </Card>
        </div>
        {type === "edit" && (
          <div className="organizations-handle-side-section">
            <OrganizationsCountries
              data={organizationData?.country}
              refetchData={getOrganizationData}
            />
            <OrganizationsBranches organizationId={id} />
          </div>
        )}
      </div>
    </>
  );
};

export default OrganizationHandle;
