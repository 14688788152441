import React, { useState } from "react";
import { Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./becomeaPartner.scss";
import ContactForm from "./contactForm";
import InformationSection from "./informationSection";

const BecomeAPartner = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="becomeaPartner">
      <TabPanel value="5" style={{ minHeight: "600px", padding: "0px", marginTop: "10px" }}>
        <TabContext value={tabValue}>
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label="Become a Partner Info Section" value="0" />
            <Tab label="Let’s Collaborate Form" value="1" />
          </TabList>

          <TabPanel value="0" style={{ minHeight: "600px", padding: "0px" , marginTop: "10px"}}>
            <InformationSection></InformationSection>
          </TabPanel>
          <TabPanel value="1" style={{ minHeight: "600px", padding: "0px" , marginTop: "10px"}}>
            <ContactForm></ContactForm>
          </TabPanel>
        </TabContext>
      </TabPanel>
    </div>
  );
};

export default BecomeAPartner;
