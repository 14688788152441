import React, { useContext, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Divider, Grid, Skeleton } from "@mui/material";
import dayjs from "dayjs";
//api
import {
  createBanner,
  getBannerByID,
  updateBanner,
} from "../../../core/apis/store";

//Components
import Cropper from "../../../components/singleUpload/imageCropper";
import {
  FormInput,
  FormTextArea,
  FormDropdownList,
  FormCalendarInput,
} from "../../../components/formComponents/FormComponents";
import Button from "../../../components/button/Button";
import { ColorPicker } from "../../../components/colorPicker/colorpicker";

//Context
import { AlertContext } from "../../../context/AlertContext";
import { useGlobalValues } from "../../../context/GlobalContext";

import { useGet } from "../../../hooks/useFetch";
import { Switch } from "@mui/material";
import { FormCheckboxDropdown } from "../../../components/formComponents/FormComponents";
import { blockStatues } from "../../../core/constants/constant";

const AddBanners = (props) => {
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [buttonColor, setButtonColor] = useState("#041C3F");
  const { onCancel, onSave, ID } = props;
  const [headerimageVal, setHeaderImageValue] = useState(null);
  const [mobileimageVal, setMobileImageValue] = useState(null);

  const { setAlert } = useContext(AlertContext);
  const { storeLanguage, enabledLanguages } = useGlobalValues();
  const [loading, setLoading] = useState(false);
  const [positionEditValue, setpositionEditValue] = useState("");

  const [isTOP, setIsTOP] = useState("");

  const bannersSchema = yup.object({
    bannerName: yup.string().nullable().required("Field is Required"),
    backgroundImage: yup.mixed().nullable().required("Web Image is Required"),
    mobile_background_image: yup
      .mixed()
      .nullable()
      .required("Mobile Image is Required"),
    title: yup
      .string()
      .nullable()
      .max(20, " title must be at most 20 characters"),
    title2: yup
      .string()
      .nullable()
      .max(40, " title must be at most 40 characters"),
    description: yup
      .string()
      .nullable()
      .max(150, " description must be at most 150 characters"),
    enablenewTab: yup.bool().nullable(),
    enableBlock: yup.bool().nullable().required("Field is Required"),
    buttonLink: yup.string().nullable(),
    buttonText: yup.string().nullable(),
    position_id: yup.object().nullable().required("Field is Required"),
    bannerstatus_id: yup.object().nullable().required("Field is Required"),
    countryIDs: yup.array().nullable().required("Field is Required"),
    start_date: yup.string().nullable().required("Field is Required"),
    end_date: yup.date().required("Field is Required"),
    select_all: yup.bool().nullable(),
    lang_code: yup.object().nullable().required("Field is Required"),
  });

  // Default values for the form
  const defaultValues = {
    bannerName: "",
    title: "",
    title2: "",
    description: "",
    backgroundImage: "",
    mobile_background_image: "",
    countryIDs: [],
    enablenewTab: true,
    enableBlock: true,
    bannerstatus_id: {
      id: 1,
      value: "active",
      name: "published",
    },
    position_id: "",
    buttonLink: "",
    buttonText: "",
    start_date: "",
    end_date: "",
    select_all: false,
    lang_code: {
      id: 40,
      name: "English",
      code: "en",
      is_enabled: true,
    },
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(bannersSchema),
  });

  //GET THE DATA
  const { data: countries, countriesLoading } = useGet({
    url: `/get-all-countries`,
  });

  let countriesArray = countries?.data?.data || [];

  //Get all banner positions
  let url = "admin/banner-positions";
  const { data: bannerPositions, bannerpositionsLoading } = useGet({
    url: url,
  });

  // Format banner positions data for the dropdown

  const bannerpositionsArray = bannerPositions?.data?.data
    ?.filter((item) => item == "TOP" || item == "RIGHT")
    ?.map((item, index) => ({
      id: index + 1,
      name: item,
    }));

  const handleSubmitForm = async (values) => {
    let formData = new FormData();
    setLoading(true);

    // change start date and enddat format
    let startDate = dayjs(values?.start_date).format("YYYY-MM-DD");
    let endDate = dayjs(values?.end_date).format("YYYY-MM-DD");

    // Append form data to send to the server
    formData.append("name", values?.bannerName);
    formData.append("title_one", values?.title);
    formData.append("title_two", values?.title2);
    formData.append("description", values?.description);
    formData.append("is_button_enabled", values?.enableBlock ? 1 : 0);
    formData.append("is_new_tab", values?.enablenewTab ? 1 : 0);
    formData.append(
      "button_text",
      values?.buttonText ? values.buttonText : "Tech better with us"
    );
    formData.append(
      "button_link",
      values?.buttonLink ? values.buttonLink : "no link"
    );
    formData.append("button_color", buttonColor);
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    formData.append("position", values?.position_id.name);
    formData.append("is_active", values.bannerstatus_id.id === 1 ? 1 : 0);
    formData.append("select_all_countries", values.select_all);

    // Only append country_ids if select_all is false
    if (!values.select_all) {
      // Remove duplicates from countryIDs
      const uniqueCountryIDs = [...new Set(values.countryIDs)];

      // Append unique country IDs to formData
      uniqueCountryIDs.forEach((item, index) =>
        formData.append(`country_ids[${index}]`, item)
      );
    }
    // Handle background images (only append if they are File objects)
    if (values?.backgroundImage instanceof File) {
      formData.append("background_image", values?.backgroundImage);
    } else {
      formData.delete("background_image");
    }

    if (values?.mobile_background_image instanceof File) {
      formData.append(
        "mobile_background_image",
        values?.mobile_background_image
      );
    } else {
      formData.delete("mobile_background_image");
    }

    if (ID) {
      updateBanner(ID, formData).then((res) => {
        onSave();
        setAlert({
          visible: true,
          text: res?.data.success ? "Successfully Updated" : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
        setLoading(false);
      });
    } else {
      formData.append(
        "language_code",
        values?.lang_code?.code ? values?.lang_code?.code : "en"
      );
      createBanner(formData).then((res) => {
        onSave();
        setAlert({
          visible: true,
          text: res?.data.success ? "Successfully Created" : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
        setLoading(false);
      });
    }
  };

  const handleHeaderImage = (croppedImage) => {
    setHeaderImageValue(croppedImage);
    setValue("backgroundImage", croppedImage);
  };

  const handleMobileImage = (croppedImage) => {
    setMobileImageValue(croppedImage);
    setValue("mobile_background_image", croppedImage);
  };

  const handleCancel = () => {
    onCancel();
  };

  // Fetch data and populate form if ID is provided
  useEffect(() => {
    if (ID) {
      setIsFetchingData(true); // Start fetching data
      getBannerByID(ID).then((res) => {
        if (res?.data?.success) {
          const bannerData = res?.data?.data;
          const populatedFormData = {
            bannerName: bannerData?.name,
            title: bannerData?.title_one ? bannerData?.title_one : "title_one",
            title2: bannerData?.title_two ? bannerData?.title_two : "title_one",
            buttonColor: bannerData?.button_color,
            description: bannerData?.description
              ? bannerData?.description
              : "title_one",
            countryIDs: bannerData?.country,
            backgroundImage: bannerData?.background_image,
            mobile_background_image: bannerData?.mobile_image,
            enablenewTab: bannerData?.is_new_tab,
            enableBlock: bannerData?.is_button_enabled,
            position_id: bannerData?.position,
            buttonLink: bannerData?.button_link,
            buttonText: bannerData?.button_text,
            active: bannerData?.is_active,
            start_date: bannerData?.start_date,
            end_date: bannerData?.end_date,
          };

          // Populate country IDs from fetched data
          let countriesSelected =
            populatedFormData.countryIDs?.map((i) => i?.id) || [];

          setpositionEditValue(populatedFormData?.position_id);

          setValue("bannerName", populatedFormData?.bannerName);
          setValue("countryIDs", countriesSelected);
          setValue("backgroundImage", populatedFormData?.backgroundImage);
          setValue(
            "mobile_background_image",
            populatedFormData?.mobile_background_image
          );

          setValue("active", populatedFormData?.active);

          setValue("start_date", populatedFormData?.start_date);
          setValue("end_date", populatedFormData?.end_date);

          setValue("title", populatedFormData?.title);
          setValue("title2", populatedFormData?.title2);
          setValue("description", populatedFormData?.description);

          setValue("enablenewTab", populatedFormData?.enablenewTab);
          setValue("enableBlock", populatedFormData?.enableBlock);

          setValue("buttonColor", populatedFormData?.buttonColor);
          setValue("buttonText", populatedFormData?.buttonText);
          setValue("buttonLink", populatedFormData?.buttonLink);
        }

        setIsFetchingData(false); // Stop fetching data
      });
    } else {
      setIsFetchingData(false); // If there's no ID, stop loading immediately
    }
  }, [ID]);

  useEffect(() => {
    if (ID) {
      if (bannerpositionsArray) {
        let positionID = bannerpositionsArray?.filter(
          (item) => item.name === positionEditValue
        );
        setValue("position_id", positionID[0]);
      }
    }
  }, [bannerpositionsArray]);

  const enableBlock = watch("enableBlock", true);
  const enableBanner = watch("position_id", true);

  return (
    <div className="addBanners">
      <div className="store-title">{ID ? "Edit Banner" : "New Banner"}</div>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            {isFetchingData ? (
              <Skeleton variant="text" width="100%" height={80} />
            ) : (
              <Controller
                name="position_id"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormDropdownList
                    data={bannerpositionsArray}
                    loading={bannerpositionsArray ? false : true}
                    label="Position"
                    name="position_id"
                    mainClassName="width-100"
                    placeholder="Select Position"
                    value={value}
                    required
                    onChange={(e) => {
                      onChange(e);
                      setIsTOP(e.value === "TOP");
                    }}
                    hideVariant={true}
                    error={error?.message}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            {isFetchingData ? (
              <Skeleton variant="text" width="100%" height={80} />
            ) : (
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormInput
                    name="bannerName"
                    label="Banner Name "
                    hideVariant
                    placeholder={"Enter Banner Name"}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    helperText={error?.message}
                  />
                )}
                name="bannerName"
                control={control}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            {!ID && (
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormDropdownList
                    required
                    data={enabledLanguages || []}
                    label="Add Banner In"
                    name="lang_code"
                    placeholder={"Select language"}
                    value={value}
                    hideVariant
                    accessValue="name"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    helperText={error?.message}
                  />
                )}
                name="lang_code"
                control={control}
              />
            )}
          </Grid>
        </Grid>
        {enableBanner.name === "TOP" && (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <div>
                        <Cropper
                          sendCroppedFile={handleHeaderImage}
                          MAX_IMAGE_WIDTH={1200}
                          MAX_IMAGE_HEIGHT={280}
                          title={`Drag your Image here only webp 1200 * 280 `}
                          className="width100"
                          isTrue={true}
                          iwebp={true}
                          imageSrc={getValues("backgroundImage")}
                        />

                        {!ID && headerimageVal === null && (
                          <p className="error">{"Field Is Required"}</p>
                        )}
                      </div>
                    )}
                    name="backgroundImage"
                    control={control}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <div>
                        <Cropper
                          sendCroppedFile={handleMobileImage}
                          MAX_IMAGE_WIDTH={706}
                          MAX_IMAGE_HEIGHT={320}
                          title={
                            "Drag your Mobile Image here only webp 706 * 320"
                          }
                          className="width100"
                          isTrue={true}
                          iwebp={true}
                          imageSrc={getValues("mobile_background_image")}
                        />

                        {!ID && mobileimageVal === null && (
                          <p className="error">{"Field Is Required"}</p>
                        )}
                      </div>
                    )}
                    name="mobile_background_image"
                    control={control}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={8}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        name="title"
                        label="Title"
                        hideVariant
                        placeholder={
                          "Enter title (title must be at most 20 characters)"
                        }
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name="title"
                    control={control}
                  />
                )}
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        name="title2"
                        label="Main Title"
                        hideVariant
                        placeholder={
                          "Enter Main Title (Main Title must be at most 40 characters)"
                        }
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name="title2"
                    control={control}
                  />
                )}
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Controller
                    name={`description`}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormTextArea
                        hideVariant
                        name={`description`}
                        label="Description"
                        placeholder="Enter description (description must be at most 150 characters)"
                        value={value || ""}
                        rows={5}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                        fullWidth
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Controller
                    name="bannerstatus_id"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormDropdownList
                        data={blockStatues || []}
                        label="Banner Status"
                        name="bannerstatus_id"
                        placeholder={"Select Banner Status"}
                        value={value || ""}
                        hideVariant
                        onChange={(e) => {
                          onChange(e);
                          console.log(e);
                        }}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Controller
                    name={`countryIDs`}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormCheckboxDropdown
                        label="Countries"
                        hideVariant
                        name="countryIDs"
                        id="countryIDs"
                        multiple
                        loading={countriesLoading}
                        options={countriesArray || []}
                        required
                        error={error?.message}
                        value={countriesArray?.filter((i) =>
                          value.includes(+i?.id)
                        )}
                        onChange={(_, selectedValues) => {
                          console.log(selectedValues);

                          const isSelectAllSelected = selectedValues.some(
                            (option) => option.value === "select-all"
                          );
                          // If "Select All" is selected, select all countries
                          if (isSelectAllSelected) {
                            setValue("select_all", true);
                            setValue(
                              "countryIDs",
                              countriesArray?.map((i) => i.id) // Selects all country IDs
                            );
                          } else {
                            // If "Select All" is not selected, update with the selected values
                            setValue("select_all", false);
                            setValue(
                              "countryIDs",
                              selectedValues.map((i) => i.id)
                            ); // Updates with specific selected IDs
                          }
                        }}
                      />
                    )}
                  />
                )}
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <>
                    <Controller
                      name="start_date"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormCalendarInput
                          label="Start Date"
                          required
                          hideVariant
                          minDate={new Date()}
                          value={value}
                          name="start_date"
                          onChange={(value) => {
                            onChange(value);
                          }}
                          helperText={error ? error?.message : ""}
                        />
                      )}
                    />
                    <Controller
                      name="end_date"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormCalendarInput
                          label="End Date"
                          required
                          hideVariant
                          minDate={getValues("start_date")}
                          value={value}
                          name="end_date"
                          onChange={(value) => {
                            onChange(value);
                          }}
                          helperText={error ? error?.message : ""}
                        />
                      )}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <>
                  {isFetchingData ? (
                    <Skeleton variant="text" width="100%" height={80} />
                  ) : (
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <div className="switch">
                          <label> Enable Button Block</label>
                          <Switch
                            checked={value}
                            onChange={(e) => onChange(e.target.checked)}
                          />
                        </div>
                      )}
                      name="enableBlock"
                      control={control}
                    />
                  )}
                </>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  enableBlock && (
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormInput
                          name="buttonText"
                          label="Button Text"
                          hideVariant
                          placeholder={"Enter Button Text"}
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          helperText={error?.message}
                        />
                      )}
                      name="buttonText"
                      control={control}
                    />
                  )
                )}
              </Grid>
              {enableBlock && (
                <Grid item xs={12} sm={4}>
                  {isFetchingData ? (
                    <Skeleton variant="text" width="100%" height={80} />
                  ) : (
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <div className="switch">
                          <label>Open in new Tab</label>
                          <Switch
                            checked={value}
                            onChange={(e) => onChange(e.target.checked)}
                          />
                        </div>
                      )}
                      name="enablenewTab"
                      control={control}
                    />
                  )}
                  {isFetchingData ? (
                    <Skeleton variant="text" width="100%" height={80} />
                  ) : (
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormInput
                          name="buttonLink"
                          label="Button Link"
                          hideVariant
                          placeholder={"Enter Button Link"}
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          helperText={error?.message}
                        />
                      )}
                      name="buttonLink"
                      control={control}
                    />
                  )}
                </Grid>
              )}
              <Grid item xs={12} sm={4}>
                <Grid item xs={12}>
                  {isFetchingData ? (
                    <Skeleton variant="rectangular" width="100%" height={40} />
                  ) : (
                    enableBlock && (
                      <ColorPicker
                        label="Button Color"
                        selectedColor={buttonColor}
                        onColorChange={setButtonColor}
                        isLoading={isFetchingData}
                      />
                    )
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Button
                    onClick={handleCancel}
                    name={"Cancel"}
                    type="button"
                    className="mx-1"
                  />
                )}
              </Grid>
              <Grid item>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Button
                    selected
                    name={"Save"}
                    type="submit"
                    className="mx-1"
                    loading={loading}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        )}
        {enableBanner.name === "RIGHT" && (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <div>
                        <Cropper
                          sendCroppedFile={handleHeaderImage}
                          MAX_IMAGE_WIDTH={706}
                          MAX_IMAGE_HEIGHT={320}
                          title={`Drag your Image here only webp 700 * 320 `}
                          className="width100"
                          isTrue={true}
                          iwebp={true}
                          imageSrc={getValues("backgroundImage")}
                        />

                        {!ID && headerimageVal === null && (
                          <p className="error">{"Field Is Required"}</p>
                        )}
                      </div>
                    )}
                    name="backgroundImage"
                    control={control}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <div>
                        <Cropper
                          sendCroppedFile={handleMobileImage}
                          MAX_IMAGE_WIDTH={706}
                          MAX_IMAGE_HEIGHT={320}
                          title={
                            "Drag your Mobile Image here only webp 706 * 320"
                          }
                          className="width100"
                          isTrue={true}
                          iwebp={true}
                          imageSrc={getValues("mobile_background_image")}
                        />

                        {!ID && mobileimageVal === null && (
                          <p className="error">{"Field Is Required"}</p>
                        )}
                      </div>
                    )}
                    name="mobile_background_image"
                    control={control}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Controller
                    name="bannerstatus_id"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormDropdownList
                        data={blockStatues || []}
                        label="Banner Status"
                        name="bannerstatus_id"
                        placeholder={"Select Banner Status"}
                        value={value || ""}
                        hideVariant
                        onChange={(e) => {
                          onChange(e);
                          console.log(e);
                        }}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Controller
                    name={`countryIDs`}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormCheckboxDropdown
                        label="Countries"
                        hideVariant
                        name="countryIDs"
                        id="countryIDs"
                        multiple
                        loading={countriesLoading}
                        options={countriesArray || []}
                        required
                        error={error?.message}
                        value={countriesArray?.filter((i) =>
                          value.includes(+i?.id)
                        )}
                        onChange={(_, selectedValues) => {
                          console.log(selectedValues);

                          const isSelectAllSelected = selectedValues.some(
                            (option) => option.value === "select-all"
                          );
                          // If "Select All" is selected, select all countries
                          if (isSelectAllSelected) {
                            setValue("select_all", true);
                            setValue(
                              "countryIDs",
                              countriesArray?.map((i) => i.id) // Selects all country IDs
                            );
                          } else {
                            // If "Select All" is not selected, update with the selected values
                            setValue("select_all", false);
                            setValue(
                              "countryIDs",
                              selectedValues.map((i) => i.id)
                            ); // Updates with specific selected IDs
                          }
                        }}
                      />
                    )}
                  />
                )}
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <>
                    <Controller
                      name="start_date"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormCalendarInput
                          label="Start Date"
                          required
                          hideVariant
                          minDate={new Date()}
                          value={value}
                          name="start_date"
                          onChange={(value) => {
                            onChange(value);
                          }}
                          helperText={error ? error?.message : ""}
                        />
                      )}
                    />
                    <Controller
                      name="end_date"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormCalendarInput
                          label="End Date"
                          required
                          hideVariant
                          minDate={getValues("start_date")}
                          value={value}
                          name="end_date"
                          onChange={(value) => {
                            onChange(value);
                          }}
                          helperText={error ? error?.message : ""}
                        />
                      )}
                    />
                  </>
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        name="buttonLink"
                        label="Button Link"
                        hideVariant
                        placeholder={"Enter Button Link"}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="buttonLink"
                    control={control}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                <Grid item xs={12}>
                  {isFetchingData ? (
                    <Skeleton variant="rectangular" width="100%" height={40} />
                  ) : (
                    enableBlock && (
                      <ColorPicker
                        label="Button Color"
                        selectedColor={buttonColor}
                        onColorChange={setButtonColor}
                        isLoading={isFetchingData}
                      />
                    )
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Button
                    onClick={handleCancel}
                    name={"Cancel"}
                    type="button"
                    className="mx-1"
                  />
                )}
              </Grid>
              <Grid item>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Button
                    selected
                    name={"Save"}
                    type="submit"
                    className="mx-1"
                    loading={loading}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        )}
      </form>
    </div>
  );
};

export default AddBanners;
