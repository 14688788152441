import React, { useContext, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Skeleton from "@mui/material/Skeleton";
import * as yup from "yup";
//api
import { createBlog, getBlogsByID, updateBlog } from "../../../core/apis/store";

//Components
import Cropper from "../../../components/singleUpload/imageCropper";
import {
  FormInput,
  FormMultiSelect,
  FormTextArea,
  FormDropdownList,
} from "../../../components/formComponents/FormComponents";
import NewEditor from "../../../components/editor/NewEditor";
import Button from "../../../components/button/Button";

//Context
import { AlertContext } from "../../../context/AlertContext";
import { useGlobalValues } from "../../../context/GlobalContext";

import { useGet } from "../../../hooks/useFetch";

const AddBlogs = (props) => {
  const [isFetchingData, setIsFetchingData] = useState(true); // New state for loading while fetching data
  const { onCancel, onSave, ID } = props;
  const [headerimageVal, setHeaderImageValue] = useState(null);
  const [bodyimageVal, setBodyImageValue] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage, enabledLanguages } = useGlobalValues();
  const [loading, setLoading] = useState(false);
  const blogsSchema = yup.object({
    headerImage: yup.mixed().nullable().required("Field is required"),
    headerImageText: yup.string().nullable().required("Field is required"),
    bodyImage: yup.mixed().nullable().required("Field is required"),
    featuredImageText: yup.string().nullable().required("Field is required"),
    authorName: yup.string().nullable().required("Field is required"),
    title: yup.string().nullable().required("Field is required"),
    previewDescription: yup
      .string()
      .nullable()
      .required("Field is required")
      .max(200, "Preview description must be at most 200 characters"),

    tags_id: yup.array().nullable().required("Field is required"),
    published: yup.bool().nullable().required("Field is required"),
    blogContent: yup.string().nullable().required("Field is required"),
    lang_code: yup.object().nullable(),
  });

  const defaultValues = {
    authorName: "",
    title: "",
    previewDescription: "",
    tags_id: [], // Assuming tags_id should be an array
    headerImage: "",
    headerImageText: "",
    bodyImage: "",
    featuredImageText: "",
    published: true, // Assuming published is a boolean
    blogContent: "",
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(blogsSchema),
  });

  const handleSubmitForm = async (values) => {
    let formData = new FormData();
    setLoading(true);

    formData.append("author_name", values?.authorName);
    formData.append("title", values?.title);
    formData.append("preview_description", values?.previewDescription);

    values.tags_id.forEach((tag, index) =>
      formData.append(`tags[${index}]`, tag?.tag_name)
    );
    formData.append("header_image", values?.headerImage);
    formData.append("header_image_alt_text", values?.headerImageText);
    formData.append("featured_image", values?.bodyImage);
    formData.append("featured_image_alt_text", values?.featuredImageText);
    formData.append("published", values?.published ? 1 : 0);
    formData.append("blog_content", values?.blogContent);

    if (ID) {
      formData.append("language", values?.lang_code);
      if (!(values?.headerImage instanceof File)) {
        formData.delete("header_image");
      }
      if (!(values?.bodyImage instanceof File)) {
        formData.delete("featured_image");
      }

      updateBlog(ID, formData).then((res) => {
        console.log(res, "Blog Updated");
        onSave();
        setAlert({
          visible: true,
          text: res?.data.success ? "Successfully Updated" : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
        setLoading(false);
      });
    } else {
      formData.append(
        "language",
        values?.lang_code?.code ? values?.lang_code?.code : "en"
      );
      createBlog(formData).then((res) => {
        console.log(res, "API Response");
        onSave();
        setAlert({
          visible: true,
          text: res?.data.success ? "Successfully Created" : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
        setLoading(false);
      });
    }
  };

  const handleHeaderImage = (croppedImage) => {
    setHeaderImageValue(croppedImage);
    setValue("headerImage", croppedImage);
  };

  const handleBodyImage = (croppedImage) => {
    setBodyImageValue(croppedImage);
    setValue("bodyImage", croppedImage);
  };

  const handleCancel = () => {
    onCancel();
  };

  //TagsMultiSelect api
  const { data: tags, loading: loadingtags } = useGet({
    url: "admin/blog/tags",
  });

  let blogArray = tags?.data?.data?.map((item, index) => ({
    ...item,
    id: index + 1, // You can customize the id generation logic as needed
  }));

  // Fetch data and populate form if ID is provided
  useEffect(() => {
    if (ID) {
      setIsFetchingData(true); // Start fetching data
      getBlogsByID(ID).then((res) => {
        if (res?.data?.success) {
          const blogData = res?.data?.data;
          const updatedTags = blogData?.tags?.map((item1) => {
            const match = blogArray?.find(
              (item2) => item2.tag_name === item1.tag_name
            );
            if (match) {
              return { ...item1, id: match.id };
            }
            return item1; // If no match, return the original item
          });

          const populatedFormData = {
            authorName: blogData?.author_name,
            title: blogData?.title,
            previewDescription: blogData?.preview_description,
            tags_id: updatedTags,
            headerImage: blogData?.header_image,
            headerImageText: blogData?.header_image_alt_text,
            bodyImage: blogData?.featured_image,
            featuredImageText: blogData?.featured_image_alt_text,
            published: blogData?.published,
            blogContent: blogData?.blog_content,
          };
          setValue("authorName", populatedFormData?.authorName);
          setValue("title", populatedFormData?.title);
          setValue("previewDescription", populatedFormData?.previewDescription);
          setValue("tags_id", populatedFormData?.tags_id);
          setValue("headerImage", populatedFormData?.headerImage);
          setValue("headerImageText", populatedFormData?.headerImageText);
          setValue("bodyImage", populatedFormData?.bodyImage);
          setValue("featuredImageText", populatedFormData?.featuredImageText);
          setValue("published", populatedFormData?.published);
          setValue("blogContent", populatedFormData?.blogContent);
          setValue("lang_code", blogData?.language);
        }
        setIsFetchingData(false); // Stop fetching data
      });
    } else {
      setIsFetchingData(false); // If there's no ID, stop loading immediately
    }
  }, [ID,tags]);

  return (
    <div className="addBlogs">
      <div className="store-title">
        {ID ? "Edit Blog" : "New Blog"}
        {isFetchingData ? (
          <>
            <div className="wrapContainer">
              <div className="width-container70">
                <Skeleton
                  variant="rectangular"
                  height={40}
                  style={{ marginBottom: "16px" }}
                />
                <Skeleton
                  variant="rectangular"
                  height={40}
                  style={{ marginBottom: "16px" }}
                />
                <Skeleton
                  variant="rectangular"
                  height={40}
                  style={{ marginBottom: "16px" }}
                />
              </div>
              <div className="width-container30">
                {" "}
                <Skeleton
                  variant="rectangular"
                  height={40}
                  style={{ marginBottom: "16px" }}
                />
                <Skeleton
                  variant="rectangular"
                  height={40}
                  style={{ marginBottom: "16px" }}
                />
                <Skeleton
                  variant="rectangular"
                  height={40}
                  style={{ marginBottom: "16px" }}
                />
              </div>
            </div>
          </>
        ) : (
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="wrapContainer">
              <div className="width-container70">
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      required
                      name="title"
                      label="Title"
                      hideVariant
                      placeholder={"Enter Blog Title"}
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      helperText={error?.message}
                    />
                  )}
                  name="title"
                  control={control}
                />
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div>
                      <Cropper
                        required
                        sendCroppedFile={handleHeaderImage}
                        MAX_IMAGE_WIDTH={1300}
                        MAX_IMAGE_HEIGHT={500}
                        title={"Drag your Header Image here only webp"}
                        className="width100 height100"
                        isTrue={true}
                        iwebp={true}
                        imageSrc={getValues("headerImage")}
                      />
                      {error && headerimageVal == null ? (
                        <p className="error">{error.message}</p>
                      ) : null}
                    </div>
                  )}
                  name="headerImage"
                  control={control}
                />

                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <FormMultiSelect
                        required
                        label="Tags"
                        placeholder="Select Tags"
                        name="tags_id"
                        id="tags_id"
                        isfreeSolo
                        value={value}
                        data={blogArray || []}
                        hideVariant
                        accessValue="tag_name"
                        onChange={(e) => onChange(e)}
                        helperText={
                          getValues("tags_id")?.length > 0
                            ? ""
                            : "Field is Required"
                        }
                      />
                    );
                  }}
                  name="tags_id"
                  control={control}
                />

                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <NewEditor
                      required
                      label={""}
                      name="blogContent"
                      labelclassname="label-editor-title"
                      value={value}
                      lang={getValues("lang_code") || "en"}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                  name="blogContent"
                  control={control}
                />
              </div>
              <div className="width-container30">
                {!ID && (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormDropdownList
                        required
                        data={enabledLanguages || []}
                        label="Add Blog In"
                        name="lang_code"
                        placeholder={"Select language"}
                        value={value}
                        hideVariant
                        accessValue="name"
                        onChange={(e) => {
                          onChange(e);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name="lang_code"
                    control={control}
                  />
                )}
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      required
                      name="authorName"
                      label="Author Name"
                      hideVariant
                      placeholder={"Enter Author Name"}
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      helperText={error?.message}
                    />
                  )}
                  name="authorName"
                  control={control}
                />

                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div>
                      <Cropper
                        required
                        sendCroppedFile={handleBodyImage}
                        MAX_IMAGE_WIDTH={400}
                        MAX_IMAGE_HEIGHT={300}
                        title={"Drag your Featured Image here only webp"}
                        className="width100 height100"
                        isTrue={true}
                        iwebp={true}
                        imageSrc={getValues("bodyImage")}
                      />
                      {error && bodyimageVal == null ? (
                        <p className="error">{error.message}</p>
                      ) : null}
                    </div>
                  )}
                  name="bodyImage"
                  control={control}
                />

                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormTextArea
                      required
                      rows="3"
                      name="previewDescription"
                      label="Preview Description"
                      className="labelclassname"
                      placeholder="Enter Blog Preview Description"
                      value={value}
                      hideVariant
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      helperText={error?.message}
                    />
                  )}
                  name="previewDescription"
                  control={control}
                />

                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      required
                      name="featuredImageText"
                      label="Featured Alt Image Text"
                      hideVariant
                      placeholder={"featured_image_alt_text  "}
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      helperText={error?.message}
                    />
                  )}
                  name="featuredImageText"
                  control={control}
                />
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      required
                      name="headerImageText"
                      label="Header Alt Image Text"
                      hideVariant
                      placeholder="header_image_alt_text"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      helperText={error?.message}
                    />
                  )}
                  name="headerImageText"
                  control={control}
                />
              </div>
            </div>

            <div className="action-footer">
              <Button
                selected
                name={"Save"}
                type="submit"
                loading={loading}
                className="mx-1"
              />
              <Button
                onClick={() => handleCancel()}
                name={"Cancel"}
                type="button"
                className="mx-1"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddBlogs;
