import React from "react";
import "./Roles.scss";
import { TableCell } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Paginator from "../../../components/paginator/Paginator";
import TableBodyComponent from "../../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../../components/table/tableBody/TableRowComponent";
import TableHeader from "../../../components/table/tableHeader/TableHeader";
import { useGet } from "../../../hooks/useFetch";
import withH1 from "../../../assests/HOC/withH1";

const Roles = () => {
  const tableHeaders = [
    { title: "Name" },
    { title: "User Type" },
    { title: "Description" },
  ];
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };
  let url = `admin/get-all-roles`;
  const { data: roles, loading } = useGet({ url: url, payload: payload });
  let data = roles?.data?.data || [];
  return (
    <>
      <TableHeader placeholder="Search here" to="/roles/new-role" />
      <TableBodyComponent
        header={tableHeaders}
        loading={loading}
        total={data?.total}
      >
        {data?.data?.map((element) => (
          <TableRowComponent
            className="BrandDetails"
            handleEdit={() => navigate(`/roles/role/${element?.id}`)}
            key={element?.id}
          >
            <TableCell> {element?.name}</TableCell>
            <TableCell>{element?.user_type?.name}</TableCell>
            <TableCell>{element?.description}</TableCell>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
      <Paginator count={data?.last_page} disabled={loading} />
    </>
  );
};

export default withH1(Roles, "Roles");
