// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "../locale/en.json";
import arTranslation from "../locale/ar.json";
import frTranslation from "../locale/fr.json";

const resources = {
  en: {
    translation: enTranslation,
  },
  ar: {
    translation: arTranslation,
  },
  fr: {
    translation: frTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // default language
  keySeparator: false, // allow using dots in keys
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
