import { useState, useEffect } from "react";
import "./imageCropper.scss";
import { useDropzone } from "react-dropzone";
import CropperModal from "./cropperModal";
import documentationImg from "../../assests/Images/file-upload.png";

const MultipleCropper = (props) => {
  const {
    MAX_FILE_SIZE,
    MAX_IMAGE_WIDTH,
    MAX_IMAGE_HEIGHT,
    sendCroppedFile,
    className,
    imageSrc,
    sizeMsg,
    error,
    message,
    iwebp,
  } = props;

  const [files, setFiles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);

  useEffect(() => {
    if (imageSrc) {
      // Logic for setting initial image source if provided
    }
  }, [imageSrc]);

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      if (file.size > MAX_FILE_SIZE) {
        alert(
          `File size exceeds the maximum limit of ${
            MAX_FILE_SIZE / (1024 * 1024)
          } MB: ${file.name}`
        );
        return;
      }

      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = () => {
        if (
          image.width != MAX_IMAGE_WIDTH ||
          image.height != MAX_IMAGE_HEIGHT
        ) {
          // Open cropper modal for images exceeding max width or height
          setModalOpen(true);
          setCurrentFileIndex(files.length); // Track current file being cropped

          // Add file to state, with needsCrop flag to indicate it needs cropping
          setFiles((prevFiles) => [
            ...prevFiles,
            { file, src: URL.createObjectURL(file), needsCrop: true },
          ]);
        } else {
          // Directly send non-cropped image
          sendCroppedFile(file);
          setFiles((prevFiles) => [
            ...prevFiles,
            { file, src: URL.createObjectURL(file), needsCrop: false },
          ]);
        }
      };
    });
  };

  const handleCroppedFile = (croppedFile) => {
    if (croppedFile) {
      const updatedFiles = [...files];
      updatedFiles[currentFileIndex].file = croppedFile;
      updatedFiles[currentFileIndex].src = URL.createObjectURL(croppedFile);
      updatedFiles[currentFileIndex].needsCrop = false;
      setFiles(updatedFiles);
      sendCroppedFile(croppedFile);
    }
    setModalOpen(false);
    setCurrentFileIndex(null);
  };

  const handleRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    sendCroppedFile(null);
  };

  // UseDropzone hook to handle file drop and selection
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  return (
    <div className="wrapper-container">
      <CropperModal
        modalOpen={modalOpen}
        src={files[currentFileIndex]?.src}
        setModalOpen={setModalOpen}
        max_image_width={MAX_IMAGE_WIDTH}
        max_image_height={MAX_IMAGE_HEIGHT}
        onCropComplete={handleCroppedFile}
        multiple={true}
        iwebp={iwebp}
      />

      <div className="wrapper-image">
        <div {...getRootProps()} className={`dropzone ${className}`}>
          <input {...getInputProps()} />
          <img
            src={documentationImg}
            alt="Uploaded"
            style={{ width: "50%" }}
            className="image-fileinput"
          />
          <p>Drag and drop images here, or click to select images</p>
          <em>{sizeMsg}</em>
        </div>{" "}
        {error && <span className="error-message">{message}</span>}
      </div>
    </div>
  );
};

MultipleCropper.defaultProps = {
  MAX_FILE_SIZE: 10 * 1024 * 1024,
  MAX_IMAGE_WIDTH: 400,
  MAX_IMAGE_HEIGHT: 400,
  ASPECT_RATIO: 1,
};

export default MultipleCropper;
