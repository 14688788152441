//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

//COMPONENT
import Button from "../../../components/button/Button";
import { useForm, Controller } from "react-hook-form";
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
} from "@mui/material";
import { FormDropdownList } from "../../../components/formComponents/FormComponents";
import { useGet } from "../../../hooks/useFetch";
import { CloseSVG } from "../../../assests/icons/SVG";
import { AlertContext } from "../../../context/AlertContext";
import { CreateNewInspection } from "../../../core/apis/sellDevice";
import { getAllProducts } from "../../../core/apis/product";
//CSS
import "./TransactionDetails.scss";

const HandleSchema = yup.object({
  product_id: yup.object().nullable().required("Field is required"),
  questions: yup.array().of(
    yup.object().shape({
      question_id: yup.object(),
      answer_id: yup.object().nullable().required("Field is required"),
    })
  ),
  attributes_answers: yup.array().of(
    yup.object().shape({
      attribute_id: yup.object(),
      attribute_value_id: yup.object().nullable().required("Field is required"),
    })
  ),
});

export function InspectionHandle(props) {
  const { data, onClose, refetchData } = props;
  let selected_insp = data?.inspection?.find((el) => el.is_selected === true);

  //GET THE DATA by product slug
  const { data: questionsData, loading } = useGet({
    url: `/product-estimation-question/${selected_insp?.item?.product.slug}`,
  });

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  const [allProducts, setAllProducts] = useState([]);
  const { setAlert } = useContext(AlertContext);
  const [productLoading, setProductLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const handleSubmitForm = (formData) => {
    setSaveLoading(true);
    CreateNewInspection({
      product_id: formData.product_id?.id,
      transaction_id: data?.id,
      attributes_answers: formData.attributes_answers?.map((el) => ({
        attribute_id: el?.attribute_id?.id,
        attribute_value_id: el?.attribute_value_id?.id,
      })),
      questions: formData.questions?.map((el) => ({
        question_id: el?.question_id?.id,
        answer_id: el?.answer_id?.id,
      })),
    }).then((response) => {
      if (response?.data.success) {
        refetchData();
        onClose();
      }
      setAlert({
        text: response.data ? response.data?.message : response.message,
        type: response.data?.success ? "success" : "error",
      });

      setSaveLoading(false);
    });
  };

  useEffect(() => {
    if (questionsData) {
      reset({
        product_id: null,
        questions: questionsData?.data?.data?.questions[0].map((el) => ({
          question_id: el,
          answer_id: null,
        })),
        attributes_answers:
          questionsData?.data?.data?.attributes?.estimation_attributes
            .concat(
              questionsData?.data?.data?.attributes?.non_estimation_attributes
            )
            .map((el) => ({
              attribute_id: el,
              attribute_value_id: null,
            })),
      });
    }
    if (data) {
      setProductLoading(true);
      let user_insp = data?.inspection?.find(
        (val) => val.is_admin_inspection === false
      );

      getAllProducts({
        category_ids: [user_insp?.item?.product?.category_id],
      }).then((response) => {
        setAllProducts(response?.data?.data || []);
        setProductLoading(false);
      });
    }
  }, [questionsData, data]);

  console.log("questionsData", questionsData);

  return (
    <Dialog disablebackdropclick="true" fullWidth open={true}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {"New Inspection"}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseSVG />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="product-questions">
          {!loading ? (
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <div className="question-all-list">
                <div className="inspection-question-element">
                  <div className="inspection-element">Product</div>
                  <div className="inspection-element">
                    {" "}
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormDropdownList
                          required
                          data={allProducts}
                          loading={productLoading}
                          name={`product_id`}
                          dependencyError={["product_id"]}
                          placeholder={"Select answer"}
                          value={value}
                          onChange={(e) => onChange(e)}
                          helperText={error?.message}
                        />
                      )}
                      name={`product_id`}
                      control={control}
                    />
                  </div>
                </div>

                {getValues("attributes_answers")?.map(
                  (attributesItem, index) => (
                    <div className="inspection-question-element">
                      <div className="inspection-element">
                        {attributesItem?.attribute_id?.name?.en}
                      </div>
                      <div className={`inspection-element `}>
                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormDropdownList
                              required
                              data={attributesItem?.attribute_id?.values || []}
                              loading={false}
                              name={`attributes_answers.${index}.attribute_value_id`}
                              placeholder={"Select answer"}
                              value={value}
                              onChange={(e) => onChange(e)}
                              helperText={error?.message}
                            />
                          )}
                          name={`attributes_answers.${index}.attribute_value_id`}
                          control={control}
                        />
                      </div>
                    </div>
                  )
                )}
                {getValues("questions")?.map((questionItem, index) => (
                  <div className="inspection-question-element">
                    <div className="inspection-element">
                      {questionItem?.question_id?.question?.en}
                    </div>
                    <div className={`inspection-element `}>
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormDropdownList
                            required
                            data={questionItem?.question_id?.answer || []}
                            loading={false}
                            accessValue={"answer"}
                            name={`questions.${index}.answer_id`}
                            placeholder={"Select answer"}
                            value={value}
                            onChange={(e) => onChange(e)}
                            helperText={error?.message}
                          />
                        )}
                        name={`questions.${index}.answer_id`}
                        control={control}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="action-footer">
                <Button
                  name={"Save"}
                  selected
                  loading={saveLoading}
                  width={"30%"}
                  type="submit"
                />
              </div>
            </form>
          ) : (
            <Skeleton count={5} />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default InspectionHandle;
