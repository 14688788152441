import { api } from "./main";

export const CreateUser = async (payload) => {
  return await api
    .post("admin/add-user", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const EditUser = async (payload) => {
  return await api
    .put(`admin/update-other-user/${payload?.user_id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
