// EXTERNAL LIBRARIES
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
// STYLING
import "./ViewNotification.scss";
import Spinner from "../../../../components/MUI-skeleton/Spinner";

// API
import { api } from "../../../../core/apis/main";
import { Card } from "@mui/material";

const ViewNotification = () => {
  // DATA AND HOOKS
  const params = useParams();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState();

  // API REQUESTS
  // Getting notification by id
  const getNotification = async () => {
    setLoad(true);
    return await api
      .get(`admin/get-notification-by-id/${params.id}`)
      .then((res) => {
        if (res?.data?.success) {
          let d = res?.data?.data;
          let arr = [];
          d?.user?.map((el) => {
            arr.push(el.full_name);
          });
          arr = arr.join(", ");
          setData({
            title: d?.title,
            users: arr,
            description: d?.content,
          });
        }
        setLoad(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // FUNCTIONALITY
  useEffect(() => {
    getNotification();
  }, []);

  return !load ? (
    <div>
      <h1>{data?.title}</h1>
      <Card className="viewNoteCard">
        <span>
          <label>Sent:</label>
          <p> {moment(data?.created_at).format("DD-MM-YYYY HH:mm")}</p>
        </span>
        <span>
          <label>To:</label>
          <p>{data?.users}</p>
        </span>
        <span>
          <label>Message:</label>
          <p dangerouslySetInnerHTML={{ __html: data?.description }}></p>
        </span>
      </Card>
    </div>
  ) : (
    <Spinner />
  );
};

export default ViewNotification;
