import React from "react";

const withH1 = (WrappedComponent, title) => {
  return (props) => {
    return (
      <div>
        <h1>{title}</h1>
        <WrappedComponent {...props} />
      </div>
    );
  };
};

export default withH1;
