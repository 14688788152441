import React, { useState, useContext } from "react";
import "./blogs.scss";
import AddBlogs from "../Blogs/addBlogs";
import { Chip, Typography } from "@mui/material";
import { AlertContext } from "../../../context/AlertContext";
import TableBodyComponent from "../../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../../components/table/tableBody/TableRowComponent";
import { TableCell } from "@mui/material";
import Paginator from "../../../components/paginator/Paginator";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";

import { useGet } from "../../../hooks/useFetch";
//api
import { deleteBlog } from "../../../core/apis/store";

import Button from "../../../components/button/Button";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const tableHeaders = [
    { title: "Title" },
    { title: "Summary" },
    { title: "Language" },
  ];
  const { setAlert } = useContext(AlertContext);
  const [blogID, setBlogID] = useState("");
  const [showAddBlogs, setShowAddBlogs] = useState(false);
  const [addBlog, setAddBlog] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  const navigate = useNavigate();

  let url = `admin/blog`;
  const { data: blogs, loading, refetch } = useGet({ url: url });
  let data = blogs?.data.data;

  const handleToggleAddBlog = () => {
    setShowAddBlogs((prevState) => !prevState); // Toggle state
  };

  const handleToggleEditQuestion = (id) => {
    setShowAddBlogs(true);
    setBlogID(id);
  };

  const handleTogglefalse = () => {
    setBlogID(null);
    setShowAddBlogs(false);
    refetch();
  };

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteBlog(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setAddBlog(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res?.data?.success
            ? "Deleted SuccessFully"
            : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  return (
    <div className="blogs">
      {showAddBlogs ? (
        <AddBlogs
          onCancel={handleTogglefalse}
          onSave={handleTogglefalse}
          ID={blogID}
        />
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginTop: "10px",
              padding: "5px",
            }}
          >
            <div>
              <Button
                selected
                style={{ marginleft: "5px", marginRight: "5px" }}
                width={"150px"}
                name="Add Blog"
                onClick={handleToggleAddBlog}
              />
            </div>
          </div>
          <div className="exportList">
            <TableBodyComponent
              header={tableHeaders}
              loading={loading}
              total={data?.total}
            >
              {data?.map((element) => (
                <TableRowComponent
                  internal
                  key={element?.id}
                  handleDelete={() => {
                    setAddBlog(element);
                    setOpenDelete(true);
                  }}
                  handleEdit={() => {
                    handleToggleEditQuestion(element.id);
                  }}
                >
                  <TableCell>{element?.title}</TableCell>
                  <TableCell>{element?.preview_description}</TableCell>
                  <TableCell>{element?.language}</TableCell>
                </TableRowComponent>
              ))}
            </TableBodyComponent>
            <Paginator count={data?.last_page} disabled={loading} />
            {openDelete && (
              <NoticeConfirmation
                data={addBlog}
                handleResponse={handleDeleteResponse}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Blogs;
