// EXTERNAL LIBRARIES
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import Search from "../../../../components/search/Search";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  Card,
  TableCell,
  CardHeader,
  CardContent,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  FormGroup,
} from "@mui/material";

// STYLING
import "./UserGroupsDetails.scss";
import Spinner from "../../../../components/MUI-skeleton/Spinner";

// CUSTOM HOOKS
import { useGet } from "../../../../hooks/useFetch";

// FORM
import {
  FormInput,
  FormMultiSelect,
} from "../../../../components/formComponents/FormComponents";
import Button from "../../../../components/button/Button";
import { api } from "../../../../core/apis/main";
import TableBodyComponent from "../../../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../../../components/table/tableBody/TableRowComponent";
import Paginator from "../../../../components/paginator/Paginator";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  groupParamsByKey,
  objectCleaner,
} from "../../../../core/functions/Functions";
import {
  CreateNotificationGroup,
  UpdateNotificationGroup,
} from "../../../../core/apis/notifications";
import { AlertContext } from "../../../../context/AlertContext";
const HandleSchema = yup.object({
  name: yup.string().required("Field is required"),
  users: yup.array().when("select_all_users", {
    is: (value) => value === false,
    then: yup.array().min(1, "Please select at least one user"),

    otherwise: yup.array(),
  }),

  user_type_ids: yup.array(),
  country_ids: yup.array(),
  select_all_users: yup.boolean(),
});

const UserGroupsDetails = (props) => {
  // DATA AND HOOKS
  const { create } = props;
  const setTimerRef = useRef();
  const params = useParams();
  let navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [search, setSearch] = useState(searchParams.get("search") || "");
  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  const [name, setName] = useState("");

  const [dataG, setDataG] = useState();

  // the listed users after the user types get selected
  // const [listedUsers, setListedUsers] = useState([]);
  // the user_ids of the selected users
  const [selectedUsers, setSelectedUsers] = useState([]);

  // API REQUESTS
  const { data: types, typesLoading } = useGet({
    url: "/get-all-user-types",
  });
  const { data: countries, countriesLoading } = useGet({
    url: "/get-all-countries",
  });

  const handleSearch = useCallback(
    (e) => {
      setSearch(e.target.value);
      if (setTimerRef.current) {
        clearTimeout(setTimerRef.current);
        setTimerRef.current = null;
      }

      setTimerRef.current = setTimeout(() => {
        let url = {
          ...groupParamsByKey(searchParams),
          page: 1,
          search: e.target.value,
        };
        setSearchParams(objectCleaner(url));
        clearTimeout(setTimerRef.current);
        setTimerRef.current = null;
      }, 1000);
    },
    [search]
  );
  // Get group by id on update
  const getGroup = async () => {
    setLoading(true);
    return await api
      .get(`admin/get-notification-group-by-id/${params.id}`)
      .then((res) => {
        setLoading(false);

        let responseData = res?.data?.data[0];
        reset({
          name: responseData?.name,
          users: responseData?.users,
          select_all_users: responseData?.users === data?.total ? true : false,
        });
        setName(responseData?.name);
        setSelectedUsers(responseData?.users);
        setDataG(responseData);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let url = `admin/get-all-users`;
  const {
    data: users,
    loading: userLoading,
    refetch,
  } = useGet({
    url: url,
    payload: {
      country_ids: getValues("country_ids")?.map((el) => el?.id),
      user_type_ids: getValues("user_type_ids")?.map((el) => el?.id),
      per_page: 10,
      page: searchParams.get("page") || 1,
      name: searchParams.get("search"),
    },
  });
  let data = users?.data.data;

  // Setting selected users
  const handleSelectedUsers = (value, user) => {
    let usersArray = [...getValues("users")];
    let check = selectedUsers.find((el) => el.id === user.id);
    if (value) {
      if (!check) {
        if ([...usersArray, user]?.length === data?.total) {
          setValue("users", [], {
            shouldValidate: true,
          });
          setValue("select_all_users", true, {
            shouldValidate: true,
          });
        } else {
          setValue("users", [...usersArray, user], {
            shouldValidate: true,
          });
        }

        setSelectedUsers((cur) => [...cur, user]);
      }
    } else {
      if (check) {
        let arr = usersArray.filter((el) => el.id !== user.id);
        if (arr?.length === data?.total) {
          setValue("users", [], {
            shouldValidate: true,
          });
          setValue("select_all_users", true, {
            shouldValidate: true,
          });
        } else {
          setValue("users", arr, {
            shouldValidate: true,
          });
        }

        setSelectedUsers(arr);
      }
    }
  };

  // Manipulating form values and making api call
  const handleSubmitForm = (formData) => {
    setSaveLoading(true);
    if (!create) {
      UpdateNotificationGroup({
        id: params.id,
        name: formData?.name,

        ...(formData?.select_all_users === false
          ? {
              users: formData?.users?.map((el) => el?.id),
            }
          : {
              country_ids: formData?.country_ids,
              user_type_ids: formData?.user_type_ids,
            }),

        select_all_users: formData?.select_all_users,
      }).then((res) => {
        if (res?.data?.success) {
          navigate("/user-groups");
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
        setSaveLoading(false);
      });
    } else {
      CreateNotificationGroup({
        name: formData?.name,
        ...(formData?.select_all_users === false
          ? {
              users: formData?.users?.map((el) => el?.id),
            }
          : {
              country_ids: formData?.country_ids?.map((el) => el?.id),
              user_type_ids: formData?.user_type_ids?.map((el) => el?.id),
            }),

        select_all_users: formData?.select_all_users,
      }).then((res) => {
        if (res?.data?.success) {
          navigate("/user-groups");
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
        setSaveLoading(false);
      });
    }
  };

  const handleSelectAll = (newValue) => {
    if (newValue === false) {
      setValue("users", [], { shouldValidate: true });
    }
    setValue("select_all_users", newValue, { shouldValidate: true });
  };

  // Fetching data on edit
  useEffect(() => {
    if (!create) {
      getGroup();
    } else {
      reset({
        user_type_ids: [],
        users: [],
        name: "",
        select_all_users: false,
      });
    }
  }, []);

  return !loading ? (
    <>
      <h1>{create ? "New User Group" : dataG?.name}</h1>

      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="userGroups">
          <div className="filters-section">
            <div className="form-section">
              <Card>
                <CardContent>
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        label="Group Name"
                        placeholder={"Enter name"}
                        name="name"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="name"
                    control={control}
                  />

                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormMultiSelect
                        data={types?.data?.data || []}
                        loading={typesLoading}
                        fixedOptions={[]}
                        label="User Types"
                        name="user_type_ids"
                        placeholder={"Select user type"}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          refetch();
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name="user_type_ids"
                    control={control}
                  />
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormMultiSelect
                        data={countries?.data?.data || []}
                        loading={countriesLoading}
                        fixedOptions={[]}
                        label="Countries"
                        name="country_ids"
                        placeholder={"Select country"}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          refetch();
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name="country_ids"
                    control={control}
                  />
                </CardContent>
              </Card>
            </div>

            <div className="already-selected-users">
              <Card>
                <CardHeader
                  className="header"
                  title="Selected Users"
                  subheader={
                    getValues("select_all_users") == true
                      ? "All users are selected"
                      : `${getValues("users")?.length} total users`
                  }
                ></CardHeader>
                <div className="form-error-message">
                  <div className="margin-error">
                    {getValues("users")?.length === 0 &&
                    getValues("select_all_users") === false
                      ? "Please select users"
                      : ""}
                  </div>
                </div>
                <CardContent>
                  {getValues("select_all_users") == false && (
                    <List className="list-items-selected">
                      {getValues("users")?.map((user, i) => (
                        <ListItem key={i}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={true}
                                  checked={true}
                                  onChange={(e) =>
                                    handleSelectedUsers(e.target.checked, user)
                                  }
                                />
                              }
                            />
                          </FormGroup>
                          <div className="user-render">{user?.full_name}</div>
                        </ListItem>
                      ))}
                    </List>
                  )}
                  <div className="action-footer">
                    <Button
                      type="submit"
                      selected
                      name={create ? "Create" : "Save"}
                      loading={saveLoading}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          <div className="all-users-list">
            <Card style={{ height: "100%" }}>
              <CardHeader
                className="header"
                title="Users"
                subheader={`${data?.total ? data?.total : 0} total users`}
              ></CardHeader>
              <CardContent className="no-padding-top">
                <div className="branches-users">
                  <div className="search-section">
                    <Search
                      className="search"
                      placeholder={"Search by name"}
                      onChange={handleSearch}
                      value={search}
                      curved
                    />
                  </div>

                  <TableBodyComponent
                    header={[
                      {
                        title: "Name",
                      },
                      { title: "Email" },
                    ]}
                    handleSelectAll={handleSelectAll}
                    total={data?.total || 0}
                    selectAllValue={
                      getValues("select_all_users") ? true : false
                    }
                    loading={userLoading}
                    internal={true}
                    actions={false}
                    prefixActions={true}
                  >
                    {data?.data?.map((element) => (
                      <TableRowComponent
                        row={element}
                        internal={true}
                        actions={false}
                      >
                        <TableCell>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={getValues("select_all_users")}
                                  value={
                                    getValues("select_all_users")
                                      ? true
                                      : getValues(`users`)?.find(
                                          (el) => el?.id === element?.id
                                        )
                                      ? true
                                      : false
                                  }
                                  checked={
                                    getValues("select_all_users")
                                      ? true
                                      : getValues(`users`)?.find(
                                          (el) => el?.id === element?.id
                                        )
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    handleSelectedUsers(
                                      e.target.checked,
                                      element
                                    )
                                  }
                                />
                              }
                            />
                          </FormGroup>
                        </TableCell>

                        <TableCell>{element.full_name}</TableCell>
                        <TableCell>{element.email}</TableCell>
                      </TableRowComponent>
                    ))}
                  </TableBodyComponent>
                  <Paginator count={data?.last_page} disabled={loading} />
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </form>
    </>
  ) : (
    <Spinner />
  );
};

export default UserGroupsDetails;
