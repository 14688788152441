import React, { useContext, useEffect, useState } from "react";
import {
  getallretailerhomepagecards,
  reOrderManageRetailHomePage,
} from "../../core/apis/manageretailer";
import { TableCell } from "@mui/material";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import BasicTabs from "../../components/Tabs/Tabs";
import ManageRetailerHandle from "./ManageRetailerHandle";
import { useQuery } from "@tanstack/react-query";
import { Button, IconButton } from "@mui/material";
import { DragBlueSVG } from "../../assests/icons/SVG";
import { AlertContext } from "../../context/AlertContext";
import withH1 from "../../assests/HOC/withH1";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import "./ManageRetailer.scss";
import ManageRetailerItem from "./ManageRetailerItem";
import { useGlobalValues } from "../../context/GlobalContext";
import { useGet } from "../../hooks/useFetch";

const ManageRetailer = (props) => {
  //const [RetailerCards, setRetailerCards] = useState([]);
  const [data, setData] = useState([]);
  const [canDrag, setCanDrag] = useState(false);
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [value, setValue] = useState("en");
  const { setAlert } = useContext(AlertContext);
  const [update, setUpdate] = useState(false);
  const { enabledLanguages } = useGlobalValues();

  const {
    data: response,
    loading,
    refetch,
  } = useGet({
    url: "/get-all-retailerhomepage-cards",
  });
  const sortedRetailerCards = response?.data?.data?.sort(
    (a, b) => a.order - b.order
  );

  const RetailerCards = sortedRetailerCards;
  
  useEffect(() => {
    if (response) {
      const sortedRetailerCards = response?.data?.data?.sort(
        (a, b) => a.order - b.order
      );
      setData(sortedRetailerCards);
    }
  }, [response]);

  const handleEdit = (value) => {
    setDetail(value);
    setOpen(true);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const dragId = data.find((item) => item.id === active.id);
      const dropId = data.find((item) => item.id === over.id);

      if (dragId && dropId) {
        const oldIndex = data.indexOf(dragId);
        const newIndex = data.indexOf(dropId);
        setData((items) => {
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    }
  };

  const handleSubmit = () => {
    setSaveLoading(true);
    reOrderManageRetailHomePage({
      retail_home_page_card_ids: data.map((el) => el.id),
    })
      .then((res) => {
        setUpdate(false);
        if (res?.data?.success) {
          setCanDrag(false);
        }
        console.log(res.data.message);
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
        setSaveLoading(false);

        // Refetch data after submitting changes
        refetch(); // Assuming refetch is available in the scope
      })
      .catch((error) => {
        console.error("Error while reordering:", error);
        setAlert({
          text: "An error occurred while reordering.",
          type: "error",
        });
        setSaveLoading(false);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="home-group-component">
      {canDrag && (
        <div className="home-header">
          <div className="title">Title</div>
          <div className="actions">
            <div>
              <Button
                variant="text"
                onClick={() => {
                  setCanDrag(false);
                }}
              >
                Cancel
              </Button>
            </div>

            <div>
              <Button variant="text" onClick={() => handleSubmit()}>
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
      {!canDrag && (
        <BasicTabs
          handleChange={handleChange}
          tabsList={enabledLanguages}
          value={value}
          children={
            <>
              {value == "en" ? (
                <div className="home-header">
                  <div className="title"></div>
                  <div className="actions">
                    <div>
                      <IconButton onClick={() => setCanDrag(true)}>
                        <DragBlueSVG />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className={value != "ar" ? "" : "arabic-component"}>
                <TableBodyComponent
                  isArabic={value == "ar" ? true : false}
                  loading={loading}
                  total={RetailerCards?.total}
                >
                  {RetailerCards?.map((element) => (
                    <TableRowComponent
                      handleEdit={() => handleEdit(element)}
                      row={element}
                      key={element?.id}
                    >
                      <TableCell
                        className={value != "ar" ? " " : "textAlighRight"}
                      >
                        {element?.title?.[value]
                          ? element?.title?.[value]
                          : element?.title?.en}
                      </TableCell>
                    </TableRowComponent>
                  ))}
                </TableBodyComponent>
                {open && (
                  <ManageRetailerHandle
                    data={detail}
                    languageValue={value}
                    onClose={() => {
                      setDetail(null);
                      setOpen(false);
                      refetch();
                    }}
                  />
                )}
              </div>
            </>
          }
        />
      )}
      {canDrag && (
        <div className="home-page-listing">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={data}
              strategy={verticalListSortingStrategy}
            >
              {data?.map((element, index) => (
                <div className="home-page-list-element" key={element?.id}>
                  <ManageRetailerItem element={element} canDrag={canDrag} />
                </div>
              ))}
            </SortableContext>
          </DndContext>
        </div>
      )}
    </div>
  );
};

export default withH1(ManageRetailer, "Welcome Page Management ");
