// EXTERNAL LIBRARIES
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

// STYLING
import "./Attributes.scss";
import {
  TableExportSVG,
  EditSVG,
  DragBlueSVG,
  DeleteSVG,
} from "../../assests/icons/SVG";
import Skeleton from "../../components/MUI-skeleton/Skeleton";

// COMPONENTS
import AttributeItem from "./AttributeItem";
import { api } from "../../core/apis/main";

// CUSTOM HOOKS
import { useAxios, useGet } from "../../hooks/useFetch";
import { AlertContext } from "../../context/AlertContext";

// TABLE
import TableHeader from "../../components/table/tableHeader/TableHeader";

// DATA NOT FOUND
import DataNotFound from "../../components/notFound/DataNotFound";
import { IconButton } from "@mui/material";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { deleteAttributes } from "../../core/apis/attribute";
import withH1 from "../../assests/HOC/withH1";

// This component lists data and has drag and update order functionality
const Attributes = () => {
  // DATA AND HOOKS
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setAlert } = useContext(AlertContext);
  const [openDelete, setOpenDelete] = useState(false);
  const [attDetail, setAttDetail] = useState(null);
  const [drag, setDrag] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  const [idArray, setIdArray] = useState([]);

  let payload = {
    per_page: 100,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };

  let url = `admin/get-all-attributes`;

  // API CALL
  const {
    data: attributes,
    loading: loading,
    callAPI: getAttributes,
    refetch,
  } = useAxios(url, "get", payload);
  let data = attributes?.data?.data;

  // Patches drag data
  const patchData = async () => {
    setLoadingForm(true);
    return await api
      .patch("/admin/update-attributes-order", {
        attribute_ids: idArray,
      })
      .then((res) => {
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
        getAttributes();
        setDrag(false);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingForm(false);
      });
  };

  // Drag sensors from external library
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDelete = (element) => {
    setAttDetail(element);
    setOpenDelete(true);
  };

  // Handle drag movement and setting array values
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setIdArray((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        // setUpdate(true);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  // Extract ids for drag array function
  const extractIds = () => {
    let array = [];
    data?.map((element) => {
      array.push(element.id);
    });
    setIdArray(array);
  };

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteAttributes(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setAttDetail(null);
          getAttributes();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  // Extract ids on data change
  useEffect(() => {
    extractIds();
  }, [data]);

  // If drag array is updated patch data
  useEffect(() => {
    if (update) {
      patchData();
    }
    setUpdate(false);
  }, [update, idArray]);

  // Get attributes on mount
  useEffect(() => {
    getAttributes();
  }, []);

  return (
    <>
      <TableHeader
        placeholder="Search by variant name"
        to="/variants/new-variant"
        filterButton={false}
      />
      {!loading && !loadingForm && (
        <div className="attributeHeader">
          <p>Name</p>
          <div>
            <p
              onClick={() => {
                setDrag(!drag);
                // getAttributes();
              }}
            >
              {!drag ? <DragBlueSVG /> : "Cancel"}
            </p>
            {drag && <p onClick={() => setUpdate(true)}>Save</p>}
            {/* <TableExportSVG /> */}
          </div>
        </div>
      )}

      {drag && idArray && !loading && !loadingForm && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={idArray || []}
            strategy={verticalListSortingStrategy}
          >
            {idArray?.map((id) => (
              <AttributeItem
                key={id}
                id={id}
                element={data.filter((el) => {
                  return el.id === id;
                })}
              />
            ))}
          </SortableContext>
        </DndContext>
      )}
      {!drag &&
        data &&
        !loading &&
        !loadingForm &&
        data?.map((element, i) => (
          <div key={i} className="attributeItem">
            <p>{element?.name?.en}</p>
            <div>
              {" "}
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => navigate(`/variants/variant/${element?.id}`)}
              >
                <EditSVG />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDelete(element)}
              >
                <DeleteSVG fill="#EE1818" />
              </IconButton>
            </div>
          </div>
        ))}
      {loading && <Skeleton count={10} />}
      {loadingForm && <Skeleton count={10} />}
      {!data && !idArray && !loading && <DataNotFound notTable={true} />}

      {openDelete && (
        <NoticeConfirmation
          data={attDetail}
          handleResponse={handleDeleteResponse}
        />
      )}
    </>
  );
};

export default withH1(Attributes, "Variants");
