import * as React from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

const CustomTab = ({ label, icon, ...props }) => {
  return (
    <Tab
      {...props}
      label={
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <span style={{ marginleft: "5px", marginRight: "5px" }}>{label}</span>
          {icon}
        </Box>
      }
    />
  );
};

export default CustomTab;
