//UTILITIES
import React from "react";
import moment from "moment";
import { useNavigate } from "react-router";
import Paginator from "../../components/paginator/Paginator";
import { useSearchParams } from "react-router-dom";
import TableHeader from "../../components/table/tableHeader/TableHeader";
import { useGet } from "../../hooks/useFetch";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import { TableCell } from "@mui/material";
import CountUp from "react-countup";
//css
import "./Transactions.scss";
import withH1 from "../../assests/HOC/withH1";

const HistoricalTransactions = () => {
  //VARIABLES
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  let payload = {
    per_page: 10,
    barcode: searchParams.get("search"),
    transaction_status_id: searchParams.get("transaction_status_id"),
  };
  let page = searchParams.get("page") || 1;
  let url = `admin/get-all-historical-transactions?page=${page}`;
  const tableHeaders = [
    { title: "Barcode" },
    { title: "User" },
    { title: "Price" },
    { title: "Date" },
    { title: "Status" },
    { title: "Type" },
  ];
  //API CALL
  const { data: transaction, loading } = useGet({ url: url, payload: payload });
  let data = transaction?.data.data;

  return (
    <div className="open-transaction">
      <TableHeader placeholder="Search by barcode" filterButton={false} />
      <TableBodyComponent
        header={tableHeaders}
        loading={loading}
        total={data?.total}
        actions={false}
      >
        {data?.data?.map((element) => (
          <TableRowComponent
            row={element}
            actions={false}
            handleClick={() =>
              navigate(
                `/historical-${
                  element?.is_buy ? "buying" : "selling"
                }-transaction/${element?.id}`
              )
            }
          >
            <TableCell>{element?.barcode}</TableCell>
            <TableCell>{element?.user?.full_name}</TableCell>
            <TableCell>
              <CountUp
                end={element?.transaction_amount}
                duration={0.9}
                decimals={2}
              />
              {element?.currency?.code}
            </TableCell>
            <TableCell>
              {moment(element?.created_at).calendar() || "---"}
            </TableCell>
            <TableCell>{element?.transaction_status?.name?.en}</TableCell>
            <TableCell>{element?.is_buy ? "Buying" : "Selling"}</TableCell>
          </TableRowComponent>
        ))}
      </TableBodyComponent>

      <Paginator count={data?.last_page} disabled={loading} />
    </div>
  );
};

export default withH1(HistoricalTransactions, "Historical Transactions");
