// EXTERNAL LIBRARIES
import React, { useState, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Paper, TableCell } from "@mui/material";
import { AlertContext } from "../../context/AlertContext";
// TABLE
import TableHeader from "../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import Paginator from "../../components/paginator/Paginator";
import yellowcircle from "../../../src/assests/icons/yellowcircle.png";
import b2b from "../../assests/Images/b2b.png";
import Twouseround from "../../assests/Images/2useround.png";
import Barcode from "react-barcode";

// CUSTOM HOOKS
import { useGet } from "../../hooks/useFetch";

// DATA NOT FOUND
import DataNotFound from "../../components/notFound/DataNotFound";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { deleteProductSku } from "../../core/apis/productSkus";
import withH1 from "../../assests/HOC/withH1";

import "./style.scss";

const ItemsList = () => {
  // HOOKS AND DATA
  const { setAlert } = useContext(AlertContext);
  const [searchParams] = useSearchParams();
  const [detail, setDetail] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  let navigate = useNavigate();

  const tableHeaders = [
    { title: "BarCode" },
    { title: "SKU" },
    { title: "Name" },
    { title: "Location" },
    { title: "Cost" },
    { title: "Price" },
    { title: " Status" },
  ];

  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
  };

  // Fetching the data to be displayed
  let url = `/admin/get-all-items`;
  const {
    data: productSKU,
    loading,
    refetch,
  } = useGet({ url: url, payload: payload });
  let data = productSKU?.data?.data;

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteProductSku(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setDetail(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };
  return (
    <div className="productskuList">
      <Paper elevation={24} className="paper-div">
        <p>
          <span
            style={{
              color: "#3538CD",
              borderRadius: "5px",
              padding: "5px",
              backgroundColor: "#C7D7FE",
            }}
          >
            {data?.total} Items{" "}
          </span>
        </p>
        <TableHeader
          to="/product-sku/new-product-sku"
          placeholder="Search by product SKU"
          filterButton={true}
          filterByCountry
          filterByBrands
          filterByManageRulesCategory
        />
        <TableBodyComponent
          loading={loading}
          header={tableHeaders}
          total={data?.total}
        >
          {data?.data?.length ? (
            data?.data?.map((element) => (
              <TableRowComponent
                internal
                handleEye={() =>
                  navigate(`/new-product-sku-edit/${element?.id}`)
                }
                handleDelete={() => {
                  setDetail(element);
                  setOpenDelete(true);
                }}
                handleEdit={() =>
                  navigate(`/product-sku/edit-product-sku/${element?.id}`)
                }
                row={element}
                key={element?.id}
              >
                <TableCell style={{ fontWeight: "bold" }}>
                  <Barcode
                    value={element?.item_code}
                    textPosition={"left"}
                    fontSize={10}
                    width={1}
                    height={20}
                    margin={0}
                  />
                </TableCell>
                <TableCell>{element?.product_sku?.sku_code}</TableCell>
                <TableCell>{element?.product_sku?.name}</TableCell>
                <TableCell>
                  <span
                    style={{
                      backgroundColor: "#C7D7FE",
                      color: "#3538CD",
                      borderRadius: "5px",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "left",
                      width: "min-content",
                      marginBottom: "0px",
                    }}
                  >
                    {element?.country_id === 118
                      ? "Lebanon"
                      : element?.country_id === 190
                      ? "Saudi Arabia"
                      : element?.country_id === 228
                      ? "UAE"
                      : element?.country_id}
                  </span>
                </TableCell>
                <TableCell>{element?.item_cost}</TableCell>
                <TableCell>{element?.offered_price || "N/A"}</TableCell>
                <TableCell>
                  {element?.latest_action?.item_action?.item_status?.name?.en}
                </TableCell>
              </TableRowComponent>
            ))
          ) : (
            <DataNotFound />
          )}
        </TableBodyComponent>
      </Paper>
      {data && <Paginator count={data?.last_page} disabled={loading} />}
      {openDelete && (
        <NoticeConfirmation
          data={detail}
          handleResponse={handleDeleteResponse}
        />
      )}
    </div>
  );
};
export default withH1(ItemsList, "Items ");
