import { api } from "./main";

export const getProductUsageTypeById = async (id) => {
  return await api
    .get(`admin/get-product-usage-type-by-id/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const createProductUsageType = async (payload) => {
  return await api
    .post(`admin/create-product-usage-type`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const updateProductUsageType = async (payload) => {
  return await api
    .patch(`admin/update-product-usage-type/${payload?.id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const deleteProductUsageType = async (payload) => {
  return await api
    .delete(`admin/delete-product-usage-type/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
