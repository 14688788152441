import React, { useState } from "react";
import { Button, Popover, SvgIcon } from "@mui/material";
import { FilterSVG } from "../../assests/icons/SVG";
import "./FilterDropdown.scss";
export const FilterComponent = (props) => {
  const { children } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Button
        variant="contained"
        className="FilterSVG"
        onClick={handleClick}
        aria-label="filter button"
      >
        <SvgIcon component={FilterSVG} inheritViewBox fontSize={"large"} />
      </Button>
      <Popover
        className="filterComponent"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {children}
      </Popover>
    </>
  );
};
