import React, { useState } from "react";
import { Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./blogs.scss";
import Blogs from "./blogs";
import BlogsSection from "./blogsSection";
import BlogsSettings from "./blogsSettings"

const BlogsTab = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="blogs">
      <TabPanel
        value="8"
        style={{ minHeight: "600px", padding: "0px", marginTop: "10px" }}
      >
        <TabContext value={tabValue}>
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label="Blogs" value="0" />
            <Tab label="Blog Ad Section" value="1" />
            <Tab label="Blog Settings" value="2" />
          </TabList>

          <TabPanel value="0" style={{ minHeight: "600px", padding: "0px" }}>
            <Blogs></Blogs>
          </TabPanel>
          <TabPanel value="1" style={{ minHeight: "600px", padding: "0px" }}>
            <BlogsSection></BlogsSection>
          </TabPanel>
          <TabPanel value="2" style={{ minHeight: "600px", padding: "0px" }}>
            <BlogsSettings></BlogsSettings>
          </TabPanel>
        </TabContext>
      </TabPanel>
    </div>
  );
};

export default BlogsTab;
