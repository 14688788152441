//UTILITIES
import React, { useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
//COMPONENT

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  getNativeSelectUtilityClasses,
  Typography,
  Skeleton,
} from "@mui/material";

import {
  FormSwitch,
  FormTimePicker,
} from "../../components/formComponents/FormComponents";

export function CPOpenHours(props) {
  //VARIABLES
  const { data, errors, control, type, loading } = props;
  const { getValues, setValue, clearErrors } = props;
  const WeedDaysArray = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [expanded, setExpanded] = useState(true);
  //FUNCTIONS
  const getMin = (hour_from) => {
    if (hour_from) {
      let min_day = new Date("1995-12-17T" + hour_from);
      min_day.setHours(min_day.getHours() + 1);

      return min_day;
    }
  };

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="cp-open-hours">
      <Accordion
        expanded={type === "edit" ? expanded : true}
        onChange={handleExpanded}
      >
        <AccordionSummary
          expandIcon={type === "edit" ? <ExpandMoreIcon /> : null}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Opening hours</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!loading ? (
            getValues("opening_hours")?.map((element, index) => (
              <span className="body-row" key={index}>
                <p className="week-day">{WeedDaysArray[index]}</p>
                <div className="switch">
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <FormSwitch
                        name={`opening_hours.${index}.is_active`}
                        label={value === true ? "Open" : "Closed"}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          setValue(`opening_hours.${index}.hour_from`, "", {
                            shouldValidate: true,
                          });
                          setValue(`opening_hours.${index}.hour_to`, "", {
                            shouldValidate: true,
                          });
                        }}
                      />
                    )}
                    name={`opening_hours.${index}.is_active`}
                    control={control}
                  />
                </div>
                <div className="timePicker">
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <FormTimePicker
                        clearErrors={clearErrors}
                        name={`opening_hours.${index}.hour_from`}
                        value={value}
                        disabled={
                          getValues(`opening_hours.${index}.is_active`) === true
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          onChange(e);
                        }}
                        helperText={
                          errors?.opening_hours?.[index]?.hour_from?.message
                        }
                      />
                    )}
                    name={`opening_hours.${index}.hour_from`}
                    control={control}
                  />
                  To
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <FormTimePicker
                        clearErrors={clearErrors}
                        name={`opening_hours.${index}.hour_to`}
                        value={value}
                        disabled={
                          getValues(`opening_hours.${index}.is_active`) === true
                            ? false
                            : true
                        }
                        minTime={getMin(
                          getValues(`opening_hours.${index}.hour_from`),
                        )}
                        onChange={(e) => onChange(e)}
                        helperText={
                          errors?.opening_hours?.[index]?.hour_to?.message
                        }
                      />
                    )}
                    name={`opening_hours.${index}.hour_to`}
                    control={control}
                  />
                </div>
              </span>
            ))
          ) : (
            <Skeleton count={5} />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default CPOpenHours;
