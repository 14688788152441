import React, { useState } from "react";
import { Typography, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Sectionone from "./sectionone";
import Sectiontwo from "./sectiontwo";
import "./sellmydevice.scss";

const Sellmydevice = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="sellmydevice">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
          padding: "5px",
        }}
      >
      </div>
      <TabContext value={tabValue}>
        <TabList
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab label="Categories Section" value="0" />
          <Tab label="Info Section" value="1" />
        </TabList>
        <TabPanel
          value="0"
          style={{ minHeight: "600px", padding: "0px", marginTop: "10px" }}
        >
          <Sectionone></Sectionone>
        </TabPanel>
        <TabPanel
          value="1"
          style={{ minHeight: "600px", padding: "0px", marginTop: "10px" }}
        >
          <Sectiontwo></Sectiontwo>
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default Sellmydevice;
