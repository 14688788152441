import React, { useContext, useState } from "react";
import "./ResetPassword.scss";
import { AlertContext } from "../../../context/AlertContext";
import * as AuthApi from "../../../core/apis/auth";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/button/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { Input } from "../../../components/input/Input";
import { InputAdornment } from "@mui/material";
import { MailOutline } from "@mui/icons-material";

const loginSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

const ForgotPassword = () => {
  //1st step
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  let navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      const data = {
        user_type_id: 1,
        email: values.email,
      };
      setLoading(true);
      AuthApi.checkEmail(data).then((res) => {
        res.data.success &&
          navigate("/reset-password", { state: { email: data.email } });
        setAlert({
          ...alert,
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
      setLoading(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="forgot-password">
      <h2 className="forgot-password-header">Forgot Password</h2>
      <Input
        className="curvedInput"
        type="email"
        placeholder="Enter Email"
        name="email"
        id="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutline style={{ color: "#4BA4F0" }} />
            </InputAdornment>
          ),
        }}
      />
      <Button
        name="Continue"
        width="100%"
        selected
        loading={loading}
        disabled={!formik.dirty || loading}
        type="submit"
      />
    </form>
  );
};

export default ForgotPassword;
