import React, { useEffect } from "react";
import { Breadcrumbs as MUIBreadcrumbs } from "@mui/material";
import { ButtonBase, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "./Breadcrumbs.scss";
import { urlToTitle } from "../../core/functions/Functions";
const Breadcrumbs = (props) => {
  // const { items } = props;
  let location = useLocation().pathname.split("/");
  let navigate = useNavigate();
  const lastregex = new RegExp("[0-9]+");
  let last = location?.[location?.length - 1];
  let finalLocation = location?.filter(
    (el) => !lastregex.test(el) || el === "",
  );

  return (
    finalLocation.length > 2 && (
      <MUIBreadcrumbs
        aria-label="breadcrumbs"
        className="breadcrumbs"
        separator=">"
      >
        {finalLocation
          ?.slice(1, finalLocation?.length - 1)
          .map((item, index) => (
            <ButtonBase
              key={index}
              onClick={() => navigate(index - (finalLocation?.length - 2))}
            >
              {urlToTitle(item)}
            </ButtonBase>
          ))}

        <Typography className="last">
          {urlToTitle(finalLocation?.[finalLocation?.length - 1])}
        </Typography>
      </MUIBreadcrumbs>
    )
  );
};

export default Breadcrumbs;
