import React, { useState, useEffect, useContext } from "react";
import toast, { Toaster } from "react-hot-toast";
import { requestForToken, onMessageListener } from "./Firebase";
import { NotificationContext } from "../context/NotificationContext";

const Notification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const { updateNotificationCount } = useContext(NotificationContext);
  const notify = () => toast(<ToastDisplay />, { duration: 4000 });
  const ToastDisplay = () => (
    <div>
      <b>{notification?.title} </b>
      <p>{notification?.body}</p>
    </div>
  );

  useEffect(() => {
    notification?.title && notify();
  }, [notification]);
  requestForToken();
  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
      updateNotificationCount();
    })
    .catch((err) => console.log("failed: ", err));

  return <Toaster />;
};

export default Notification;
