// EXTERNAL LIBRARIES
import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import * as svg from "../../../assests/icons/SVG";

import { yupResolver } from "@hookform/resolvers/yup";
import CustomTab from "../../../components/Tabs/customTab";
import { USAFlagIcon } from "../../../assests/icons/SVG";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import KSAFlag from "../../../assests/Images/united-ara.png";
import { Card, CardContent, IconButton, Skeleton } from "@mui/material";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import Cropper from "../../../components/singleUpload/imageCropper";

// STYLING
import "./AttributeDetails.scss";
import "../Attributes.scss";
import {
  PlusCircleSVG,
  DeleteSVG,
  DragBlueSVG,
  CloseSVG,
  EditSVG,
} from "../../../assests/icons/SVG";

// CUSTOM HOOKS
import { useAxios, useGet } from "../../../hooks/useFetch";
import { AlertContext } from "../../../context/AlertContext";

// FORM
import {
  FormCheckBox,
  FormInput,
  FormMultiSelect,
} from "../../../components/formComponents/FormComponents";
import Button from "../../../components/button/Button";
import { api } from "../../../core/apis/main";
import { useGlobalValues } from "../../../context/GlobalContext";
import { updateAttribute } from "../../../core/apis/attribute";

// COMPONENTS
import AttributeItem from "../AttributeItem";

const HandleSchema = yup.object({
  name: yup.string("Field is invalid").required("Field is required"),
  estimation_factor: yup.bool(),
  attribute_values: yup.array().of(
    yup.object().shape({
      name: yup.string("Field is invalid").required("Field is required"),
      id: yup.number().nullable(),
    })
  ),
  category_ids: yup
    .array()
    .min(1, "Field is required")
    .required("Field is required"),
});

const AttributeEdit = (props) => {
  // DATA AND HOOKS
  const params = useParams();
  let navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  const {
    fields: attributesFields,
    remove: attributesRemove,
    append: attributesAppend,
  } = useFieldArray({ control, name: "attribute_values" });

  const { data: categories, categoriesLoading } = useGet({
    url: "admin/get-all-categories",
  });

  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [drag, setDrag] = useState(false);
  const [update, setUpdate] = useState(false);
  const [idArray, setIdArray] = useState([]);

  const [tabvalue, setTabValue] = useState("en");

  const [imageVal, setImageValue] = useState(null);

  const { enabledLanguages } = useGlobalValues();

  // API REQUESTS
  const {
    data: dataAttribute,
    loading: loading,
    callAPI: getAttribute,
  } = useAxios(`admin/get-attribute-by-id/${params.id}`, "get");

  let data = dataAttribute?.data?.data;
  let attributeValues = data?.attribute_value;

  //FUNCTIONS
  const handleAddValue = () => {
    attributesAppend({
      name: "",
      id: null,
    });
  };
  // Patches drag data
  const patchData = async () => {
    setLoadingOrder(true);
    return await api
      .patch("/admin/update-attribute-values-order", {
        attribute_id: data?.id,
        attribute_value_ids: idArray,
      })
      .then((res) => {
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
        getAttribute();
        setUpdate(false);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingOrder(false);
      });
  };

  // Updates attribute
  const handleSubmitForm = (formData) => {
    let valuesData = new FormData();
    valuesData.append("id", params.id);
    valuesData.append("name", formData?.name);
    valuesData.append("language", formData.language);
    valuesData.append("estimation_factor", formData?.estimation_factor ? 1 : 0);

    // UPDATE NOT TO SEND IT NULL IF ID IS NOT AVAILABLE
    formData.attribute_values.forEach((el, index) => {
      valuesData.append(`attribute_values[${index}][id]`, el.id ? el.id : "");
      valuesData.append(`attribute_values[${index}][name]`, el.name);
      valuesData.append(
        `attribute_values[${index}][abbreviation]`,
        el.abbreviation
      );
    });

    formData.category_ids.forEach((el, index) => {
      valuesData.append(`categories_ids[${index}]`, el.id);
    });

    if (imageVal != null) {
      valuesData.append("image", imageVal);
    }

    setLoadingForm(true);

    updateAttribute(params.id, valuesData)
      .then((res) => {
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
        if (res?.data?.success) {
          setUpdate(false);
          navigate("/variants");
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingForm(false);
      });
  };

  // Drag sensors for external library
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Set array on drag change
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setIdArray((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        // setUpdate(true);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  // Extract ids for drag array
  const extractIds = () => {
    let array = [];
    attributeValues?.map((element) => {
      array.push(element.id);
    });
    setIdArray(array);
  };

  // Extract ids on drag and sets input values in array
  useEffect(() => {
    if (drag) {
      extractIds();
    } else if (!drag) {
      reset({
        estimation_factor: data?.is_estimation_factor,
        name: data?.name?.[tabvalue] || data?.name?.en || "",
        language: tabvalue,
        image_path: data?.image_path,
        category_ids: data?.categories,
        attribute_values: attributeValues?.map((el) => ({
          name: el?.name?.[tabvalue],
          id: el?.id,
          abbreviation: el.abbreviation,
        })),
      });
    }
  }, [data, drag, tabvalue]);

  // On drag save, call api and update
  useEffect(() => {
    if (update) {
      patchData();
    }
  }, [update, idArray]);

  // Get attributes on mount
  useEffect(() => {
    getAttribute();
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleImage = (croppedImage) => {
    console.log(croppedImage);
    setImageValue(croppedImage);
  };

  return loading || loadingOrder ? (
    <Skeleton count={10} />
  ) : (
    <div className="attributeDetails">
      <div className={tabvalue != "ar" ? "head" : "head arabic-component"}>
        <h1>{data?.name?.[tabvalue] || data?.name?.en}</h1>
        <div
          className="drag"
          onClick={() => {
            setDrag(!drag);
          }}
        >
          {tabvalue != "ar" ? !drag ? <DragBlueSVG /> : "Cancel" : ""}
          {drag && <p onClick={() => setUpdate(true)}>Save</p>}
        </div>
      </div>

      {!drag ? (
        <TabContext value={tabvalue}>
          <Tabs
            value={tabvalue}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            {enabledLanguages.map((item, index) => (
              <CustomTab
                index={index}
                value={item.code}
                label={item.name}
                icon={
                  item.code == "en" ? (
                    <USAFlagIcon />
                  ) : item.code == "ar" ? (
                    <img src={KSAFlag} width="30" />
                  ) : (
                    ""
                  )
                }
              />
            ))}
          </Tabs>
          <form
            onSubmit={handleSubmit(handleSubmitForm)}
            className={tabvalue != "ar" ? "" : "  arabic-component"}
          >
            <Card className="attCard">
              <Cropper
                imageSrc={data?.image_path}
                sendCroppedFile={handleImage}
                MAX_IMAGE_WIDTH={400}
                MAX_IMAGE_HEIGHT={400}
                sizeMsg={"400*400"}
                iwebp={false}
              />

              <CardContent>
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      required
                      label="Variant Name"
                      placeholder="Enter name"
                      name="name"
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      helperText={error?.message}
                      hideVariant={true}
                    />
                  )}
                  name={`name`}
                  control={control}
                />
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormMultiSelect
                      required
                      data={categories?.data?.data?.categories || []}
                      loading={categoriesLoading}
                      label="Categories"
                      name="category_ids"
                      clearErrors={clearErrors}
                      dependencyError={["category_ids"]}
                      placeholder={"Select Categories"}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      helperText={error?.message}
                      hideVariant={true}
                    />
                  )}
                  name="category_ids"
                  control={control}
                />

                {attributesFields?.length !== 0 &&
                  attributesFields?.map((el, attributeIndex) => {
                    return (
                      <div
                        key={`${attributeIndex}_${attributesFields.length}`}
                        className="dynamic-attribute-add"
                      >
                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormInput
                              required
                              label={`Variantion ${attributeIndex + 1}`}
                              placeholder="Enter name"
                              name={`attribute_values.${attributeIndex}.name`}
                              value={value}
                              onChange={(e) => onChange(e?.target.value)}
                              helperText={error?.message}
                              hideVariant={true}
                            />
                          )}
                          name={`attribute_values.${attributeIndex}.name`}
                          control={control}
                        />

                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormInput
                              required
                              label={`Abbreviation ${attributeIndex + 1}`}
                              placeholder="Enter Abbreviation"
                              name={`attribute_values.${attributeIndex}.abbreviation`}
                              value={value}
                              onChange={(e) => onChange(e?.target.value)}
                              helperText={error?.message}
                              hideVariant={true}
                            />
                          )}
                          name={`attribute_values.${attributeIndex}.abbreviation`}
                          control={control}
                        />
                        {attributesFields.length > 1 && (
                          <IconButton
                            onClick={() => attributesRemove(attributeIndex)}
                          >
                            <DeleteSVG />
                          </IconButton>
                        )}
                      </div>
                    );
                  })}
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormCheckBox
                      name={`estimation_factor`}
                      label="Estimation Factor"
                      value={value == true ? true : false}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      helperText={error?.message}
                    />
                  )}
                  name={`estimation_factor`}
                  control={control}
                />

                <div className="action-footer">
                  <Button
                    name={"Save"}
                    selected
                    type="submit"
                    loading={loadingForm}
                    disabled={loadingForm}
                  />
                </div>
              </CardContent>
            </Card>
            <div onClick={() => handleAddValue()} className="add">
              <PlusCircleSVG />
              <p>Add New Variation</p>
            </div>
          </form>
        </TabContext>
      ) : (
        <>
          {drag && idArray && (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={idArray || []}
                strategy={verticalListSortingStrategy}
              >
                {idArray?.map((id) => (
                  <AttributeItem
                    key={id}
                    id={id}
                    element={attributeValues?.filter((el) => {
                      return el.id === id;
                    })}
                  />
                ))}
              </SortableContext>
            </DndContext>
          )}
        </>
      )}
    </div>
  );
};

export default AttributeEdit;
