import { api } from "./main";

export const getAllCategories = (payload) => {
  return api
    .get("admin/get-all-categories", { params: { ...payload } })
    .then((res) => res)
    .catch((err) => err);
};

export const createCategory = async (data) => {
  return await api
    .post("admin/create-category", data)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getCategoryDetails = async (id) => {
  return await api
    .get(`admin/get-category-by-id/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deleteCategory = async (id) => {
  return await api
    .delete(`admin/delete-category/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const moveCategory = async (id, selectedParentId) => {
  return await api
    .patch(`admin/move-category/${id}`, { parent_id: selectedParentId })
    .then((res) => res)
    .catch((err) => console.error(err));
};
