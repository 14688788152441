import React, { useContext, useState } from "react";
import { Menu } from "@mui/icons-material";
import { Link } from "react-router-dom";
import "./SideMenu.scss";
import { IconButton, ButtonBase, styled, Accordion } from "@mui/material";
import { Drawer } from "@mui/material";
import { AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { LogoWithTextSVG } from "../../assests/icons/SVG";
import * as svg from "../../assests/icons/SideMenuSVG";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { SideMenuContext } from "../../context/SideMenuContext";

export const data = [
  {
    logo: <svg.DashboardSVG />,
    to: "/dashboard",
    d: false,
    title: "Dashboard",
  },
  {
    logo: <svg.UserSVG />,
    drop: true,
    title: "Users",
    values: [
      { to: "/organizations", d: false, title: "Organizations" },
      { to: "/branches", d: false, title: "Branches" },
      { to: "/users", d: false, title: "Users" },
      { to: "/collection-points", d: false, title: "Collection Points" },
      { to: "/countries", d: false, title: "Countries" },
    ],
  },
  // { logo: <svg.AdvertisingSVG />, to: "/banners", title: "Banners" },
  { logo: <svg.RibbonSVG />, to: "/ribbons", title: "Ribbons" },
  {
    logo: <svg.HomepageGroupSVG />,
    to: "/homepage-groups",
    d: false,
    title: "Homepage Group",
  },
  {
    logo: <svg.EmailsAndNotificationsSVG />,
    drop: true,
    d: true,
    title: "Notification",
    values: [
      { to: "/emails-&-notifications", d: false, title: "Notifications " },
      { to: "/user-groups", d: false, title: "Notifications Group" },
    ],
  },
  // { logo: <svg.RolesSVG />, to: "/roles", d: true, title: "Roles" },
  {
    logo: <svg.SetOfQuestionsSVG />,
    to: "/set-of-questions",
    d: false,
    title: "Set of Questions",
  },
  {
    logo: <svg.BrandAndProductTypeSVG />,
    drop: true,
    title: "Products",
    values: [
      { to: "/brands", d: false, title: "Brands" },
      { to: "/usage-types", d: false, title: "Usage Types" },
      { to: "/item-identities", d: false, title: "Item Identities" },
      { to: "/categories", d: false, title: "Categories" },
      { to: "/products", d: false, title: "Products" },
      { to: "/product-sku", d: false, title: "Product SKU" },
      { to: "/transfer", d: false, title: "Transfer" },
    ],
  },
  {
    logo: <svg.BrandAndProductTypeSVG />,
    drop: true,
    title: "Manage Picea ",
    values: [
      { to: "/picea-users", d: false, title: "Users" },
      { to: "/picea-workflows", d: false, title: "WorkFlows" },
    ],
  },

  { logo: <svg.VariantsSVG />, to: "/variants", d: false, title: "Variants" },
  { logo: <svg.DiscountSVG />, to: "/discounts", d: false, title: "Discounts" },

  {
    logo: <svg.OpenTransactionsSVG />,
    drop: true,
    title: "Transactions",
    values: [
      { to: "/open-transactions", d: false, title: "Open" },
      {
        to: "/historical-transactions",
        d: false,
        title: "Historical",
      },
      {
        to: "/logistic-companies",
        d: false,
        title: "Logistic Companies",
      },
    ],
  },

  {
    logo: <svg.FilesSVG />,
    drop: true,
    title: "Reports ",
    values: [
      { to: "/exports", d: false, title: "Export" },
      { to: "create-reports", d: false, title: "Create Reports " },
    ],
  },

  {
    logo: <svg.PaymentInformationSVG />,
    drop: true,
    title: "Settings",
    values: [
      { to: "/country-setting", d: false, title: "Country Setting" },
      { to: "/vat", d: false, title: "VAT" },
      {
        to: "/currency-setting",
        d: false,
        title: "Currency Setting",
      },
      { to: "/payment-information", d: false, title: "Payment Management" },

      { to: "/Language-settings", d: false, title: "Language Setting" },
    ],
  },

  {
    logo: <svg.BrandAndProductTypeSVG />,
    drop: true,
    title: "Manage Retailer Info",
    values: [
      { to: "/manage-retailer", d: false, title: "Welcome Page" },
      {
        to: "/Retailer-info-page-data",
        d: false,
        title: "Info Headlines",
      },
    ],
  },
  {
    logo: <AddBusinessIcon />,
    title: "Store Customization",
    to: "/store-customizations/banners",
    d: false,
  },

  {
    logo: <svg.ExceptionIconSVG />,
    to: "/exceptions",
    d: false,
    title: "Exceptions",
  },
];
const AccordionComp = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `none`,
  "&:not(:last-child)": {
    borderBottom: "0",
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummaryComp = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetailsComp = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "none",
}));

const SideMenu = () => {
  const { sideMenu, setSideMenu } = useContext(SideMenuContext);
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Drawer variant="persistent" open={sideMenu} sx={{ width: 230 }}>
      <div className="side-menu-header">
        <LogoWithTextSVG className="LogoSVG" />
        <IconButton
          onClick={() => setSideMenu(false)}
          style={{ color: "black" }}
          aria-label=""
        >
          <Menu style={{ fontSize: "25px" }} />
        </IconButton>
      </div>
      <div className="sidebarContent">
        {data?.map((item, i) =>
          item.drop === false || !item?.drop ? (
            <ButtonBase
              component={Link}
              key={i}
              value={item.to}
              to={item.to}
              icon={item.logo}
              disabled={item.d}
              className="linkSide"
            >
              {item.logo} {item.title}
            </ButtonBase>
          ) : (
            <AccordionComp
              key={i}
              sx={{ width: 230 }}
              onChange={handleChange("panel1")}
              className="accordionSide"
            >
              <AccordionSummaryComp
                sx={{ width: 230 }}
                aria-controls="panel1d-content"
                id="panel1d-header"
                className="accordionSummary"
              >
                <Typography className="linkLogo">
                  {item.logo} {item.title}
                </Typography>
              </AccordionSummaryComp>
              <AccordionDetailsComp
                className="accordionDetails"
                sx={{ width: 230 }}
              >
                <Typography className="dropdown">
                  {item.values?.map((el, i) => (
                    <ButtonBase
                      component={Link}
                      key={i}
                      value={el.to}
                      to={el?.d ? "" : el.to}
                      disabled={el?.d}
                      className={el?.d ? "linkDrop disabled" : "linkDrop"}
                    >
                      {el.title}
                    </ButtonBase>
                  ))}
                </Typography>
              </AccordionDetailsComp>
            </AccordionComp>
          )
        )}
      </div>
    </Drawer>
  );
};
export default SideMenu;
