import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid, Skeleton } from "@mui/material";
import Button from "../../../components/button/Button";
import {
  FormInput,
  FormTextArea,
} from "../../../components/formComponents/FormComponents";
import { useGet } from "../../../hooks/useFetch";
import { useGlobalValues } from "../../../context/GlobalContext";
import { updateSectionone } from "../../../core/apis/store";
import { AlertContext } from "../../../context/AlertContext";
import "./contactUs.scss";

const FindusSection = () => {
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();
  const [isFetchingData, setIsFetchingData] = useState(true);

  // Schema validation
  const informationsectionSchema = yup.object({
    title: yup.string().nullable(),
    paragraph: yup.string().nullable(),
  });

  const handleCancel = () => {
    refetch();
  };

  const defaults = {
    title: "",
    subTitle: "",
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(informationsectionSchema),
    defaultValues: defaults,
  });

  // Fetch section details by slug
  const {
    data: sectionDetails,
    loading: loadingDetails,
    refetch,
  } = useGet({
    url: "admin/section/FIND_US_SECTION",
  });

  const contactusArray = sectionDetails?.data?.data;
  console.log(contactusArray);

  const handleSubmitForm = async (values) => {
    console.log(values, "values");
    setLoading(true);

    const payload = {
      language: storeLanguage,
      title: values.title,
      paragraph: values.paragraph,
    };

    updateSectionone("FIND_US_SECTION", payload).then((res) => {
      setAlert({
        visible: true,
        text: res?.data.success ? "Successfully Updated" : res?.data?.message,
        type: res?.data?.success ? "success" : "error",
      });
      refetch();
      setLoading(false);
    });
  };

  useEffect(() => {
    if (contactusArray) {
      setIsFetchingData(true);

      const titleObject = contactusArray?.columns?.find(
        (item) => item.language === storeLanguage && item.title
      );
      const paragraphObject = contactusArray?.columns?.find(
        (item) => item.language === storeLanguage && item.paragraph
      );

      const populatedFormData = {
        title: titleObject?.title,
        paragraph: paragraphObject?.paragraph,
      };

      setValue("title", populatedFormData.title);
      setValue("paragraph", populatedFormData.paragraph);

      setIsFetchingData(false);
    }
  }, [contactusArray, storeLanguage, setValue]);

  return (
    <div className="findusSection">
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={40} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="title"
                        label="Form Title"
                        hideVariant
                        placeholder="Enter Find us Title"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="title"
                    control={control}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width="100%" height={120} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormTextArea
                        required
                        hideVariant
                        name="paragraph"
                        label="Form sub-Paragraph"
                        placeholder="Enter Find us sub-paragraph"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="paragraph"
                    control={control}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="flex-end"
            spacing={2}
            style={{ marginTop: "5px" }}
          >
            <Grid item>
              {isFetchingData ? (
                <Skeleton variant="rectangular" width={100} height={40} />
              ) : (
                <Button
                  onClick={() => handleCancel()}
                  name={"Cancel"}
                  type="button"
                  className="mx-1"
                />
              )}
            </Grid>
            <Grid item>
              {isFetchingData ? (
                <Skeleton variant="rectangular" width={100} height={40} />
              ) : (
                <Button
                  selected
                  name={"Save"}
                  type="submit"
                  className="mx-1"
                  loading={loading}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default FindusSection;
