import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import React from "react";

const ReactQueryProvider = (props) => {
  const { children } = props;
  // Create a client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        staleTime: 1000 * 10,
      },
    },
  });
  const localStoragePersister = createSyncStoragePersister({
    storage: window.localStorage,
  });
  persistQueryClient({
    queryClient,
    persister: localStoragePersister,
  });

  return (
    <QueryClientProvider client={queryClient}>{children} </QueryClientProvider>
  );
};

export default ReactQueryProvider;
