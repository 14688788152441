import "./NoData.scss";
export default function NoData(props) {
  return (
    <div
      className="no-data"
      style={{ height: props.height ? props.height : "" }}
    >
      <h4> No data found</h4>
    </div>
  );
}
