//UTILITIES
import React, { useCallback, useRef, useState } from "react";
import { useGet } from "../../hooks/useFetch";
import { useSearchParams } from "react-router-dom";
//COMPONENT
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Search from "../../components/search/Search";
import {
  CardContent,
  TableCell,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Typography,
} from "@mui/material";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import {
  groupParamsByKey,
  objectCleaner,
} from "../../core/functions/Functions";
import Paginator from "../../components/paginator/Paginator";
//CSS
import "./Organizations.scss";

export function OrganizationsBranches(props) {
  //VARIABLES
  const { organizationId } = props;
  const setTimerRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");

  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };
  let url = `admin/get-all-branches`;
  const { data: organizationBranches, loading } = useGet({
    url: url,
    payload: {
      organization_id: organizationId,
      ...payload,
    },
  });
  let data = organizationBranches?.data.data;
  const tableHeaders = [
    { title: "Full Name", sorted: false },
    { title: "Country", sorted: false },
  ];

  const handleSearch = useCallback(
    (e) => {
      setSearch(e.target.value);
      if (setTimerRef.current) {
        clearTimeout(setTimerRef.current);
        setTimerRef.current = null;
      }

      setTimerRef.current = setTimeout(() => {
        let url = {
          ...groupParamsByKey(searchParams),
          page: 1,
          search: e.target.value,
        };
        setSearchParams(objectCleaner(url));
        clearTimeout(setTimerRef.current);
        setTimerRef.current = null;
      }, 1000);
    },
    [search],
  );

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="organization-branches-header">
          <Typography>Branches</Typography>
          <div>{`${data?.total ? data?.total : 0} total branches`}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <CardContent className="no-padding-top">
          <div className="organizations-branches">
            <div className="search-section">
              <Search
                className="search"
                placeholder={"Search by branch name"}
                onChange={handleSearch}
                value={search}
                curved
              />
            </div>

            <TableBodyComponent
              header={tableHeaders}
              loading={loading}
              internal={true}
              actions={false}
            >
              {data?.data?.map((element) => (
                <TableRowComponent
                  row={element}
                  internal={true}
                  actions={false}
                >
                  <TableCell>{element.name}</TableCell>
                  <TableCell>{element.address?.[0]?.country?.name}</TableCell>
                </TableRowComponent>
              ))}
            </TableBodyComponent>
            <Paginator count={data?.last_page} disabled={loading} />
          </div>
        </CardContent>
      </AccordionDetails>
    </Accordion>
  );
}

export default OrganizationsBranches;
