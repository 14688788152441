//https:apidev-v2.2use.com/api/documentation#/Attribute/getAttributeByIdapidev-v2.2use.com/api/documentation#/Attribute
import { api } from "./main";
export const getAllAttributes = async (payload) => {
  return await api
    .get("admin/get-all-attributes", { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const getAttributeById = async (id) => {
  return await api
    .get(`admin/get-attribute-by-id/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const createAttribute = async (payload) => {
  return await api
    .post("admin/create-attribute", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateAttribute = async (id,payload) => {
  return await api
    .post(`admin/update-attribute/${id}?_method=PUT`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateAttributeOrder = async (payload) => {
  return await api
    .patch("admin/update-attributes-order", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const updateAttributeValueOrder = async (payload) => {
  return await api
    .patch("admin/update-attribute-values-order", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deleteAttributes = async (payload) => {
  return await api
    .delete(`admin/delete-attribute/${payload}`, { params: { ...payload } })
    .then((res) => res)
    .catch((err) => console.error(err));
};
