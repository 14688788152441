//MARILYNN
import { api } from "./main";

export const getAllCountries = async (payload) => {
  return await api
    .get("get-all-countries", { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const getCountryById = async (id) => {
  return await api
    .get(`admin/get-country-by-id/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const createCountry = async (payload) => {
  return await api
    .post("admin/create-country", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateCountry = async (payload) => {
  return await api
    .put(`admin/update-country/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateCountryVat = async (payload) => {
  return await api
    .patch(`admin/update-country-vat/${payload?.country}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
//to hide or unhide country from enduser list and mobile app
export const updateCountryStatus = async (payload) => {
  return await api
    .patch(`admin/hide-unhide-country/${payload}`, { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getAllCountriesAdmin = async (payload) => {
  return await api
    .get("admin/get-all-countries", { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};

// export const useGetAllCountriesAdmin = () =>
//   useQuery({
//     queryKey: [`get-countries`],
//     queryFn: () => getAllCountriesAdmin().then((res) => res),
//   });
