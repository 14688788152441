import { api } from "./main";

export const getAllBranchesCountries = async (payload) => {
  return await api
    .get(`admin/get-branches-countries`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const CreateBranch = async (payload) => {
  return await api
    .post("admin/create-branch", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const EditBranch = async (payload) => {
  return await api
    .put(`admin/update-branch/${payload?.branch_id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deleteBranch = async (payload) => {
  return await api
    .delete(`admin/delete-branch/${payload}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getBranchById = async (id) => {
  return await api
    .get(`admin/get-branch-by-id/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
