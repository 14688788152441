//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

//COMPONENT
import Button from "../../../components/button/Button";
import { useForm, Controller } from "react-hook-form";
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
} from "@mui/material";
import {
  FormCheckBox,
  FormDropdownList,
  FormMultiSelect,
} from "../../../components/formComponents/FormComponents";
import { useGet } from "../../../hooks/useFetch";
import { CloseSVG } from "../../../assests/icons/SVG";
import { AlertContext } from "../../../context/AlertContext";
import { updateCurrencySetting } from "../../../core/apis/Currency";
import { updatePaymentGateway } from "../../../core/apis/payment";

const HandleSchema = yup.object({
  select_all_countries: yup.boolean(),
  country_ids: yup
    .array()
    .of(yup.object())
    .when("select_all_countries", {
      is: false,
      then: yup
        .array()
        .of(yup.object())
        .min(1, "Field is required")
        .required("Field is required"),
      otherwise: yup.array().of(yup.object()),
    }),
});

export function PaymentGatewayHandle(props) {
  const { data, onClose, refetchData } = props;

  //GET THE DATA
  const { data: countries, countriesLoading } = useGet({
    url: `/get-all-countries`,
  });

  const { data: paymentById, loading } = useGet({
    url: `/admin/get-payment-gateway-by-id/${data?.id}`,
  });

  let countriesArray = countries?.data?.data || [];

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  const { setAlert } = useContext(AlertContext);
  const [saveLoading, setSaveLoading] = useState(false);

  const handleSubmitForm = (formData) => {
    setSaveLoading(true);

    updatePaymentGateway({
      ...formData,
      country_ids: formData?.country_ids?.map((el) => el?.id),
    }).then((response) => {
      if (response?.data.success) {
        refetchData();
        onClose();
      }
      setAlert({
        text: response.data ? response.data?.message : response.message,
        type: response.data?.success ? "success" : "error",
      });

      setSaveLoading(false);
    });
  };

  useEffect(() => {
    if (paymentById) {
      reset({
        select_all_countries:
          paymentById?.data?.data.select_all_countries || false,
        country_ids: paymentById?.data?.data.country || [],
        id: paymentById?.data?.data.id || null,
      });
    }
  }, [paymentById]);

  return (
    <Dialog disablebackdropclick="true" fullWidth open={true}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {`Update ${data?.name?.en} Information`}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseSVG />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="product-questions">
          {!loading ? (
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormMultiSelect
                    required
                    label="Countries"
                    data={countriesArray}
                    loading={countriesLoading}
                    name={`country_ids`}
                    disabled={
                      getValues("select_all_countries") === true ? true : false
                    }
                    clearErrors={clearErrors}
                    dependencyError={["country_ids"]}
                    placeholder={"Select countries"}
                    value={value}
                    onChange={(e) => onChange(e)}
                    helperText={error?.message}
                  />
                )}
                name={`country_ids`}
                control={control}
              />
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormCheckBox
                    name="select_all_countries"
                    label="Select all countries"
                    value={value === true ? true : false}
                    onChange={(e) => {
                      onChange(e?.target.checked);
                      setValue("country_ids", [], { shouldValidate: true });
                    }}
                  />
                )}
                name={`select_all_countries`}
                control={control}
              />

              <div className="action-footer">
                <Button
                  name={"Save"}
                  selected
                  loading={saveLoading}
                  disabled={saveLoading}
                  width={"30%"}
                  type="submit"
                />
              </div>
            </form>
          ) : (
            <Skeleton count={10} />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default PaymentGatewayHandle;
