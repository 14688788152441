import React, { useState } from "react";
import "./AccountDetails.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AccountAccordion from "../../components/AccountAccordion/AccountAccordion";
import PersonalInfo from "./AccountDetailsContent/PersonalInfo/PersonalInfo";
import ChangePassword from "./AccountDetailsContent/ChangePassword/ChangePassword";
import { Box, Tab, Tabs } from "@mui/material";
import withH1 from "../../assests/HOC/withH1";

const AccountDetails = () => {
  let location = useLocation().pathname.split("/")[2];
  let navigate = useNavigate();
  const [value, setValue] = useState(location);
  const handleChange = (_, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };
  const data = [
    { title: "Personal", content: <PersonalInfo />, link: "personal-info" },
    {
      title: "Change Password",
      content: <ChangePassword />,
      link: "change-password",
    },
  ];
  return (
    <>
      <div className="account_details">
        <Box>
          <Tabs
            value={location}
            onChange={handleChange}
            aria-label="tabs"
            className="tabs"
          >
            {data.map((item, index) => (
              <Tab label={item.title} value={item.link} key={index} />
            ))}
          </Tabs>
        </Box>
        <Outlet />
      </div>
      <div className="Responsive">
        {data.map((comp, index) => (
          <AccountAccordion
            key={index}
            title={comp.title}
            no={index + 1}
            content={comp.content}
            link={comp.link}
          />
        ))}
      </div>
    </>
  );
};

export default withH1(AccountDetails, "Account Details");
