import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as AuthApi from "../../../core/apis/auth";
import "./ResetPassword.scss";
import Button from "../../../components/button/Button";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { AlertContext } from "../../../context/AlertContext";

function ResetPassword() {
  // 2nd step
  let location = useLocation().state;
  const { setAlert } = useContext(AlertContext);
  const [radio, setRadio] = useState({ otp: false, email: true });
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const handleChange = (e) => {
    e.target.value === "otp"
      ? setRadio({ otp: true, email: false })
      : e.target.value === "email"
      ? setRadio({ otp: false, email: true })
      : setRadio({ otp: false, email: false });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = {
      user_type_id: 1,
      email: location?.email,
    };
    radio.otp
      ? await AuthApi.resetPasswordByOTP(data).then((res) => {
          setAlert({
            visible: true,
            text: res.data.message,
            type: res.data.success ? "success" : "error",
          });
          res.data.success &&
            navigate(`/reset-password/by-OTP`, {
              state: { email: location?.email },
            });
        })
      : radio.email &&
        (await AuthApi.resetPasswordByEmail(data).then((res) => {
          setAlert({
            visible: true,
            text: res.data.success && res.data.message,
            type: res.data.success ? "success" : "error",
          });
          res.data.success && setTimeout(() => navigate("/login"), 2000);
        }));
    setLoading(false);
  };

  return (
    <div className="forgot-password">
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit} className=" resetPasswordForm">
        <RadioGroup defaultValue="email" name="radio-buttons-group">
          <FormControlLabel
            value="email"
            control={<Radio />}
            id="email"
            label="Reset by Email"
            onChange={handleChange}
          />
          <FormControlLabel
            value="otp"
            control={<Radio />}
            label="Reset by OTP"
            id="otp"
            onChange={handleChange}
          />
        </RadioGroup>
        <Button
          id="loginBtn"
          onClick={handleSubmit}
          name="Continue"
          width="100%"
          selected
          loading={loading}
        />
      </form>
    </div>
  );
}

export default ResetPassword;
