import React, { useState, useContext } from "react";
import "./banners.scss";
import AddBanners from "../Banners/addBanners";
import { AlertContext } from "../../../context/AlertContext";
import TableBodyComponent from "../../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../../components/table/tableBody/TableRowComponent";
import { TableCell } from "@mui/material";
import Paginator from "../../../components/paginator/Paginator";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { useGlobalValues } from "../../../context/GlobalContext";

import { useGet } from "../../../hooks/useFetch";
//api
import { deleteBanner } from "../../../core/apis/store";

import Button from "../../../components/button/Button";

const Banners = () => {
  const tableHeaders = [
    { title: "Title" },
    { title: "Start Date" },
    { title: "End Date" },
    { title: "Status" },
  ];
  const { setAlert } = useContext(AlertContext);
  const [bannerID, setBannerID] = useState("");
  const [showAddBanners, setShowAddBanners] = useState(false);
  const [addBanner, setAddBanner] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const { storeLanguage } = useGlobalValues();

  let url = `admin/get-all-banners?language_code=${storeLanguage}`;
  const { data: banners, loading, refetch } = useGet({ url: url });

  let data = banners?.data.data;

  const handleToggleAddBlog = () => {
    setShowAddBanners((prevState) => !prevState); // Toggle state
  };

  const handleToggleEditQuestion = (id) => {
    setShowAddBanners(true);
    setBannerID(id);
  };

  const handleTogglefalse = () => {
    setBannerID(null);
    setShowAddBanners(false);
    refetch();
  };

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteBanner(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setAddBanner(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res?.data?.success
            ? "Deleted SuccessFully"
            : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  return (
    <div className="banners">
      {showAddBanners ? (
        <AddBanners
          onCancel={handleTogglefalse}
          onSave={handleTogglefalse}
          ID={bannerID}
        />
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginTop: "10px",
              padding: "5px",
            }}
          >
            <div>
              <Button
                selected
                style={{ marginleft: "5px", marginRight: "5px" }}
                width={"150px"}
                name="Add Banner"
                onClick={handleToggleAddBlog}
              />
            </div>
          </div>

          <div className="exportList">
            <TableBodyComponent
              header={tableHeaders}
              loading={loading}
              total={data?.total}
            >
              {data?.map((element) => (
                <TableRowComponent
                  internal
                  key={element?.id}
                  handleDelete={() => {
                    setAddBanner(element);
                    setOpenDelete(true);
                  }}
                  sx={{ color: "red" }} // Set the color to red}
                  handleEdit={() => {
                    handleToggleEditQuestion(element.id);
                  }}
                >
                  <TableCell>{element?.name}</TableCell>
                  <TableCell>{element?.start_date}</TableCell>
                  <TableCell>{element?.end_date}</TableCell>
                  <TableCell>
                    {element?.is_active ? "Active" : "Inactive"}
                  </TableCell>
                </TableRowComponent>
              ))}
            </TableBodyComponent>
            <Paginator count={data?.last_page} disabled={loading} />
            {openDelete && (
              <NoticeConfirmation
                data={addBanner}
                handleResponse={handleDeleteResponse}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Banners;
