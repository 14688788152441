import { api } from "./main";

export const getAllProductType = async () => {
  return await api
    .get("admin/get-all-product-type")
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getAllProducts = async (payload) => {
  return await api
    .get("admin/get-all-products", { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getProductByID = async (payload) => {
  return await api
    .get(`admin/get-product-by-id/${payload}`, { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateConditionFactor = async (payload) => {
  return await api
    .put(
      `admin/update-condition-factor-by-product-id/${payload?.product_id}`,
      payload
    )
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateAttributeValueFactor = async (payload) => {
  return await api
    .put(
      `admin/update-attribute-value-factor-by-product-id/${payload?.product_id}`,
      payload
    )
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const createProduct = async (payload) => {
  return await api
    .post(`admin/create-product`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateProduct = async (payload) => {
  return await api
    .post(`admin/update-product/${payload?.id}`, payload.data)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deleteProduct = async (payload) => {
  return await api
    .delete(`admin/delete-product/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getProductVariation = async (payload) => {
  return await api
    .get(`admin/get-all-attributes`, { params: { ...payload } })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getAllProductsCategory = async (payload) => {
  return await api
    .get(`admin/get-products-category`, { params: { ...payload } })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateproductfactorsbyid = async (payload, id) => {
  return await api
    .put(`admin/update-product-factors-by/${id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const UpdateAllProductsConditionFactor = async (id, payload) => {
  return await api
    .put(
      `admin/update-condition-factor-for-all-products-by-specified-category/${id}`,
      payload
    )
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const ValidatePrice = async (payload) => {
  return await api
    .post(`admin/evaluate-product-combination`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const UploadProductsPrices = async (payload) => {
  return await api
    .post(`admin/upload-products-prices`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const DownloadProductsPrices = async () => {
  return await api
    .get("admin/download-products-prices", {
      responseType: "blob",
    })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const DownloadProductsPricesBasedOnCategory = async (payload) => {
  return await api
    .post("admin/export-products", payload, {
      responseType: "blob",
    })
    .then((res) => res)
    .catch((err) => console.error(err));
};
