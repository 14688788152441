// GlobalContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { getalllanguagesfilterBystatus } from "../core/apis/newlanguage";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  // For Category Inorder to get all Catgeories BY Id
  const [enabledLanguages, setenabledLanguages] = useState([]);

  const setenabledLanguagesList = (value) => {
    setenabledLanguages(value);
  };

  //Store Default Language
  const [storeLanguage, setstoreLanguage] = useState("en");
  const setstoredefaultLanguage = (value) => {
    setstoreLanguage(value);
  };

  useEffect(() => {
    getalllanguagesfilterBystatus().then((res) => {
      const sortedLanguages = res?.sort((a, b) => {
        // Place English first by checking if the code is "en"
        if (a.code === "en") {
          return -1;
        } else if (b.code === "en") {
          return 1;
        }

        // For other languages, maintain the original order
        return 0;
      });
      setenabledLanguages(sortedLanguages);
    });
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        enabledLanguages,
        setenabledLanguagesList,
        storeLanguage, //state
        setstoredefaultLanguage, //function that make it accecible
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalValues = () => {
  return useContext(GlobalContext);
};
