//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

//COMPONENT
import Button from "../../components/button/Button";
import { useForm, Controller } from "react-hook-form";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import {
  FormInput,
  FormTextArea,
} from "../../components/formComponents/FormComponents";
import TitleComponent from "../../components/titleComponent/TitleComponent";
import { CloseSVG } from "../../assests/icons/SVG";
import { AlertContext } from "../../context/AlertContext";
import { UpdateRetailerhomepageCardById } from "../../core/apis/manageretailer";
import { UploadComponent } from "../../components/formComponents/FormComponents";
//CSS
import "./ManageRetailer.scss";

const HandleSchema = yup.object({
  title: yup.string("Invalid").nullable().required("Field is required"),
  description: yup.string("Invalid").nullable().required("Field is required"),
  icon: yup.mixed().required("Field is Required"),
});

export function ManageRetailerHandle(props) {
  const { data, onClose, languageValue } = props;

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  const { setAlert } = useContext(AlertContext);
  const [saveLoading, setSaveLoading] = useState(false);

  const handleSubmitForm = (formData) => {
    setSaveLoading(true);

    const valuesData = new FormData();
    valuesData.append("title", formData.title);
    valuesData.append("description", formData.description);
    valuesData.append("icon", formData.icon);
    valuesData.append("language", languageValue);
    valuesData.append("order", formData.order);

    if (formData?.icon instanceof File === false) {
      valuesData.delete("icon");
    }

    UpdateRetailerhomepageCardById(formData.id, valuesData).then((res) => {
      if (res?.data.success) {
        onClose();
      }
      setAlert({
        visible: true,
        text: res.data ? res.data?.message : res.message,
        type: res.data?.success ? "success" : "error",
      });
      setSaveLoading(false);
    });
  };

  useEffect(() => {
    if (data) {
      reset({
        id: data?.id,
        title: data?.title?.[languageValue]
          ? data?.title?.[languageValue]
          : data?.title?.en,
        description: data?.description?.[languageValue]
          ? data?.description?.[languageValue]
          : data?.description?.en,
        icon: "/" + data?.icon,
        order: data?.order,
      });
    }
  }, [data]);

  return (
    <Dialog disablebackdropclick="true" fullWidth open={true}>
      <DialogTitle>
        Update
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            "&:hover": {
              backgroundColor: "transparent", // Set the hover background color
            },
          }}
          style={{ justifyContent: "end" }}
        >
          <CloseSVG />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <form
          className={
            languageValue != "ar"
              ? "ManageRhandle"
              : " ManageRhandle arabic-component"
          }
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <div>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormInput
                  required
                  label="Title"
                  placeholder={""}
                  name="title"
                  value={value}
                  onChange={(e) => onChange(e)}
                  helperText={error?.message}
                />
              )}
              name={`title`}
              control={control}
            />
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormTextArea
                  required
                  rows="2"
                  label="Description"
                  placeholder={""}
                  name="description"
                  value={value}
                  onChange={(e) => onChange(e)}
                  helperText={error?.message}
                />
              )}
              name={`description`}
              control={control}
            />

            <div className="upload-section mb-4">
              <TitleComponent title="Upload Logo" />
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <UploadComponent
                    required
                    value={value}
                    name="icon"
                    setFieldValue={(field, value) => onChange(value)}
                    helperText={error?.message}
                  />
                )}
                name="icon"
                control={control}
              />
            </div>

            <div>
              <Button
                style={{ display: "block" }}
                title="Save"
                selected
                loading={saveLoading}
                width={"30%"}
                type="submit"
              />
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ManageRetailerHandle;
