//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AlertContext } from "../../context/AlertContext";
import { useGet } from "../../hooks/useFetch";
//COMPONENT
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import DialogComponent from "../../components/dialogs/dialog/Dialog";
import {
  ListItem,
  ListItemText,
  List,
  IconButton,
  Button,
  styled,
} from "@mui/material";
import { LeftArrowSVG, RightArrowSVG } from "../../assests/icons/SVG";
import { FormInput } from "../../components/formComponents/FormComponents";

//CSS
import "./ManageRules.scss";
import palette from "../../theme/color.scss";
import { updateAttributeValueFactor } from "../../core/apis/product";

export const CustomListItem = styled(ListItem)({
  cursor: "pointer",
  "&:hover": {
    backgroundColor: palette.lightPrimaryColor,
  },
});

export function ManageAttributesHandle(props) {
  //VARIABLES

  const { onClose, data } = props;
  const { setAlert } = useContext(AlertContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [estimationAttributes, setEstimationAttributes] = useState([]);
  const [attributeDetail, setAttributeDetail] = useState(null);
  const HandleSchema = yup.object({
    attribute_values_factor: yup.array().of(
      yup.object().shape({
        attribute_value_id: yup
          .object()
          .nullable()
          .required("Field is required"),
        factor: yup
          .number("Invalid Field")
          .nullable()
          .positive("Field should be positive"),
      })
    ),
  });

  //API
  const { data: attributeByProductId, loading } = useGet({
    url: `admin/get-product-estimation-attributes/${data?.id}`,
  });

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });
  const {
    fields: conditionFields,
    remove: conditionRemove,
    append: conditionAppend,
  } = useFieldArray({ control, name: "attribute_values_factor" });

  //FUNCTIONS
  const handleSubmitForm = (formData) => {
    let final_payload = {
      product_id: data?.id,
      deleted_ids: formData.attribute_values_factor
        .filter((val) => val.factor === null)
        .map((el) => el.attribute_value_id?.id),
      attribute_values_factor: formData.attribute_values_factor
        .filter((val) => val.factor !== null)
        .map((el) => ({
          ...el,
          attribute_value_id: el.attribute_value_id?.id,
        })),
    };

    if (final_payload.attribute_values_factor.length === 0) {
      setErrorMessage("One attribute at least should have a factor");
    } else {
      updateAttributeValueFactor(final_payload).then((res) => {
        if (res.data?.success) {
          onClose();
          setAlert({
            text: res.data ? res.data.message : res.message,
            type: res.data?.success ? "success" : "error",
          });
        } else {
          setErrorMessage(res.data ? res.data.message : res.message);
        }
      });
    }
  };

  useEffect(() => {
    if (attributeByProductId) {
      let data = attributeByProductId?.data;
      setEstimationAttributes(data?.attribute || []);
    }
    if (attributeDetail) {
      let array = attributeByProductId?.data?.attribute?.find(
        (val) => val.id === attributeDetail?.id
      );

      reset({
        attribute_values_factor: array?.attribute_value?.map((el) => ({
          attribute_value_id: el,
          factor:
            el?.product_factor.length !== 0
              ? el?.product_factor[0].factor
              : null,
        })),
      });
    }
  }, [attributeByProductId, attributeDetail]);

  return (
    <DialogComponent
      open={true}
      className="cardDialog addressDialog"
      handleOpenClose={onClose}
      buttonLabel={"Save"}
      title={`${data?.name?.en}`}
      loading={loading}
      //   disabled={!formik.dirty || loading}
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <div className="manage-attribute-handle-component">
        {!attributeDetail ? (
          <List>
            {estimationAttributes?.map((val) => (
              <CustomListItem
                onClick={() => setAttributeDetail(val)}
                secondaryAction={<RightArrowSVG />}
              >
                <ListItemText primary={val?.name?.en} />
              </CustomListItem>
            ))}
          </List>
        ) : (
          <>
            <div className="attribute-factor-header">
              <Button
                className="back-button"
                startIcon={<LeftArrowSVG fill={palette.primaryColor} />}
                onClick={() => setAttributeDetail(null)}
              >
                {attributeDetail?.name?.en}
              </Button>
            </div>
            <div className="attribute-factor-handle">
              {getValues("attribute_values_factor")?.map((element, index) => (
                <div className="form-handle">
                  <div className="label">
                    {element?.attribute_value_id?.name?.en}
                  </div>
                  <div>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <FormInput
                          required
                          type="number"
                          name={`attribute_values_factor.${index}.factor`}
                          placeholder={"Enter factor"}
                          value={value}
                          onChange={(e) => {
                            onChange(
                              e.target.value === "" ? null : e.target.value
                            );
                            setErrorMessage("");
                          }}
                          helperText={
                            errors?.attribute_values_factor?.[index]?.factor
                              ?.message
                          }
                        />
                      )}
                      name={`attribute_values_factor.${index}.factor`}
                      control={control}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="form-error-message">{errorMessage}</div>
          </>
        )}
      </div>
    </DialogComponent>
  );
}

export default ManageAttributesHandle;
