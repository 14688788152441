import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Grid, Skeleton, Switch } from "@mui/material";
import Button from "../../../components/button/Button";
import {
  FormInput,
  FormDropdownList,
} from "../../../components/formComponents/FormComponents";
import { useGet } from "../../../hooks/useFetch";
import { useGlobalValues } from "../../../context/GlobalContext";
import { updateSectionone } from "../../../core/apis/store";
import { AlertContext } from "../../../context/AlertContext";
import { Statues } from "../../../core/constants/constant";
import "./blogs.scss";
import { Container } from "@mui/system";

const BlogsSettings = () => {
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();
  const [isFetchingData, setIsFetchingData] = useState(true);

  const blogssectionSchema = yup.object({
    pageTitle: yup.string().nullable(),
    enable: yup.object().nullable(),
  });

  const defaults = {
    enable: true,
    pageTitle: "",
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(blogssectionSchema),
    defaultValues: defaults,
  });

  // Get Section Details by Slug
  const {
    data: blogssectionDetails,
    loading: blogsloadingDetails,
    refetch: refetchblogs,
  } = useGet({
    url: "admin/section/BLOGS_SECTION",
  });

  const blogssectionArray = blogssectionDetails?.data?.data;

  const handleCancel = () => {
    refetchblogs();
  };

  useEffect(() => {
    if (blogssectionArray) {
      // Check if either array exists
      console.log(blogssectionArray);
      setIsFetchingData(true);
      // New Objects
      const pageTitleObject = blogssectionArray?.columns?.find(
        (item) => item.title && item.language === storeLanguage
      );
      const enableObject = blogssectionArray?.columns?.find(
        (item) => item.is_enable && item.language === storeLanguage
      );
      const populatedFormData = {
        pageTitle: pageTitleObject?.title,
        enable:
          enableObject?.is_enable === "1"
            ? { value: "active", name: "Active" }
            : { value: "inactive", name: "In-active" },
      };
      // Setting form values using setValue
      Object.keys(populatedFormData).forEach((key) => {
        setValue(key, populatedFormData[key]);
      });
      setIsFetchingData(false);
    } else {
      setIsFetchingData(false); // Ensure to set fetching data to false if both arrays are undefined or null
    }
  }, [blogssectionArray, storeLanguage, setValue]);

  const handleSubmitForm1 = async (values) => {
    setLoading(true);
    const payload = {
      language: storeLanguage,
      title: values.pageTitle,
      is_enable: values.enable.value === "active" ? 1 : 0,
    };
    updateSectionone("BLOGS_SECTION", payload).then((res) => {
      setAlert({
        visible: true,
        text: res?.data.success ? "Successfully Updated" : res?.data?.message,
        type: res?.data?.success ? "success" : "error",
      });
      refetchblogs();
      setLoading(false);
    });
  };

  return (
    <div className="blogsSettings">
    
        <form onSubmit={handleSubmit(handleSubmitForm1)}>
          <Grid container spacing={1} sm={6} xs={12} >
            <Grid item xs={12}>
              {isFetchingData ? (
                <Skeleton variant="text" width="100%" height={40} />
              ) : (
                <Controller
                  name="pageTitle"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      required
                      name="pageTitle"
                      label=" Page Title"
                      hideVariant
                      placeholder="Blog Listing Page Title"
                      value={value || ""}
                      onChange={(e) => onChange(e.target.value)}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {isFetchingData ? (
                <Skeleton variant="rectangular" width={100} height={40} />
              ) : (
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormDropdownList
                      required
                      data={Statues || []}
                      label="Enable Share Buttons"
                      name="enable"
                      placeholder={"Select Status"}
                      value={value}
                      hideVariant
                      accessValue="name"
                      onChange={(e) => {
                        onChange(e);
                      }}
                      helperText={error?.message}
                    />
                  )}
                  name="enable"
                  control={control}
                />
              )}
            </Grid>

            {/* Buttons */}
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width={100} height={40} />
                ) : (
                  <Button
                    onClick={() => handleCancel()}
                    name={"Cancel"}
                    type="button"
                    className="mx-1"
                  />
                )}
              </Grid>
              <Grid item>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width={100} height={40} />
                ) : (
                  <Button
                    selected
                    name={"Save"}
                    type="submit"
                    className="mx-1"
                    loading={loading}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
   
    </div>
  );
};

export default BlogsSettings;
