//UTILITIES
import React, { useState, useEffect, useContext } from "react";

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
//COMPONENT
import Button from "../../components/button/Button";
import { useGet } from "../../hooks/useFetch";
import {
  CardContent,
  TableCell,
  Checkbox,
  FormControlLabel,
  FormGroup,
  CardHeader,
  ListItem,
  List,
  Paper,
  Divider,
} from "@mui/material";
import { FormDropdownList } from "../../components/formComponents/FormComponents";
//API
import { transferItem } from "../../core/apis/item";
//CSS
import "./Transfer.scss";
import { useSearchParams } from "react-router-dom";
import Paginator from "../../components/paginator/Paginator";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import { AlertContext } from "../../context/AlertContext";
import withH1 from "../../assests/HOC/withH1";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import HandleAddInputs from "./handleAddItem";
import MoveDownIcon from "@mui/icons-material/MoveDown";

const HandleSchema = yup.object({
  country_id_from: yup.object().nullable(),
  country_id_to: yup.object().nullable(),
  product_sku_id: yup.object().nullable(),
  status_id: yup.object().nullable(),
  item_ids: yup.array().required("Field is required"),
});

export function TransferProductSku(props) {
  //VARIABLES
  const { setAlert } = useContext(AlertContext);
  const [searchParams] = useSearchParams(); //pagination and filter
  const [saveLoading, setSaveLoading] = useState(false);
  const [openCollect, setopenCollect] = useState(false);

  const { data: countries, loading: loadingCountries } = useGet({
    url: `get-all-countries`,
  });

  let countriesData = countries?.data?.data;

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(HandleSchema),
    reValidateMode: "onChange" | "onBlur",
  });

  const { data: productskus, productSkusLoading } = useGet({
    url: "admin/get-all-product-skus",
  });
  let productskusData = productskus?.data.data;

  //FUNCTIONS
  const handleSubmitForm = (formData) => {
    setopenCollect(true);
  };

  const statuses = [
    { id: "all", value: 0, name: "All" },
    { id: 5, value: 5, name: "un Published" },
    { id: 8, value: 8, name: "Published" },
  ];

  let payload = {
    per_page: 20,
    page: searchParams.get("page") || 1,
    item_status_id:
      getValues("status_id.id") === "all" ? null : getValues("status_id.id") || 5,
    product_sku_id: getValues("product_sku_id.id") || null,
    country_id: getValues("country_id_from.id") || null,
  };

  const {
    data: ALLITEMS,
    loading,
    refetch,
  } = useGet({ url: "admin/get-all-items", payload: payload });
  let itemsArray = ALLITEMS?.data.data;

  useEffect(() => {
    reset({
      country_id_from: null,
      country_id_to: null,
      status_id: { id: 5, value: 5, name: "un Published" },
      product_sku_id: null,
      item_ids: [],
      select_all_items: false,
    });
  }, []);

  const handleSelectedItems = (checkBoxNewValue, selectedOne) => {
    if (checkBoxNewValue === false) {
      let array = [...getValues("item_ids")];
      let find_item = array.indexOf(selectedOne);
      array.splice(find_item, 1);

      if (array?.length === itemsArray?.data?.total) {
        setValue(`item_ids`, array, {
          shouldValidate: true,
        });
        setValue("select_all_items", true, { shouldValidate: true });
      } else {
        setValue(`item_ids`, array, {
          shouldValidate: true,
        });
      }
    } else {
      let array = [...getValues("item_ids")];
      array.push(selectedOne);
      setValue(`item_ids`, array, {
        shouldValidate: true,
      });
    }
  };

  const handleSelectAll = (newValue) => {
    if (newValue === false) {
      setValue("item_ids", [], { shouldValidate: true });
    } else {
      let array = itemsArray?.data;
      setValue(`item_ids`, array);
    }
    setValue("select_all_items", newValue, { shouldValidate: true });
  };

  const handleSucess = (value) => {
    setSaveLoading(true);
    transferItem({
      country_id_to: value.country_id_to.id,
      item_ids: getValues("item_ids").map((el) => el?.id),
      transfer_costs: value["transfer_cost"],
    }).then((response) => {
      if (response?.data?.success) {
        setopenCollect(false);
        reset({
          item_ids: [],
          select_all_items: false,
        });
        setAlert({
          visible: true,
          text: response.data ? response.data?.message : response.message,
          type: response.data?.success ? "success" : "error",
        });
        refetch();
      }

      setSaveLoading(false);
    });
  };

  const handleOnCancel = (value) => {
    setopenCollect(false);
  };

  const handleResetFilter = () => {
    setValue("country_id_from", null);
    setValue("product_sku_id", null);
    setValue("status_id", { id: 5, value: 5, name: "un Published" });
    refetch();
  };

  return (
    <div className="transferList">
      <form className="transfer-product-sku-items">
        <Paper elevation={24} className="paper-div">
          <div className="filterby">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormDropdownList
                  required
                  mainClassName="width25"
                  hideVariant={true}
                  clearErrors={clearErrors}
                  name="country_id_from"
                  data={countriesData}
                  loading={loadingCountries}
                  dependencyError={["country_id_from"]}
                  label="Filter Items By Country"
                  placeholder={"Select Country"}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error?.message}
                />
              )}
              name="country_id_from"
              control={control}
            />
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormDropdownList
                  required
                  mainClassName="width25"
                  hideVariant={true}
                  clearErrors={clearErrors}
                  name="product_sku_id"
                  data={productskusData}
                  loading={productSkusLoading}
                  dependencyError={["product_sku_id"]}
                  label="Filter Items By SKU"
                  placeholder={"Select SKU"}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error?.message}
                />
              )}
              name="product_sku_id"
              control={control}
            />
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormDropdownList
                  required
                  mainClassName="width25"
                  hideVariant={true}
                  clearErrors={clearErrors}
                  name="status_id"
                  data={statuses}
                  loading={productSkusLoading}
                  dependencyError={["status_id"]}
                  label="Filter Items By Status"
                  placeholder={"Select Status"}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error?.message}
                />
              )}
              name="status_id"
              control={control}
            />
            <Button
              name={"Reset"}
              endIcon={<MoveDownIcon />}
              loading={saveLoading}
              onClick={() => handleResetFilter()}
            ></Button>
          </div>
          <Divider />
          <div className="item-selected">
            <div className="transfer-item-list">
              <div className="cardtableHeader">
                <CardHeader
                  className="header"
                  title="Items"
                  subheader={`${
                    itemsArray?.total ? itemsArray?.total : 0
                  } total items`}
                ></CardHeader>
              </div>
              <CardContent>
                <TableBodyComponent
                  header={[
                    { title: "Item Code" },
                    { title: "Location" },
                    { title: "Status" },
                  ]}
                  handleSelectAll={handleSelectAll}
                  loading={loading}
                  selectAllValue={getValues("select_all_items") ? true : false}
                  internal={true}
                  actions={false}
                  total={itemsArray?.total || 0}
                  prefixActions
                >
                  {itemsArray?.data?.length > 0 &&
                    itemsArray?.data?.map((item) => (
                      <TableRowComponent
                        row={item}
                        internal={true}
                        actions={false}
                      >
                        <TableCell>
                          <FormControlLabel
                            disabled={
                              !item?.latest_action?.item_action_id === 9
                            }
                            control={
                              <Checkbox
                                disabled={getValues("select_all_items")}
                                value={
                                  getValues("select_all_items")
                                    ? true
                                    : getValues(`item_ids`)?.find(
                                        (el) => el?.id === item?.id
                                      )
                                    ? true
                                    : false
                                }
                                checked={
                                  getValues("select_all_items")
                                    ? true
                                    : getValues(`item_ids`)?.find(
                                        (el) => el?.id === item?.id
                                      )
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  handleSelectedItems(e.target.checked, item)
                                }
                              />
                            }
                          />
                        </TableCell>

                        <TableCell>{item?.item_code}</TableCell>
                        <TableCell>
                          {item?.country?.name || item?.country_id}
                        </TableCell>
                        <TableCell>
                          {
                            item?.latest_action?.item_action?.item_status?.name
                              ?.en
                          }
                        </TableCell>
                      </TableRowComponent>
                    ))}
                </TableBodyComponent>
                <Paginator
                  count={itemsArray?.last_page || 0}
                  disabled={false}
                />
              </CardContent>
            </div>
            <div className="already-selected-items">
              <div className="cardtableHeader" style={{ alignItems: "center" }}>
                <>
                  <CardHeader
                    className="header"
                    title="Selected Items"
                    subheader={`${getValues("item_ids")?.length} total items`}
                  ></CardHeader>

                  <Button
                    name={"Transfer Items"}
                    selected
                    disabled={getValues("item_ids")?.length > 0 ? false : true}
                    endIcon={<MoveDownIcon />}
                    loading={saveLoading}
                    onClick={handleSubmit(handleSubmitForm)}
                  ></Button>
                </>
              </div>

              <CardContent>
                <List className="list-items-selected">
                  {getValues("item_ids")?.map((element) => (
                    <ListItem>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={true}
                              checked={true}
                              onChange={(e) =>
                                handleSelectedItems(e.target.checked, element)
                              }
                            />
                          }
                        />
                      </FormGroup>
                      {element?.item_code}
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </div>
          </div>
        </Paper>
      </form>
      <Dialog open={openCollect} onClose={() => setopenCollect(false)}>
        <DialogTitle id="alert-dialog-title">Add Transfer Fees</DialogTitle>
        <Divider></Divider>
        <DialogContent>
          <HandleAddInputs onSuccess={handleSucess} onCancel={handleOnCancel} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withH1(TransferProductSku, "Transfer");
