import React, { useState } from "react";
import Button from "../../../../components/button/Button";
import { Skeleton } from "@mui/material";
import "./PersonalInfo.scss";
import { PhoneInput } from "../../../../components/phoneInput/PhoneInput";
import { useAxios, useGet } from "../../../../hooks/useFetch";
import {
  FormInput,
  FormSwitch,
} from "../../../../components/formComponents/FormComponents";
import { useFormik } from "formik";
import * as yup from "yup";
import { checkPhoneValidity } from "../../../../core/apis/userManagement";
const personalSchema = yup.object({
  full_name: yup
    .string("Enter your full name")
    .required("Full name is required"),
  phone_number: yup
    .number("Invalid Field")
    .nullable()
    .required("Field is required"),
});

const PersonalInfo = () => {
  const { data, loading, refetch } = useGet({ url: "user/get-self-user" });
  let account = data?.data?.data;
  const [saveLoading, setSaveLoading] = useState(false);
  const [phone_number_error, setPhone_number_error] = useState("");
  const formik = useFormik({
    initialValues: {
      ...account,
    },
    enableReinitialize: true,
    validationSchema: personalSchema,
    onSubmit: () => {
      SubmitForm();
    },
  });

  const SubmitForm = async () => {
    setSaveLoading(true);
    let phoneCheck = await checkPhone(formik.values.phone_number);
    if (phoneCheck) {
      callAPI();
      setSaveLoading(false);
      refetch();
    } else {
      setSaveLoading(false);
    }
  };
  const { loading: loadingUpdate, callAPI } = useAxios(
    `user/update-self-user`,
    "put",
    formik?.values,
  );

  //FUNCTIONS
  const checkPhone = async (phone) => {
    let boolean = false;
    await checkPhoneValidity(phone).then((res) => {
      if (!res?.data?.data) {
        boolean = false;
        setPhone_number_error(res?.data?.message);
      } else {
        boolean = true;
        setPhone_number_error("");
      }
    });

    return boolean;
  };

  const handleNumber = () => (number) => {
    formik.setFieldValue("phone_number", number);
    setPhone_number_error("");
  };

  return (
    <div className="personal_info">
      {loading ? (
        <Skeleton width="100%" height="300px" />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="rows">
            <FormInput
              label="Email"
              fullWidth
              className="inputform"
              name="email"
              id="email"
              readOnly
              disabled
              placeholder="Enter Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <FormInput
              label="Full Name"
              placeholder="Enter Full Name"
              name="full_name"
              id="full_name"
              value={formik.values.full_name}
              onChange={formik.handleChange}
              error={
                formik.touched.full_name && Boolean(formik.errors.full_name)
              }
              helperText={formik.touched.full_name && formik.errors.full_name}
            />
          </div>
          <PhoneInput
            required
            className="phone-input-mg-form"
            value={formik.values?.phone_number}
            onChange={handleNumber}
            helperText={
              phone_number_error !== ""
                ? phone_number_error
                : formik.errors.phone_number
            }
          />
          <FormSwitch
            label="Enabled Notification"
            name="enable_notification"
            value={formik?.values?.enable_notification}
            onChange={(_, checked) => {
              formik.setFieldValue("enable_notification", checked);
            }}
          />

          <div className="buttonContainer">
            <Button
              type="submit"
              width="25%"
              name="Update"
              selected
              disabled={loadingUpdate || saveLoading}
              loading={loadingUpdate || saveLoading}
            />
          </div>
        </form>
      )}
    </div>
  );
};
export default PersonalInfo;
