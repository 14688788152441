// EXTERNAL LIBRARIES
import React, { useEffect, useState, useContext } from "react";
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { Button } from "@mui/material";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

// CUSTOM HOOKS
import { useAxios, useGet } from "../../../hooks/useFetch";
import { AlertContext } from "../../../context/AlertContext";

// STYLING
import Skeleton from "../../../components/MUI-skeleton/Skeleton";
import "./CategoryQuestions.scss";
import {
  TableExportSVG,
  EditSVG,
  DragBlueSVG,
  DeleteSVG,
} from "../../../assests/icons/SVG";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";

// DATA NOT FOUND
import DataNotFound from "../../../components/notFound/DataNotFound";

// TABLE
import TableHeader from "../../../components/table/tableHeader/TableHeader";

// COMPONENT
import CategoryItem from "./CategoryItem";
import { api } from "../../../core/apis/main";

// This component lists questions and has the option to drag and edit their order
const CategoryQuestions = () => {
  // DATA AND HOOKS
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { setAlert } = useContext(AlertContext);
  let navigate = useNavigate();
  let categoryState = useLocation().state;

  const [drag, setDrag] = useState(false);
  const [idArray, setIdArray] = useState([]);

  const [openDelete, setOpenDelete] = useState(false); //bool delete dialog
  const [selectedData, setSelectedData] = useState(null); //selected data when opening delete dialog

  // Payload to filter the listed data
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };

  // Fetching questions corresponding to the current category
  const {
    data: questions,
    loading,
    refetch: getQuestions,
  } = useGet({
    url: `/get-questions-by-category/${params.id}`,
    payload: payload,
  });
  let data = questions?.data?.data;

  // Patching the order of the displayed questions
  const patchData = async () => {
    return await api
      .patch(`/admin/update-question-order`, {
        question_ids: idArray,
      })
      .then((res) => {
        if (res?.data?.success) {
          getQuestions();
          setDrag(false);
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Sensors to drag from the external library
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Setting array on drag
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setIdArray((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  // Delete confirmation modal if yes, make the request
  const handleDeleteResponse = async (type, element) => {
    if (type === "yes") {
      return await api
        .delete(`admin/delete-question/${element}`)
        .then((res) => {
          setAlert({
            text: res.data.message,
            type: res.data.success ? "success" : "error",
          });
          setOpenDelete(false);
          setSelectedData(null);
          getQuestions();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setOpenDelete(false);
      setSelectedData(null);
    }
  };

  // Id array for drag
  const extractIds = () => {
    let array = [];
    data?.data?.map((element) => {
      array.push(element.id);
    });
    setIdArray(array);
  };

  // Opening delete confirmation modal
  const handleDelete = (element) => {
    setOpenDelete(true);
    setSelectedData(element);
  };

  // Extracting new ids on data change
  useEffect(() => {
    extractIds();
  }, [data]);

  // Getting questions on mount
  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <>
      <h1>Set of Questions</h1>
      <TableHeader
        to="/set-of-questions/new-question"
        placeholder="Search by question name"
        filterButton={false}
        state={{
          id: params.id,
          name: categoryState?.name?.split('"')[3],
          create: true,
        }}
      />
      <div className="categoryHeader">
        <div>{categoryState?.name?.split('"')[3]}</div>
        <div>
          <div>
            <Button variant="text" onClick={() => setDrag(!drag)}>
              {drag ? "Cancel" : <DragBlueSVG />}
            </Button>
          </div>
          {drag && (
            <div>
              <Button variant="text" onClick={() => patchData()}>
                Save
              </Button>
            </div>
          )}
        </div>
        {/* <div>
          <p
            onClick={() => {
              setDrag(!drag);
              // getAttributes();
            }}
          >
            {!drag ? <DragBlueSVG /> : "Cancel"}
          </p>
          {drag && <p onClick={() => patchData()}>Save</p>}
          <TableExportSVG />
        </div> */}
      </div>
      {drag && idArray && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={idArray || []}
            strategy={verticalListSortingStrategy}
          >
            {idArray?.map((id) => (
              <CategoryItem
                key={id}
                id={id}
                element={data?.data.filter((el) => {
                  return el.id === id;
                })}
              />
            ))}
          </SortableContext>
        </DndContext>
      )}
      {!drag &&
        data &&
        data?.data?.map((element, i) => (
          <div key={i} className="categoryItem">
            <p>{element?.question?.en}</p>
            <span className="ic">
              <span
                onClick={() =>
                  navigate(`/set-of-questions/question/${element?.id}`, {
                    state: { create: false },
                  })
                }
              >
                <EditSVG />
              </span>
              <span onClick={() => handleDelete(element?.id)}>
                <DeleteSVG />
              </span>
            </span>
          </div>
        ))}
      {loading && <Skeleton count={10} />}
      {!data && !idArray && !loading && <DataNotFound notTable={true} />}

      {openDelete && (
        <NoticeConfirmation
          data={selectedData}
          handleResponse={(e) => handleDeleteResponse(e, selectedData)}
        />
      )}
    </>
  );
};

export default CategoryQuestions;
