//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

//COMPONENT
import Button from "../../components/button/Button";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import {
  FormDropdownList,
  FormInput,
  FormMultiSelect,
} from "../../components/formComponents/FormComponents";
import { CloseSVG } from "../../assests/icons/SVG";
import { AlertContext } from "../../context/AlertContext";

//CSS
import "./ItemHandle.scss";
import { addItemToProductSku, changeItemAction } from "../../core/apis/item";
import { useGet } from "../../hooks/useFetch";

const HandleSchema = yup.object({
  collection_points: yup
    .array()
    .min(1, "Field is required")
    .required("Field is required"),
  items: yup.array().of(
    yup.object().shape({
      collection_point_id: yup.object(),
      quantity: yup
        .number("Invalid Field")
        .nullable()
        .required("Field is required"),
    })
  ),
});

export function ProductItemAdd(props) {
  const { data, onClose, refetch } = props;

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  const {
    fields: itemsFields,
    remove: itemsRemove,
    append: itemsAppend,
  } = useFieldArray({ control, name: "items" });

  const { setAlert } = useContext(AlertContext);
  const { data: cps, cpLoading } = useGet({
    url: "admin/get-all-collection-point",
  });
  let cpsArray = cps?.data.data;

  const [saveLoading, setSaveLoading] = useState(false);

  //FUNCTIONS
  const handleAddCollectionPointQuantity = (value) => {
    let array = [];
    value?.map((item) => {
      let checkAvailable = getValues("items").find(
        (val) => val.collection_point_id === item.id
      );

      if (checkAvailable) {
        array.push(checkAvailable);
      } else {
        array.push({
          collection_point_id: item,

          quantity: null,
        });
      }
    });

    itemsRemove();

    array.map((val) => {
      itemsAppend(val);
    });
  };

  const handleSubmitForm = (formData) => {
    setSaveLoading(true);
    addItemToProductSku({
      product_sku_id: data?.id,
      items: formData.items?.map((el) => ({
        collection_point_id: el?.collection_point_id?.id,
        quantity: el?.quantity,
      })),
    }).then((response) => {
      if (response?.data.success) {
        refetch();
        onClose();
      }
      setAlert({
        text: response.data ? response.data?.message : response.message,
        type: response.data?.success ? "success" : "error",
      });

      setSaveLoading(false);
    });
  };

  return (
    <Dialog disablebackdropclick="true" fullWidth open={true}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {"Add Items"}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseSVG />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="item-status-handle">
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormMultiSelect
                    required
                    label="Collection Points"
                    data={cpsArray || []}
                    loading={cpLoading}
                    name={`collection_points`}
                    placeholder={"Select collection point"}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      handleAddCollectionPointQuantity(e);
                    }}
                    helperText={error?.message}
                  />
                )}
                name={`collection_points`}
                control={control}
              />
              {getValues("items")?.map((element, index) => (
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      type="number"
                      name={`items.${index}.quantity`}
                      label={`${element.collection_point_id?.name?.en} quantity`}
                      placeholder={"Enter quantity"}
                      value={value}
                      onChange={(e) =>
                        onChange(e.target.value === "" ? null : e.target.value)
                      }
                      helperText={error?.message}
                    />
                  )}
                  name={`items.${index}.quantity`}
                  control={control}
                />
              ))}

              <div className="action-footer">
                <Button
                  name={"Save"}
                  selected
                  width={"30%"}
                  loading={saveLoading}
                  type="submit"
                />
              </div>
            </>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ProductItemAdd;
