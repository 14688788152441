//UTILITIES
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useGet } from "../../../hooks/useFetch";
import moment from "moment";
//COMPONENT
import Button from "../../../components/button/Button";
import { useForm, Controller } from "react-hook-form";
import {
  Card,
  CardContent,
  TableCell,
  Skeleton,
  Stepper,
  Step,
  StepLabel,
  ButtonBase,
  SvgIcon,
  TextField,
} from "@mui/material";
import CollapsedTable from "../../../components/collapsedTable/CollapsedTable";
import CollapsedTableRow from "../../../components/collapsedTable/CollapsedTableRow";
import TransactionProductDetail from "./TransactionProductDetail";
import { FormDropdownList } from "../../../components/formComponents/FormComponents";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  TakeOrderActions,
  getShippingStatus,
} from "../../../core/apis/transactions";
//CSS
import "./TransactionDetails.scss";
import { AlertContext } from "../../../context/AlertContext";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import ReactToPrint from "react-to-print";
import { PrintoutSVG } from "../../../assests/icons/SVG";
import { Disclaimer } from "../../../components/disclaimer/Disclaimer";
import Invoice from "../../../components/invoice/Invoice";
import {
  CashOnDeliverySVG,
  TamaraSVG,
} from "../../../assests/icons/BankcardsBrandsSVG";
import {
  AuthorizeOrder,
  CapturePayment,
  RefundPayment,
  tamaraOrderDetails,
} from "../../../core/apis/tamara";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

let globalActionId;
const BeforeApprovalHandleSchema = yup.object({
  next_action_id: yup
    .object()
    .nullable()
    .required("Field is required")
    .test("globalActionId", "assign value to variable", (value) => {
      globalActionId = value;
      return true;
    }),
  product_skus: yup.array().of(
    yup.object().shape({
      element: yup.object(),
      cp: yup.array().of(yup.object()),
      items: yup
        .array()
        .of(yup.object())
        .test("length", "Please select right quantity", function (value) {
          const { path, createError, parent } = this;
          var indx = path.substring(path.indexOf("[") + 1, path.indexOf("]"));

          if (
            globalActionId?.id === 17 ||
            (globalActionId?.id === 16 &&
              parent?.element?.pivot?.quantity === value?.length &&
              value?.length !== 0)
          ) {
            return true;
          }

          return createError({
            path: `product_skus.${indx}.items`,
            message: "Please select right quantity",
          });
        }),
    })
  ),
});
const AfterApprovalHandleSchema = yup.object({
  next_action_id: yup.object().nullable().required("Field is required"),
  product_skus: yup.array().of(
    yup.object().shape({
      element: yup.object(),
      cp: yup.array().of(yup.object()),
      items: yup.array().of(yup.object()),
    })
  ),
});
const steps = [
  "Order Placed",
  "Ready For Shipment",
  "Driver Picked Up",
  "Driver On The Way",
  "Delivered",
];
const PaymentLogo = [
  {
    label: "Tamara",
    logo: <TamaraSVG style={{ width: "40px", height: "fit-content" }} />,
  },
  {
    label: "Cash on delivery",
    logo: (
      <CashOnDeliverySVG style={{ width: "40px", height: "fit-content" }} />
    ),
  },
];
export function TransactionBuyDetails(props) {
  const { setAlert } = useContext(AlertContext);
  const { id } = useParams();
  const [step, setStep] = useState(1);
  const [afterAdminApproval, setAfterAdminApproval] = useState(false);
  const [refundComment, setRefundComment] = useState("");
  //API
  const {
    data: transactionDetail,
    loading,
    refetch,
  } = useGet({
    url: `admin/get-transaction-by-id/${id}`,
  });
  const transactionData = transactionDetail?.data.data;

  //FORM INIT
  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      afterAdminApproval
        ? AfterApprovalHandleSchema
        : BeforeApprovalHandleSchema
    ),
  });

  //VARIABLES
  const [tamaraStatus, setTamarastatus] = useState("");
  const [historical, setHistorical] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);
  const [tamaraLoading, setTamaraLoading] = useState(false);
  const DisclaimerRef = useRef();

  //FUNCTIONS
  const handleRejectResponse = (response, value) => {
    if (response === "yes") {
      CallAPI(value);
    }
    setOpenNotice(false);
  };

  const CallAPI = (formData) => {
    setSaveLoading(true);
    let itemsIds = [];
    formData?.product_skus?.map((el) => {
      el?.items?.map((val) => {
        itemsIds.push(val?.id);
      });
    });
    TakeOrderActions({
      id: transactionData?.id,
      action_id: formData?.next_action_id?.id,
      logistic_company_slug: "wakilni",
      item_ids: itemsIds,
    }).then((response) => {
      setAlert({
        visible: true,

        text: response.data ? response.data.message : response.message,
        type: response.data?.success ? "success" : "error",
      });
      if (response.data?.success) {
        refetch();
        reset({ next_action_id: null, product_skus: formData.product_skus });
      }
      setSaveLoading(false);
    });
  };

  const handleSubmitDecisionForm = (formData) => {
    // if status is reject 17

    if (formData?.next_action_id?.id == 17) {
      setMessage({ message: "All items will be rejected", data: formData });
      setOpenNotice(true);
    } else {
      CallAPI(formData);
    }
  };

  useEffect(() => {
    let array = [];
    if (transactionData) {
      //Check in which phase we are
      setAfterAdminApproval(
        transactionData?.next_action?.find((el) => el.id === 16) ? false : true
      );
      transactionData?.product_sku?.map((el) => {
        array.push({
          element: el,
          cp: [],
          items: [],
        });
      });
      reset({ product_skus: array, next_action_id: null });
      setHistorical(transactionData?.transaction_status_id === 3);
    }
    getShippingStatus(transactionData?.tracking_number).then((res) => {
      setStep(res?.status > 4 ? 4 : res?.status);
    });
    tamaraOrderDetails(id).then((res) => {
      setTamarastatus(res?.data?.data.status);
    });
  }, [transactionData]);
  const AuthorizeTamaraOrder = () => {
    setTamaraLoading(true);

    (tamaraStatus === "approved"
      ? AuthorizeOrder
      : tamaraStatus === "authorised"
      ? CapturePayment
      : RefundPayment)(id, { comment: refundComment }).then((res) => {
      setAlert({
        visible: true,
        text: res.data ? res.data.message : res.message,
        type: res.data?.success ? "success" : "error",
      });
      tamaraOrderDetails(id).then((res) => {
        setTamarastatus(res?.data?.data.status);
        refetch();
      });
      setTamaraLoading(false);
    });
  };
  return (
    <>
      <div className="transaction-detail-component-buy">
        {!loading ? (
          <>
            <h1>Transaction Details (Buy)</h1>
            <div className="header">
              <div className="transactionStatus">
                {transactionData?.transaction_status?.name?.en}
              </div>
              {transactionData?.country_id === 118 &&
              (transactionData?.payment_gateway_id === 1 ||
                transactionData?.transaction_status_id === 3) ? (
                <>
                  <ReactToPrint
                    trigger={() => (
                      <ButtonBase
                        variant="contained"
                        className="PrintoutSVG"
                        aria-label="filter button"
                      >
                        <SvgIcon
                          component={PrintoutSVG}
                          inheritViewBox
                          sx={{ fontSize: 30 }}
                        />
                      </ButtonBase>
                    )}
                    content={() => DisclaimerRef.current}
                    removeAfterPrint
                  />
                  <Invoice ref={DisclaimerRef} data={transactionData} />
                </>
              ) : null}
            </div>
            <div className="body">
              <div className="shipping-details">
                <div className="sub-title">Details</div>
                <Card>
                  <CardContent>
                    <div className="shipping-body-item">
                      <div className="title">Customer Name</div>
                      <div className="body">
                        {transactionData?.user?.full_name}
                      </div>
                    </div>
                    <div className="shipping-body-item">
                      <div className="title">Customer Number</div>
                      <div className="body">
                        {transactionData?.user?.phone_number}
                      </div>
                    </div>
                    <div className="shipping-body-item">
                      <div className="title">Email</div>
                      <div className="body">{transactionData?.user?.email}</div>
                    </div>
                    <div className="shipping-body-item">
                      <div className="title">Address</div>
                      <div className="body">
                        {" "}
                        {transactionData?.address?.address_line}
                      </div>
                    </div>
                    <div className="shipping-body-item">
                      <div className="title">City</div>
                      <div className="body">
                        {transactionData?.address?.city_name} {"  "}{" "}
                        {transactionData?.address?.country?.name}
                      </div>
                    </div>
                    <div className="shipping-body-item">
                      <div className="title">Transaction Date</div>
                      <div className="body">
                        {moment(transactionData?.created_at).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </div>
                    </div>
                    <div className="shipping-body-item">
                      <div className="title">Payment Method</div>
                      <div className="body">
                        {
                          PaymentLogo.find(
                            (el) =>
                              el.label ===
                              transactionData?.payment_gateway?.name?.en
                          )?.logo
                        }
                        <span>
                          {transactionData?.payment_gateway?.name?.en}
                        </span>
                      </div>
                    </div>
                    <div className="shipping-body-item">
                      <div className="title">Subtotal</div>
                      <div className="body">
                        {transactionData?.amount}{" "}
                        {transactionData?.currency?.code}
                      </div>
                    </div>
                    <div className="shipping-body-item">
                      <div className="title">Delivery Method</div>
                      <div className="body">
                        {transactionData?.delivery_method?.name?.en}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
              <form onSubmit={handleSubmit(handleSubmitDecisionForm)}>
                <div className="product-list">
                  <div className="sub-title">Items</div>
                  <div className="form-error-message">
                    {errors.hasOwnProperty("product_skus")
                      ? `Please select the correct quantity for each product`
                      : ""}
                  </div>
                  <div>
                    <TableContainer
                      component={Paper}
                      sx={{ padding: 3, boxShadow: 2 }}
                    >
                      <Table
                        sx={{ minWidth: 650, padding: 3 }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>SKU Code</TableCell>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Cost</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {transactionData?.items?.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                {row?.product_sku?.sku_code}
                              </TableCell>
                              <TableCell>
                                {row?.product_sku?.title?.en}
                              </TableCell>
                              <TableCell>
                                {row?.product_sku?.sell_price}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {transactionData?.logistic_company_id ? (
                      <div className="bottomSection">
                        <div className="logistic">
                          <img
                            className="img"
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              transactionData?.logistic_company?.logo_path
                            }
                          />
                          <p>
                            <i>
                              {transactionData?.logistic_company?.description}
                            </i>
                            <br />
                            Your tracking number is:{" "}
                            <b>
                              <a
                                href={
                                  process.env.REACT_APP_WAKILNI_URL +
                                  transactionData?.tracking_number
                                }
                                target="_blank"
                              >
                                {transactionData?.tracking_number}
                              </a>
                            </b>
                          </p>
                        </div>
                        <div className="trackYourOrder card">
                          <h6>Track Your Order</h6>
                          <Stepper activeStep={step} alternativeLabel>
                            {steps.map((label) => (
                              <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {!historical &&
                    transactionData?.next_action?.length !== 0 && (
                      <div className="inspections-actions">
                        <div style={{ width: "30%" }}>
                          <Controller
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <FormDropdownList
                                disabled={tamaraStatus === "approved"}
                                variant="outlined"
                                data={transactionData?.next_action}
                                loading={false}
                                clearErrors={clearErrors}
                                formComponent={false}
                                name="next_action_id"
                                placeholder={"Choose your decision"}
                                value={value}
                                dependencyError={[
                                  "next_action_id",
                                  "inspection_id",
                                ]}
                                onChange={(e) => {
                                  onChange(e);
                                  setValue("inspection_id", null, {
                                    shouldValidate: true,
                                  });

                                  clearErrors("product_skus");
                                }}
                                helperText={error?.message}
                              />
                            )}
                            name="next_action_id"
                            control={control}
                          />
                        </div>
                        {transactionData?.payment_gateway_id &&
                          tamaraStatus === "approved" && (
                            <Button
                              name="Authorize Tamara Order"
                              selected
                              loading={tamaraLoading}
                              onClick={AuthorizeTamaraOrder}
                              disabled={tamaraStatus !== "approved"}
                            />
                          )}
                        {transactionData?.payment_gateway_id &&
                          tamaraStatus === "authorised" && (
                            <Button
                              name="Capture Tamara Payment"
                              selected
                              loading={tamaraLoading}
                              onClick={AuthorizeTamaraOrder}
                              disabled={tamaraStatus !== "authorised"}
                            />
                          )}
                        {transactionData?.payment_gateway_id &&
                          tamaraStatus === "fully_captured" && (
                            <Button
                              name="Refund Tamara Payment"
                              selected
                              loading={tamaraLoading}
                              onClick={AuthorizeTamaraOrder}
                              disabled={tamaraStatus !== "fully_captured"}
                            />
                          )}
                        {transactionData?.payment_gateway_id &&
                          tamaraStatus === "fully_captured" && (
                            <TextField
                              size="small"
                              label="Refund Comment"
                              value={refundComment}
                              onChange={(e) => setRefundComment(e.target.value)}
                              disabled={tamaraStatus !== "fully_captured"}
                            />
                          )}

                        <Button
                          name={"Submit"}
                          selected
                          loading={saveLoading}
                          // width={"30%"}
                          type="submit"
                          disabled={tamaraStatus === "approved"}
                        />
                      </div>
                    )}
                </div>
              </form>
            </div>
          </>
        ) : (
          <Skeleton count={10} />
        )}
      </div>

      {openNotice && (
        <NoticeConfirmation
          message={message?.message}
          handleResponse={handleRejectResponse}
          data={message?.data}
        />
      )}
    </>
  );
}

export default TransactionBuyDetails;
