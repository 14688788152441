import { api } from "./main";

export const getTransactionStatuses = (payload) => {
  return api
    .get(`get-all-transaction-statuses`, { params: { ...payload } })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getTransactionOrderStatuses = (payload) => {
  return api
    .get(`get-all-transaction-order-statuses`, { params: { ...payload } })
    .then((res) => res)
    .catch((err) => console.error(err));
};
