// EXTERNAL LIBRARIES
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Button as ButtonMui,
  IconButton,
} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// STYLING
import "./AttributeDetails.scss";
import { DeleteSVG, PlusCircleSVG } from "../../../assests/icons/SVG";

// CUSTOM HOOKS

import { AlertContext } from "../../../context/AlertContext";

// FORM
import {
  FormCheckBox,
  FormInput,
  FormMultiSelect,
} from "../../../components/formComponents/FormComponents";
import Button from "../../../components/button/Button";
import { api } from "../../../core/apis/main";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import { USAFlagIcon } from "../../../assests/icons/SVG";
import { translateByGoogleApi } from "../../../core/apis/newlanguage";
import CustomTab from "../../../components/Tabs/customTab";
import { useGlobalValues } from "../../../context/GlobalContext";
import { updateAttribute } from "../../../core/apis/attribute";
import Cropper from "../../../components/singleUpload/imageCropper";
import { useGet } from "../../../hooks/useFetch";

const HandleSchema = yup.object({
  name: yup.string("Field is invalid").required("Field is required"),
  estimation_factor: yup.bool(),
  attribute_values: yup.array().of(
    yup.object().shape({
      value: yup.string("Field is invalid").required("Field is required"),
    })
  ),
  category_ids: yup
    .array()
    .min(1, "Field is required")
    .required("Field is required"),
});

const AttributeDetails = (props) => {
  // DATA AND HOOKS
  const { create, onModelPage, onsuccess } = props;
  let navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const [saveLoading, setSaveLoading] = useState(false);
  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  const [tabvalue, setTabValue] = useState("en");

  const [imageVal, setImageValue] = useState(null);

  const { enabledLanguages } = useGlobalValues();

  const {
    fields: attributesFields,
    remove: attributesRemove,
    append: attributesAppend,
  } = useFieldArray({ control, name: "attribute_values" });

  const { data: categories, categoriesLoading } = useGet({
    url: "admin/get-all-categories",
  });

  //FUNCTIONS
  const handleAddValue = () => {
    attributesAppend({
      value: "",
    });
  };

  // API REQUESTS

  const handleSubmitForm = (formData) => {
    console.log(formData);
    let valuesData = new FormData();

    valuesData.append("name", formData?.name);
    if (imageVal != null) {
      valuesData.append("image", imageVal);
    }

    valuesData.append("language", formData.language);
    valuesData.append("estimation_factor", formData?.estimation_factor ? 1 : 0);

    formData.attribute_values.forEach((el, index) => {
      valuesData.append(`attribute_values[${index}][name]`, el.value);
      valuesData.append(
        `attribute_values[${index}][abbreviation]`,
        el.abbreviation
      );
    });

    formData.category_ids.forEach((el, index) => {
      valuesData.append(`categories_ids[${index}]`, el.id);
    });

    setSaveLoading(true);
    return api
      .post(`admin/create-attribute`, valuesData)
      .then((res) => {
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
        if (res?.data?.success) {
          enabledLanguages.map((item) => {
            if (item.code != "en") {
              handleTranslateByGoogle(
                res.data.data,
                item.code,
                formData.category_ids
              );
            }
          });
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const handleTranslateByGoogle = async (
    values,
    targetLanguage,
    categories
  ) => {
    let variants = values.attribute_values.map((item) => {
      return item.name.en;
    });
    let arrayofdata = [values.name.en, variants];

    await translateByGoogleApi(arrayofdata, targetLanguage).then((res) => {
      let valuesData = new FormData();
      valuesData.append("id", values.id);
      valuesData.append("name", res[0]?.translatedText);
      valuesData.append("language", targetLanguage);
      valuesData.append("estimation_factor", values.is_estimation_factor);
      valuesData.append("order", values.order);
      categories.forEach((el, index) => {
        valuesData.append(`categories_ids[${index}]`, el.id);
      });

      values.attribute_values.forEach((item, index) => {
        // Access the corresponding translation from res array using index + 1
        const translatedText = res[index + 1]?.translatedText || ""; // Use empty string as fallback

        // Construct a new object with the existing item properties and the translated name
        const transformedItem = {
          ...item,
          name: translatedText,
        };
        Object.entries(transformedItem).forEach(([key, value]) => {
          // Convert key to a unique string based on index to avoid key name collisions
          const formDataKey = `attribute_values[${index}][${key}]`;
          valuesData.append(formDataKey, value);
        });
      });
      handleUpdateVariants(values.id, valuesData);
    });
  };

  const handleUpdateVariants = (id, values) => {
    updateAttribute(id, values).then((res) => {
      setAlert({
        visible: true,
        text: res.data.message,
        type: res.data.success ? "success" : "error",
      });
      if (res?.data?.success & !onModelPage) {
        navigate("/variants");
      } else if (res?.data?.success & onModelPage) {
        console.log("succes");
        onsuccess(true);
      }
    });
  };

  useEffect(() => {
    reset({
      name: "",
      language: tabvalue,
      category_ids: [],
      attribute_values: [
        {
          value: "",
        },
      ],
      estimation_factor: false,
    });
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleImage = (croppedImage) => {
    setImageValue(croppedImage);
  };

  return (
    <>
      <div className="attributeDetails">
        <h1>{create && "New Variant"}</h1>
        <TabContext value={tabvalue}>
          {!onModelPage && (
            <Tabs
              value={tabvalue}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              {enabledLanguages.map((item, index) =>
                item.code === "en" ? (
                  <CustomTab
                    index={index}
                    value={item.code}
                    label={item.name}
                    icon={<USAFlagIcon />}
                  />
                ) : (
                  ""
                )
              )}
            </Tabs>
          )}
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Card className="attCard">
              <Cropper
                sendCroppedFile={handleImage}
                MAX_IMAGE_WIDTH={400}
                MAX_IMAGE_HEIGHT={400}
                sizeMsg={"400*400"}
                iwebp={false}
              />
              <CardContent>
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      required
                      label="Variant Name"
                      placeholder="Enter name"
                      name="name"
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      helperText={error?.message}
                      hideVariant={true}
                    />
                  )}
                  name={`name`}
                  control={control}
                />

                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormMultiSelect
                      required
                      data={categories?.data?.data?.categories || []}
                      loading={categoriesLoading}
                      label="Categories"
                      name="category_ids"
                      clearErrors={clearErrors}
                      dependencyError={["category_ids"]}
                      placeholder={"Select Categories"}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      helperText={error?.message}
                      hideVariant={true}
                    />
                  )}
                  name="category_ids"
                  control={control}
                />

                {attributesFields?.length !== 0 &&
                  attributesFields?.map((el, attributeIndex) => {
                    const isLastIndex =
                      attributeIndex === attributesFields.length - 1;
                    return (
                      <div
                        key={`${attributeIndex}_${attributesFields.length}`}
                        className="dynamic-attribute-add"
                      >
                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormInput
                              required
                              label={`Variantion ${attributeIndex + 1}`}
                              placeholder="Enter name"
                              name={`attribute_values.${attributeIndex}.value`}
                              value={value}
                              onChange={(e) => onChange(e?.target.value)}
                              helperText={error?.message}
                              hideVariant={true}
                            />
                          )}
                          name={`attribute_values.${attributeIndex}.value`}
                          control={control}
                        />
                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormInput
                              required
                              label={`Abbreviation ${attributeIndex + 1}`}
                              placeholder="Enter Abbreviation"
                              name={`attribute_values.${attributeIndex}.abbreviation`}
                              value={value}
                              onChange={(e) => onChange(e?.target.value)}
                              helperText={error?.message}
                              hideVariant={true}
                            />
                          )}
                          name={`attribute_values.${attributeIndex}.abbreviation`}
                          control={control}
                        />
                        {attributesFields.length > 1 && (
                          <IconButton
                            onClick={() => attributesRemove(attributeIndex)}
                          >
                            <DeleteSVG />
                          </IconButton>
                        )}
                        {isLastIndex && onModelPage && (
                          <IconButton onClick={() => handleAddValue()}>
                            <PlusCircleSVG />
                          </IconButton>
                        )}
                      </div>
                    );
                  })}
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormCheckBox
                      name={`estimation_factor`}
                      label="Estimation Factor"
                      value={value == true ? true : false}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      helperText={error?.message}
                    />
                  )}
                  name={`estimation_factor`}
                  control={control}
                />

                <div className="action-footer">
                  <Button
                    name={"Create"}
                    selected
                    type="submit"
                    loading={saveLoading}
                    disabled={saveLoading}
                  />
                </div>
              </CardContent>
            </Card>
            {!onModelPage && (
              <div onClick={() => handleAddValue()} className="add">
                <PlusCircleSVG />
                <p>Add New Answer</p>
              </div>
            )}
          </form>
        </TabContext>
      </div>
    </>
  );
};

export default AttributeDetails;
