//MARILYNN
//UTILITIES
import PropTypes from "prop-types";
//COMPONENT
import DialogCustomTitle from "../../titleComponent/DialogCustomTitle";
import Button from "../../button/Button";
import { DialogActions, DialogContent } from "@mui/material";
import { NoticeDialog } from "../../../theme/Theme";
//CSS
import "./NoticeConfirmation.scss";

const NoticeConfirmation = (props) => {
  const { message, data, handleResponse, cannotBeDeleted, msg } = props;
  return (
    <NoticeDialog open={true}>
      <DialogCustomTitle title="" onClose={() => handleResponse("no", data)} />
      <DialogContent>
        {cannotBeDeleted ? (
          <div className="notice-confirmation-dialog">
            <div className="title">Warning!</div>
            <div className="message">This Transactions is <b>{msg}</b> cannot Be Deleted</div>
          </div>
        ) : (
          <div className="notice-confirmation-dialog">
            <div className="title">Notice!</div>
            <div className="message">{message}</div>
          </div>
        )}
      </DialogContent>

      {cannotBeDeleted ? (
        <DialogActions></DialogActions>
      ) : (
        <DialogActions>
          <Button
            name="Cancel"
            width={"50%"}
            onClick={() => handleResponse("no", data)}
          />
          <Button
            name="Confirm"
            selected
            width="50%"
            onClick={() => handleResponse("yes", data)}
          />
        </DialogActions>
      )}
    </NoticeDialog>
  );
};

NoticeConfirmation.defaultProps = {
  message: "Are you sure you want to delete?",
};
NoticeConfirmation.propTypes = {
  message: PropTypes.string,
  handleResponse: PropTypes.func,
  data: PropTypes.element,
};

export default NoticeConfirmation;
