import React, { useContext, useState, useEffect } from "react";
import "./UsageTypes.scss";
import { TableCell } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Paginator from "../../../components/paginator/Paginator";
import TableBodyComponent from "../../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../../components/table/tableBody/TableRowComponent";
import TableHeader from "../../../components/table/tableHeader/TableHeader";
import { useGet } from "../../../hooks/useFetch";
import BasicTabs from "../../../components/Tabs/Tabs";
import { useGlobalValues } from "../../../context/GlobalContext";
import * as yup from "yup";

import { AlertContext } from "../../../context/AlertContext";
import { useTranslation } from "react-i18next";
import UsageTypeHandle from "./UsageTypeHandle";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { deleteProductUsageType } from "../../../core/apis/productUsageType";
import withH1 from "../../../assests/HOC/withH1";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "../../../components/MUI-skeleton/Skeleton";


const usageTypeSchema = yup.object({
  name: yup.string("Enter Usage Type").required("Usage Type is required"),
});

const UsageTypes = () => {
  const { t, i18n } = useTranslation();

  const { setAlert } = useContext(AlertContext);
  const { enabledLanguages } = useGlobalValues();

  const tableHeaders = [{ title: t("name") }];

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [value, setValue] = useState("en");

  const [searchParams] = useSearchParams();
  const [detail, setDetail] = useState(null);

  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };

  const {
    data: usageTypes,
    loading: loadingPage,
    refetch,
  } = useGet({ url: `admin/get-all-product-usage-types`, payload: payload });

  let data = usageTypes?.data?.data;

  const handleEdit = (value) => {
    setDetail(value);
    setOpen(true);
  };

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteProductUsageType(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setDetail(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  const handleChange = (event, newValue) => {
    i18n.changeLanguage(newValue);
    setValue(newValue);
  };

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  return loadingPage ? (
    <div className="loaderClass">
      <Skeleton count={10} />
    </div>
  ) : (
    <div>
      <BasicTabs
        handleChange={handleChange}
        tabsList={enabledLanguages}
        value={value}
        children={
          <div className={value != "ar" ? "" : "arabic-component"}>
            <TableHeader
              placeholder="Search by usage type"
              add={() => setOpen(true)}
              filterButton={false}
            />
            <TableBodyComponent
              isArabic={value == "ar" ? true : false}
              header={tableHeaders}
              loading={loadingPage}
              total={data?.total}
            >
              {data?.data?.map((element) => (
                <TableRowComponent
                  className="BrandDetails"
                  handleDelete={() => {
                    setDetail(element);
                    setOpenDelete(true);
                  }}
                  handleEdit={() => handleEdit(element)}
                  row={element}
                  key={element?.id}
                >
                  <TableCell className={value != "ar" ? " " : "textAlighRight"}>
                    {element?.name?.[value]
                      ? element?.name?.[value]
                      : element?.name?.en}
                  </TableCell>
                </TableRowComponent>
              ))}
            </TableBodyComponent>
          </div>
        }
      />
      <Paginator count={data?.last_page} disabled={loadingPage} />
      {open && (
        <UsageTypeHandle
          data={detail}
          refetch={refetch}
          languageValue={value}
          onClose={() => {
            setDetail(null);
            setOpen(false);
          }}
        />
      )}
      {openDelete && (
        <NoticeConfirmation
          data={detail}
          handleResponse={handleDeleteResponse}
        />
      )}
    </div>
  );
};

export default withH1(UsageTypes, "Usage Types");
