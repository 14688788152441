import React, { useContext, useEffect, useState } from "react";
import "./Header.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Menu } from "@mui/icons-material";
import { IconButton, Popover, Badge } from "@mui/material";
import {
  LogoWithTextSVG,
  BellSVG,
  AccountPersonSVG,
} from "../../assests/icons/SVG";
import { LogoutSVG, SettingsSVG } from "../../assests/icons/SVG";
import * as AuthApi from "../../core/apis/auth";
import { useAuth } from "../../hooks/useAuth";
import { SideMenuContext } from "../../context/SideMenuContext";
import { NotificationContext } from "../../context/NotificationContext";
const Header = () => {
  let navigate = useNavigate();
  const { notificationCount } = useContext(NotificationContext);
  const { sideMenu, setSideMenu } = useContext(SideMenuContext);
  const { logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  useEffect(() => {}, []);
  return (
    <header className="navbar">
      <div className="logo-container">
        {AuthApi.isUserLoggedIn() && (
          <IconButton onClick={() => setSideMenu(true)} aria-label="">
            <Menu style={{ fontSize: "25px" }} />
          </IconButton>
        )}
        <NavLink
          to={AuthApi.isUserLoggedIn() ? "/dashboard" : "/login"}
          aria-label="navigate to Sales"
          className={sideMenu ? "logo d-none" : "logo"}
        >
          <LogoWithTextSVG className="LogoSVG" />
        </NavLink>
      </div>
      {AuthApi.isUserLoggedIn() ? (
        <div className="header-actions">
          <IconButton
            onClick={() => navigate("/notifications-list")}
            aria-label=""
          >
            {notificationCount !== 0 ? (
              <Badge badgeContent={notificationCount} color="primary">
                <BellSVG />{" "}
              </Badge>
            ) : (
              <BellSVG />
            )}
          </IconButton>

          <IconButton onClick={handleClick} aria-label="">
            <AccountPersonSVG />
          </IconButton>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className="logoutPopover" style={{ display: "flex" }}>
              <Link to="/account-details/personal-info" onClick={handleClose}>
                <SettingsSVG />
                Account Details
              </Link>
              <div style={{ cursor: "pointer" }} onClick={logout}>
                <LogoutSVG />
                Logout
              </div>
            </div>
          </Popover>
        </div>
      ) : null}
    </header>
  );
};

export default Header;
