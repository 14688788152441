//UTILITIES
import React from "react";
import PropTypes from "prop-types";
//COMPONENT

import {
  TableSortLabel,
  TableRow,
  TableCell,
  TableHead,
  IconButton,
} from "@mui/material";
import { TableExportSVG } from "../../assests/icons/SVG";
//CSS
import "./CollapsedTable.scss";

export function CollapsedTableHeader(props) {
  //PROPS
  const { header, actions, requestSort, handleExport, internal } = props;
  const { prefixAction } = props;

  const handleRequestSort = (_, item) => requestSort(item);

  return (
    <TableHead>
      <TableRow className="collpase-table-component-row">
        {prefixAction && <TableCell></TableCell>}
        {header.map((item, index) => (
          <TableCell key={index}>
            {item.sorted === true ? (
              <TableSortLabel
                direction={props.orderBy}
                active={props.sortedBy === item.value}
                onClick={(e) => handleRequestSort(e, item.value)}
              >
                {item.title}
              </TableSortLabel>
            ) : (
              <div className="header-label">{item.title}</div>
            )}
          </TableCell>
        ))}
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
  );
}
CollapsedTableHeader.defaultProps = {};
CollapsedTableHeader.propTypes = {};

export default CollapsedTableHeader;
