// STYLING
import { NoData } from "../../assests/icons/SVG";
import "./DataNotFound.scss";

const DataNotFound = (props) => {
  const { notTable } = props;
  return (
    <>
      {notTable ? (
        <div className="noData">
          <NoData width="138px" height="138px" />
          <p>No Data Found!</p>
        </div>
      ) : (
        <tr className="noDataRow">
          <td className="noDataDiv">
            <NoData width="138px" height="138px" />
            <p>No Data Found!</p>
          </td>
        </tr>
      )}
    </>
  );
};

export default DataNotFound;
