//UTILITIES
import React, { useState, useRef, useCallback } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
//COMPONENT
import Search from "../../components/search/Search";
import { Card, CardContent, CardHeader } from "@mui/material";
import { SearchSVG } from "../../assests/icons/SVG";
import { InputAdornment, TableCell, TextField } from "@mui/material";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import Paginator from "../../components/paginator/Paginator";
import {
  groupParamsByKey,
  objectCleaner,
} from "../../core/functions/Functions";
//CSS
import "./Branches.scss";
import { useGet } from "../../hooks/useFetch";

export function BranchesUsers(props) {
  //VARIABLES
  const { branchId } = props;
  const setTimerRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };
  let url = `admin/get-all-users`;
  const { data: branchUsers, loading } = useGet({
    url: url,
    payload: {
      branch_id: branchId,
      ...payload,
    },
  });
  let data = branchUsers?.data.data;
  const handleSearch = useCallback(
    (e) => {
      setSearch(e.target.value);
      if (setTimerRef.current) {
        clearTimeout(setTimerRef.current);
        setTimerRef.current = null;
      }

      setTimerRef.current = setTimeout(() => {
        let url = {
          ...groupParamsByKey(searchParams),
          page: 1,
          search: e.target.value,
        };
        setSearchParams(objectCleaner(url));
        clearTimeout(setTimerRef.current);
        setTimerRef.current = null;
      }, 1000);
    },
    [search],
  );
  const tableHeaders = [
    { title: "Full Name", sorted: false },
    { title: "Email", sorted: false },
    { title: "Role", sorted: false },
  ];
  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        className="header"
        title="Users"
        subheader={`${data?.total ? data?.total : 0} total users`}
      ></CardHeader>
      <CardContent className="no-padding-top">
        <div className="branches-users">
          <div className="search-section">
            <Search
              className="search"
              placeholder={"Search by name"}
              onChange={handleSearch}
              value={search}
              curved
            />
          </div>

          <TableBodyComponent
            header={tableHeaders}
            loading={loading}
            internal={true}
            actions={false}
          >
            {data?.data?.map((element) => (
              <TableRowComponent row={element} internal={true} actions={false}>
                <TableCell>{element.full_name}</TableCell>
                <TableCell>{element.email}</TableCell>
                <TableCell>{element.role?.user_type?.name}</TableCell>
              </TableRowComponent>
            ))}
          </TableBodyComponent>
          <Paginator count={data?.last_page} disabled={loading} />
        </div>
      </CardContent>
    </Card>
  );
}

export default BranchesUsers;
