import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid, Typography, IconButton } from "@mui/material";
import Button from "../../../components/button/Button";
import {
  FormInput,
  FormDropdownList,
} from "../../../components/formComponents/FormComponents";
import { useGlobalValues } from "../../../context/GlobalContext";
import { AlertContext } from "../../../context/AlertContext";
import { DeleteSVG } from "../../../assests/icons/SVG";
import { AddCircleOutline } from "@mui/icons-material";
import "./footer.scss";
import {
  deleteFooterLink,
  createFooterBlock,
  updateFooterBlock,
} from "../../../core/apis/store";
import { blockStatues } from "../../../core/constants/constant";

const QuickLinksForm = (props) => {
  const { edit, data, onFinish } = props;
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();
  const [langCode, setlangCode] = useState("");
  const [id, setBlockId] = useState("");
  const [links, setLinks] = useState([
    {
      id: 1,
      footer_block_id: 1,
      title: "",
      link: "",
      sort_order: 1,
    },
  ]);
  // Schema validation
  const informationsectionSchema = yup.object({
    footer_block_title: yup.string().nullable(),
    footer_block_is_enabled: yup
      .object()
      .nullable()
      .required("Field is required"),
  });

  const {
    control,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(informationsectionSchema),
    defaultValues: {
      footer_block_title: "",
      footer_block_is_enabled: { value: "active", name: "published" },
    },
  });

  useEffect(() => {
    if (edit && data) {
      setBlockId(data.id);
      setLinks(data.links);
      setValue("footer_block_title", data.title);
      setValue(
        "footer_block_is_enabled",
        data.is_active
          ? { value: "active", name: "published" }
          : { value: "inactive", name: "un-published" }
      );
      setlangCode(data.language);
    }
  }, [edit, data]);

  const handleCancel = () => {
    reset({
      footer_block_title: "",
      footer_block_is_enabled: { value: "active", name: "published" },
    });
    onFinish();
  };

  const handleDeleteFooterLink = (id) => {
    deleteFooterLink(id).then((res) => {
      setAlert({
        visible: true,
        text: res?.data?.success ? "Deleted Successfully" : res?.data?.message,
        type: res?.data?.success ? "success" : "error",
      });
    });
  };

  const handleSubmitForm = async (values) => {
    setLoading(true);
    const payload = {
      language: edit ? langCode : storeLanguage,
      footer_block_title: values.footer_block_title,
      footer_block_is_enabled:
        values.footer_block_is_enabled.value === "active" ? true : false,
      footer_block_links: links.map((link) => ({
        title: link.title,
        link: link.link,
        sort_order: link.sort_order,
      })),
    };

    console.log(payload);
    if (!edit) {
      createFooterBlock(payload).then((res) => {
        setAlert({
          visible: true,
          text: res?.data?.success
            ? "Created Successfully"
            : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
        setLoading(false);
        onFinish();
      });
    } else {
      updateFooterBlock(id, payload).then((res) => {
        setAlert({
          visible: true,
          text: res?.data?.success
            ? "Updated Successfully"
            : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
        setLoading(false);
        onFinish();
      });
    }
  };

  // Function to handle input changes for each link
  const handleInputChange = (id, field, value) => {
    const updatedLinks = links.map((link) =>
      link.id === id ? { ...link, [field]: value } : link
    );
    setLinks(updatedLinks);
  };

  const addNewLink = () => {
    const newLink = {
      id: links.length + 1,
      footer_block_id: 1,
      title: "",
      link: "",
      sort_order: links.length + 1,
    };
    setLinks([...links, newLink]);
  };

  const deleteLink = (id) => {
    // Remove the link
    const updatedLinks = links.filter((link) => link.id !== id);

    // Reorder the remaining links
    const reorderedLinks = updatedLinks.map((link, index) => ({
      ...link,
      sort_order: index + 1, // Set new sort_order starting from 1
    }));

    setLinks(reorderedLinks);
  };

  return (
    <div className="footerquickLink">
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormInput
                  required
                  name={`footer_block_title`}
                  label={`Block Title`}
                  hideVariant
                  placeholder={`Enter Title`}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  helperText={error?.message}
                />
              )}
              name={`footer_block_title`}
              control={control}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormDropdownList
                  required
                  data={blockStatues || []}
                  label="Block Status"
                  name="footer_block_is_enabled"
                  placeholder={"Select Status"}
                  value={value}
                  hideVariant
                  accessValue="name"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error?.message}
                />
              )}
              name="footer_block_is_enabled"
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{marginTop:"5px"}}>
          {links.map((item, index) => (
            <Grid item xs={12} sm={6} key={item.id}>
              <Typography variant="h6">Link {index + 1}</Typography>
              <Grid container>
                {/* Editable Title Field */}
                <Grid item xs={12}>
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name={`title-${item.id}`}
                        label="Title"
                        hideVariant
                        placeholder="Enter Title"
                        value={item.title}
                        onChange={(e) => {
                          handleInputChange(item.id, "title", e.target.value);
                          onChange(e.target.value);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name={`title-${item.id}`}
                    control={control}
                    defaultValue={item.title}
                  />
                </Grid>

                {/* Editable Link Field */}
                <Grid item xs={12}>
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        hideVariant
                        name={`linkTitle-${item.id}`}
                        label="Link"
                        placeholder="Enter URL"
                        value={item.link}
                        onChange={(e) => {
                          handleInputChange(item.id, "link", e.target.value);
                          onChange(e.target.value);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name={`linkTitle-${item.id}`}
                    control={control}
                    defaultValue={item.link}
                  />
                </Grid>

                {/* Action Buttons: Delete and Add New */}
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="flex-end"
                  spacing={2}
                >
                  {index > 0 && (
                    <Grid item>
                      <IconButton
                        color="primary"
                        onClick={() => deleteLink(item.id)}
                      >
                        <DeleteSVG sx={{ color: "#EE1818" }} />
                      </IconButton>
                    </Grid>
                  )}
                  {index + 1 === links.length && (
                    <Grid item>
                      <IconButton color="primary" onClick={addNewLink}>
                        <AddCircleOutline />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          justifyContent="flex-end"
          spacing={2}
          style={{ marginTop: "20px" }}
        >
          <Grid item>
            <Button
              onClick={() => handleCancel()}
              name={"Cancel"}
              type="button"
              className="mx-1"
            />
          </Grid>
          <Grid item>
            <Button
              selected
              name={"Save"}
              type="submit"
              className="mx-1"
              loading={loading}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default QuickLinksForm;
