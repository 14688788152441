// EXTERNAL LIBRARIES
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TableCell } from "@mui/material";

// STYLING
import "./Questions.scss";

// TABLE
import TableHeader from "../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import Paginator from "../../components/paginator/Paginator";

// CUSTOM HOOKS
import { useGet } from "../../hooks/useFetch";

// DATA NOT FOUND
import DataNotFound from "../../components/notFound/DataNotFound";
import withH1 from "../../assests/HOC/withH1";

// This component lists the question categories => which is a listing of questions
const Questions = () => {
  // DATA AND HOOKS
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const tableHeaders = [{ title: "Category" }, { title: "Questions" }];

  // Payload to filter the listed data
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };

  // Fetching the listed data with useGet
  let url = `admin/get-all-categories-question-count`;
  const {
    data: questions,
    loading,
    refetch,
  } = useGet({ url: url, payload: payload });
  let data = questions?.data?.data;

  return (
    <div className="questions">
      <TableHeader
        to="/set-of-questions/new-question"
        placeholder="Search by category name"
        filterButton={false}
        state={{ state: { create: true } }}
      />
      <TableBodyComponent
        loading={loading}
        header={tableHeaders}
        total={data?.total}
        actions={false}
      >
        {data?.data?.map((element) => (
          <TableRowComponent
            row={element}
            key={element?.id}
            actions={false}
            handleClick={() => {
              navigate("/set-of-questions/" + element?.id, {
                state: { name: element?.name },
              });
            }}
          >
            <TableCell>{element?.name.split('"')[3]}</TableCell>
            <TableCell>{element?.question_count}</TableCell>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
      <Paginator count={data?.last_page} disabled={loading} />
    </div>
  );
};

export default withH1(Questions, "Set Of Questions");
