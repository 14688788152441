import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "../src/theme/main.scss";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACESSAMPLERATE,
  replaysSessionSampleRate:
    process.env.REACT_APP_SENTRY_REPLAYSSESSIONSAMPLERATE,
  replaysOnErrorSampleRate:
    process.env.REACT_APP_SENTRY_REPLAYSONERRORSAMPLERATE,
  integrations: [new Sentry.Replay()],
});

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
