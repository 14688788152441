import { api } from "./main";

export const getAllHomePageGroup = async (payload) => {
  let page = payload?.page ? `?page=${payload.page}` : "";
  return api
    .get(`admin/get-all-home-page-groups${page}`, {
      params: { ...payload },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const CreateHomegroup = async (data) => {
  return await api
    .post("admin/create-home-page-group", data)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const UpdateHomeGroup = (payload) => {
  return api
    .put(`admin/update-home-page-group/${payload?.group_id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const reOrderHomePageGroup = (payload) => {
  return api
    .patch(`admin/update-home-page-groups-order`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deleteHomeGroup = async (payload) => {
  return await api
    .delete(`/admin/delete-home-page-group/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
