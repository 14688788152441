import React, { useState } from "react";
import { Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./about.scss";
import AboutusSection from "./about-us-section";
import BannerSection from "./banner-section";
import TeamSection from "./our-team-section";

const Aboutus = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="about">
      <TabPanel
        value="7"
        style={{ minHeight: "600px", padding: "0px", marginTop: "10px" }}
      >
        <TabContext value={tabValue}>
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label="About us Informative Section" value="0" />
            <Tab label="About us Banner Section" value="1" />
            <Tab label="About us Team Section" value="2" />
          </TabList>

          <TabPanel value="0" style={{ minHeight: "600px", padding: "0px" }}>
            <AboutusSection></AboutusSection>
          </TabPanel>
          <TabPanel value="1" style={{ minHeight: "600px", padding: "0px" }}>
            <BannerSection></BannerSection>
          </TabPanel>
          <TabPanel value="2" style={{ minHeight: "600px", padding: "0px" }}>
            <TeamSection></TeamSection>
          </TabPanel>
        </TabContext>
      </TabPanel>
    </div>
  );
};

export default Aboutus;
