import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import boldIcon from "../../assests/icons/editorIcons/boldIcon.png";
import italicIcon from "../../assests/icons/editorIcons/italicIcon.png";
import orderedIcon from "../../assests/icons/editorIcons/orderedIcon.png";
import unorderedIcon from "../../assests/icons/editorIcons/unorderedIcon.png";
import undoIcon from "../../assests/icons/editorIcons/undoIcon.png";
import redoIcon from "../../assests/icons/editorIcons/redoIcon.png";
import underlineIcon from "../../assests/icons/editorIcons/underlineIcon.png";
import header1Icon from "../../assests/icons/editorIcons/header1Icon.png";
import header2Icon from "../../assests/icons/editorIcons/header2Icon.png";
import eyeDropIcon from "../../assests/icons/editorIcons/eyeDropIcon.png";
import htmlToDraft from "html-to-draftjs";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { useEffect } from "react";
import React, { useState } from "react";
import { FormHelperText, InputLabel } from "@mui/material";
import "./Editor.scss";

//THIS EDITOR IS NOT MUI VERISION
//BECAUSE MUI VERSION IS MAKING CSS PROBLEMS WITH BUTTONS AND DIALOGS

export function NewEditor(props) {
  const { value, label, required, helperText, labelclassname , lang} = props;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onChange = (newState) => {
    setEditorState(newState);
    props.onChange(draftToHtml(convertToRaw(newState.getCurrentContent())));
  };

  console.log(lang)

  // useEffect(() => {
  //   if (value && (value.en != null || value.en != undefined)) {
  //     const html = value?.en ? value?.en : value;
  //     const contentBlock = htmlToDraft(html);
  //     const contentState = ContentState.createFromBlockArray(
  //       contentBlock.contentBlocks
  //     );
  //     setEditorState(EditorState.createWithContent(contentState));
  //   }
  // }, []);

  useEffect(() => {
    if (value) {
      let html;

      // Check if `value` is an object and has `en` property
      if (
        typeof value === "object" &&
        value?.[lang] !== null &&
        value?.[lang] !== undefined
      ) {
        html = value?.[lang];
      } else if (typeof value === "string") {
        // If `value` is a string, use it directly
        html = value;
      }

      if (html) {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  }, []);

  console.log(value)

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Simulate an upload and return the image URL
        resolve({ data: { link: reader.result } });
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <div className="form-input-wrapper" style={{ width: "100%" }}>
        {label && (
          <InputLabel className={labelclassname ? labelclassname : ""}>
            {label}
            <span className="required-start">{required ? " * " : ""}</span>
          </InputLabel>
        )}
        <Editor
          toolbarClassName="toolbarTextEditor"
          wrapperClassName="wrapperTextEditor"
          editorClassName="editorTextEditor"
          editorState={editorState}
          onEditorStateChange={onChange}
          toolbar={{
            image: {
              uploadCallback: uploadImageCallBack,
              previewImage: true,
              alt: { present: true, mandatory: false },
            },
          }}
        />{" "}
        {helperText !== "" && <FormHelperText>{helperText}</FormHelperText>}
      </div>
    </>
  );
}

export default NewEditor;
