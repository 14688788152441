import React, { createContext, useEffect, useState } from "react";
import { isUserLoggedIn } from "../core/apis/auth";
import { getUserNotification } from "../core/apis/notifications";

const NotificationContext = createContext();

const NotificationContextProvider = (props) => {
  const { children } = props;


  const [notificationCount, setNotificationCount] = useState(
    localStorage?.getItem("notifyCount") === "undefined"
      ? 0
      : localStorage?.getItem("notifyCount")
  );

  const updateNotificationCount = () => {
    getUserNotificationData();
  };

  const getUserNotificationData = () => {
    getUserNotification().then((res) => {
      if (res?.data?.success) {
        setNotificationCount(res?.data?.data?.unread_notifications_count);
        localStorage.setItem(
          "notifyCount",
          res?.data?.data?.unread_notifications_count
        );
      }
    });
  };

  useEffect(() => {
    if (isUserLoggedIn()) {
      getUserNotificationData();
    }
  }, []);

  return (
    <NotificationContext.Provider
      value={{ notificationCount, updateNotificationCount }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
export { NotificationContextProvider, NotificationContext };
