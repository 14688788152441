import { api } from "./main";

export const getAllBrands = (payload) => {
  return api
    .get("admin/get-all-brands", { params: payload })
    .then((res) => res.data.data)
    .catch((err) => console.error(err));
};

export const getBrandById = (id) => {
  return api
    .get(`admin/get-brand-by-id/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const createBrand = async (data) => {
  return await api
    .post("admin/create-brand", data)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateBrand = async (data, id) => {
  return await api
    .post(`admin/update-brand/${id}`, data)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deleteBrand = async (payload) => {
  return await api
    .delete(`admin/delete-brand/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
