import "./languagesetting.scss";
import React, { useEffect, useState, useContext, useRef } from "react";
import {
  getalllanguages,
  enableLanguages,
  getalllanguagesfilterBystatus,
} from "../../core/apis/newlanguage";
import { TableCell } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Paginator from "../../components/paginator/Paginator";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import TableHeader from "../../components/table/tableHeader/TableHeader";
import withH1 from "../../assests/HOC/withH1";
import { AlertContext } from "../../context/AlertContext";
import { useGlobalValues } from "../../context/GlobalContext";

const Languagesetting = () => {
  const [languageslist, setLanguagesList] = useState();
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [loading, setLoading] = useState(true);
  const tableHeaders = [{ title: "Language Settings" }];
  const [searchParams] = useSearchParams();
  const { setAlert } = useContext(AlertContext);

  const { setenabledLanguagesList } = useGlobalValues();

  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
    is_enabled: searchParams.get("is_enabled") || 1,
  };

  const prevPayload = useRef(payload);

  //FUNCTIONS
  const handleSwitch = (e, id) => {
    setIsSwitchOn(e.target.checked);
    handleEnableDisableLanguages(e.target.checked, id);
  };

  const getlanguages = () => {
    getalllanguages(payload).then((response) => {
      setLanguagesList(response.data.data);
      setLoading(false);
    });
  };

  const handleEnableDisableLanguages = (value, id) => {
    setLoading(true);
    let payload = { is_enabled: value };
    enableLanguages(id, payload).then((res) => {
      setAlert({
        visible: true,
        text: res.data.message,
        type: res.data.success ? "success" : "error",
      });
      getlanguages();
      getalllanguagesfilterBystatus().then((res) => {
        const sortedLanguages = res.sort((a, b) => {
          // Place English first by checking if the code is "en"
          if (a.code === "en") {
            return -1;
          } else if (b.code === "en") {
            return 1;
          }
          return 0;
        });
        setenabledLanguagesList(sortedLanguages);
      });
    });
  };

  useEffect(() => {
    getlanguages();
  }, []);

  useEffect(() => {
    // Check if any property in the payload has changed
    if (
      prevPayload.current.per_page !== payload.per_page ||
      prevPayload.current.page !== payload.page ||
      prevPayload.current.name !== payload.name ||
      prevPayload.current.is_enabled !== payload.is_enabled
    ) {
      setLoading(true);
      getlanguages();
      // Update the previous payload
      prevPayload.current = { ...payload };
    }
  }, [payload]);

  return (
    <div>
      <>
        <TableHeader
          placeholder="Search by Language"
          filterbyenableLanguage
          withAll={false}
        />
        <TableBodyComponent
          header={tableHeaders}
          loading={loading}
          total={languageslist?.total}
        >
          {languageslist?.data.map((element) => (
            <TableRowComponent
              className="LanguageSettings"
              row={element}
              key={element?.id}
              isEnglish={element.code === "en" ? true : false}
              isSwitchOn={element.is_enabled}
              handleSwitch={(e) => {
                handleSwitch(e, element?.id);
              }}
            >
              <TableCell>{element?.name}</TableCell>
            </TableRowComponent>
          ))}
        </TableBodyComponent>
        <Paginator count={languageslist?.last_page} disabled={loading} />
      </>
    </div>
  );
};

export default withH1(Languagesetting, "Languages");
