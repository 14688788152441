import React, { useState } from "react";
import { Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./contactUs.scss";
import ContactusSection from "./contactusSection";
import FindusSection from "./findusSection";

const ContactUs = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="contactUs">
      <TabPanel value="6" style={{ minHeight: "600px", padding: "0px", marginTop: "10px" }}>
        <TabContext value={tabValue}>
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label="Contact us Form Section" value="0" />
            <Tab label="Find us Form Section" value="1" />
          </TabList>

          <TabPanel
            value="0"
            style={{ minHeight: "600px", padding: "0px", marginTop: "10px" }}
          >
            <ContactusSection></ContactusSection>
          </TabPanel>
          <TabPanel
            value="1"
            style={{ minHeight: "600px", padding: "0px", marginTop: "10px" }}
          >
            <FindusSection></FindusSection>
          </TabPanel>
        </TabContext>
      </TabPanel>
    </div>
  );
};

export default ContactUs;
