import { api } from "./main";

export const getallheadlineinfo = async () => {
  return await api
    .get("/get-all-headline-info")
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const UpdateheadlineinfoById = async (id, payload) => {
  return await api
    .put(`/admin/update-headline-info/${id}`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};
