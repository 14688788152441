import React, { useContext, useState } from "react";
import { TableCell } from "@mui/material";
import Paginator from "../../components/paginator/Paginator";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import TableHeader from "../../components/table/tableHeader/TableHeader";
import "./Banners.scss";
import { useAxios, useGet } from "../../hooks/useFetch";
import { useNavigate, useSearchParams } from "react-router-dom";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { AlertContext } from "../../context/AlertContext";
import { deleteBanner } from "../../core/apis/banner";
import withH1 from "../../assests/HOC/withH1";
const tableHeaders = [
  { title: "Name" },
  { title: "Start Date" },
  { title: "End Date" },
  { title: "Country" },
];
const Banners = () => {
  const { setAlert } = useContext(AlertContext);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [bannerDetail, setBannerDetail] = useState(null);

  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };
  let url = `admin/get-all-banners`;

  const {
    data: banners,
    loading: loadingPage,
    refetch,
  } = useGet({
    url: url,
    payload: payload,
    reloading: true,
  });
  let data = banners?.data?.data || [];

  const handleDelete = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteBanner(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setBannerDetail(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpen(false);
  };

  return (
    <div>
      <TableHeader
        placeholder="Search by name"
        to="/banners/new-banner"
        filterButton={false}
      />
      <TableBodyComponent
        header={tableHeaders}
        loading={loadingPage}
        total={data?.total}
      >
        {data?.data?.map((element) => (
          <TableRowComponent
            className="BrandDetails"
            handleEdit={() => navigate(`/banners/banner/${element?.id}`)}
            handleDelete={() => {
              setBannerDetail(element);

              setOpen(true);
            }}
            row={element}
            key={element?.id}
          >
            <TableCell>{element?.name}</TableCell>
            <TableCell>{element?.start_date}</TableCell>
            <TableCell>{element?.end_date}</TableCell>

            <TableCell>
              {element?.country.map(
                (item, index) =>
                  `${item.name} ${
                    element?.country.length - 1 > index ? ", " : ""
                  }`,
              )}
            </TableCell>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
      <Paginator count={data?.last_page} disabled={loadingPage} />
      {open && (
        <NoticeConfirmation data={bannerDetail} handleResponse={handleDelete} />
      )}
    </div>
  );
};

export default withH1(Banners, "Banners");
