//UTILITIES
import React, { useContext, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { AlertContext } from "../../context/AlertContext";
//COMPONENT
import { TableCell } from "@mui/material";
import Paginator from "../../components/paginator/Paginator";
import TableHeader from "../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";

//API
import { useGet } from "../../hooks/useFetch";
import { deleteBranch } from "../../core/apis/branch";
//CSS
import "./Branches.scss";
import withH1 from "../../assests/HOC/withH1";

export function Branches(props) {
  //VARIABLES
  const tableHeaders = [
    { title: "Branch" },
    {
      title: "Organization",
    },
    {
      title: "Country",
    },
    {
      title: "Number of users",
    },
  ];

  let navigate = useNavigate();
  const [searchParams] = useSearchParams(); //pagination and filter
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
    country_ids: searchParams.getAll("country_ids"),
  };
  let url = `admin/get-all-branches`;
  const {
    data: branches,
    loading,
    refetch,
  } = useGet({ url: url, payload: payload });
  let data = branches?.data.data;
  const { setAlert } = useContext(AlertContext);
  const [branchDetail, setBranchDetail] = useState(null);

  const [openDelete, setOpenDelete] = useState(false); //bool for delete dialog

  //FUNCTIONS

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteBranch(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setBranchDetail(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  return (
    <div className="branches">
      <TableHeader
        placeholder="Search by branch name"
        to="/branches/new-branch"
        filterByBranchCountry
        checkbox
      />
      <TableBodyComponent
        header={tableHeaders}
        loading={loading}
        total={data?.total}
        // handleExport={handleExport}
      >
        {data?.data?.map((element) => (
          <TableRowComponent
            handleDelete={() => {
              setBranchDetail(element);
              setOpenDelete(true);
            }}
            row={element}
            handleEdit={() => navigate(`/branches/edit-branch/${element?.id}`)}
          >
            <TableCell>{element.name}</TableCell>
            <TableCell>
              {element.organization?.organization?.name?.en}
            </TableCell>
            <TableCell>{element?.address?.[0]?.country?.name}</TableCell>
            <TableCell>{element.user_count}</TableCell>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
      <Paginator count={data?.last_page} disabled={loading} />
      {openDelete && (
        <NoticeConfirmation
          data={branchDetail}
          onClose={() => {
            setBranchDetail(null);
            setOpenDelete(false);
          }}
          handleResponse={handleDeleteResponse}
        />
      )}
    </div>
  );
}

export default withH1(Branches, "Branches");
