import React, { Fragment, useCallback, useEffect } from "react";
import { useRef, useState } from "react";
import { Button, Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { FormLabel, Radio, RadioGroup, SvgIcon, Skeleton } from "@mui/material";
import "./TableHeader.scss";
import { NavLink, useSearchParams } from "react-router-dom";
import { PlusSVG } from "../../../assests/icons/SVG";
import { FilterComponent } from "../../filterDropdown/FilterDropdown";
import Search from "../../search/Search";
import { getAllBrands } from "../../../core/apis/brands";
import PropTypes from "prop-types";
import {
  getTransactionOrderStatuses,
  getTransactionStatuses,
} from "../../../core/apis/Statuses";
import {
  groupParamsByKey,
  objectCleaner,
} from "../../../core/functions/Functions";
import {
  getAllProductsCategory,
  getAllProductType,
} from "../../../core/apis/product";
import { getAllCountries } from "../../../core/apis/country";
import { getAllProductSkus } from "../../../core/apis/productSkus";
import { getAllBranchesCountries } from "../../../core/apis/branch";
import { getAllNotificationTypes } from "../../../core/apis/notifications";
import { getAllUsersTypes } from "../../../core/apis/userManagement";
import InfiniteScroll from "react-infinite-scroller";
import { FormSearchBar } from "../../formComponents/FormComponents";
import { getAllCollectionPoints } from "../../../core/apis/collectionPoint";

const TableHeader = (props) => {
  const {
    to,
    withAll,
    normalPosition,
    filterByHideStatus,
    filterByProductType,
    placeholder,
    children,
    filterByUserType,
    filterByBranchCountry,
    filterByManageRulesCategory,
    filterbyenableLanguage,
  } = props;
  const { add, filterButton, addSearch, filterByTransactionStatus, state } =
    props;
  const {
    filterByTransactionType,
    filterByTransactionAction,
    filterByNotificationType,
    filterByBrands,
    filterByTransactionOrderStatus,
    filterByItemsCollectionPoints,
    productSkuId,
    filterByProductStatus,
    checkbox,
    filterByCountry,
    filterByNewProductsku
  } = props;

  const { handleTransfer } = props;
  const [productType, setProductType] = useState([]);
  const [countries, setCountries] = useState([]);
  const [collectionPoints, setCollectionPoints] = useState([]);
  const [brands, setBrands] = useState([]);
  const [productStatuses, setProductStatuses] = useState([]);
  const [transactionStatuses, setTransactionStatuses] = useState([]);
  const [transactionOrderStatuses, setTransactionOrderStatuses] = useState([]);
  const [manageRulesCategories, setManageRulesCategories] = useState([]);
  const [userType, setUserType] = useState([]);
  const [notificationType, setNotificationType] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const [paginatorPage, setPaginatorPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [TotalPage, setTotalPage] = useState(0);
  const setTimerRef = useRef();
  const [filterSearch, setFilterSearch] = useState({
    category_ids: "",
  });

  const [newProductSKU, setnewProductSKU] = useState([]);

  const handleSearch = useCallback(
    (e) => {
      setSearch(e.target.value);
      if (setTimerRef.current) {
        clearTimeout(setTimerRef.current);
        setTimerRef.current = null;
      }

      setTimerRef.current = setTimeout(() => {
        let url = {
          ...groupParamsByKey(searchParams),
          page: 1,
          search: e.target.value,
        };
        setSearchParams(objectCleaner(url));
        clearTimeout(setTimerRef.current);
        setTimerRef.current = null;
      }, 1000);
    },
    [search]
  );

  const currencyStatus = [
    { name: "Not Hidden", value: "false", checked: true },
    {
      name: "Hidden",
      value: "true",
      checked: false,
    },
  ];
  const transactionTypes = [
    { name: "Sell", value: "false", checked: false },
    {
      name: "Buy",
      value: "true",
      checked: false,
    },
  ];
  const transactionAction = [
    { name: "Accept with maintenance", value: 4, checked: false },
    {
      name: "Inspect",
      value: 5,
      checked: false,
    },
    {
      name: "Propose Offer",
      value: 10,
      checked: false,
    },
  ];
  const Langauges = [
    { name: "Enabled", value: 1, checked: true },
    {
      name: "Disabled",
      value: 0,
      checked: false,
    },
  ];

  const urlSearch = {
    productType: searchParams.getAll("brandId"),
    brandId: searchParams.getAll("brandId"),
    statusId: searchParams.getAll("statusId"),
    transaction_status_ids: searchParams.getAll("transaction_status_ids"),
    transaction_order_status_ids: searchParams.getAll(
      "transaction_order_status_ids"
    ),
    category_ids: searchParams.getAll("category_ids"),
    action_ids: searchParams.getAll("action_ids"),
    notification_type: searchParams.get("notification_type"),
    user_type_ids: searchParams.getAll("user_type_ids"),
    country_ids: searchParams.getAll("country_ids"),
    brand_ids: searchParams.getAll("brand_ids"),
    is_hidden: searchParams.get("is_hidden"),
    is_buy: searchParams.get("is_buy"),
    collection_point_ids: searchParams.getAll("collection_point_ids"),
    is_enabled: searchParams.get("is_enabled"),
  };

  useEffect(() => {
    filterByProductType &&
      getAllProductType().then((res) => {
        let items = res.data.data.map((item) => ({
          name: item.product_type,
          value: item.id,
          checked: false,
        }));
        setProductType(items);
      });

    filterByCountry &&
      getAllCountries().then((res) => {
        let items = res.data.data.map((item) => ({
          name: item.name,
          value: item.id,
          checked: false,
        }));
        setCountries(items);
      });
    filterByItemsCollectionPoints &&
      getAllCollectionPoints({ product_sku_id: productSkuId }).then((res) => {
        let items = res.data.data.map((item) => ({
          name: item.name?.en,
          value: item.id,
          checked: false,
        }));
        setCollectionPoints(items);
      });
    filterByBranchCountry &&
      getAllBranchesCountries().then((res) => {
        let items = res.data.data.map((item) => ({
          name: item.name,
          value: item.id,
          checked: false,
        }));
        setCountries(items);
      });

    if (filterByManageRulesCategory) {
      setLoading(true);
      getAllProductsCategory({
        per_page: 10,
        page: 1,
        name: filterSearch.category_ids,
      }).then((res) => {
        let items = res?.data?.data?.data?.map((item) => ({
          name: item.name?.en,
          value: item.id,
          checked: false,
        }));
        setPaginatorPage(res?.data?.data?.current_page);
        setTotalPage(res?.data?.data?.last_page);
        setManageRulesCategories(items);
        setLoading(false);
      });
    }

    filterByBrands &&
      getAllBrands().then((res) => {
        let items = res?.map((item) => ({
          name: item.name?.en,
          value: item.id,
          checked: false,
        }));
        setBrands(items);
      });

    filterByUserType &&
      getAllUsersTypes().then((res) => {
        if (res?.data?.success) {
          let status = res?.data.data
            ?.filter((el) => el?.id !== 3) //removed buyer
            .map((item) => ({
              name: item.name,
              value: item.id,
              checked: false,
            }));

          setUserType(status);
        }
      });
    filterByNotificationType &&
      getAllNotificationTypes({ ids: [1, 2] }).then((res) => {
        if (res?.data?.success) {
          let status = res?.data.data?.map((item) => ({
            name: item.name,
            value: item.id,
            checked: false,
          }));

          setNotificationType(status);
        }
      });

    filterByTransactionOrderStatus &&
      getTransactionOrderStatuses().then((res) => {
        let status = res?.data.data?.map((item) => ({
          name: item.name?.en,
          value: item.id,
          checked: false,
        }));

        setTransactionOrderStatuses(status);
      });

    filterByTransactionStatus &&
      getTransactionStatuses({ ids: [1, 2, 5] }).then((res) => {
        let status = res?.data.data?.map((item) => ({
          name: item.name?.en,
          value: item.id,
          checked: false,
        }));

        setTransactionStatuses(status);
      });

    filterByNewProductsku &&
      getAllProductSkus().then((res) => {
        let items = res?.data?.data?.map((item) => ({
          name: item.sku_code,
          value: item?.id,
          checked: false,
        }));
        setnewProductSKU(items);
      });
  }, [filterSearch.category_ids]);

  const handleFilter = (e) => {
    let choosenBrands = urlSearch;
    choosenBrands[e.target.name] = e.target.value;
    setSearchParams(
      objectCleaner({
        ...groupParamsByKey(searchParams),
        ...choosenBrands,
        page: 1,
      })
    );
  };

  const handleCheckbox = (e) => {
    let choosenBrands = urlSearch;
    e.target.checked && choosenBrands[e.target.name].push(e.target.value);
    if (!e.target.checked)
      choosenBrands[e.target.name] = choosenBrands[e.target.name].filter(
        (item) => +item !== +e.target.value
      );
    setSearchParams(choosenBrands);
  };

  const handlePageChange = () => {
    let array = [...manageRulesCategories];
    if (paginatorPage + 1 <= TotalPage) {
      let curpage =
        paginatorPage < TotalPage ? paginatorPage + 1 : paginatorPage;
      getAllProductsCategory({
        name: filterSearch.category_ids,
        page: curpage,
        per_page: 10,
      }).then((response) => {
        if (response.data?.success) {
          setPaginatorPage(response.data.data?.current_page);
          setTotalPage(response.data.data?.last_page);
          if (response.data.data) {
            response.data.data?.data.map((item) => {
              array.push({
                name: item.name?.en,
                value: item.id,
                checked: false,
              });
            });
          }

          setManageRulesCategories(array);
        }
      });
    }
  };

  const filterbox = [
    {
      title: "Product Status",
      data: productStatuses,
      name: "statusId",
      visible: filterByProductStatus,
      bool: false,
      textSearch: false,
    },
    {
      title: "Brands",
      data: brands,
      name: "brand_ids",
      visible: filterByBrands,
      bool: false,
      textSearch: false,
    },
    {
      title: "Product SKU",
      data: newProductSKU,
      name: "product_sku_id",
      visible: filterByNewProductsku,
      bool: false,
      textSearch: false,
    },
    {
      title: "Product Type",
      data: productType,
      name: "productType",
      visible: filterByProductType,
      bool: false,
      textSearch: false,
    },
    {
      title: "Transaction Type",
      data: transactionTypes,
      name: "is_buy",
      visible: filterByTransactionType,
      bool: true,
      textSearch: false,
    },
    {
      title: "Transaction Status",
      data: transactionStatuses,
      name: "transaction_status_ids",
      visible: filterByTransactionStatus,
      bool: false,
      textSearch: false,
    },

    {
      title: "Transaction Order Status",
      data: transactionOrderStatuses,
      name: "transaction_order_status_ids",
      visible: filterByTransactionOrderStatus,
      bool: false,
      textSearch: false,
    },
    {
      title: "User Type",
      data: userType,
      name: "user_type_ids",
      visible: filterByUserType,
      bool: false,
      textSearch: false,
    },
    {
      title: "Country",
      data: countries,
      name: "country_ids",
      visible: filterByCountry,
      bool: false,
      textSearch: false,
    },
    {
      title: "Country",
      data: countries,
      name: "country_ids",
      visible: filterByBranchCountry,
      bool: false,
      textSearch: false,
    },
    {
      title: "Currency Status",
      data: currencyStatus,
      name: "is_hidden",
      visible: filterByHideStatus,
      bool: true,
      textSearch: false,
    },

    {
      title: "Type",
      data: notificationType,
      name: "notification_type",
      visible: filterByNotificationType,
      bool: false,
      textSearch: false,
    },
    {
      title: "Action",
      data: transactionAction,
      name: "action_ids",
      visible: filterByTransactionAction,
      bool: false,
      textSearch: false,
    },
    {
      title: "Category",
      data: manageRulesCategories,
      name: "category_ids",
      visible: filterByManageRulesCategory,
      bool: false,
      textSearch: true,
    },
    {
      title: "Collection Point",
      data: collectionPoints,
      name: "collection_point_ids",
      visible: filterByItemsCollectionPoints,
      bool: false,
      textSearch: false,
    },
    {
      title: "Languages Status",
      data: Langauges,
      name: "is_enabled",
      visible: filterbyenableLanguage,
      bool: true,
      textSearch: false,
    },
  ];

  return (
    <div className={`TableHeader ${normalPosition ? "normalPosition" : ""}`}>
      {addSearch ? (
        <Search
          className="search"
          placeholder={placeholder}
          onChange={handleSearch}
          value={search}
          curved
        />
      ) : null}
      {to || add ? (
        <Button
          aria-label="Add button"
          className="PlusSVG"
          component={NavLink}
          to={add ? null : to && to}
          state={add ? null : state && state}
          onClick={to ? null : add && add}
        >
          <SvgIcon component={PlusSVG} inheritViewBox fontSize={"large"} />
        </Button>
      ) : null}

      {filterButton ? (
        <FilterComponent>
          {children ? (
            children
          ) : (
            <FormControl sx={{ padding: "10px 16px" }}>
              {filterbox
                .filter((item) => item.visible)
                .map((item, index) => (
                  <Fragment key={index}>
                    <FormLabel style={{ margin: "5px 0px 0px 0px" }}>
                      Filter By {item.title}
                    </FormLabel>
                    {item.textSearch && (
                      <FormSearchBar
                        variant="standard"
                        placeholder={"Search"}
                        value={filterSearch[item.name]}
                        onChange={(value) => {
                          setFilterSearch({
                            ...filterSearch,
                            [item.name]: value,
                          });
                        }}
                      />
                    )}
                    <div
                      style={{
                        maxHeight: item?.textSearch ? "250px" : "unset",
                        overflow: item?.textSearch ? "auto" : "unset",
                      }}
                    >
                      {!loading ? (
                        <InfiniteScroll
                          pageStart={0}
                          initialLoad={false}
                          loadMore={handlePageChange}
                          hasMore={
                            !loading && paginatorPage < TotalPage ? true : false
                          }
                          loader={<Skeleton />}
                          useWindow={false}
                          threshold={300}
                        >
                          <RadioGroup
                            onChange={handleFilter}
                            value={urlSearch[item.name] || ""}
                            defaultValue="All"
                          >
                            {!checkbox && withAll && (
                              <FormControlLabel
                                className="radioFilter"
                                value=""
                                control={<Radio />}
                                label="All"
                                name={item.name}
                              />
                            )}

                            {item.data?.map((status) => {
                              return (
                                <FormControlLabel
                                  className="radioFilter"
                                  name={item.name}
                                  value={status.value}
                                  key={status.value}
                                  control={checkbox ? <Checkbox /> : <Radio />}
                                  label={status.name}
                                  onChange={
                                    checkbox ? handleCheckbox : undefined
                                  }
                                  checked={
                                    !item?.bool
                                      ? searchParams
                                          .getAll(item.name)
                                          .some((id) => +id === +status.value)
                                      : searchParams.get(item?.name)
                                      ? searchParams.get(item?.name) ==
                                        status.value
                                      : status?.checked
                                  }
                                />
                              );
                            })}
                          </RadioGroup>
                        </InfiniteScroll>
                      ) : (
                        <Skeleton />
                      )}
                    </div>
                  </Fragment>
                ))}
            </FormControl>
          )}
        </FilterComponent>
      ) : null}
    </div>
  );
};
TableHeader.defaultProps = {
  filterButton: true,
  addSearch: true,
  checkbox: false,
  withAll: true,
  normalPosition: false,
};
TableHeader.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  filterButton: PropTypes.bool,
  normalPosition: PropTypes.bool,
  addSearch: PropTypes.bool,
  filterByProductStatus: PropTypes.bool,
  filterByTransactionStatus: PropTypes.bool,
  filterByProductType: PropTypes.bool,
  filterByBrands: PropTypes.bool,
  filterByNewProductsku: PropTypes.bool,
  filterByUserType: PropTypes.bool,
  filterByCountry: PropTypes.bool,
  filterbyenableLanguage: PropTypes.bool,
  checkbox: PropTypes.bool,
  withAll: PropTypes.bool,
  to: PropTypes.any,
  add: PropTypes.func,
};
export default TableHeader;
