// EXTERNAL LIBRARIES
import React, { useState, useEffect, useContext } from "react";

// STYLING
import "./VatDetails.scss";

// CUSTOM HOOKS

import { AlertContext } from "../../../context/AlertContext";

// FORM
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IconButton, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FormInput } from "../../../components/formComponents/FormComponents";
import Button from "../../../components/button/Button";
import { CloseSVG } from "../../../assests/icons/SVG";
import { updateCountryVat } from "../../../core/apis/country";
import { Controller, useForm } from "react-hook-form";

const HandleSchema = yup.object({
  country: yup.object().required("Field is required"),
  vat: yup.number("Invalid Field").nullable().required("Field is required"),
});

const VatDetails = (props) => {
  // DATA AND HOOKS
  const { refetch, onClose, element } = props;
  const { setAlert } = useContext(AlertContext);
  const [saveLoading, setSaveLoading] = useState(false);
  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(HandleSchema),
  });

  const handleSubmitForm = (formData) => {
    setSaveLoading(true);
    updateCountryVat({
      country: element ? element?.id : formData?.country?.id,
      vat: formData?.vat,
    }).then((res) => {
      if (res?.data?.success) {
        onClose();
        refetch();
      }

      setAlert({
        text: res.data.message,
        type: res.data.success ? "success" : "error",
      });
      setSaveLoading(false);
    });
  };

  useEffect(() => {
    reset({
      country: element ? element : null,
      vat: element ? element?.vat : null,
    });
  }, []);

  return (
    <Dialog disablebackdropclick="true" fullWidth open={true}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {`${element === null ? "Add" : "Update"} VAT`}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseSVG />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="vatDetails">
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Controller
              render={({ field: { onChange }, fieldState: { error } }) => (
                <FormInput
                  type="string"
                  label="Country"
                  placeholder="Select a country"
                  name="country"
                  value={element?.name}
                  disabled={element ? true : false}
                  helperText={error?.message}
                  onChange={(value) => onChange(value)}
                />
              )}
              name={`country`}
              control={control}
            />

            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormInput
                  type="number"
                  label="Value"
                  placeholder="Enter VAT value (%)"
                  name="vat"
                  value={value}
                  onChange={(e) =>
                    onChange(e.target.value === "" ? null : e.target.value)
                  }
                  helperText={error?.message}
                />
              )}
              name={`vat`}
              control={control}
            />

            <div className="action-footer">
              <Button
                name={!element ? "Create" : "Update" || ""}
                selected
                loading={saveLoading}
                type="submit"
                onSubmit={() => refetch()}
              />
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default VatDetails;
