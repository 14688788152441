import React, { forwardRef } from "react";
import {
  Dialog,
  Slide,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import "./Dialog.scss";
import PropTypes from "prop-types";
import Button from "../../button/Button";
import { Close } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogComponent = (props) => {
  const { open, handleOpenClose, handleModal, loading, onSubmit } = props;
  const { buttonLabel, children, title, className, disabled } = props;
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleOpenClose}
      className="dialogComp"
      maxWidth="md"
    >
      <DialogTitle>
        {title}
        <IconButton onClick={handleOpenClose}>
          <Close className="closeSVG" />
        </IconButton>
      </DialogTitle>

      <DialogContent className={`modalBody ${className ? className : ""}`}>
        <form onSubmit={onSubmit}>
          {children}
          {buttonLabel && (
            <div className="Buttons">
              <Button
                onClick={handleOpenClose}
                name="Cancel"
                
                width="30%"
                type="submit"
              />
              <Button
                onClick={handleModal}
                name={buttonLabel}
                width="30%"
                selected
                type="submit"
                loading={loading}
                disabled={disabled}
              />
            </div>
          )}
        </form>
      </DialogContent>
    </Dialog>
  );
};
DialogComponent.defaultProps = {
  handleModal: undefined,
};
DialogComponent.propTypes = {
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  open: PropTypes.bool,
  handleOpenClose: PropTypes.func,
  handleModal: PropTypes.func,
  className: PropTypes.string,
};
export default DialogComponent;
