import React, { useEffect } from "react";
import { Pagination } from "@mui/material";
import "./Paginator.scss";
import { useSearchParams } from "react-router-dom";
import { groupParamsByKey } from "../../core/functions/Functions";

const Paginator = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handlePagination = (_, value) => {
    setSearchParams({ ...groupParamsByKey(searchParams), page: value });
  };
  useEffect(() => {}, []);

  return (
    <div className="paginator-section">
      <Pagination
        className="paginator"
        color="primary"
        page={+searchParams.get("page") || 1}
        onChange={handlePagination}
        {...props}
      />
    </div>
  );
};

export default Paginator;
