import React, { useState, useContext } from "react";
import Button from "../../../components/button/Button";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AlertContext } from "../../../context/AlertContext";
import * as yup from "yup";
import { useGet } from "../../../hooks/useFetch";
import "./reportdetails.scss";
import {
  FormDropdownList,
  FormMultiSelect,
  FormCalendarInput,
  FormMultiSelectLoader,
} from "../../../components/formComponents/FormComponents";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  branchesExport,
  organizationsExport,
  transactionsExport,
  usersExport,
  itemsExport,
} from "../../../core/apis/reports";
import dayjs from "dayjs";
import { Paper } from "@mui/material";
import Swal from "sweetalert2";

const CreateReport = () => {
  let navigate = useNavigate();
  const [selectedReportType, setSelectedReportType] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [areOrganizationsSelected, setAreOrganizationsSelected] =
    useState(false);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);

  const reportSchema = yup.object({
    report_id: yup.object().nullable().required("Field is required"),
    countries_id: yup.array(""),
    organization_id: yup.array(""),
    categories_id: yup.array(""),
    conditions_id: yup.array(""),
    skus_id: yup.array(""),
    statuses_id: yup.array(""),
    branches_id: yup.array(""),
    date: yup.string(),
    users_id: yup.array(""),
    usertypes_id: yup.array(""),
    startdate_id: yup.string().nullable(),
    enddate_id: yup.string().nullable(),
  });

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(reportSchema) });

  let payload = {
    per_page: 10,
    page: 1,
  };

  let url = `admin/get-all-countries`;

  // API CALL
  const { data: countries, loadingCountries } = useGet({
    url: url,
    payload: payload,
  });

  let data = countries?.data?.data?.data;

  const { data: organizations, loading: loadingorganizations } = useGet({
    url: "admin/get-all-organization",
    payload,
  });
  let organizationsArray = organizations?.data?.data?.data;

  // Categories
  const { data: categories, loading: loadingcategories } = useGet({
    url: "admin/get-all-categories",
  });
  let categoriesArray = categories?.data?.data?.categories;

  // Conditions
  const { data: conditions, loading: loadingconditions } = useGet({
    url: "user/get-all-conditions",
  });
  let conditionsArray = conditions?.data?.data;

  // SKU
  const { data: skus, loading: loadingskus } = useGet({
    url: "admin/get-all-product-skus",
    payload: payload,
  });
  let skusArray = skus?.data?.data?.data;

  // Status
  const { data: statuses, loading: loadingstatuses } = useGet({
    url: "get-all-transaction-statuses",
  });
  let statusesArray = statuses?.data?.data;
  // user types
  const { data: usertypes, loading: loadingusertypes } = useGet({
    url: "get-all-user-types",
  });
  let usertypesArray = usertypes?.data?.data;

  // users
  const { data: users, loading: loadingusers } = useGet({
    url: "admin/get-all-users",
    payload: payload,
  });

  let usersArray = users?.data?.data?.data;

  const { data: branches, loading: loadingbranches } = useGet({
    url: "admin/get-all-branches",
    payload: {
      organization_ids: selectedOrganizations,
    },
  });

  let branchesArray = branches?.data.data;

  // items-statuses
  const { data: itemsStatues, loading: loadingItemsStatuese } = useGet({
    url: "admin/get-all-items-statuses",
  });

  let ItemstatusesArray = itemsStatues?.data?.data;

  const reportTypes = [
    { id: 1, name: "Organizations" },
    { id: 2, name: "Branches" },
    { id: 3, name: "Users" },
    { id: 4, name: "Transactions" },
    { id: 5, name: "Items" },
  ];

  const handleReportTypeChange = (value) => {
    setSelectedReportType(value);
    reset({
      report_id: value, // Preserve current report_id value
      countries_id: [], // Reset to default (empty array)
      organization_id: [], // Reset to default (empty array)
      categories_id: [], // Reset to default (empty array)
      conditions_id: [], // Reset to default (empty array)
      skus_id: [], // Reset to default (empty array)
      statuses_id: [], // Reset to default (empty array)
      branches_id: [], // Reset to default (empty array)
      date: "", // Reset to default (empty string)
      users_id: [], // Reset to default (empty array)
      usertypes_id: [], // Reset to default (empty array)
      startdate_id: "", // Reset to default (empty string)
      enddate_id: "", // Reset to default (empty string)
    });
  };

  const handleDownload = (fileUrl, fileName) => {
    setDownloading(true);
    let alink = document.createElement("a");
    alink.href = fileUrl;
    alink.download = fileName;
    alink.click();
    setDownloading(false);
    closeSwal();
  };

  let swalInstance = null;

  const handleswal = () => {
    let timerInterval;
    let startTime = Date.now();
    let timerDuration = 4000;
    swalInstance = Swal.fire({
      title: "Exporting",

      timerProgressBar: true,
      icon: "info",
      didOpen: () => {
        Swal.showLoading();

        timerInterval = setInterval(() => {
          const timeElapsed = Date.now() - startTime;
          const timeLeft = Math.max(timerDuration - timeElapsed, 0);
          if (timeLeft <= 0) {
            clearInterval(timerInterval);
          }
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });
  };

  const closeSwal = () => {
    if (swalInstance) {
      Swal.close();
    }
  };

  // Example usage

  const handleSubmitForm = async (values) => {
    handleswal();
    setLoading(true);
    const ids = values?.countries_id?.map((item) => item.id);
    const Usertypeids = values?.usertypes_id?.map((usertype) => usertype.id);
    const Branchids = values?.branches_id?.map((Branch) => Branch.id);
    const Organizationsids = values?.organization_id?.map(
      (element) => element.id
    );
    const Statusids = values?.statuses_id?.map((status) => status.id);
    const Usersids = values?.users_id?.map((users) => users.id);
    const Startdate = values?.startdate_id;
    const Enddate = values?.enddate_id;
    const Productskuids = values?.skus_id?.map((skus) => skus.id);
    const Categoryids = values?.categories_id?.map(
      (categories) => categories.id
    );

    const conditionIDS = values?.conditions_id?.map(
      (condition) => condition.id
    );

    let payload = {
      country_ids: ids,
    };

    let branchesPayload = {
      country_ids: ids,
      organization_ids: Organizationsids,
    };

    let usersPayload = {
      organization_ids: Organizationsids,
      country_ids: ids,
      user_type_ids: Usertypeids,
      branch_ids: Branchids,
    };

    let transactionsPayload = {
      start_date: Startdate ? dayjs(Startdate).format("YYYY-MM-DD") : "",
      end_date: Enddate ? dayjs(Enddate).format("YYYY-MM-DD") : "",
      statuses: Statusids,
      organization_ids: Organizationsids,
      country_ids: ids,
      user_ids: Usersids,
      branch_ids: Branchids,
    };

    let itemsPayload = {
      country_ids: ids,
      product_sku_ids: Productskuids,
      category_ids: Categoryids,
      item_status_ids: Statusids,
      condition_ids: conditionIDS,
    };

    try {
      let fileUrl = null;
      let alertText = "";
      let alertType = "";
      switch (values.report_id.id) {
        case 1:
          const organizationsResponse = await organizationsExport(payload);
          fileUrl = organizationsResponse.data.data;
          alertText = organizationsResponse.data.message;
          alertType = organizationsResponse.data.success ? "success" : "error";
          break;
        case 2:
          const branchesResponse = await branchesExport(branchesPayload);
          fileUrl = branchesResponse.data.data;
          alertText = branchesResponse.data.message;
          alertType = branchesResponse.data.success ? "success" : "error";
          break;
        case 3:
          const usersResponse = await usersExport(usersPayload);
          fileUrl = usersResponse.data.data;
          alertText = usersResponse.data.message;
          alertType = usersResponse.data.success ? "success" : "error";
          break;
        case 4:
          const transactionsResponse = await transactionsExport(
            transactionsPayload
          );
          fileUrl = transactionsResponse.data.data;
          alertText = transactionsResponse.data.message;
          alertType = transactionsResponse.data.success ? "success" : "error";
          break;
        case 5:
          const itemsResponse = await itemsExport(itemsPayload);
          fileUrl = itemsResponse.data.data;
          alertText = itemsResponse.data.message;
          alertType = itemsResponse.data.success ? "success" : "error";
          break;
        default:
          console.error("Invalid report type selected");
      }

      // Set alert based on the outcome
      setAlert({
        visible: true,
        text: alertText,
        type: alertType,
      });
      setLoading(false);
      setTimeout(closeSwal);

      // Handle the file download
      if (fileUrl) {
        handleDownload(fileUrl, "report.xlsx");
        reset({
          report_id: null, // Preserve current report_id value
          countries_id: [], // Reset to default (empty array)
          organization_id: [], // Reset to default (empty array)
          categories_id: [], // Reset to default (empty array)
          conditions_id: [], // Reset to default (empty array)
          skus_id: [], // Reset to default (empty array)
          statuses_id: [], // Reset to default (empty array)
          branches_id: [], // Reset to default (empty array)
          date: "", // Reset to default (empty string)
          users_id: [], // Reset to default (empty array)
          usertypes_id: [], // Reset to default (empty array)
          startdate_id: "", // Reset to default (empty string)
          enddate_id: "", // Reset to default (empty string)
        });
        setSelectedReportType(null);
      }
    } catch (error) {
      console.error("Export failed:", error);
    }
  };

  const handleOrganizationsChange = (selected) => {
    // Map selected organizations to their IDs
    const selectedOrganizationsIDS = selected.map((org) => org.id);

    // Update the state with the selected organization IDs
    setSelectedOrganizations(selectedOrganizationsIDS); // Assuming org has an `id` property

    // Retrieve the current branches from the form values
    const currentBranches = getValues("branches_id");

    // Filter branches to include only those that belong to the selected organizations
    const filteredBranches = currentBranches.filter(
      (branch) =>
        selectedOrganizationsIDS.includes(branch.organization.organization_id) // Assuming branch has an `organization_id` property
    );
    // Clear branches if no organizations are selected
    if (selected.length === 0) {
      // Reset branches_id in the form values to an empty array
      setValue("branches_id", []);
    } else {
      // Update the form value for branches_id with the filtered branches
      setValue("branches_id", filteredBranches); // Set filtered branches
    }
  };

  return (
    <Paper elevation={24} className="paper-div">
      <div className="create-report">
        <h1>Create Reports</h1>
        <form onSubmit={handleSubmit(handleSubmitForm)} className="report-form">
          <Controller
            name="report_id"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormDropdownList
                required
                data={reportTypes || []}
                label="Report Type"
                name="report_id"
                placeholder={"Select Report Type"}
                value={value || ""}
                hideVariant
                onChange={(e) => {
                  const selectedValue = e?.target?.value || e;
                  onChange(selectedValue);
                  handleReportTypeChange(selectedValue); // Track selected report type
                }}
                helperText={error?.message}
              />
            )}
          />

          {(selectedReportType?.id === 1 ||
            selectedReportType?.id === 2 ||
            selectedReportType?.id === 3 ||
            selectedReportType?.id === 4 ||
            selectedReportType?.id === 5) && ( // Select Country
            <>
              <Controller
                name="countries_id"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormMultiSelectLoader
                    label="Select Country"
                    name="countries_id"
                    id="countries_id"
                    perpage="10"
                    placeholder="Select Country"
                    data={data || []}
                    loading={loadingCountries}
                    hideVariant
                    value={value || []}
                    onChange={(e) => onChange(e)}
                    accessValue="name"
                    loadMoreUrl="admin/get-all-countries"
                  />
                )}
              />
            </>
          )}
          {selectedReportType?.id === 5 && ( // Selectt Category
            <>
              <Controller
                name="categories_id"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormMultiSelect
                    label="Category"
                    placeholder="Select Category"
                    name="categories_id"
                    id="categories_id"
                    value={value || []}
                    hideVariant
                    arabicPlaceholder=""
                    data={categoriesArray || []}
                    onChange={(e) => onChange(e)}
                    loading={loadingcategories}
                  />
                )}
              />
            </>
          )}
          {selectedReportType?.id === 5 && ( // Select Conditions
            <>
              <Controller
                name="conditions_id"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormMultiSelect
                    label="Condition"
                    placeholder="Select Condition"
                    name="conditions_id"
                    id="conditions_id"
                    value={value || []}
                    hideVariant
                    arabicPlaceholder=""
                    data={conditionsArray || []}
                    onChange={(e) => onChange(e)}
                    loading={loadingconditions}
                  />
                )}
              />
            </>
          )}
          {selectedReportType?.id === 5 && ( // Select Skus
            <>
              <Controller
                name="skus_id"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormMultiSelectLoader
                    label="Sku"
                    name="skus_id"
                    id="skus_id"
                    perpage="10"
                    placeholder="Select Sku"
                    data={skusArray || []}
                    loading={loadingskus}
                    hideVariant
                    value={value || []}
                    onChange={(e) => onChange(e)}
                    accessValue={"sku_code"}
                    loadMoreUrl="admin/get-all-product-skus"
                  />
                )}
              />
            </>
          )}
          {selectedReportType?.id === 4 && ( // Select users
            <>
              <Controller
                name="users_id"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormMultiSelectLoader
                    label="Users"
                    name="users_id"
                    id="users_id"
                    perpage="10"
                    placeholder="Select Users"
                    data={usersArray || []}
                    loading={loadingusers}
                    hideVariant
                    noOptionsMessage="No user Found"
                    value={value || []}
                    onChange={(e) => onChange(e)}
                    accessValue="full_name"
                    loadMoreUrl="admin/get-all-users"
                  />
                )}
              />
            </>
          )}
          {selectedReportType?.id === 4 && ( // Select Start  Date and End date
            <>
              <Controller
                name="startdate_id"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormCalendarInput
                    label="Start Date"
                    required
                    hideVariant
                    value={value}
                    name="startdate_id"
                    onChange={(value) => {
                      onChange(value);
                    }}
                  />
                )}
              />
              <Controller
                name="enddate_id"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormCalendarInput
                    label="End Date"
                    required
                    value={value}
                    hideVariant
                    name="enddate_id"
                    onChange={(value) => {
                      onChange(value);
                    }}
                  />
                )}
              />
            </>
          )}
          {(selectedReportType?.id === 4 || selectedReportType?.id === 5) && ( // Select Status
            <Controller
              name="statuses_id"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormMultiSelect
                  label="Status"
                  placeholder="Select Status"
                  name="statuses_id"
                  id="statuses_id"
                  value={value || []}
                  hideVariant
                  arabicPlaceholder=""
                  data={
                    selectedReportType?.id === 5
                      ? ItemstatusesArray || []
                      : statusesArray || []
                  }
                  onChange={(e) => onChange(e)}
                  loading={
                    selectedReportType?.id === 5
                      ? loadingItemsStatuese
                      : loadingstatuses
                  }
                />
              )}
            />
          )}
          {(selectedReportType?.id === 2 ||
            selectedReportType?.id === 3 ||
            selectedReportType?.id === 4) && (
            <Controller
              name="organization_id"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormMultiSelectLoader
                  label="Organizations"
                  name="organization_id"
                  id="organization_id"
                  perpage="10"
                  placeholder="Select Organizations"
                  data={organizationsArray || []}
                  loading={loadingorganizations}
                  hideVariant
                  value={value || []}
                  onChange={(e) => {
                    handleOrganizationsChange(e); // Call modified function to handle changes
                    onChange(e); // Ensure to call onChange to update react-hook-form state
                    setAreOrganizationsSelected(e.length > 0); // Update state to enable/disable branches select
                  }}
                  accessValue="name"
                  loadMoreUrl="admin/get-all-organization"
                />
              )}
            />
          )}
          {(selectedReportType?.id === 3 || selectedReportType?.id === 4) && (
            <>
              <Controller
                name="branches_id"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormMultiSelect
                    label="Branches"
                    placeholder="Select Branches"
                    name="branches_id"
                    id="branches_id"
                    value={value || []}
                    hideVariant
                    arabicPlaceholder=""
                    data={branchesArray || []}
                    onChange={(e) => onChange(e)}
                    loading={loadingbranches}
                    disabled={!areOrganizationsSelected}
                  />
                )}
              />
            </>
          )}
          {selectedReportType?.id === 3 && ( // Select User types
            <>
              <Controller
                name="usertypes_id"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormMultiSelect
                    label="User types"
                    placeholder="Select User Types"
                    name="usertypes_id"
                    id="usertypes_id"
                    hideVariant
                    value={value || []}
                    arabicPlaceholder=""
                    data={usertypesArray || []}
                    onChange={(e) => onChange(e)}
                    loading={loadingusertypes}
                  />
                )}
              />
            </>
          )}
          <div className="button-group">
            <Button
              name={"Cancel"}
              width={"25%"}
              type="button"
              className="mx-1"
              onClick={() => navigate(-1)}
            />
            <Button
              selected
              name={"Export"}
              width={"25%"}
              type="submit"
              loading={loading}
              disabled={selectedReportType ? false : true}
            />
          </div>
        </form>
      </div>
    </Paper>
  );
};

export default CreateReport;
