// EXTERNAL LIBRARIES
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TableCell } from "@mui/material";

// STYLING
import "./PaymentInformation.scss";

// TABLE
import TableHeader from "../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import Paginator from "../../components/paginator/Paginator";
import PaymentGatewayHandle from "./paymentDetails/PaymentGatewayHandle";
// CUSTOM HOOKS
import { useGet } from "../../hooks/useFetch";

// DATA NOT FOUND
import DataNotFound from "../../components/notFound/DataNotFound";
import withH1 from "../../assests/HOC/withH1";

const PaymentInformation = () => {
  // DATA AND HOOKS
  const [searchParams] = useSearchParams();
  const [paymentDetail, setPaymentDetail] = useState(null);
  const [openUpdate, setOpenUpdate] = useState(false);

  const tableHeaders = [
    { title: "Type of Payment" },
    { title: "Type" },
    { title: "Country" },
    { title: "Status" },
  ];

  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };

  // API CALL (fetching data to be displayed)
  let url = `/admin/get-payment-gateways`;
  const {
    data: countries,
    loading,
    refetch,
  } = useGet({ url: url, payload: payload });
  let data = countries?.data?.data;

  return (
    <>
      <TableHeader placeholder="Search here" filterButton={false} />
      <TableBodyComponent
        loading={loading}
        header={tableHeaders}
        total={data?.total}
      >
        {data?.data?.length ? (
          data?.data?.map((element) => (
            <TableRowComponent
              handleEdit={() => {
                setPaymentDetail(element);
                setOpenUpdate(true);
              }}
              row={element}
              key={element?.id}
            >
              <TableCell>{element?.name?.en}</TableCell>
              <TableCell>{element?.is_buy ? "Buying" : "Selling"}</TableCell>
              <TableCell>
                {element.country_count === 0
                  ? "No countries"
                  : element?.country_count === 1
                  ? element?.country[0]?.name
                  : element.country_count === 2
                  ? element?.country[0]?.name + ", " + element?.country[1]?.name
                  : element?.country[0]?.name +
                    ", " +
                    element?.country[1]?.name +
                    "..."}
              </TableCell>
              <TableCell>
                {element?.is_active ? "Active" : "Inactive"}
              </TableCell>
            </TableRowComponent>
          ))
        ) : (
          <DataNotFound />
        )}
      </TableBodyComponent>
      {data && <Paginator count={data?.last_page} disabled={loading} />}

      {openUpdate && (
        <PaymentGatewayHandle
          data={paymentDetail}
          refetchData={refetch}
          onClose={() => {
            setPaymentDetail(null);
            setOpenUpdate(false);
          }}
        />
      )}
    </>
  );
};
export default withH1(PaymentInformation, "Payment Management");
