import React, { useContext, useState } from "react";

import { Card, CardContent } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/button/Button";

import {
  FormInput,
  UploadComponent,
} from "../../../components/formComponents/FormComponents";

import { getFormData } from "../../../core/functions/Functions";

//custom hooks
import { useAxios, useGet } from "../../../hooks/useFetch";
//styling
import "./ItemDetails.scss";

//validation
import { useFormik } from "formik";
import * as yup from "yup";
import {
  delteItemImages,
  uploadFeaturedItemImage,
  uploadItemImages,
} from "../../../core/apis/item";
import { AlertContext } from "../../../context/AlertContext";
import { api } from "../../../core/apis/main";
import useSWR from "swr";

const itemSchema = yup.object({
  cost: yup.string("Enter your email").required("Email is required"),
});
const isStringArray = (arr) =>
  Array.isArray(arr) && arr.every((item) => typeof item === "string");

const removeBlob = (obj) => {
  let newobj = { ...obj };
  typeof newobj?.featured_image === "string" && delete newobj?.featured_image;
  isStringArray(newobj?.images) && delete newobj?.images;
  return newobj;
};
const ItemDetails = (props) => {
  const { create } = props;

  let navigate = useNavigate();
  const params = useParams();

  //get item by id
  const {
    data: item,
    loading,
    refetch,
  } = useGet({
    url: `admin/get-item-by-id/${params.id}`,
    start: !create,
  });

  let items = item?.data?.data?.item_details || {};

  //get item identities for a specific item
  const { data: itemIdentities } = useGet({
    url: `admin/get-item-item-identities/${params.id}`,
    start: !create,
  });

  //formik function on submitting the form
  const formik = useFormik({
    initialValues: {
      description: items?.description?.en || "",
      qr_link: items?.qr_link,
      cost: items?.item_cost || "",
      item_identities: items?.identity?.map((el) => ({
        item_identity_id: el?.id,
        value: el?.pivot?.value,
      })),
    },
    enableReinitialize: true,

    validationSchema: itemSchema,
    onSubmit: () => {
      updateItem();
      refetch(); //get item by id
      data?.data?.success && navigate(-1);
    },
  });

  //API for updating items and item images
  const {
    data,
    loading: loadingUpdate,
    callAPI: updateItem,
  } = useAxios(
    `admin/${create ? "create" : "update"}-item${
      create ? "" : `/${params.id}`
    }`,
    "put",
    removeBlob(formik?.values)
  );

  //handle item identites values
  const handleIdentities = (identity) => (e) => {
    formik.setFieldValue("item_identities", [
      ...formik?.values?.item_identities?.filter(
        (el) => el?.item_identity_id !== identity?.id
      ),
      { item_identity_id: identity?.id, value: e.target.value },
    ]);
  };

  return !create && loading ? (
    "loading..."
  ) : (
    <>
      <h1>{create ? "New Item" : items?.item_code}</h1>
      <form className="BrandDetails" onSubmit={formik.handleSubmit}>
        <Card className="form-section">
          <CardContent>
            <FormInput
              label="Item Cost"
              placeholder="Enter Cost"
              name="cost"
              id="cost"
              value={formik.values.cost}
              onChange={formik.handleChange}
              error={formik.touched.cost && Boolean(formik.errors.cost)}
              helperText={formik.touched.cost && formik.errors.cost}
            />
            <FormInput
              label="QR Link"
              placeholder="Enter QR Link"
              name="qr_link"
              id="qr_link"
              value={formik.values.qr_link}
              onChange={formik.handleChange}
              error={formik.touched.qr_link && Boolean(formik.errors.qr_link)}
              helperText={formik.touched.qr_link && formik.errors.qr_link}
            />
            <FormInput
              label="Description"
              placeholder="Enter Description"
              name="description"
              id="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
            {itemIdentities?.data?.data?.map((identity) => (
              <FormInput
                key={identity?.id}
                label={identity?.name?.en}
                name={identity?.name?.en}
                id={identity?.id}
                placeholder={`Enter ${identity?.name?.en}`}
                value={
                  formik?.values?.item_identities?.find(
                    (el) => el?.item_identity_id === identity?.id
                  )?.value
                }
                onChange={handleIdentities(identity)}
                error={
                  formik.touched.identity?.name &&
                  Boolean(formik?.errors?.identity?.name)
                }
                helperText={
                  formik.touched.identity?.name && formik.errors.identity?.name
                }
              />
            ))}
            <div className="action-footer">
              <Button
                name={create ? "Create" : "Save"}
                selected
                loading={loadingUpdate}
                disabled={!formik.dirty || (!create && loading)}
                type="submit"
              />
            </div>
          </CardContent>
        </Card>
        <UploadItemImages />
      </form>
    </>
  );
};

export default ItemDetails;
const UploadItemImages = (props) => {
  const { create } = props;

  const { setAlert } = useContext(AlertContext);
  const params = useParams();
  const [loadingFeatured, setLoadingFeatured] = useState(false);
  const [loadingUpdateImages, setLoadingUpdateImages] = useState(false);

  //get item identities for a specific item
  const {
    data: images,
    isLoading: imagesLoading,
    mutate,
    isValidating,
  } = useSWR(`admin/get-item-images/${params.id}`, (url) =>
    api.get(url).then((res) => res)
  );

  //formik function on submitting the form
  const formik = useFormik({
    initialValues: {
      featured_image: images?.data?.data?.find((i) => i.is_featured)
        ?.image_path,
      images: images?.data?.data
        ?.filter((i) => !i.is_featured)
        ?.map((i) => i.image_path),
    },
    enableReinitialize: true,

    onSubmit: () => {
      setLoadingUpdateImages(true);

      uploadItemImages(
        getFormData(
          removeBlob({
            item_id: params?.id,
            images: formik?.values?.images?.filter(
              (i) => typeof i !== "string"
            ),
          })
        )
      ).then((res) => {
        setLoadingUpdateImages(false);

        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });

        res.data.success && mutate();
      });
    },
  });

  const onUploadFeaturedImage = (acceptedImage) => {
    setLoadingFeatured(true);
    formik.setFieldValue("featured_image", acceptedImage[0]);

    uploadFeaturedItemImage(
      getFormData({
        item_id: params.id,
        featured_image: acceptedImage[0],
      })
    ).then((res) => {
      setAlert({
        visible: true,
        text: res.data.message,
        type: res.data.success ? "success" : "error",
      });
      res.data.success && mutate();

      setLoadingFeatured(false);
    });
  };
  //delete selected images
  const handleDeleteImage = (image) => (e) => {
    e.stopPropagation();
    let payload = {
      images_id: [images?.data?.data?.find((i) => i.image_path === image)?.id],
    };

    delteItemImages(params.id, payload).then((res) => {
      setAlert({
        visible: true,
        text: res.data.message,
        type: res.data.success ? "success" : "error",
      });

      res.data.success && mutate();
    });
  };

  return (
    !imagesLoading && (
      <div className="upload-section">
        <h3>Featured Image</h3>
        <UploadComponent
          value={formik?.values?.featured_image}
          setFieldValue={formik.setFieldValue}
          name="featured_image"
          loading={loadingFeatured}
          onDrop={onUploadFeaturedImage}
        />
        <h3>Images</h3>
        <UploadComponent
          loading={loadingUpdateImages}
          value={formik?.values?.images}
          setFieldValue={formik.setFieldValue}
          name="images"
          multiple
          handleDelete={handleDeleteImage}
        />
        <Button
          selected
          onClick={formik.handleSubmit}
          name="Upload"
          disabled={
            !formik.dirty || loadingFeatured || imagesLoading || isValidating
          }
        />
      </div>
    )
  );
};
