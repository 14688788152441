import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/button/Button";
import * as AuthApi from "../../../core/apis/auth";
import { AlertContext } from "../../../context/AlertContext";
import { useFormik } from "formik";
import { Input } from "../../../components/input/Input";
import * as yup from "yup";
import "./ResetPassword.scss";

const codeSchema = yup.object({
  otp: yup.string("Enter your code").required("Code is required"),
});

const OTP = () => {
  let navigate = useNavigate();
  let location = useLocation()?.state;
  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: codeSchema,
    onSubmit: (values) => {
      const data = {
        otp: values.otp,
        email: location?.email,
        user_type_id: 1,
      };
      setLoading(true);
      AuthApi.checkOTP(data).then((res) => {
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
        res.data.success &&
          setTimeout(
            () => navigate(`/new-password/?token=${values.otp}`),
            1000
          );
      });
      setLoading(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="forgot-password">
      <h2>Verify OTP Code</h2>
      <Input
        className="curvedInput"
        type="text"
        placeholder="Enter Code"
        name="otp"
        id="otp"
        value={formik.values.otp}
        onChange={formik.handleChange}
        error={formik.touched.otp && Boolean(formik.errors.otp)}
        helperText={formik.touched.otp && formik.errors.otp}
      />
      <Button
        name="Continue"
        width="100%"
        selected
        loading={loading}
        disabled={!formik.dirty || loading}
        type="submit"
      />
    </form>
  );
};

export default OTP;
