import Skeleton from "@mui/material/Skeleton";
import React, { useState, useContext, useEffect } from "react";
import { AlertContext } from "../../../context/AlertContext";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  FormInput,
  FormTextArea,
  FormMultiSelect,
} from "../../../components/formComponents/FormComponents";
import "./FAQs.js";
import { Switch, FormControlLabel } from "@mui/material";
import Button from "../../../components/button/Button";
import { useGlobalValues } from "../../../context/GlobalContext";
import {
  createQuestion,
  getQuestionsByID,
  updateQuestion,
} from "../../../core/apis/store";
import { useGet } from "../../../hooks/useFetch.js";

const AddQuestions = (props) => {
  const { onCancel, onSave, ID } = props;
  const [loading, setLoading] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();

  const defaultValues = {
    title: "",
    description: "",
    categories_id: [], // Ensure this is an array by default
    active: false,
  };

  // Categories
  const { data: categories, loading: loadingcategories } = useGet({
    url: "admin/faq/categories",
  });

  let categoriesArray = categories?.data?.data?.map((item, index) => ({
    ...item,
    id: index + 1, // You can customize the id generation logic as needed
  }));

  const createQuestionSchema = yup.object({
    title: yup.string().nullable().required("Field is required"),
    description: yup.string().nullable().required("Field is required"),
    categories_id: yup.array(""),
    active: yup.bool(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(createQuestionSchema),
  });

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmitForm = async (values) => {
    setLoading(true);
    let names = values.categories_id.map((element) =>
      typeof element?.category_name === "object"
        ? element?.category_name?.[storeLanguage]
        : element?.category_name
    );

    let payload = {
      question: values.title,
      answer: values.description,
      categories_name: names,
      language: storeLanguage,
    };

    // Add `is_active` to the payload if `ID` is present
    if (ID) {
      payload.is_active = values.active ? 1 : 0;

      updateQuestion(ID, payload).then((res) => {
        onSave();
        setAlert({
          visible: true,
          text: res?.data.success ? "Successfully Updated" : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
        setLoading(false);
      });
    } else {
      createQuestion(payload).then((res) => {
        onSave();
        setAlert({
          visible: true,
          text: res?.data.success ? "Successfully Created" : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
        setLoading(false);
      });
    }
  };

  // Fetch data and populate form if ID is provided
  useEffect(() => {
    if (ID) {
      setIsFetchingData(true); // Start fetching data
      getQuestionsByID(ID).then((res) => {
        if (res?.data?.success) {
          const questionData = res?.data?.data;
          // Create a new array with the updated categories
          const updatedCategories = questionData?.categories?.map((item1) => {
            const match = categoriesArray?.find(
              (item2) =>
                item2.category_name?.[storeLanguage] ===
                item1.category_name?.[storeLanguage]
            );
            if (match) {
              return { ...item1, id: match.id }; // Return a new object with the matched ID
            }
            return item1; // If no match, return the original item
          });

          const populatedFormData = {
            title: questionData?.question?.[storeLanguage],
            description: questionData?.answer?.[storeLanguage],
            categories_id: updatedCategories,
            active: questionData?.is_active,
          };

          setValue("title", populatedFormData.title);
          setValue("description", populatedFormData.description);
          setValue("categories_id", populatedFormData.categories_id);
          setValue("active", populatedFormData.active);
        }
        setIsFetchingData(false); // Stop fetching data
      });
    } else {
      setIsFetchingData(false); // If there's no ID, stop loading immediately
    }
  }, [ID, storeLanguage, setValue, categories]);

  return (
    <div className="addQuestionsFAQS">
      <div className="store-title">
        {ID ? "Edit Question" : "New Question"}
        {isFetchingData ? (
          <div className="flexcontainer">
            {/* Skeleton Loaders */}
            <div className="form-section">
              {/* Title Skeleton */}
              <Skeleton
                variant="rectangular"
                width="100%"
                height={56}
                style={{ marginBottom: "16px" }}
              />

              {/* Description Skeleton */}
              <Skeleton
                variant="rectangular"
                width="100%"
                height={120}
                style={{ marginBottom: "16px" }}
              />
              {/* Categories Skeleton */}
              <Skeleton
                variant="rectangular"
                width="100%"
                height={120}
                style={{ marginBottom: "16px" }}
              />

              {/* Active Switch Skeleton */}
              <Skeleton
                variant="rectangular"
                width={100}
                height={40}
                style={{ marginBottom: "16px" }}
              />
            </div>

            {/* Button Skeleton */}
            <div className="action-footer">
              <Skeleton
                variant="rectangular"
                width={120}
                height={40}
                style={{ marginRight: "16px" }}
              />
              <Skeleton variant="rectangular" width={120} height={40} />
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="flexcontainer">
              <div className="form-section">
                {/* Title Field */}
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      required
                      name="title"
                      label="Question"
                      hideVariant
                      placeholder={"Enter Question"}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      helperText={error?.message}
                    />
                  )}
                  name="title"
                  control={control}
                />
                {/* Description Field */}
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormTextArea
                      required
                      name="description"
                      label="Answer"
                      className="labelclassname"
                      placeholder={"Enter Answer"}
                      value={value}
                      hideVariant
                      onChange={(e) => onChange(e.target.value)}
                      helperText={error?.message}
                    />
                  )}
                  name="description"
                  control={control}
                />

                <Controller
                  name="categories_id"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <FormMultiSelect
                        label="Category"
                        placeholder="Select Category"
                        name="categories_id"
                        id="categories_id"
                        value={value || []}
                        data={categoriesArray || []}
                        hideVariant
                        isfreeSolo
                        accessValue="category_name"
                        arabicPlaceholder=""
                        onChange={(e) => onChange(e)}
                      />
                    );
                  }}
                />
                {/* Active Switch */}
                {ID ? (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormControlLabel
                        label="Active"
                        control={<Switch checked={value} />}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                      />
                    )}
                    name="active"
                    control={control}
                  />
                ) : null}

                <div className="action-footer">
                  <Button
                    selected
                    //onSave={handleSave}
                    name={"Save"}
                    type="submit"
                    loading={loading}
                    className="mx-1"
                  />
                  <Button
                    onClick={() => handleCancel()}
                    name={"Cancel"}
                    type="button"
                    className="mx-1"
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddQuestions;
