export const defaultLat = 33.8547;
export const defaultLong = 35.8623;

export const blockStatues = [
  {
    id: 1,
    value: "active",
    name: "published",
  },
  {
    id: 2,
    value: "inactive",
    name: "un-published",
  },
];

export const Statues = [
  {
    id: 1,
    value: "active",
    name: "Active",
  },
  {
    id: 2,
    value: "inactive",
    name: "In-active",
  },
];

export const LiveStatues = [
  {
    id: 1,
    value: true,
    name: "Live",
  },
  {
    id: 2,
    value: false,
    name: "Not Live",
  },
];
