// EXTERNAL LIBRARIES
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { Controller, useForm } from "react-hook-form";
// STYLING
import "./RibbonHandle.scss";

// CUSTOM HOOKS
import { AlertContext } from "../../../context/AlertContext";

// FORM
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
//COMPONENTS
import { IconButton, Dialog, DialogContent, DialogTitle } from "@mui/material";
import {
  FormInput,
  FormSwitch,
} from "../../../components/formComponents/FormComponents";
import Button from "../../../components/button/Button";
import { CloseSVG } from "../../../assests/icons/SVG";
import { useGlobalValues } from "../../../context/GlobalContext";

//API
import { createRibbon, updateRibbon } from "../../../core/apis/ribbon";
import { translateByGoogleApi } from "../../../core/apis/newlanguage";

const HandleSchema = yup.object({
  description: yup.string().nullable().required("Field is required").max(30),
  is_active: yup.boolean(),
});

const RibbonDetails = (props) => {
  let navigate = useNavigate();
  const { refetch, onClose, element, languageValue } = props;
  const { setAlert } = useContext(AlertContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const [hardAction, setHardAction] = useState(false);
  const [message, setMessage] = useState("");
  const { enabledLanguages } = useGlobalValues();

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(HandleSchema),
  });

  const handleSubmitForm = (formData) => {
    callAPI(formData);
  };

  const callAPI = (formData) => {
    setSaveLoading(true);

    if (!element) {
      createRibbon({ ...formData, hard_action: hardAction }).then((res) => {
        if (res?.data?.data?.hard_action) {
          //message do you want to proceed if clicked
          setHardAction(true);
          setMessage(res.data?.message + ". Do you want to proceed ?");
        } else {
          setMessage("");
          setAlert({
            visible: true,
            text: res.data ? res.data.message : res.message,
            type: res.data?.success ? "success" : "error",
          });
          if (res.data?.success) {
            enabledLanguages.map((item) => {
              if (item.code != "en") {
                handleTranslateByGoogle(res.data.data, item.code);
              }
            });
            navigate("/ribbons");
            onClose();
            refetch();
          }
        }

        setSaveLoading(false);
      });
    } else {
      updateRibbon({
        ribbon_id: element?.id,
        ...formData,
        language: languageValue,
        hard_action: hardAction,
      }).then((res) => {
        if (res?.data?.data?.hard_action) {
          //message do you want to proceed if clicked
          setHardAction(true);
          setMessage(res.data?.message + ". Do you want to proceed ?");
        } else {
          setMessage("");
          //to not show the error message twice
          setAlert({
            visible: true,
            text: res.data ? res.data?.message : res?.message,
            type: res.data?.success ? "success" : "error",
          });
          if (res.data?.success) {
            navigate("/ribbons");
            onClose();
            refetch();
          }
        }

        setSaveLoading(false);
      });
    }
  };

  const handleTranslateByGoogle = async (values, targetLanguage) => {
    let arrayofdata = [values.description.en];
    await translateByGoogleApi(arrayofdata, targetLanguage).then((res) => {
      let payload = {
        ribbon_id: values.id,
        description: res[0]?.translatedText,
        is_active: values.is_active,
        hard_action: values.is_active,
        language: targetLanguage,
      };
      updateRibbon(payload).then((res) => {
        if (res?.data?.data?.hard_action) {
          //message do you want to proceed if clicked
          setHardAction(true);
          setMessage(res.data?.message + ". Do you want to proceed ?");
        } else {
          setMessage("");
          //to not show the error message twice
          setAlert({
            visible: true,
            text: res.data ? res.data?.message : res?.message,
            type: res.data?.success ? "success" : "error",
          });
          if (res.data?.success) {
            navigate("/ribbons");
            onClose();
            refetch();
          }
        }

        setSaveLoading(false);
      });
    });
  };

  useEffect(() => {
    reset({
      language: languageValue,
      description: element ? element?.description?.[languageValue] : "",
      is_active: element ? element?.is_active : false,
    });
  }, [element]);

  return (
    <Dialog disablebackdropclick="true" fullWidth open={true}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {`${element === null ? "Add" : "Update"} Ribbon`}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseSVG />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="ribbonDetails">
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="description-status">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormInput
                    type="string"
                    placeholder="Enter ribbon description"
                    name="description"
                    value={value}
                    helperText={error?.message}
                    onChange={(value) => {
                      onChange(value);
                    }}
                  />
                )}
                name={`description`}
                control={control}
              />
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormSwitch
                    label="Active"
                    name="is_active"
                    value={value}
                    onChange={(e) => onChange(e?.target.checked)}
                  />
                )}
                name="is_active"
                control={control}
              />
            </div>
            {message != "" && <div className="message"> {message}</div>}
            <div className="button-action">
              <Button
                name={
                  !element
                    ? hardAction
                      ? "Yes"
                      : "Create"
                    : (hardAction ? "Yes" : "Update") || ""
                }
                selected
                loading={saveLoading}
                type="submit"
              />
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RibbonDetails;
