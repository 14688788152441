//HERE ARE THE COMPONENT THAT CAN BE ADDED TO A FORM AS COMPONENT (REUSABLE)

//UTILITIES
import React, { useEffect, useState, useCallback } from "react";
import palette from "../../theme/color.scss"; //import of colors to be added if needed
import PropTypes from "prop-types";
import moment from "moment";
//COMPONENT (MUI)
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { FileUploader } from "react-drag-drop-files";
import { Card, IconButton, LinearProgress } from "@mui/material";
import {
  Avatar,
  TextField,
  Checkbox,
  FormGroup,
  Switch,
  Box,
  createFilterOptions,
} from "@mui/material";
import { CircularProgress, List, ListItem, ListItemText } from "@mui/material";
import { RadioGroup, Radio, Autocomplete, Button } from "@mui/material";
import { Chip } from "@mui/material";
import { ListItemAvatar, FormControl, FormHelperText } from "@mui/material";
import { InputLabel, FormControlLabel, InputAdornment } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
//ICONS
import * as svg from "../../assests/icons/SVG";
import { default as ReusableButton } from "../button/Button";
//CSS
import "./FormComponent.scss";
import { REACT_APP_IMAGE_URL } from "../../core/apis/main";
import { useDropzone } from "react-dropzone";
import { RemoveCircle, RemoveCircleOutline } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { api } from "../../core/apis/main";
import debounce from "lodash/debounce";
import ClearIcon from "@mui/icons-material/Clear";

//INPUT (USED FOR ALL INPUTS : EMAIL , INPUT , NUMBER)
//TYPE :TEXT OR NUMBER

export const FormInput = (props) => {
  const {
    helperText,
    disabled,
    label,
    placeholder,
    className,
    mainClassName,
    arabicLabel,
    arabicPlaceholder,
    isArabic,
  } = props;
  const { value, onChange, required, type, name, error, variant, hideVariant } =
    props;

  return (
    <div
      className={
        mainClassName
          ? "form-input-wrapper" + " " + mainClassName
          : "form-input-wrapper"
      }
      style={{ width: "100%" }}
    >
      {label && (
        <InputLabel className="mb-2">
          {isArabic ? arabicLabel : label}
          <span className="required-start">{required ? " * " : ""}</span>
        </InputLabel>
      )}
      <TextField
        fullWidth
        size="small"
        variant={hideVariant ? undefined : variant} // Apply variant only if hideVariant is false
        type={type}
        className={className}
        value={value}
        placeholder={isArabic ? arabicPlaceholder : placeholder}
        helperText={helperText}
        disabled={disabled}
        onChange={onChange}
        name={name}
        inputProps={{
          autoComplete: "new-password",
          form: {
            autoComplete: "off",
          },
        }}
      />
    </div>
  );
};
FormInput.defaultProps = {
  variant: "standard",
  hideVariant: false,
};
FormInput.propTypes = {
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
};
//INPUT FOR PASSWORD
export const FormPassword = (props) => {
  const { label, placeholder, value, onChange, required, helperText } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="form-input-wrapper">
      <InputLabel>
        {label}
        <span className="required-start">{required ? "*" : ""}</span>
      </InputLabel>
      <TextField
        fullWidth
        size="small"
        placeholder={placeholder}
        variant="standard"
        type={showPassword ? "text" : "password"}
        onChange={onChange}
        value={value}
        helperText={helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position={"end"}
              sx={{
                backgroundColor: palette.whiteColor,
              }}
            >
              <IconButton onClick={handleClickShowPassword}>
                {showPassword ? <svg.BlockEyeSVG /> : <svg.EyeSVG />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

//DROPDOWN LIST
export const FormDropdownList = (props) => {
  const {
    label,
    name,
    placeholder,
    type,
    variant,
    hideVariant,
    disabled,
    required,
    value,
    dependencyError,
    formComponent,
    mainClassName,
    isArabic,
    arabicPlaceholder,
    arabicLabel,
  } = props;
  const {
    data,
    noOptionsMessage,
    loading,
    onChange,
    helperText,
    clearErrors,
    accessValue,
  } = props;

  const [val, setVal] = useState(null);

  useEffect(() => {
    if (value) {
      setVal(value);
      if (clearErrors) {
        dependencyError ? clearErrors(dependencyError) : clearErrors(name);
      }
    }
  }, [value]);
  //"non-form-wrapper"
  return (
    <div
      className={
        formComponent && mainClassName
          ? "form-input-wrapper" + " " + mainClassName
          : formComponent && !mainClassName
          ? "form-input-wrapper"
          : "non-form-wrapper"
      }
    >
      {label && (
        <InputLabel className="mb-2">
          {isArabic ? arabicLabel : label}
          <span className="required-start">{required && " * "}</span>
        </InputLabel>
      )}

      <Autocomplete
        size="small"
        disabled={disabled}
        fullWidth
        disableClearable={required}
        ListboxProps={{ style: { maxHeight: 200, overflow: "auto" } }}
        getOptionLabel={(option) =>
          option?.[accessValue]
            ? typeof option?.[accessValue] === "object"
              ? option?.[accessValue]["en"]
              : option?.[accessValue]
            : "Error displaying options"
        }
        options={data}
        value={value || null}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        loadingText={"Loading"}
        noOptionsText={noOptionsMessage ? noOptionsMessage : "No Options"}
        loading={loading}
        onChange={(event, selected) => {
          onChange(selected);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={hideVariant ? undefined : variant}
            placeholder={isArabic ? arabicPlaceholder : placeholder}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

FormDropdownList.defaultProps = {
  accessValue: "name",
  variant: "standard",
  hideVariant: false,
  formComponent: true,
};

FormDropdownList.propTypes = {
  accessValue: PropTypes.string,
  variant: PropTypes.string,
  formComponent: PropTypes.bool,
};

//Dropdown Admin Portal
export const FormDropdown = (props) => {
  const { loading, disabled, value, onChange, multiple, name, id } = props;
  const { options, placeholder, label, renderOption } = props;
  const { error, helperText, getOptionDisabled, open, onClose, onOpen } = props;
  return (
    <Box>
      {label && <InputLabel>{label}</InputLabel>}
      <Autocomplete
        onOpen={onOpen}
        onClose={onClose}
        open={open}
        id={id}
        name={name}
        sx={{ width: "100%" }}
        autoHighlight
        loading={loading}
        disabled={disabled}
        value={value}
        onChange={onChange}
        options={options}
        multiple={multiple}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={(option) => {
          return (
            option[renderOption]?.en || option?.[renderOption] || option?.label
          );
        }}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
            name="select"
          >
            {option[renderOption]?.en ||
              option?.[renderOption] ||
              option?.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            error={error}
            helperText={helperText}
            name={name}
            variant="standard"
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14, minWidth: 90 },
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
              style: { fontSize: 14 },
            }}
          />
        )}
      />
    </Box>
  );
};
FormDropdown.defaultProps = {
  label: "Please Enter a Label",
  placeholder: "Choose an Option",
};
FormDropdown.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
};
export const FormCheckboxDropdown = (props) => {
  const {
    loading,
    disabled,
    value,
    onChange,
    multiple,
    name,
    id,
    isArabic,
    arabicLabel,
  } = props;
  const { options, placeholder, label, renderOption, limitTags, hideVariant } =
    props;
  const { error, helperText, getOptionDisabled, data, required } = props;
  const filter = createFilterOptions();
  const allSelected = options?.length === value?.length;
  return (
    <Box>
      {label && (
        <InputLabel className="mb-2">
          {isArabic ? arabicLabel : label}
          <span className="required-start">{required ? " * " : ""}</span>
        </InputLabel>
      )}

      <Autocomplete
        size="small"
        disableCloseOnSelect
        sx={{ width: "100%" }}
        className="direction-left"
        limitTags={limitTags || 2}
        id={id}
        name={name}
        autoHighlight
        loading={loading}
        disabled={disabled}
        value={value}
        onChange={onChange}
        options={options}
        multiple={multiple}
        getOptionDisabled={getOptionDisabled}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return [{ name: "Select All", value: "select-all" }, ...filtered];
        }}
        getOptionLabel={(option) =>
          option[renderOption]?.en || option?.[renderOption] || option?.label
        }
        renderOption={(props, option, { selected }) => {
          const selectAllProps =
            option.value === "select-all" // To control the state of 'select-all' checkbox
              ? { checked: allSelected }
              : {};

          return (
            <li {...props}>
              <Checkbox
                checked={selected}
                {...selectAllProps}
                indeterminate={
                  option.value === "select-all" && value?.length
                    ? options?.length > value?.length
                    : null
                }
              />
              {option?.country_code && (
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option?.country_code?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option?.country_code?.toLowerCase()}.png 2x`}
                  alt="flag"
                />
              )}
              {option.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            error={error}
            helperText={helperText}
            name={name}
            variant={hideVariant ? undefined : "standard"}
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              style: {
                fontSize: 14,
                minWidth: 90,
                maxHeight: 40,
                overflow: "hidden",
              },
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
              style: { fontSize: 14 },
            }}
          />
        )}
      />
    </Box>
  );
};
FormCheckboxDropdown.defaultProps = {
  label: "Please Enter a Label",
  placeholder: "Choose an Option",
  renderOption: "name",
  fixedOptions: [],
};
FormCheckboxDropdown.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any.isRequired,
  fixedOptions: PropTypes.array,
};

export const UploadComponent = (props) => {
  const { setFieldValue, value, helperText, multiple, name } = props;
  const {
    handleDelete,
    loading,
    handleAction,
    onDrop,
    isArabic,
    arabicLabel,
    isArabicicon,
    arabicicon,
  } = props;
  const { getRootProps, getInputProps } = useDropzone({
    disabled: loading,
    accept: "image/*",
    multiple: multiple || false,
    onDrop: onDrop
      ? onDrop
      : (acceptedFiles) => {
          setFieldValue(
            name || "image",
            multiple ? [...value, ...acceptedFiles] : acceptedFiles[0]
          );
        },
    onDropAccepted: async () => {
      await handleAction();
    },
  });
  const isArrayOfImages =
    (value instanceof Array ? value : [value])?.length > 1;
  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input disabled={loading} {...getInputProps()} />
      {isArabic
        ? arabicLabel
          ? arabicLabel
          : "Drag and drop some files here, or click to select files"
        : "Drag and drop some files here, or click to select files"}
      {Object?.values(value || {})?.length ? (
        <>
          <div
            className={`imagesContainer ${
              isArrayOfImages ? "images" : "image"
            } ${loading ? "loading" : ""}`}
          >
            {(value instanceof Array ? value : [value]).map((file, index) => (
              <div className="imageContainer">
                {isArrayOfImages ? (
                  <IconButton
                    className="deleteIcon"
                    onClick={handleDelete(file)}
                  >
                    <RemoveCircle color="error" />
                  </IconButton>
                ) : null}
                <img
                  key={file?.path || index}
                  style={{ objectFit: "contain" }}
                  alt="the uploaded file"
                  width="100px"
                  height="100px"
                  src={
                    file instanceof File
                      ? URL.createObjectURL(file)
                      : REACT_APP_IMAGE_URL + file
                  }
                />
              </div>
            ))}
          </div>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setFieldValue(name || "image", null);
            }}
            variant="text"
            startIcon={<svg.DeleteSVG />}
            disabled={loading}
          >
            <span style={{ marginRight: "8px" }}>
              {isArabic ? (arabicicon ? arabicicon : "Remove") : "Remove"}
            </span>
          </Button>
        </>
      ) : null}
      {helperText !== "" && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  );
};

UploadComponent.propTypes = {
  handleDelete: PropTypes.func,
  value: PropTypes.any.isRequired,
  multiple: PropTypes.bool,
};
UploadComponent.defaultProps = {
  handleDelete: () => {},
  handleAction: () => {},
};
//SWITCH
export const FormSwitch = (props) => {
  const { value, label, onChange, name, disabled } = props;
  return (
    <div className="form-input-wrapper">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          className="form-switch-label"
          control={
            <Switch
              name={name}
              checked={value}
              color="primary"
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          }
          label={<b style={{ color: palette.primaryColor }}>{label}</b>}
          labelPlacement="start"
        />
      </FormGroup>
    </div>
  );
};

//TEXTAREA
export const FormTextArea = (props) => {
  //TRANSLATION
  const { rows, label, value, required } = props;
  const {
    onChange,
    helperText,
    placeholder,
    disabled,
    mainClassName,
    labelclassname,
  } = props;

  return (
    <div
      className={
        mainClassName
          ? "form-input-wrapper" + " " + mainClassName
          : "form-input-wrapper"
      }
    >
      {label && (
        <InputLabel className={`${labelclassname ? labelclassname : ""} mb-2`}>
          {label}
          <span className="required-start">{required ? "*" : ""}</span>
        </InputLabel>
      )}
      <TextField
        fullWidth
        multiline
        rows={rows || 4}
        size="small"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        helperText={helperText}
        disabled={disabled}
      />
    </div>
  );
};

//CALENDAR
export const FormCalendarInput = (props) => {
  const { required, label, onChange, minDate } = props;
  const { helperText, disabledAfter, value, hideVariant } = props;

  //IF HE WANTS TO DISABLED SOME DATES
  const disabledDate = (date) => {
    if (disabledAfter) {
      if (date > new Date()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <div className="form-input-wrapper">
      {label && (
        <InputLabel className="mb-2">
          {label}
          <span className="required-start">{required ? "*" : ""}</span>
        </InputLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          className="date-picker-style"
          fullWidth
          minDate={minDate ? minDate : null}
          inputFormat="dd-MM-yyyy"
          value={value}
          shouldDisableDate={disabledDate}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              size="small"
              variant={hideVariant ? undefined : "standard"}
              fullWidth
              {...params}
              error={false}
              helperText={helperText}
            />
          )}
          PaperProps={{
            sx: {
              "& .MuiPickersDay-root": {
                "&.Mui-selected": {
                  color: palette.whiteColor,
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

//FORM TIME PICKER
export const FormTimePicker = (props) => {
  const {
    required,
    label,
    value,
    onChange,
    clearErrors,
    helperText,
    disabled,
    name,
    minTime,
  } = props;

  const selected_value = value ? new Date(`1995-12-17T${value}`) : null;

  const handleOnChange = (new_value) => {
    onChange(new_value !== null ? moment(new_value).format("HH:mm") : null);
    clearErrors(name);
  };

  return (
    <div className="form-input-wrapper">
      <InputLabel>
        {label}
        <span className="required-start">{required ? "*" : ""}</span>
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          name={name}
          disabled={disabled}
          className="date-picker-style"
          fullWidth
          minTime={minTime}
          ampm={true}
          inputFormat="HH:mm"
          value={selected_value}
          onChange={(e) => handleOnChange(e)}
          renderInput={(params) => (
            <TextField
              size="small"
              fullWidth
              {...params}
              error={false}
              helperText={helperText}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

export const FormMultiSelect = (props) => {
  const {
    label,
    data,
    disabled,
    required,
    onChange,
    value,
    fixedOptions,
    limitTags,
    variant,
    name,
    accessValue,
    isArabic,
    arabicLabel,
    arabicPlaceholder,
    hideVariant,
    mainClassName,
    linkTitle,
    handleAddLink,
    msg,
    isfreeSolo,
  } = props;
  const {
    helperText,
    loading,
    placeholder,
    noOptionsMessage,
    clearErrors,
    dependencyError,
  } = props;

  const [val, setVal] = useState(fixedOptions ? [...fixedOptions] : []);

  //  const filter = createFilterOptions();
  // const allSelected = data?.length === value?.length;

  useEffect(() => {
    if (value) {
      setVal(value);
      if (clearErrors) {
        dependencyError ? clearErrors(dependencyError) : clearErrors(name);
      }
    }
  }, [value]);

  return (
    <div
      className={
        mainClassName
          ? "form-input-wrapper" + " " + mainClassName
          : "form-input-wrapper"
      }
    >
      <InputLabel className="mb-2">
        {isArabic ? arabicLabel : label}{" "}
        <span className="required-start">{required ? "*" : ""}</span>
        <span className="required-start">
          {msg && (
            <Tooltip title={msg} placement="right">
              <InfoIcon style={{ color: "#ff0000" }} />
            </Tooltip>
          )}
        </span>
      </InputLabel>

      <Autocomplete
        disableCloseOnSelect
        multiple
        freeSolo={isfreeSolo}
        size="small"
        limitTags={limitTags}
        fullWidth
        className={isArabic ? "direction-left" : ""}
        options={data}
        disabled={disabled}
        ListboxProps={{ style: { maxHeight: 200, overflow: "auto" } }}
        getOptionLabel={(option) =>
          typeof option?.[accessValue] === "object"
            ? option?.[accessValue]["en"]
            : option?.[accessValue]
        }
        loading={loading}
        noOptionsText={noOptionsMessage ? noOptionsMessage : "No Options"}
        loadingText={"Loading"}
        value={val}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(event, newValue) => {
          if (newValue.some((option) => option.value === "select-all")) {
            onChange(data);
          } else if (isfreeSolo) {
            // If freeSolo is enabled, add new values
            const updatedValue = [
              ...new Map(
                [
                  ...fixedOptions,
                  ...newValue.map((val) =>
                    typeof val === "string"
                      ? { id: val, [accessValue]: val }
                      : val
                  ),
                ].map((item) => [item["id"], item])
              ).values(),
            ];
            onChange(updatedValue);
          } else {
            onChange([
              ...new Map(
                [...fixedOptions, ...newValue].map((item) => [item["id"], item])
              ).values(),
            ]);
          }
        }}
        // filterOptions={(options, params) => {
        //   const filtered = filter(options, params);
        //   return [{ name: "Select All", value: "select-all" }, ...filtered];
        // }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={hideVariant ? undefined : variant}
            placeholder={isArabic ? arabicPlaceholder : placeholder}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              key={option?.id}
              color="primary"
              variant="outlined"
              style={{ maxWidth: "200px" }}
              label={
                typeof option?.[accessValue] === "object"
                  ? option?.[accessValue]["en"]
                  : option?.[accessValue]
              }
              {...getTagProps({ index })}
              disabled={fixedOptions.find((val) =>
                val?.id === option?.id ? true : false
              )}
            />
          ))
        }
        renderOption={(props, option, { selected }) => {
          // const selectAllProps =
          //   option.value === "select-all" // To control the state of 'select-all' checkbox
          //     ? { checked: allSelected }
          //     : {};

          return (
            <div {...props}>
              {typeof option?.[accessValue] === "object"
                ? option?.[accessValue]["en"]
                : option?.[accessValue]}
            </div>
          );
        }}
      />
      {linkTitle && (
        <Button
          className="add-button"
          startIcon={<ControlPointIcon />}
          onClick={handleAddLink}
        >
          {linkTitle}
        </Button>
      )}
    </div>
  );
};
FormMultiSelect.defaultProps = {
  accessValue: "name",
  variant: "standard",
  hideVariant: false,
  formComponent: true,
  fixedOptions: [],
  limitTags: 4,
  isfreeSolo: false,
};

FormMultiSelect.propTypes = {
  accessValue: PropTypes.string,
  variant: PropTypes.string,
  formComponent: PropTypes.bool,
  fixedOptions: PropTypes.array,
  limitTags: PropTypes.number,
};
//CHECKBOX

export const FormMultipleCheckBox = (props) => {
  const { onChange, data, required, label, value, helperText } = props;

  const handleOnChange = (item, booleanValue) => {
    let array = value;

    let changed_one = data.findIndex((d) => d.value === item.value);
    if (booleanValue === false) {
      array.splice(changed_one, 1);
    } else {
      array.push(item.value);
    }

    onChange(array);
  };
  return (
    <div className="form-input-wrapper">
      <InputLabel>
        {label}
        <span className="required-start">{required ? "*" : ""}</span>
      </InputLabel>
      <FormControl>
        <FormGroup row>
          {data.map((item) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={value.includes(item.value)}
                    onChange={(e, value) => handleOnChange(item, value)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={item.name}
              />
            );
          })}
        </FormGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
};

export const FormCheckBox = (props) => {
  const {
    onChange,
    label,
    required,
    name,
    helperText,
    value,
    arabicLabel,
    isArabic,
    arabicPlaceholder,
  } = props;
  const { disabled } = props;

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={value}
            value={value}
            disabled={disabled}
            onChange={onChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={isArabic ? arabicLabel : label}
      />
      {helperText !== "" && (
        <FormControl>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    </FormGroup>
  );
};

export const FormRadioButton = (props) => {
  const { onChange, data, required, label, value, helperText } = props;
  const { disabled, name, loading } = props;

  const [val, setVal] = useState(null);
  useEffect(() => {
    setVal(value);
  }, [value, data]);

  return (
    <div className="form-input-wrapper">
      <InputLabel>
        {label}
        <span className="required-start">{required ? "*" : ""}</span>
      </InputLabel>
      {!loading ? (
        <FormControl>
          <RadioGroup row value={val?.id} name={name}>
            {data?.map((item) => {
              return (
                <>
                  <FormControlLabel
                    key={item.id}
                    disabled={disabled}
                    value={item.id}
                    control={<Radio checked={val?.id == item.id} />}
                    label={item.name}
                    onClick={(e) => (disabled ? null : onChange(item))}
                  />
                  {item.icon && (
                    <div className="radio-button-icon">{item.icon}</div>
                  )}
                </>
              );
            })}
          </RadioGroup>

          {helperText !== "" && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      ) : (
        <LinearProgress color="primary" />
      )}
    </div>
  );
};

//FORM SINGLE UPLOAD
export const FormSingleUpload = (props) => {
  const { type, label, onChange, accept } = props;
  const { aspectRatio, value, name, helperText, required } = props;

  const handleOnChange = (files) => onChange(files);
  const handleRemoveImage = () => onChange(null);

  let file_type =
    type === "File"
      ? accept
      : type === "Video"
      ? ["MP4", "MOV", "OGG", "QT"]
      : ["JPG", "PNG"];
  let size_max = type === "File" ? 10 : type === "Video" ? 5 : 1;
  return (
    <div className="form-single-upload">
      {!value ? (
        <div className="form-upload-single-section">
          <FileUploader
            maxSize={size_max}
            types={file_type}
            name={name}
            onSizeError={(error) => console.error(error)}
            onTypeError={(error) => console.error(error)}
            multiple={false}
            handleChange={(files) => handleOnChange(files)}
            children={
              <div>
                <ReusableButton
                  startIcon={<svg.UploadSVG />}
                  name={label}
                  required={required}
                />
              </div>
            }
          />
          {helperText && (
            <FormControl>
              <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
          )}
        </div>
      ) : (
        <>
          <img
            alt="the uploaded file"
            style={{ objectFit: "contain" }}
            width="90%"
            height="200px"
            src={
              value && value instanceof File
                ? URL.createObjectURL(value)
                : REACT_APP_IMAGE_URL + value
            }
          />
          <div
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={handleRemoveImage}
              variant="text"
              startIcon={<svg.DeleteSVG />}
            >
              Remove
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
FormSingleUpload.defaultProps = {
  label: "Upload",
};
FormSingleUpload.propTypes = {
  type: PropTypes.oneOf(["News", "Video"]),
  label: PropTypes.string,
  aspectRatio: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};
//FORM MULTIPLE UPLOAD
export const FormMultipleUpload = (props) => {
  const {
    name,
    label,
    accept,
    helperText,
    onChange,
    type,
    value,
    handleDeleteImage,
  } = props;

  const handleOnChange = (files) => onChange(files);

  let file_type =
    type === "File"
      ? accept
      : type === "Image"
      ? ["JPG", "PNG"]
      : ["JPG", "PNG"];

  let size_max = type === "File" ? 10 : type === "Video" ? 5 : 1;

  return (
    <div className="form-input-wrapper">
      {!value && (
        <div className="form-upload-section">
          <FileUploader
            name={name}
            maxSize={size_max}
            types={file_type}
            onTypeError={(err) => console.error(err)}
            onSizeError={(err) => console.error(err)}
            classes={""}
            multiple={false}
            handleChange={(files) => {
              handleOnChange(files);
            }}
            children={
              <div className="form-upload-children">
                <ReusableButton startIcon={<svg.UploadSVG />} name={label} />
                <div className="ratio-label">Upload multiple images</div>
              </div>
            }
          />
        </div>
      )}

      {value && value.length !== 0 && (
        <div className="list-uploaded-images">
          <List className="list-images">
            {value?.map((item, index) => {
              return (
                <ListItem
                  className="image-uploaded-item"
                  secondaryAction={
                    <IconButton
                      aria-label="upload picture"
                      component="label"
                      onClick={() => handleDeleteImage(item, index)}
                    >
                      <input hidden accept="image/*" type="file" />
                      <svg.DeleteSVG />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      src={
                        item
                          ? item.path
                            ? REACT_APP_IMAGE_URL + item.path
                            : URL.createObjectURL(item)
                          : null
                      }
                    ></Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.name ? item.name : item.path.split("/").pop()}
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
      )}
      {helperText && (
        <FormControl>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    </div>
  );
};

export const FormSearchBar = (props) => {
  const { placeholder, variant, value, onChange } = props;

  const handleInputChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <TextField
      type="text"
      fullWidth
      size="small"
      placeholder={placeholder}
      variant={variant}
      onChange={(e) => handleInputChange(e)}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment className="input-adorment" position="start">
            <svg.SearchSVG />
          </InputAdornment>
        ),
      }}
    />
  );
};
// export const UploadSingleComponent = (props) => {
//   <div className="filesSection">
//     <div className="fileInput">
//       <input name="file" type="file" onChange={handleFileChange} multiple />
//       <label htmlFor="file" className="fileLabel">
//         <span className="fileUpload">
//           <svg.UploadSVG />
//           Upload
//         </span>
//         <span className="fileUploadLabel">Upload multiple images</span>
//       </label>
//     </div>
//     {/* <div className="form-error-message">
//       {(create && fileList?.length === 0) ||
//       (!create && existingImages?.length == 0 && fileList?.length === 0)
//         ? "Field is required"
//         : ""}
//     </div> */}
//   </div>;
// };

export const FormDropdownListloader = (props) => {
  const {
    label,
    name,
    placeholder,
    type,
    variant,
    hideVariant,
    disabled,
    required,
    value,
    dependencyError,
    formComponent,
    mainClassName,
    isArabic,
    arabicPlaceholder,
    arabicLabel,
    data,
    noOptionsMessage,
    loading,
    onChange,
    helperText,
    clearErrors,
    accessValue,
    perpage,
    fixedOptions,
    loadMoreUrl, // URL for loading more data
  } = props;

  const [val, setVal] = useState(fixedOptions ? [...fixedOptions] : []);
  const [dropdownData, setDropdownData] = useState(data);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (value) {
      setVal(value);
      if (clearErrors) {
        dependencyError ? clearErrors(dependencyError) : clearErrors(name);
      }
    }
  }, [value, clearErrors, dependencyError, name]);

  useEffect(() => {
    setDropdownData(data);
  }, [data]);

  const fetchData = async (query, pageNum = 1) => {
    setLoadingMore(true);
    try {
      const response = await api.get(loadMoreUrl, {
        params: {
          page: pageNum,
          per_page: perpage,
          name: query,
        },
      });
      const newData = response.data.data.data;
      if (pageNum === 1) {
        setDropdownData(newData);
      } else {
        setDropdownData((prevData) => [...prevData, ...newData]);
      }
      setHasMore(newData.length > 0);
      setPage(pageNum);
    } catch (error) {
      console.error("Error loading more data:", error);
    } finally {
      setLoadingMore(false);
    }
  };

  const debouncedFetchData = useCallback(debounce(fetchData, 300), []);

  useEffect(() => {
    if (searchQuery) {
      debouncedFetchData(searchQuery);
    } else {
      setDropdownData(data); // Reset to preloaded data if search query is cleared
      setHasMore(true);
      setPage(1);
    }
  }, [searchQuery, debouncedFetchData, data]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const clearSearch = () => {
    setSearchQuery("");
  };

  const loadMoreData = () => {
    if (!hasMore || loadingMore) return;
    fetchData(searchQuery, page + 1);
  };

  return (
    <div
      className={
        formComponent && mainClassName
          ? `form-input-wrapper ${mainClassName}`
          : formComponent
          ? "form-input-wrapper"
          : "non-form-wrapper"
      }
    >
      {label && (
        <InputLabel>
          {isArabic ? arabicLabel : label}
          {required && <span className="required-start"> * </span>}
        </InputLabel>
      )}

      <Autocomplete
        size="small"
        disabled={disabled}
        fullWidth
        disableClearable={required}
        ListboxProps={{
          style: { maxHeight: 200, overflow: "auto" },
          onScroll: (event) => {
            const bottom =
              event.target.scrollHeight - event.target.scrollTop ===
              event.target.clientHeight;
            if (bottom && !loadingMore) {
              loadMoreData();
            }
          },
        }}
        getOptionLabel={(option) =>
          option?.[accessValue]
            ? typeof option?.[accessValue] === "object"
              ? option?.[accessValue].en
              : option?.[accessValue]
            : "Select "
        }
        options={dropdownData}
        value={val}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        loadingText="Loading"
        noOptionsText={noOptionsMessage || "No Options"}
        loading={loading}
        onChange={(event, selected) => onChange(selected)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={hideVariant ? undefined : variant}
            placeholder={isArabic ? arabicPlaceholder : placeholder}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingMore ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}

                  {params.InputProps.endAdornment}
                </>
              ),
              onChange: handleSearchChange,
            }}
          />
        )}
      />
    </div>
  );
};

FormDropdownListloader.defaultProps = {
  accessValue: "name",
  variant: "standard",
  hideVariant: false,
  formComponent: true,
};

FormDropdownListloader.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  hideVariant: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.any,
  dependencyError: PropTypes.string,
  formComponent: PropTypes.bool,
  mainClassName: PropTypes.string,
  isArabic: PropTypes.bool,
  arabicPlaceholder: PropTypes.string,
  arabicLabel: PropTypes.string,
  data: PropTypes.array.isRequired,
  noOptionsMessage: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  clearErrors: PropTypes.func,
  accessValue: PropTypes.string,
  loadMoreUrl: PropTypes.string.isRequired, // Added prop for the URL
};

export const FormMultiSelectLoader = (props) => {
  const {
    label,
    data,
    disabled,
    required,
    onChange,
    value,
    fixedOptions,
    limitTags,
    variant,
    name,
    accessValue,
    isArabic,
    arabicLabel,
    arabicPlaceholder,
    hideVariant,
    mainClassName,
    linkTitle,
    handleAddLink,
    msg,
    perpage,
    loadMoreUrl,
  } = props;
  const {
    helperText,
    loading,
    placeholder,
    noOptionsMessage,
    clearErrors,
    dependencyError,
  } = props;

  const [val, setVal] = useState(fixedOptions ? [...fixedOptions] : []);
  const [dropdownData, setDropdownData] = useState(data);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (value) {
      setVal(value);
      if (clearErrors) {
        dependencyError ? clearErrors(dependencyError) : clearErrors(name);
      }
    }
  }, [value, clearErrors, dependencyError, name]);

  useEffect(() => {
    setDropdownData(data);
  }, [data]);

  const fetchData = async (query, pageNum = 1) => {
    setLoadingMore(true);
    try {
      const response = await api.get(loadMoreUrl, {
        params: {
          page: pageNum,
          per_page: perpage,
          name: query,
        },
      });
      const newData = response.data.data.data;
      if (pageNum === 1) {
        setDropdownData(newData);
      } else {
        setDropdownData((prevData) => [...prevData, ...newData]);
      }
      setHasMore(newData.length > 0);
      setPage(pageNum);
    } catch (error) {
      console.error("Error loading more data:", error);
    } finally {
      setLoadingMore(false);
    }
  };

  const debouncedFetchData = useCallback(debounce(fetchData, 300), []);

  useEffect(() => {
    if (searchQuery) {
      debouncedFetchData(searchQuery);
    } else {
      setDropdownData(data); // Reset to preloaded data if search query is cleared
      setHasMore(true);
      setPage(1);
    }
  }, [searchQuery, debouncedFetchData, data]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const clearSearch = () => {
    setSearchQuery("");
  };

  const loadMoreData = () => {
    if (!hasMore || loadingMore) return;
    fetchData(searchQuery, page + 1);
  };

  return (
    <div
      className={
        mainClassName
          ? "form-input-wrapper" + " " + mainClassName
          : "form-input-wrapper"
      }
    >
      <InputLabel>
        {isArabic ? arabicLabel : label}{" "}
        <span className="required-start">{required ? "*" : ""}</span>
        <span className="required-start">
          {msg && (
            <Tooltip title={msg} placement="right">
              <InfoIcon style={{ color: "#ff0000" }} />
            </Tooltip>
          )}
        </span>
      </InputLabel>

      <Autocomplete
        disableCloseOnSelect
        multiple
        size="small"
        limitTags={limitTags}
        fullWidth
        className={isArabic ? "direction-left" : ""}
        options={dropdownData}
        disabled={disabled}
        ListboxProps={{
          style: { maxHeight: 200, overflow: "auto" },
          onScroll: (event) => {
            const bottom =
              event.target.scrollHeight - event.target.scrollTop ===
              event.target.clientHeight;
            if (bottom && !loadingMore) {
              loadMoreData();
            }
          },
        }}
        getOptionLabel={(option) =>
          typeof option?.[accessValue] === "object"
            ? option?.[accessValue]["en"]
            : option?.[accessValue]
        }
        loading={loadingMore}
        noOptionsText={noOptionsMessage ? noOptionsMessage : "No Options"}
        loadingText={"Loading"}
        value={val}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(event, newValue) => {
          if (newValue.some((option) => option.value === "select-all")) {
            onChange(data);
          } else {
            onChange([
              ...new Map(
                [...fixedOptions, ...newValue].map((item) => [item["id"], item])
              ).values(),
            ]);
            clearSearch();
            setDropdownData(data); // Reset to preloaded data on selection
            setHasMore(true);
            setPage(1);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={hideVariant ? undefined : variant}
            placeholder={isArabic ? arabicPlaceholder : placeholder}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingMore ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}

                  {params.InputProps.endAdornment}
                </>
              ),
              onChange: handleSearchChange,
            }}
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              key={option?.id}
              color="primary"
              variant="outlined"
              style={{ maxWidth: "200px" }}
              label={
                typeof option?.[accessValue] === "object"
                  ? option?.[accessValue]["en"]
                  : option?.[accessValue]
              }
              {...getTagProps({ index })}
              disabled={fixedOptions.find((val) =>
                val?.id === option?.id ? true : false
              )}
            />
          ))
        }
        renderOption={(props, option, { selected }) => {
          return (
            <div {...props}>
              {typeof option?.[accessValue] === "object"
                ? option?.[accessValue]["en"]
                : option?.[accessValue]}
            </div>
          );
        }}
      />

      {linkTitle && (
        <Button
          className="add-button"
          startIcon={<ControlPointIcon />}
          onClick={handleAddLink}
        >
          {linkTitle}
        </Button>
      )}
    </div>
  );
};

FormMultiSelectLoader.defaultProps = {
  accessValue: "name",
  variant: "standard",
  hideVariant: false,
  formComponent: true,
  fixedOptions: [],
  limitTags: 4,
};

FormMultiSelectLoader.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
  fixedOptions: PropTypes.array,
  limitTags: PropTypes.number,
  variant: PropTypes.string,
  name: PropTypes.string.isRequired,
  accessValue: PropTypes.string,
  isArabic: PropTypes.bool,
  arabicLabel: PropTypes.string,
  arabicPlaceholder: PropTypes.string,
  hideVariant: PropTypes.bool,
  mainClassName: PropTypes.string,
  linkTitle: PropTypes.string,
  handleAddLink: PropTypes.func,
  msg: PropTypes.string,
  perpage: PropTypes.number,
  loadMoreUrl: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  clearErrors: PropTypes.func,
  dependencyError: PropTypes.string,
};

export default FormMultiSelectLoader;
