import React from "react";
import "./Button.scss";
import { CardSVG } from "../../assests/icons/SVG";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Button = (props) => {
  const { selected, href, style, width, type, startIcon ,endIcon} = props;
  const { title, isArabic, arabicLabel } = props;
  const { name, onClick, required, id, className, disabled, loading, to } =
    props;
  return (
    <LoadingButton
      {...props}
      startIcon={startIcon || null}
      endIcon={endIcon || null}
      type={type}
      to={href && href}
      disabled={disabled}
      loading={loading}
      loadingPosition="end"
      name={id}
      component={href ? Link : "button"}
      sx={{
        backgroundColor: `${selected ? "#4ba4f0" : "#ffffff"}`,
        // fontSize: 18,
        textTransform: "none",
        borderRadius: "50px",
        color: `${!selected ? "#4ba4f0" : "#ffffff"}`,
        ...style,
        width: width
      }}
      onClick={onClick}
      className={`buttonComponent ${className ? className : ""} ${
        selected ? "selectedButton selectedColor" : "isntSelectedButton"
      }`}
      variant={selected ? "contained" : "outlined"}
    >
      {(name?.toLowerCase() === "add to cart" ||
        name?.toLowerCase() === "in cart") && (
        <CardSVG className={!selected ? "cardSVG" : ""} />
      )}
      <p name={id}>
        {loading ? "  Loading... " : isArabic ? arabicLabel : name || title}
        {required && <span>*</span>}
      </p>
    </LoadingButton>
  );
};
Button.defaultProps = {
  selected: false,
  loading: false,
  required: false,
};
Button.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};
export default Button;
