//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

//COMPONENT
import Button from "../../components/button/Button";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { FormDropdownList } from "../../components/formComponents/FormComponents";
import { CloseSVG } from "../../assests/icons/SVG";
import { FormInput } from "../../components/formComponents/FormComponents";
import { useGet } from "../../hooks/useFetch";
import { DeleteSVG, PlusCircleSVG } from "../../assests/icons/SVG";
import { AlertContext } from "../../context/AlertContext";

//CSS
import "./ItemHandle.scss";
import { changeItemAction } from "../../core/apis/item";

const HandleSchema = yup.object({
  next_action_id: yup.object().nullable().required("Field is required"),
  maintenance_cost: yup.array().of(
    yup.object().shape({
      cost: yup.string("Field is invalid"),
      description: yup.string("Field is invalid"),
    })
  ),
  new_condition_id: yup.object().nullable(),
});

export function ProductStatusHandle(props) {
  const { data, onClose, refetch } = props;

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  const { setAlert } = useContext(AlertContext);
  const [nextAction, setNextAction] = useState([]);

  const [saveLoading, setSaveLoading] = useState(false);

  const { data: conditions, conditionsLoading } = useGet({
    url: "/user/get-all-conditions",
  });

  useEffect(() => {
    if (data) {
      setNextAction(data?.next_action);
    }
  }, [data]);

  const handleSubmitForm = (formData) => {
    setSaveLoading(true);
    let payload = {
      id: data?.id,
      next_action_id: formData.next_action_id?.id,
      ...(data?.next_action[0]?.id === 7 && {
        maintenance_cost: formData.maintenance_cost,
      }),
      ...(data?.next_action[0]?.id === 7 && {
        new_condition_id: formData?.new_condition_id?.id,
      }),
    };
    changeItemAction(payload).then((response) => {
      if (response?.data.success) {
        refetch();
        onClose();
      }
      setAlert({
        text: response.data ? response.data?.message : response.message,
        type: response.data?.success ? "success" : "error",
      });
    });
  };

  const {
    fields: feesFields,
    remove: feesRemove,
    append: feesAppend,
  } = useFieldArray({ control, name: "maintenance_cost" });

  const handleAddValue = () => {
    feesAppend({
      cost: "",
      description: "",
    });
  };

  useEffect(() => {
    reset({
      new_condition_id: null,
      maintenance_cost: [{ cost: "", description: "" }],
    });
  }, []);

  return (
    <Dialog disablebackdropclick="true" fullWidth open={true}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {"Update Item Status"}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseSVG />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={handleSubmit(handleSubmitForm)}
          className="item-status-handle"
        >
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormDropdownList
                required
                data={nextAction || []}
                loading={false}
                name={`next_action_id`}
                dependencyError={["next_action_id"]}
                placeholder={"Select status"}
                value={value}
                onChange={(e) => onChange(e)}
                helperText={error?.message}
                hideVariant={true}
              />
            )}
            name={`next_action_id`}
            control={control}
          />
          {data?.next_action[0].id === 7 && (
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormDropdownList
                  required
                  data={conditions?.data?.data || []}
                  loading={conditionsLoading}
                  name={`new_condition_id`}
                  dependencyError={["new_condition_id"]}
                  placeholder={"Select Condition"}
                  value={value}
                  onChange={(e) => onChange(e)}
                  helperText={error?.message}
                  hideVariant={true}
                />
              )}
              name={`new_condition_id`}
              control={control}
            />
          )}
          {data?.next_action[0].id === 7 && (
            <div className="feesRow">
              {feesFields?.length !== 0 &&
                feesFields?.map((el, attributeIndex) => {
                  const isLastIndex = attributeIndex === feesFields.length - 1;
                  return (
                    <div
                      key={`${attributeIndex}_${feesFields.length}`}
                      className="dynamic-fees-add"
                    >
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormInput
                            required
                            label={`Description ${attributeIndex + 1}`}
                            placeholder="Enter Description"
                            name={`maintenance_cost.${attributeIndex}.description`}
                            value={value}
                            onChange={(e) => onChange(e?.target.value)}
                            helperText={error?.message}
                            hideVariant={true}
                          />
                        )}
                        name={`maintenance_cost.${attributeIndex}.description`}
                        control={control}
                      />
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormInput
                            required
                            label={`Cost ${attributeIndex + 1}`}
                            placeholder="Enter Cost"
                            name={`maintenance_cost.${attributeIndex}.cost`}
                            value={value}
                            onChange={(e) => onChange(e?.target.value)}
                            helperText={error?.message}
                            hideVariant={true}
                          />
                        )}
                        name={`maintenance_cost.${attributeIndex}.cost`}
                        control={control}
                      />
                      {feesFields.length > 1 && (
                        <IconButton
                          onClick={() => feesRemove(attributeIndex)}
                          className="feesIconButton"
                        >
                          <DeleteSVG />
                        </IconButton>
                      )}
                      {isLastIndex && (
                        <IconButton
                          onClick={() => handleAddValue()}
                          className="feesIconButton"
                        >
                          <PlusCircleSVG />
                        </IconButton>
                      )}
                    </div>
                  );
                })}
            </div>
          )}

          <div className="action-footer">
            <Button
              name="Save"
              selected
              width={"30%"}
              loading={saveLoading}
              type="submit"
            />
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ProductStatusHandle;
