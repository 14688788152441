// EXTERNAL LIBRARIES
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TableCell } from "@mui/material";
import moment from "moment";
// STYLING
import "./EmailsNotifications.scss";

// TABLE
import TableHeader from "../../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../../components/table/tableBody/TableRowComponent";
import Paginator from "../../../components/paginator/Paginator";

// CUSTOM HOOKS
import { useGet } from "../../../hooks/useFetch";

// DATA NOT FOUND
import DataNotFound from "../../../components/notFound/DataNotFound";
import withH1 from "../../../assests/HOC/withH1";

const EmailsNotifications = () => {
  // DATA AND HOOKS
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const tableHeaders = [
    { title: "Title" },
    { title: "Receivers" },
    { title: "Date" },
    { title: "Type" },
  ];

  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    title: searchParams.get("search"),
    type: searchParams.get("notification_type"),
  };

  // API REQUESTS
  let url = `/admin/get-all-notifications`;
  const {
    data: notifications,
    loading,
    refetch,
  } = useGet({ url: url, payload: payload });
  let data = notifications?.data?.data;

  return (
    <>
      <TableHeader
        placeholder="Search by title"
        to="/emails-&-notifications/add-notification"
        filterByNotificationType
      />
      <TableBodyComponent
        loading={loading}
        header={tableHeaders}
        actions={false}
        total={data?.total}
      >
        {data?.data?.length ? (
          data?.data?.map((element) => (
            <TableRowComponent
              row={element}
              actions={false}
              key={element?.id}
              handleClick={() => {
                navigate("/emails-&-notifications/notification/" + element?.id);
              }}
            >
              <TableCell>{element?.title}</TableCell>
              <TableCell>
                {element?.user
                  ?.slice(0, 2)
                  .map((el) => el?.full_name)
                  .join(",")}
                {element?.user?.length > 2 ? "..." : ""}
              </TableCell>
              <TableCell>
                {moment(element?.created_at).format("DD-MM-YYYY HH:mm")}
              </TableCell>
              <TableCell>
                {element?.notification_type_id === 1 ? "Email" : "Notification"}
              </TableCell>
            </TableRowComponent>
          ))
        ) : (
          <DataNotFound />
        )}
      </TableBodyComponent>
      {data && <Paginator count={data?.last_page} disabled={loading} />}
    </>
  );
};

export default withH1(EmailsNotifications, "Emails & Notifications");
