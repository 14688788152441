import React, { useContext, useState } from "react";
import "./ChangePassword.scss";
import { AlertContext } from "../../../../context/AlertContext";
import * as UsersApi from "../../../../core/apis/personal";
import Button from "../../../../components/button/Button";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { FormPassword } from "../../../../components/formComponents/FormComponents";
import { yupResolver } from "@hookform/resolvers/yup";

const changePasswordSchema = yup.object({
  current_password: yup
    .string("Enter your password")
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
  password: yup
    .string("Enter your password")
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
const ChangePassword = () => {
  const { setAlert } = useContext(AlertContext);
  const [saveLoading, setSaveLoading] = useState(false);

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(changePasswordSchema) });

  const inputs = [
    { label: "Current Password", name: "current_password" },
    { label: "New Password", name: "password" },
    { label: "Confirm New Password", name: "password_confirmation" },
  ];

  const handleSubmitForm = (formData) => {
    setSaveLoading(true);
    UsersApi.changePassword(formData).then((res) => {
      setAlert({
        visible: true,
        type: res.data.success ? "success" : "error",
        text: res.data.message,
      });
      setSaveLoading(false);
      res.data.success &&
        reset({
          current_password: "",
          password: "",
          password_confirmation: "",
        });
    });
  };

  return (
    <form className="change_password" onSubmit={handleSubmit(handleSubmitForm)}>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormPassword
            required
            name="current_password"
            label="Current Password"
            placeholder={"Enter current password"}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            helperText={error?.message}
          />
        )}
        name="current_password"
        control={control}
      />
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormPassword
            required
            name="password"
            label="New Password"
            placeholder={"Enter new password"}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            helperText={error?.message}
          />
        )}
        name="password"
        control={control}
      />
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormPassword
            required
            name="password_confirmation"
            label="Confirm new password"
            placeholder={"Enter new password"}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            helperText={error?.message}
          />
        )}
        name="password_confirmation"
        control={control}
      />

      <div className="action-footer">
        <Button
          className="submit"
          name="Submit"
          width="30%"
          selected
          disabled={saveLoading}
          loading={saveLoading}
          type="submit"
        />
      </div>
    </form>
  );
};
export default ChangePassword;
