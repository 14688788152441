import React from "react";
import MainRoute from "./core/routes/routes";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme/Theme";
import { AlertContextProvider } from "./context/AlertContext";
import { SideMenuContextProvider } from "./context/SideMenuContext";
import * as Sentry from "@sentry/react";
import { CountryContextProvider } from "./context/CountryContext";
import { NotificationContextProvider } from "./context/NotificationContext";
import ReactQueryProvider from "./context/ReactQuery";
import { GlobalProvider } from "./context/GlobalContext";
import i18n from './translation/i18n';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ReactQueryProvider>
        <GlobalProvider>
          <SideMenuContextProvider>
            <AlertContextProvider>
              <CountryContextProvider>
                <NotificationContextProvider>
                  <MainRoute />
                </NotificationContextProvider>
              </CountryContextProvider>
            </AlertContextProvider>
          </SideMenuContextProvider>
        </GlobalProvider>
      </ReactQueryProvider>
    </ThemeProvider>
  );
};

export default Sentry.withProfiler(App);
