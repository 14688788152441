import { useRef, useState, useEffect, useMemo } from "react";
import "./imageCropper.scss";
import { useDropzone } from "react-dropzone";
import CropperModal from "./cropperModal";
import { REACT_APP_IMAGE_URL } from "../../core/apis/main";
import * as svg from "../../assests/icons/SVG";
import { Button } from "@mui/material";
import documentationImg from "../../assests/Images/file-upload.png";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// Container
const Cropper = (props) => {
  const {
    MAX_FILE_SIZE,
    MAX_IMAGE_WIDTH,
    MAX_IMAGE_HEIGHT,
    sendCroppedFile,
    title,
    className,
    imgClassName,
    imageSrc,
    sizeMsg,
    isTrue,
    handlePublish,
    isloading,
    iwebp,
    imagwidth,
    showTitle,
    showDeleteIcon,
  } = props;

  // image src
  const [src, setSrc] = useState(null);
  const [preImageSrc, setimageSrc] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  // preview
  const [preview, setPreview] = useState(null);
  const [erromsg, seterromsg] = useState(null);

  // modal state
  const [modalOpen, setModalOpen] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (imageSrc) {
      setimageSrc(imageSrc);
    }
  }, [imageSrc]);

  // Function triggered when files are dropped or selected
  const onDrop = (acceptedFiles) => {
    seterromsg(null);
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      // Check file size against maximum limit
      if (file.size > MAX_FILE_SIZE) {
        alert(
          `File size exceeds the maximum limit of ${
            MAX_FILE_SIZE / (1024 * 1024)
          } MB`
        );
        return;
      }

      // Check if the file is in WebP format
      if (iwebp) {
        if (file.type !== "image/webp") {
          seterromsg("Only webP files are accepted.");
          return;
        }
      }

      setSelectedFile(file); // Set the selected file

      // Check image dimensions
      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = () => {
        if (
          image.width == MAX_IMAGE_WIDTH &&
          image.height == MAX_IMAGE_HEIGHT
        ) {
          setModalOpen(false); // Disable cropping if image dimensions are within limits
          sendCroppedFile(file);
        } else {
          setSrc(URL.createObjectURL(file));
          setModalOpen(true); // Allow cropping if image dimensions exceed limits
        }
      };
    }
  };

  // UseDropzone hook to handle file drop and selection
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const handleCroppedFile = (file) => {
    setSelectedFile(file);
    sendCroppedFile(file);
  };

  const handleRemove = () => {
    setimageSrc(null);
    setSrc(null);
    setSelectedFile(null);
    setModalOpen(false);
    sendCroppedFile(null);
  };

  const handleEdit = () => {
    setModalOpen(true);
  };

  const renderImage = useMemo(() => {
    if (preImageSrc && !selectedFile) {
      if (preImageSrc instanceof File === true) {
        return preImageSrc ? URL.createObjectURL(preImageSrc) : null;
      } else return `${REACT_APP_IMAGE_URL}${preImageSrc}`;
    }
    return selectedFile ? URL.createObjectURL(selectedFile) : null;
  }, [preImageSrc, selectedFile]);

  return (
    <>
      <div
        className={`${
          isTrue ? "wrapper-container width27" : "wrapper-container"
        }  ${className}`}
      >
        <CropperModal
          modalOpen={modalOpen}
          src={src}
          setPreview={setPreview}
          setModalOpen={setModalOpen}
          max_image_width={MAX_IMAGE_WIDTH}
          max_image_height={MAX_IMAGE_HEIGHT}
          onCropComplete={handleCroppedFile}
          iwebp={iwebp}
        />

        <div
          className={isTrue ? "wrapper-image displayBlock" : "wrapper-image"}
        >
          <div {...getRootProps()} className={`dropzone  ${className}`}>
            {!isloading ? (
              <>
                {(selectedFile && !modalOpen) || preImageSrc ? (
                  <div>
                    <img
                      src={renderImage}
                      alt="Uploaded"
                      className={imgClassName ? imgClassName : "cropped-image"}
                    />
                    <p className="error">{erromsg ? erromsg : null}</p>
                  </div>
                ) : (
                  <>
                    <input {...getInputProps()} />
                    {isTrue && (
                      <img
                        src={documentationImg}
                        alt="Uploaded"
                        style={{ width: "30%" }}
                        className={`${imagwidth} image-fileinput `}
                      />
                    )}

                    {showDeleteIcon && (
                      <img
                        src={documentationImg}
                        alt="Uploaded"
                        style={{ width: "30%" }}
                        className={`${imagwidth} image-fileinput `}
                      />
                    )}

                    {showTitle && (
                      <p style={{ fontSize: "15px" }}>
                        {title
                          ? title
                          : "Drag and drop an image here, or click to select an image"}
                      </p>
                    )}
                    <em>{sizeMsg}</em>
                    <p className="error">{erromsg ? erromsg : null}</p>
                  </>
                )}
              </>
            ) : (
              <div className="uploading">
                <CloudUploadIcon
                  className="iconRotate"
                  style={{ fontSize: "100px" }}
                />{" "}
                <p> Uploading...</p>
              </div>
            )}
          </div>
          <div className="button-group">
            {/* {selectedFile && isTrue && (
              <Button
                onClick={() => handlePublish()}
                variant="text"
                style={{ color: "#008000" }}
                startIcon={<CheckBoxIcon />}
              >
                Save
              </Button>
            )} */}
            {selectedFile && !isTrue && (
              <Button
                onClick={handleEdit}
                variant="text"
                startIcon={<svg.CropSvg />}
              >
                Edit
              </Button>
            )}

            {(!isTrue && selectedFile) ||
            (!isTrue && imageSrc) ||
            (showDeleteIcon && imageSrc) ? (
              <Button
                onClick={handleRemove}
                variant="text"
                startIcon={<svg.DeleteSVG />}
              >
                Clear
              </Button>
            ) : undefined}
          </div>
        </div>
      </div>
    </>
  );
};
Cropper.defaultProps = {
  MAX_FILE_SIZE: 10 * 1024 * 1024,
  MAX_IMAGE_WIDTH: 400,
  MAX_IMAGE_HEIGHT: 400,
  ASPECT_RATIO: 1,
  showTitle: true,
  showDeleteIcon: false,
};

export default Cropper;
