//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

//COMPONENT
import Button from "../../../components/button/Button";
import { useForm, Controller } from "react-hook-form";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
} from "@mui/material";
import {
  FormDropdownList,
  FormInput,
} from "../../../components/formComponents/FormComponents";
import { useGet } from "../../../hooks/useFetch";
import { CloseSVG } from "../../../assests/icons/SVG";
//CSS
import "./TransactionDetails.scss";
import {
  createItemProductSku,
  getAllProductSkus,
  getProductSkusByID,
} from "../../../core/apis/productSkus";
import { changeItemAction } from "../../../core/apis/item";
import NewEditor from "../../../components/editor/NewEditor";
import { AlertContext } from "../../../context/AlertContext";
import { CountryContext } from "../../../context/CountryContext";

const HandleSchema = yup.object({
  product_id: yup.object().nullable().required("Field is required"),
  collection_point_id: yup.object().nullable().required("Field is required"),
  description: yup.string().nullable(),
  warranty: yup.string().nullable(),
  buyer_price: yup
    .number("Invalid Field")
    .nullable()
    .required("Field is required"),
  offered_price: yup
    .number("Invalid Field")
    .nullable()
    .required("Field is required"),
  cost: yup.number("Invalid Field").nullable().required("Field is required"),
  maintenance_cost: yup.number("Invalid Field").nullable(),
  condition_id: yup.object().nullable().required("Field is required"),
  product_usage_type_id: yup.object().nullable().required("Field is required"),
  attributes_data: yup.array().of(
    yup.object().shape({
      attribute_id: yup.object().nullable().required("Field is required"),
      attribute_value_id: yup.object().required("Field is required"),
    })
  ),
});

export function TransactionItemPlacement(props) {
  const { data, onClose, refetchData, action } = props;
  const { country } = useContext(CountryContext);
  //GET collection point data

  const { data: cps, cpsLoading } = useGet({
    url: `/admin/get-all-collection-point`,
  });
  const { data: usages, productUsageLoading } = useGet({
    url: "/admin/get-all-product-usage-types",
  });

  const { data: conditions, conditionsLoading } = useGet({
    url: "/user/get-all-conditions",
  });
  const { data: products, productsLoading } = useGet({
    url: "/admin/get-products",
  });

  let cpsArray = cps?.data?.data;
  let usagesArray = usages?.data?.data;
  let conditionsArray = conditions?.data?.data;
  let productsArray = products?.data?.data;
  const [loadingData, setLoadingData] = useState(false);
  const [getProductLoading, setGetProductLoading] = useState(false);
  const [productSkusData, setProductSkusData] = useState([]);

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  const { setAlert } = useContext(AlertContext);
  const [saveLoading, setSaveLoading] = useState(false);

  const ChangeItemAction = (values) => {
    changeItemAction(values).then((response) => {
      if (response?.data.success) {
        onClose();
        refetchData();
      }
      setAlert({
        text: response.data ? response.data?.message : response.message,
        type: response.data?.success ? "success" : "error",
      });
      setSaveLoading(false);
    });
  };

  const handleSubmitForm = (formData) => {
    setSaveLoading(true);
    if (productSkusData?.length !== 0) {
      ChangeItemAction({
        id: data?.inspection?.[0]?.item_id,
        next_action_id: action?.id,
        product_sku_id: formData?.product_sku_id?.id,
        collection_point_id: formData?.collection_point_id?.id,
        maintenance_cost: formData?.maintenance_cost || 0,
      });
    } else {
      createItemProductSku({
        ...formData,
        product_id: formData?.product_id?.id,
        collection_point_id: formData?.collection_point_id?.id,
        condition_id: formData?.condition_id?.id,
        product_usage_type_id: formData?.product_usage_type_id?.id,
        attributes_data: formData?.attributes_data?.map((el) => ({
          attribute_id: el?.attribute_id?.id,
          attribute_value_id: el?.attribute_value_id?.id,
        })),
      }).then((response) => {
        if (response?.data?.success) {
          ChangeItemAction({
            id: data?.inspection?.[0]?.item_id,
            next_action_id: action?.id,
            product_sku_id: response?.data?.data?.id,
            collection_point_id: formData?.collection_point_id?.id,
            maintenance_cost: formData?.maintenance_cost || 0,
          });
        }
      });
    }
  };

  const getProductSkuByID = (productSkusId) => {
    setGetProductLoading(true);
    getProductSkusByID({
      id: productSkusId?.id,
      country_id: country?.id || 1,
    }).then((response) => {
      if (response?.data?.success) {
        let responseData = response?.data?.data;

        reset({
          collection_point_id: getValues("collection_point_id"),
          product_sku_id: productSkusId,
          buyer_price: responseData?.buyer_price,
          cost: responseData?.cost,
          offered_price: responseData?.offered_price,
          description: responseData?.description
            ? Object(responseData?.description).hasOwnProperty("en")
              ? responseData?.description?.en
              : responseData?.description
            : "",
          qr_link: responseData?.qr_link,
          product_usage_type_id: responseData?.usage_type,
          condition_id: responseData?.condition,
          warranty: responseData?.warranty,
          product_id: responseData?.product,
          attributes_data: responseData?.product_variation?.map((el) => ({
            attribute_id: el?.attribute_value?.attribute,
            attribute_value_id: el?.attribute_value,
          })),
        });
      }
      setGetProductLoading(false);
    });
  };

  useEffect(() => {
    if (data) {
      setLoadingData(true);
      let selected_insp = data?.inspection?.find(
        (el) => el?.is_selected === true
      );

      getAllProductSkus({
        product_ids: [selected_insp?.item?.product_id],
        attribute_value_ids: selected_insp?.attribute_value?.map((el) => el.id),
        ...(action?.id === 3 && {
          condition_ids: selected_insp?.condition
            ? [selected_insp?.condition?.id]
            : [],
        }),
      }).then((response) => {
        if (response?.data?.success) {
          setProductSkusData(response?.data?.data);

          //IF THERE IS NO SIMILAR PRODUCT SKU BASED ON THE ATTRIBUTES
          //OR CONDITION PROVIDED
          //WE SHOULD INTRODUCE A NEW PRODUCT SKU
          if (response?.data?.data?.length === 0) {
            reset({
              buyer_price: null,
              cost: null,
              offered_price: null,
              description: "",
              qr_link: "",
              warranty: "",
              product_usage_type_id: null,
              collection_point_id: null,
              product_id: selected_insp?.item?.product,
              attributes_data: selected_insp?.attribute_value?.map((el) => ({
                attribute_id: el?.attribute,
                attribute_value_id: el,
              })),
              condition_id: action?.id === 7 ? null : selected_insp?.condition,
            });
          }
        }
        setLoadingData(false);
      });
    }
  }, [data]);

  return (
    <Dialog disablebackdropclick="true" fullWidth open={true}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {"Item Placement"}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseSVG />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="product-questions">
          {!loadingData ? (
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <div className="form-inputs-double-children">
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormDropdownList
                      required
                      data={cpsArray || []}
                      loading={cpsLoading}
                      label="Collection Point"
                      name={`collection_point_id`}
                      dependencyError={["collection_point_id"]}
                      placeholder={"Select collection point"}
                      value={value}
                      onChange={(e) => onChange(e)}
                      helperText={error?.message}
                    />
                  )}
                  name={`collection_point_id`}
                  control={control}
                />
                {productSkusData?.length !== 0 && (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormDropdownList
                        required
                        data={productSkusData || []}
                        loading={false}
                        accessValue={"sku_code"}
                        name={`product_sku_id`}
                        label="Product Sku"
                        dependencyError={["product_sku_id"]}
                        placeholder={"Select product sku"}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          getProductSkuByID(e);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name={`product_sku_id`}
                    control={control}
                  />
                )}
                {/* //complete with maintenance to add item maintenance cost */}
                {action?.id === 7 && (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        type="number"
                        label="Maintenance Cost"
                        placeholder="Enter cost"
                        name="maintenance_cost"
                        value={value}
                        onChange={(e) =>
                          onChange(
                            e.target.value === "" ? null : e.target.value
                          )
                        }
                        helperText={error?.message}
                      />
                    )}
                    name={`maintenance_cost`}
                    control={control}
                  />
                )}
              </div>
              {((productSkusData?.length !== 0 &&
                getValues("product_sku_id")) ||
                productSkusData?.length === 0) && (
                <>
                  <div className="form-inputs-double-children">
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormInput
                          required
                          type="number"
                          label="Offered Price"
                          placeholder="Enter price"
                          name="offered_price"
                          disabled={productSkusData?.length !== 0}
                          value={value}
                          onChange={(e) =>
                            onChange(
                              e.target.value === "" ? null : e.target.value
                            )
                          }
                          helperText={error?.message}
                        />
                      )}
                      name={`offered_price`}
                      control={control}
                    />

                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormInput
                          required
                          type="number"
                          label="Product SKU Cost"
                          placeholder="Enter cost"
                          disabled={productSkusData?.length !== 0}
                          name="cost"
                          value={value}
                          onChange={(e) =>
                            onChange(
                              e.target.value === "" ? null : e.target.value
                            )
                          }
                          helperText={error?.message}
                        />
                      )}
                      name={`cost`}
                      control={control}
                    />
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormInput
                          required
                          type="number"
                          label="Buyer Price"
                          placeholder="Enter buyer price"
                          name="buyer_price"
                          disabled={productSkusData?.length !== 0}
                          value={value}
                          onChange={(e) =>
                            onChange(
                              e.target.value === "" ? null : e.target.value
                            )
                          }
                          helperText={error?.message}
                        />
                      )}
                      name={`buyer_price`}
                      control={control}
                    />
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormInput
                          label="QR Link"
                          placeholder="Enter link"
                          name="qr_link"
                          disabled={productSkusData?.length !== 0}
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          helperText={error?.message}
                        />
                      )}
                      name={`qr_link`}
                      control={control}
                    />
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormInput
                          label="Warranty"
                          placeholder="Enter warranty"
                          name="warranty"
                          disabled={productSkusData?.length !== 0}
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          helperText={error?.message}
                        />
                      )}
                      name={`warranty`}
                      control={control}
                    />
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormDropdownList
                          required
                          data={conditionsArray || []}
                          loading={conditionsLoading}
                          label="Condition"
                          name="condition_id"
                          disabled={
                            productSkusData?.length !== 0 ||
                            (productSkusData?.length === 0 && action?.id === 3)
                          }
                          //if 3 => without maintenance should not enter new condition
                          //if action 7 (complete with maintenance , user should enter new condition,)
                          placeholder={"Select condition"}
                          value={value}
                          helperText={error?.message}
                          onChange={(value) => {
                            onChange(value);
                          }}
                        />
                      )}
                      name={`condition_id`}
                      control={control}
                    />
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormDropdownList
                          required
                          data={usagesArray || []}
                          loading={productUsageLoading}
                          label="Usage Type"
                          name="product_usage_type_id"
                          placeholder={"Select usage type"}
                          value={value}
                          disabled={productSkusData?.length !== 0}
                          onChange={(value) => {
                            onChange(value);
                          }}
                          helperText={error?.message}
                        />
                      )}
                      name={`product_usage_type_id`}
                      control={control}
                    />
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormDropdownList
                          required
                          data={productsArray || []}
                          loading={productsLoading}
                          label="Product"
                          disabled
                          name="product_id"
                          placeholder={"Select product"}
                          value={value}
                          onChange={(value) => {
                            onChange(value);
                          }}
                          helperText={error?.message}
                        />
                      )}
                      name={`product_id`}
                      control={control}
                    />
                    {getValues("attributes_data")?.map((el, index) => (
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormDropdownList
                            required
                            disabled
                            loading={productsLoading}
                            data={[]}
                            label={el?.attribute_id?.name?.en}
                            name={`attributes_data.${index}.attribute_value_id`}
                            placeholder={"Select value"}
                            value={value}
                            onChange={(value) => {
                              onChange(value);
                            }}
                            helperText={error?.message}
                          />
                        )}
                        name={`attributes_data.${index}.attribute_value_id`}
                        control={control}
                      />
                    ))}
                  </div>
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <NewEditor
                        label="Description"
                        name="description"
                        value={value}
                        onChange={(e) => onChange(e)}
                        helperText={error?.message}
                      />
                    )}
                    name={`description`}
                    control={control}
                  />
                </>
              )}

              <div className="action-footer">
                <Button
                  name={"Save"}
                  selected
                  disabled={saveLoading || getProductLoading}
                  loading={saveLoading}
                  width={"30%"}
                  type="submit"
                />
              </div>
            </form>
          ) : (
            <Skeleton count={10} />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default TransactionItemPlacement;
