// EXTERNAL LIBRARIES
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// STYLING
import "./Items.scss";

// TABLE
import TableHeader from "../../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../../components/table/tableBody/TableRowComponent";
import Paginator from "../../../components/paginator/Paginator";

// CUSTOM HOOKS
import { useGet } from "../../../hooks/useFetch";
import withH1 from "../../../assests/HOC/withH1";

const Items = () => {
  // HOOKS
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const tableHeaders = [
    { title: "SKU" },
    { title: "Condition" },
    { title: "Quality" },
    { title: "Warehouse" },
  ];
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };
  let url = `admin/get-all-items`;

  // API CALL
  const { data: items, loading } = useGet(url, payload);
  let data = items?.data.data;
  return (
    <>
      <TableHeader
        placeholder="Search by Item Name"
        // to="/products/new-product"
      />
      <TableBodyComponent
        header={tableHeaders}
        loading={loading}
        total={data?.total}
      >
        {data?.data?.map((element) => (
          <TableRowComponent
            // handleEdit={() => navigate(`/products/product/${element?.id}`)}
            row={element}
            key={element?.id}
          >
            {/* <TableCell>{element?.name.en}</TableCell>
            <TableCell>{element?.base_price}</TableCell>
            <TableCell>{element?.brand.name.en}</TableCell>
            <TableCell>{element?.category.name.en}</TableCell> */}
          </TableRowComponent>
        ))}
      </TableBodyComponent>
      <Paginator count={data?.last_page} disabled={loading} />
    </>
  );
};

export default withH1(Items, "Items");
