import React, { useState, useContext } from "react";
import TabPanel from "@mui/lab/TabPanel";
import Skeleton from "@mui/material/Skeleton";
import "./ourPartners.scss";
import AddIcon from "@mui/icons-material/Add";
import CreatePartner from "./createPartner";
import { Card, CardMedia, CardActionArea, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useGet } from "../../../hooks/useFetch";
import { deletePartner } from "../../../core/apis/store";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { AlertContext } from "../../../context/AlertContext";

const OurPartners = () => {
  const { setAlert } = useContext(AlertContext);
  const [showCreatePartner, setShowCreatePartner] = useState(false);
  const [partnerID, setPartnerID] = useState("");
  const [partnerDetail, setPartnerDetails] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  let url = "admin/partners";
  const { data: ourpartners, loading, refetch } = useGet({ url: url });
  let data = ourpartners?.data?.data;

  const handleToggleCreatePartner = () => {
    setShowCreatePartner(true);
  };

  const handleToggleEditPartner = (id) => {
    setShowCreatePartner(true);
    setPartnerID(id);
  };

  const handleTogglefalse = () => {
    setPartnerID(null);
    setShowCreatePartner(false);
    refetch();
  };

  const handleDeletePartner = (responseType, responseData) => {
    if (responseType === "yes") {
      deletePartner(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setPartnerDetails(null);
          refetch();
          setAlert({
            visible: true,
            text: res?.data?.success
              ? "Deleted Successfully"
              : res?.data?.message,
            type: res?.data?.success ? "success" : "error",
          });
        }
      });
    }
    setOpenDelete(false);
  };

  return (
    <div>
      {showCreatePartner ? (
        <CreatePartner
          onCancel={handleTogglefalse}
          onSave={handleTogglefalse}
          ID={partnerID}
        />
      ) : (
        <TabPanel value="2">
          <div className="store-title">Our Partners</div>
          <div className="our-partner">
            {loading
              ? Array(4)
                  .fill(0)
                  .map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      width={200}
                      height={200}
                      style={{ borderRadius: "8px" }}
                    />
                  ))
              : data?.map((element) => (
                  <Card
                    key={element.id}
                    onClick={() => handleToggleEditPartner(element.id)}
                    className={
                      element?.is_active
                        ? "partner-card"
                        : "greycard-background"
                    }
                    style={{ position: "relative" }} // Ensure the card is relatively positioned
                  >
                    <CardMedia
                      component="img"
                      image={
                        process.env.REACT_APP_IMAGE_URL +
                        element.colored_image_path
                      }
                      alt={element.name}
                      loading="lazy"
                      style={{
                        width: "90%",
                        objectFit: "contain",
                      }}
                    />
                    <IconButton
                      aria-label="delete"
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                        zIndex: 10, // Ensure the button is on top of other elements
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the card's onClick
                        setPartnerDetails(element); // Set the partner detail state
                        setOpenDelete(true); // Open the confirmation dialog
                      }}
                    >
                      <DeleteIcon sx={{ color: "red" }} />
                    </IconButton>
                  </Card>
                ))}
            {!loading && (
              <CardActionArea
                onClick={handleToggleCreatePartner}
                className="addPartner"
              >
                <AddIcon style={{ fontSize: 50, color: "#4A90E2" }} />
              </CardActionArea>
            )}
          </div>
        </TabPanel>
      )}
      {openDelete && (
        <NoticeConfirmation
          data={partnerDetail}
          handleResponse={handleDeletePartner}
        />
      )}
    </div>
  );
};

export default OurPartners;
