//MARILYNN
//UTILITIES
import React from "react";

//COMPONENT
import { DialogTitle, IconButton } from "@mui/material";
import { CloseSVG } from "../../assests/icons/SVG";

import "./TitleComponent.scss";

export function DialogCustomTitle(props) {
  const { title, onClose } = props;

  return (
    <DialogTitle>
      <div className="dialog-title-section">
        <div>{title}</div>
        <div>
          <IconButton aria-label="close" onClick={() => onClose()}>
            <CloseSVG />
          </IconButton>
        </div>
      </div>
    </DialogTitle>
  );
}
export default DialogCustomTitle;
