import React, { useContext, useState } from "react";
import { TableCell } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Paginator from "../../../components/paginator/Paginator";
import TableBodyComponent from "../../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../../components/table/tableBody/TableRowComponent";
import TableHeader from "../../../components/table/tableHeader/TableHeader";
import { useGet } from "../../../hooks/useFetch";
import "./Brands.scss";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { AlertContext } from "../../../context/AlertContext";
import { deleteBrand } from "../../../core/apis/brands";
import withH1 from "../../../assests/HOC/withH1";

const Brands = () => {
  const { setAlert } = useContext(AlertContext);
  const tableHeaders = [
    { title: "Name" },
    { title: "Ar" },
    { title: "Description" },
  ];
  const [searchParams] = useSearchParams();
  const [brandDetail, setBrandDetail] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  let navigate = useNavigate();
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };
  let url = `admin/get-all-brands`;
  const {
    data: brands,
    loading,
    refetch,
  } = useGet({ url: url, payload: payload });
  let data = brands?.data.data;

  //FUNCTIONS

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteBrand(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setBrandDetail(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  return (
    <>
      <TableHeader
        placeholder="Search by Brand Name"
        to="/brands/new-brand"
        filterButton={false}
      />
      <TableBodyComponent
        header={tableHeaders}
        loading={loading}
        total={data?.total}
      >
        {data?.data?.map((element) => (
          <TableRowComponent
            className="BrandDetails"
            handleDelete={() => {
              setBrandDetail(element);
              setOpenDelete(true);
            }}
            handleEdit={() => navigate(`/brands/brand/${element?.id}`)}
            row={element}
            key={element?.id}
          >
            <TableCell>{element?.name.en}</TableCell>
            <TableCell>{element?.name.ar}</TableCell>
            <TableCell>{element?.description?.en}</TableCell>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
      <Paginator count={data?.last_page} disabled={loading} />
      {openDelete && (
        <NoticeConfirmation
          data={brandDetail}
          handleResponse={handleDeleteResponse}
        />
      )}
    </>
  );
};

export default withH1(Brands, "Brands");
