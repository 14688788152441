// EXTERNAL LIBRARIES
import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  Autocomplete,
  Card,
  InputLabel,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useNavigate } from "react-router";

// STYLING
import "./AddNotification.scss";

// CUSTOM HOOKS
import { useGet } from "../../../../hooks/useFetch";
import { AlertContext } from "../../../../context/AlertContext";
// FORM
import { FormInput } from "../../../../components/formComponents/FormComponents";
import Button from "../../../../components/button/Button";
import { api } from "../../../../core/apis/main";
import NewEditor from "../../../../components/editor/NewEditor";

const AddNotification = () => {
  // DATA AND HOOKS
  let navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);

  const [isEmail, setIsEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [formValues, setFormValues] = useState({
    title: "",
    users: [],
    groups: [],
    description: "",
  });

  // API REUQESTS
  const {
    data: users,
    loadingUsers,
    refetch: refetchUsers,
  } = useGet({ url: "/admin/get-all-users" });

  const {
    data: groups,
    loadingGroups,
    refetch: refetchGroups,
  } = useGet({ url: "/admin/get-all-notification-groups" });

  // Sending data api req (using FormData)
  const sendData = async (form) => {
    setLoading(true);
    return await api
      .post(`admin/send-notification`, form)
      .then((res) => {
        if (res?.data?.success) {
          navigate("/emails-&-notifications");
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // FUNCTIONALITY
  // Handling form on submit and making api call (transforming into FormData)
  const handleForm = () => {
    let formData = new FormData();

    let arr1 = [];
    let arr2 = [];

    formValues.groups?.map((el) => {
      arr1.push(el.id);
    });

    formValues.users?.map((el) => {
      arr2.push(el.id);
    });

    formData.append("title", formValues.title);
    formData.append("description", formValues.description);
    formData.append("notification_type_id", isEmail ? 1 : 2);
    formData.append("user_groups", arr1);
    formData.append("users", arr2);

    sendData(formData);
  };

  // Disabling form on empty fields
  useEffect(() => {
    setDisabled(false);

    if (
      formValues.title === "" ||
      formValues.description === "" ||
      (formValues.users.length === 0 && formValues.groups.length === 0)
    ) {
      setDisabled(true);
    }
  }, [formValues]);

  return (
    <div className="notification">
      <h1>{isEmail ? "Add Email" : "Add Notification"}</h1>
      <Card className="notificationCard">
        <FormInput
          label="Title"
          placeholder="Enter title"
          name="title"
          value={formValues.title || ""}
          onChange={(e) =>
            setFormValues({ ...formValues, ["title"]: e.target.value })
          }
        />
        <div className="formInputWrapper">
          <InputLabel>Users</InputLabel>
          <Autocomplete
            multiple
            value={formValues.users || []}
            onChange={(_, action) =>
              setFormValues({ ...formValues, ["users"]: action })
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={users?.data?.data || []}
            getOptionLabel={(option) => option?.full_name || ""}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                placeholder="Select Multiple Users"
              />
            )}
          />
        </div>
        <div className="formInputWrapper">
          <InputLabel>User Groups</InputLabel>
          <Autocomplete
            multiple
            value={formValues.groups || []}
            onChange={(_, action) =>
              setFormValues({ ...formValues, ["groups"]: action })
            }
            options={groups?.data?.data || []}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option?.name || ""}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                placeholder="Select Groups"
              />
            )}
          />
        </div>
      </Card>
      {isEmail ? (
        <div className="editorNot">
          <NewEditor
            value={formValues.description}
            onChange={(e) => {
              setFormValues({ ...formValues, ["description"]: e });
            }}
          />
        </div>
      ) : (
        <Card className="notificationCard">
          <FormInput
            required
            label="Description"
            placeholder="Enter description"
            name="description"
            value={formValues.description || ""}
            onChange={(e) =>
              setFormValues({ ...formValues, ["description"]: e.target.value })
            }
          />
        </Card>
      )}
      <div className={isEmail ? "activeEmail" : ""}>
        <FormControlLabel
          control={
            <Switch
              checked={isEmail}
              onChange={(_, checked) => {
                setIsEmail(checked);
                setFormValues({
                  ...formValues,
                  ["description"]: "",
                });
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={<b style={{ color: "#4ba4f0" }}>{"Send Email"}</b>}
        />
      </div>
      <div className="action-footer">
        <Button
          name="Send Message"
          selected
          onClick={() => handleForm()}
          type="button"
          loading={loading}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default AddNotification;
