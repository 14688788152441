import React, { useContext } from "react";
import PhoneInput2 from "react-phone-input-2";
import "./PhoneInput.scss";
import "react-phone-input-2/lib/material.css";
import PropTypes from "prop-types";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import { CountryContext } from "../../context/CountryContext";
export const PhoneInput = (props) => {
  const { countries, country } = useContext(CountryContext);
  const { phoneRef, onChange, value, className, helperText } = props;
  const { required, noLabel } = props;

  return (
    <div className={className ? className : ""}>
      {!noLabel && (
        <InputLabel sx={{ margin: "0 0 8px" }}>
          Contact Number
          <span className="required-start">{required ? " * " : ""}</span>
        </InputLabel>
      )}
      <PhoneInput2
        containerClass="phoneInput"
        inputClass="inputClass"
        buttonClass="buttonClass"
        ref={phoneRef}
        enableSearch
        country={country.country_code?.toLowerCase()}
        preferredCountries={["lb", "ae", "qa"]}
        onChange={onChange(countries)}
        value={value}
        enableAreaCodeStretch
        masks={{ lb: ".. ... ...", qa: ".... ...." }}
      />
      {helperText && (
        <FormControl error>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    </div>
  );
};
PhoneInput.defaultProps = {
  label: { code: "Code", number: "Contact Number" },
};
