import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

//COMPONENTS
import Paginator from "../../components/paginator/Paginator";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import TableHeader from "../../components/table/tableHeader/TableHeader";
import BasicTabs from "../../components/Tabs/Tabs";

import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { TableCell } from "@mui/material";
import withH1 from "../../assests/HOC/withH1";
import RibbonHandle from "../ribbons/RibbonDetails/RibbonHandle";
import CircularProgress from "@mui/material/CircularProgress";

//CSS
import "./Ribbons.scss";
//CUSTOM HOOKS
import { useTranslation } from "react-i18next";
import { AlertContext } from "../../context/AlertContext";
import { useGlobalValues } from "../../context/GlobalContext";

//API
import { deleteRibbon, getAllRibbons } from "../../core/apis/ribbon";

const Ribbons = () => {
  let navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState(false);
  const [element, setElement] = useState(null);

  const { enabledLanguages } = useGlobalValues();
  const [value, setValue] = useState("en");
  const { t, i18n } = useTranslation();

  const tableHeaders = [{ title: t("Description") }, { title: t("Status") }];

  let payload = {
    per_page: 10,
    page: +searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["ribbons", payload],
    queryFn: () => {
      return getAllRibbons(payload).then((res) => res?.data?.data);
    },
  });

  const handleDelete = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteRibbon(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setElement(null);
          if (+searchParams.get("page") === 1 || !searchParams.get("page")) {
            refetch(); //in case page is 1 navigate to ribbons will not refetch data
          } else {
            navigate("/ribbons");
          }
        }
        setAlert({
          visible: true,
          text: res.data?.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpen(false);
    setElement(null);
  };

  const handleChange = (event, newValue) => {
    i18n.changeLanguage(newValue);
    setValue(newValue);
  };
  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  return (
    <>
      <BasicTabs
        handleChange={handleChange}
        tabsList={enabledLanguages}
        value={value}
        children={
          <div className={value != "ar" ? "" : "arabic-component"}>
            <TableHeader
              placeholder="Search by description"
              add={() => setOpenModal(true)}
              filterButton={false}
            />
            <TableBodyComponent
              isArabic={value == "ar" ? true : false}
              header={tableHeaders}
              loading={isLoading}
              total={data?.total}
            >
              {data?.data?.map((element) => (
                <TableRowComponent
                  handleEdit={() => {
                    setElement(element);
                    setOpenModal(true);
                  }}
                  row={element}
                  key={element?.id}
                  handleDelete={() => {
                    setElement(element);
                    setOpen(true);
                  }}
                >
                  <TableCell className={value != "ar" ? " " : "textAlighRight"}>
                    {element?.description?.[value]
                      ? element?.description?.[value]
                      : element?.description?.en}
                  </TableCell>
                  <TableCell className={value != "ar" ? " " : "textAlighRight"}>
                    {element?.is_active ? "active" : "inactive"}
                  </TableCell>
                </TableRowComponent>
              ))}
            </TableBodyComponent>
          </div>
        }
      />
      <Paginator count={data?.last_page} disabled={isLoading} />
      {open && (
        <NoticeConfirmation data={element} handleResponse={handleDelete} />
      )}

      {openModal && (
        <RibbonHandle
          element={element}
          refetch={refetch}
          languageValue={value}
          onClose={() => {
            setElement(null);
            setOpenModal(false);
          }}
        />
      )}
    </>
  );
};

export default withH1(Ribbons, "Ribbons");
