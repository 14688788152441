import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Paper, Box, Tab, Divider } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Chip from "@mui/material/Chip";
import "./homePage.scss";
//Importings Tabs
import MenuEditor from "../../pages/StoreCustomization/menuEditor/menuEditor";
import OurPartners from "../../pages/StoreCustomization/ourPartners/ourPartners";
import LawandOrders from "../../pages/StoreCustomization/lawandOrders/lawandordersHomepage";
import FAQs from "./FAQs/FAQs";
import Blogs from "./Blogs/blogs";
//Icons
import WidgetsIcon from "@mui/icons-material/Widgets";
import StoreIcon from "@mui/icons-material/Store";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import QuizIcon from "@mui/icons-material/Quiz";
import BookIcon from "@mui/icons-material/Book";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import { useGlobalValues } from "../../context/GlobalContext";
import Sellmydevice from "./Sellmydevice/sellmydevice";
import Storesection from "./StoreSection/storesection";
import BecomeAPartner from "./Become_A_Partner/becomeaPartner";
import ContactUs from "./Contact/contactUs";
import Footer from "./Footer/footer";
import Aboutus from "./About/about";
import BlogsSection from "./Blogs/blogsSection";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import BadgeIcon from "@mui/icons-material/Badge";
import BlogsTab from "./Blogs/blogsTab";
import Banners from "./Banners/banners";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";

const HomePage = () => {
  const [tabValue, setTabValue] = useState("0");
  const navigate = useNavigate();
  const location = useLocation();

  const { enabledLanguages, storeLanguage, setstoredefaultLanguage } =
    useGlobalValues();

  const handlelanguagechange = (value) => {
    setstoredefaultLanguage(value);
  };

  useEffect(() => {
    const pathToValueMap = {
      "/store-customizations/sell-my-device": "0",
      "/store-customizations/store": "1",
      "/store-customizations/partners": "2",
      "/store-customizations/become-a-partner": "5",
      "/store-customizations/contact-us": "6",
      "/store-customizations/about-us": "7",
      "/store-customizations/blogs": "8",
      "/store-customizations/faqs": "9",
      "/store-customizations/lawandorder": "10",
      "/store-customizations/footer": "11",
      "/store-customizations/settings": "12",
      "/store-customizations/blogssection": "13",
      "/store-customizations/banners": "14",
    };

    setTabValue(pathToValueMap[location.pathname] || "0");
  }, [location.pathname]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    const valueToPathMap = {
      0: "/store-customizations/sell-my-device",
      1: "/store-customizations/store",
      2: "/store-customizations/partners",
      5: "/store-customizations/become-a-partner",
      6: "/store-customizations/contact-us",
      7: "/store-customizations/about-us",
      8: "/store-customizations/blogs",
      9: "/store-customizations/faqs",
      10: "/store-customizations/lawandorder",
      11: "/store-customizations/footer",
      12: "/store-customizations/settings",
      13: "/store-customizations/blogssection",
      14: "/store-customizations/banners",
    };

    navigate(valueToPathMap[newValue]);
  };

  return (
    <Paper elevation={24} className="paper-div-store">
      <div className="header-detail">
        <span>
          <i class="fa fa-cog icon-margin " aria-hidden="true"></i>Store
          Customization
        </span>

        <span>
          {enabledLanguages.map((item, index) => (
            <Chip
              label={item.name}
              variant={storeLanguage != item.code ? "outlined" : ""}
              onClick={() => handlelanguagechange(item.code)}
              value={item.code}
              className={
                storeLanguage != item.code ? "en-chip" : "en-chip color-white"
              }
              color="primary"
              key={index}
            />
          ))}
        </span>
      </div>

      {/* Divider between title and tabs */}
      <Divider sx={{ marginBottom: 2 }} />

      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab
              label="Banners"
              value="14"
              icon={<ViewCarouselIcon />}
              iconPosition="start"
            />

            <Tab
              label="Sell My device"
              value="0"
              icon={<WidgetsIcon />}
              iconPosition="start"
            />
            <Tab
              label="Store"
              value="1"
              icon={<StoreIcon />}
              iconPosition="start"
            />
            <Tab
              label="Partners"
              value="2"
              icon={<GroupAddIcon />}
              iconPosition="start"
            />
            {/* <Tab label="Services" value="3" />
            <Tab label="Business" value="4" /> */}
            <Tab
              label="Become a partner"
              value="5"
              icon={<SupervisedUserCircleIcon />}
              iconPosition="start"
            />
            <Tab
              label="Contact"
              value="6"
              icon={<PermContactCalendarIcon />}
              iconPosition="start"
            />
            <Tab
              label="About"
              value="7"
              icon={<AutoAwesomeMosaicIcon />}
              iconPosition="start"
            />
            <Tab
              label="Blogs"
              value="8"
              icon={<CollectionsBookmarkIcon />}
              iconPosition="start"
            />
            <Tab
              label="FAQs"
              value="9"
              icon={<QuizIcon />}
              iconPosition="start"
            />
            <Tab
              label="Law&Order"
              value="10"
              icon={<LocalPoliceIcon />}
              iconPosition="start"
            />
            <Tab
              label="Footer"
              value="11"
              icon={<DisplaySettingsIcon />}
              iconPosition="start"
            />
            <Tab
              label="Settings"
              value="12"
              icon={<WidgetsIcon />}
              iconPosition="start"
            />
          </TabList>
        </Box>

        <TabPanel value="0" style={{ minHeight: "600px", padding: "0px" }}>
          <Sellmydevice></Sellmydevice>
        </TabPanel>
        <TabPanel value="1" style={{ minHeight: "600px", padding: "0px" }}>
          <Storesection></Storesection>
        </TabPanel>
        <TabPanel value="2" style={{ minHeight: "600px", padding: "0px" }}>
          <OurPartners></OurPartners>
        </TabPanel>
        <TabPanel value="5" style={{ minHeight: "600px", padding: "0px" }}>
          <BecomeAPartner></BecomeAPartner>
        </TabPanel>
        <TabPanel value="6" style={{ minHeight: "600px", padding: "0px" }}>
          <ContactUs></ContactUs>
        </TabPanel>
        <TabPanel value="7" style={{ minHeight: "600px", padding: "0px" }}>
          <Aboutus></Aboutus>
        </TabPanel>
        <TabPanel value="10" style={{ minHeight: "600px", padding: "0px" }}>
          <LawandOrders></LawandOrders>
        </TabPanel>
        <TabPanel value="9" style={{ minHeight: "600px", padding: "0px" }}>
          <FAQs></FAQs>
        </TabPanel>
        <TabPanel value="8" style={{ minHeight: "600px", padding: "0px" }}>
          <BlogsTab />
        </TabPanel>
        <TabPanel value="11" style={{ minHeight: "600px", padding: "0px" }}>
          <Footer></Footer>
        </TabPanel>
        <TabPanel value="12" style={{ minHeight: "600px", padding: "0px" }}>
          <MenuEditor></MenuEditor>
        </TabPanel>
        <TabPanel value="13" style={{ minHeight: "600px", padding: "0px" }}>
          <BlogsSection></BlogsSection>
        </TabPanel>
        <TabPanel value="14" style={{ minHeight: "600px", padding: "0px" }}>
          <Banners></Banners>
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

export default HomePage;
