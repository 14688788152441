import {
  styled,
  TextField,
  InputBase,
  createTheme,
  Dialog,
} from "@mui/material";
import palette from "./color.scss";

export const NoticeDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    borderRadius: "25px",
  },
  "& .MuiDialogTitle-root": {
    border: "none",
    padding: "unset",
  },
});

export const differenttheme = createTheme({
  palette: {
    primary: {
      main: "#4ba4f0",
    },
    secondary: {
      main: "#041c3f",
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "red",
          margin: "5px 0px 0px 0px",
        },
      },
    },
  },
});
export const theme = createTheme({
  palette: {
    primary: {
      main: "#4ba4f0",
    },
    secondary: {
      main: "#041c3f",
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "red",
          margin: "5px 0px 0px 0px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: "black",
          fontWeight: "bold",
        },
        label: {
          fontSize: "14px",
          "@media (max-width:1501px)": { fontSize: "14px" },
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              border: `1px solid  ${palette.grey}`,
            },
          },

          input: {
            // backgroundColor: palette.whiteColor,
            "&::placeholder": {
              color: palette.grey,
              opacity: "1",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "black"
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // borderBottom: "1px solid #041C3F",
          margin: "0 !important",
          "&::before": {
            borderBottom: "1px solid #041C3F !important",
          },
          "&::after": {
            borderBottom: "1px solid #4ba4f0 !important",
          },
        },
        input: {
          "&:focus": {
            backgroundColor: "white !important",
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: "white",
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: { paddingRight: "0 !important" },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: { width: "100%" },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.6) ",
          padding: "3px 9px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "3px 9px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },

    MuiBadge: {
      styleOverrides: {
        badge: {
          color: "white",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "white !important",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: "0 !important",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 4px rgb(222 228 237 / 80%) !important",
          "&:not(:last-child)": {
            borderBottom: 0,
          },
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            borderBottom: "1px solid #C4C4C4",
          },
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          textOverflow: "ellispsis",
          overflowX: "hidden",
          whiteSpace: "nowrap",
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          border: "none",
          borderRadius: "25px",
          color: palette.primaryColor,
          fontWeight: 400,
          fontSize: "18px",
          padding: "2px 8px",
        },
        body: {
          padding: "0px 8px",
          height: "50px",
          fontWeight: "400",
          fontSize: "14px",
          maxWidth: "300px",
          textTransform: "capitalize",
        },
        root: {
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
});

export const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "focusColor",
})((p) => ({
  // input label when focused
  "& label.Mui-focused": {
    color: p.focusColor,
    borderBottom: "1px solid red",
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: p.focusColor,
    borderBottom: "1px solid #86b7fe",
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: p.focusColor,
    borderBottom: "1px solid #86b7fe",
  },

  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: p.focusColor,
    },
  },
}));
