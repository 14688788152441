//UTILITIES
import React, { useContext, useEffect, useState } from "react";

//COMPONENT

import { useForm, Controller } from "react-hook-form";
import Button from "../../../components/button/Button";
import { DownSVG, PlusVectorSVG } from "../../../assests/icons/SVG";
import ErrorIcon from "@mui/icons-material/Error";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { Button as ButtonMui, CardContent, Divider } from "@mui/material";
import { Accordion, AccordionDetails, Skeleton } from "@mui/material";
import { AccordionSummary, Typography, Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
//CSS
import "./TransactionDetails.scss";
import {
  FormDropdownList,
  FormInput,
} from "../../../components/formComponents/FormComponents";
import { useParams } from "react-router";
import { useGet } from "../../../hooks/useFetch";
import InspectionHandle from "./InspectionHandle";
import { changeItemAction } from "../../../core/apis/item";
import TransactionItemPlacement from "./TransactionItemPlacement";
import { AlertContext } from "../../../context/AlertContext";
import { width } from "@mui/system";

const HandleSchema = yup.object({
  next_action_id: yup.object().nullable(),
  inspection_id: yup
    .object()
    .nullable()
    .when("next_action_id", {
      is: (value) => value?.id == 10,
      then: yup.object().nullable().required("Field is required"),
      otherwise: yup.object().nullable(),
    }),
});

const TransactionSellDetails = () => {
  let { id } = useParams();
  //API
  const {
    data: transactionDetail,
    loading,
    refetch,
  } = useGet({
    url: `admin/get-transaction-by-id/${id}`,
  });
  let transactionData = transactionDetail?.data.data;
  //VARIABLES
  const { setAlert } = useContext(AlertContext);
  const [historical, setHistorical] = useState(false);
  const [openItemPlacement, setOpenItemPlacement] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [openNewInspection, setOpenNewInspection] = useState(false);
  const [decisionArray, setDecisionArray] = useState([]);

  const [openCollect, setopenCollect] = useState(false);

  const [collectLoading, setCollectLoading] = useState(false);
  const [collectbyname, setCollectedName] = useState("");

  const [userType, setuserType] = useState("");

  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  //FUNCTIONS
  const handleSubmitDecisionForm = (formData) => {
    console.log(formData);

    const inspectionName = formData?.inspection_id?.name ?? "";

    changeItemAction({
      id: transactionData?.inspection?.[0]?.item_id,
      next_action_id:
        userType == 5 && inspectionName?.toLowerCase()?.includes("offer")
          ? 10
          : formData?.next_action_id?.id,
      inspection_id: formData?.inspection_id?.id,
    }).then((response) => {
      if (response?.data.success) {
        refetch();
        reset({ decision: null });
      }
      setAlert({
        visible: true,
        text: response.data.message,
        type: response.data.success ? "success" : "error",
      });
    });
  };

  const handleCollect = () => {
    setCollectLoading(true);
    changeItemAction({
      id: transactionData?.inspection[0]?.item_id,
      action_taken_by: collectbyname,
      next_action_id: 2,
    }).then((response) => {
      if (response?.data.success) {
        refetch();
        setCollectLoading(false);
        setopenCollect(false);
      } else {
        setCollectLoading(false);
        setopenCollect(false);
      }
      setAlert({
        visible: true,
        text: response.data.message,
        type: response.data.success ? "success" : "error",
      });
    });
  };

  const handleCollectDevice = () => {
    setopenCollect(true);
  };

  useEffect(() => {
    let array = [];
    if (transactionData) {
      transactionData?.inspection?.map((el, index) => {
        array.push({
          name: transactionData?.user?.branch_id
            ? `Accept  inspection ${el?.id}`
            : `Offer inspection ${el?.id}`,
          id: el?.id,
        });
      });
      setuserType(transactionData.user.user_type_id);
      setDecisionArray(array);
      setHistorical(
        transactionData?.transaction_status_id === 3 ? true : false
      );
    }
  }, [transactionData]);

  return (
    <>
      <div className="transaction-detail-component">
        {!loading ? (
          <>
            <h1>Transaction Details</h1>
            <div className="header">
              <FormInput
                value={transactionData?.transaction_status?.name?.en}
                disabled
                variant="outlined"
              />
            </div>
            <div className="body">
              {userType != 5 && (
                <div className="transaction-description">
                  <div className="transaction-detail-item">
                    <div className="sub-title">Organization Details</div>
                    <div>
                      <Card>
                        <CardContent>
                          <div className="details-item">
                            <div className="title">Organization Name</div>
                            <div className="body">
                              {
                                transactionData?.user?.branch?.organization
                                  ?.organization?.name?.en
                              }
                            </div>
                          </div>
                          <div className="details-item">
                            <div className="title">Branch Name</div>
                            <div className="body">
                              {transactionData?.user?.branch?.name}
                            </div>
                          </div>
                          <div className="details-item">
                            <div className="title">Address</div>
                            <div className="body">
                              {transactionData?.address?.address_line}
                            </div>
                          </div>
                          <div className="details-item">
                            <div className="title">User </div>
                            <div className="body">
                              {transactionData?.user?.full_name}
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                  {transactionData?.shipping_id && (
                    <div className="transaction-detail-item">
                      <div className="sub-title">Shipping Details</div>
                      <div>
                        <Card>
                          <CardContent>
                            <div className="details-item">
                              <div className="title">Contact Name</div>
                              <div className="body">
                                {
                                  transactionData?.shipping?.address
                                    ?.contact_name
                                }
                              </div>
                            </div>
                            <div className="details-item">
                              <div className="title">Phone Number</div>
                              <div className="body">
                                {
                                  transactionData?.shipping?.address
                                    ?.contact_number
                                }
                              </div>
                            </div>
                            <div className="details-item">
                              <div className="title">Address</div>
                              <div className="body">
                                {
                                  transactionData?.shipping?.address
                                    ?.address_line
                                }
                              </div>
                            </div>
                            <div className="details-item">
                              <div className="title">Country</div>
                              <div className="body">
                                {
                                  transactionData?.shipping?.address?.country
                                    ?.name
                                }
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                  )}

                  <div className="transaction-detail-item">
                    <div className="sub-title">Transaction Summary</div>
                    <div>
                      <Card>
                        <CardContent>
                          <div className="details-item">
                            <div className="title">
                              Transaction Creation Date
                            </div>
                            <div className="body">
                              {moment(transactionData?.created_at).format(
                                "DD-MM-YYYY"
                              )}
                            </div>
                          </div>
                          <div className="details-item">
                            <div className="title">Transaction Time</div>
                            <div className="body">
                              {moment(transactionData?.created_at).format(
                                "HH:mm"
                              )}
                            </div>
                          </div>
                          <div className="details-item">
                            <div className="title">Subtotal</div>
                            <div className="body">
                              {transactionData?.inspection?.find(
                                (el) => el.is_selected === true
                              )?.inspection_value
                                ? Math.round(
                                    transactionData?.inspection?.find(
                                      (el) => el.is_selected === true
                                    )?.inspection_value
                                  )
                                : ""}{" "}
                              {transactionData?.currency?.code}
                            </div>
                          </div>
                          <div className="details-item">
                            <div className="title">Delivery Method</div>
                            <div className="body">
                              {transactionData?.delivery_method?.name?.en}
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </div>
              )}
              <div className="shipping-details">
                <div className="sub-title">Customer Details</div>
                <div>
                  <Card>
                    <CardContent>
                      <div className="shipping-body-item">
                        <div className="title">Full Name</div>
                        <div className="body">
                          {userType == 5
                            ? transactionData.user.full_name
                            : transactionData?.fill_form?.full_name}
                        </div>
                      </div>

                      {userType != 5 && (
                        <div className="shipping-body-item">
                          <div className="title">ID / Passport</div>
                          <div className="body">
                            {transactionData?.fill_form?.id_or_passport_number}
                          </div>
                        </div>
                      )}

                      <div className="shipping-body-item">
                        <div className="title">Phone Number</div>
                        <div className="body">
                          {userType == 5
                            ? transactionData.user.phone_number
                            : transactionData?.fill_form?.phone_number}
                        </div>
                      </div>
                      <div className="shipping-body-item">
                        <div className="title">Email</div>
                        <div className="body">
                          {userType == 5
                            ? transactionData.user.email
                            : transactionData?.fill_form?.email}
                        </div>
                      </div>
                      <div className="shipping-body-item">
                        <div className="title">Address</div>
                        <div className="body">
                          {userType == 5
                            ? transactionData?.shipping?.address?.address_line
                            : transactionData?.fill_form?.address_line}
                        </div>
                      </div>
                      {transactionData?.fill_form?.IBAN && (
                        <div className="shipping-body-item">
                          <div className="title">IBAN Number</div>
                          <div className="body">
                            {transactionData?.fill_form?.IBAN}
                          </div>
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </div>
              </div>

              <div className="inspections-summary">
                <div className="insp-header">
                  <div className="sub-title">Inspection Summary</div>
                  {/* //PROPOSE OFFER */}
                  {transactionData?.next_action?.some(
                    (action) => action?.id === 10
                  ) && (
                    <div>
                      <ButtonMui
                        variant="text"
                        startIcon={<PlusVectorSVG fill="#4BA4F0" />}
                        onClick={() => setOpenNewInspection(true)}
                      >
                        Add new inspection
                      </ButtonMui>
                    </div>
                  )}
                </div>

                <div className="inspections-list">
                  {transactionData?.inspection?.map((el, index) => (
                    <Accordion className="inspection-accordion">
                      <AccordionSummary
                        expandIcon={<DownSVG />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="inspection-header">
                          <Typography>{`Inspection ${el?.id}`}</Typography>
                          <div>
                            {"Done by "}
                            {el?.is_admin_inspection ? "admin" : "user"}
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="inspection-question-element">
                          <div className="inspection-element">
                            Inspection ID
                          </div>
                          <div className="inspection-element only-answer">
                            {el?.id}
                          </div>
                        </div>
                        <div className="inspection-question-element">
                          <div className="inspection-element">
                            Inspection Value
                          </div>
                          <div className="inspection-element only-answer">
                            {el?.inspection_value &&
                              Math.round(el?.inspection_value) +
                                " " +
                                transactionData?.currency?.code}
                          </div>
                        </div>
                        <div className="inspection-question-element">
                          <div className="inspection-element">Done by</div>
                          <div className="inspection-element only-answer">
                            {el?.is_admin_inspection ? "Admin" : "User"}
                          </div>
                        </div>
                        <div className="inspection-question-element">
                          <div className="inspection-element">Condition</div>
                          <div className="inspection-element only-answer">
                            {el?.condition?.name?.en}
                          </div>
                        </div>
                        <div className="inspection-question-element">
                          <div className="inspection-element">Product</div>
                          <div className="inspection-element only-answer">
                            {el?.item?.product?.name?.en}
                          </div>
                        </div>
                        {el?.question_answer.map((questionElement) => (
                          <div className="inspection-question-element">
                            <div className="inspection-element">
                              {questionElement?.question?.question?.en}
                            </div>
                            <div className="inspection-element only-answer">
                              {questionElement?.answer?.en}
                            </div>
                          </div>
                        ))}
                        {el?.attribute_value.map((attributeElement) => (
                          <div className="inspection-question-element">
                            <div className="inspection-element">
                              {attributeElement?.attribute?.name?.en}
                            </div>
                            <div className="inspection-element only-answer">
                              {attributeElement?.name?.en}
                            </div>
                          </div>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>

                {!historical &&
                  transactionData?.next_action?.length !== 0 &&
                  transactionData?.next_action[0]?.id != 2 &&
                  transactionData?.transaction_status_id !== 3 && (
                    <form onSubmit={handleSubmit(handleSubmitDecisionForm)}>
                      <div className="inspections-actions">
                        <div style={{ width: "30%" }}>
                          {decisionArray?.length < 2 && (
                            <Controller
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <FormDropdownList
                                  variant="outlined"
                                  data={
                                    transactionData?.user?.branch_id
                                      ? transactionData?.next_action?.filter(
                                          (action) => action.id !== 10
                                        )
                                      : transactionData?.next_action
                                  }
                                  loading={false}
                                  clearErrors={clearErrors}
                                  formComponent={false}
                                  name="next_action_id"
                                  placeholder={"Choose your decision"}
                                  value={value}
                                  dependencyError={[
                                    "next_action_id",
                                    "inspection_id",
                                  ]}
                                  onChange={(e) => {
                                    onChange(e);
                                    setValue(
                                      "inspection_id",
                                      transactionData?.inspection[0]
                                    );
                                  }}
                                  helperText={error?.message}
                                />
                              )}
                              name="next_action_id"
                              control={control}
                            />
                          )}
                        </div>
                        {decisionArray?.length > 1 &&
                          transactionData?.next_action?.some(
                            (action) => action.id == 10
                          ) && (
                            <div style={{ width: "30%" }}>
                              <Controller
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <FormDropdownList
                                    required
                                    variant="outlined"
                                    data={decisionArray}
                                    noOptionsMessage={"No offer available"}
                                    loading={false}
                                    formComponent={false}
                                    clearErrors={clearErrors}
                                    name="inspection_id"
                                    placeholder={"Choose inspection"}
                                    value={value}
                                    dependencyError={["inspection_id"]}
                                    onChange={(e) => {
                                      onChange(e);
                                      setValue(
                                        "next_action_id",
                                        transactionData?.next_action?.filter(
                                          (action) => action.id == 21
                                        )[0]
                                      );
                                    }}
                                    helperText={error?.message}
                                  />
                                )}
                                name="inspection_id"
                                control={control}
                              />
                            </div>
                          )}

                        <Button
                          name={"Submit"}
                          selected
                          loading={saveLoading}
                          // width={"30%"}
                          type="submit"
                        />
                      </div>
                    </form>
                  )}
                {!historical && transactionData?.next_action[0]?.id == 2 && (
                  <div className="inspections-actions">
                    <Button
                      variant="outlined"
                      selected
                      style={{ width: "150px" }}
                      name={"Collect"}
                      onClick={() => handleCollectDevice()}
                      endIcon={<WidgetsIcon />}
                    >
                      Collect
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <Skeleton count={10} />
        )}
      </div>
      {openNewInspection && (
        <InspectionHandle
          onClose={() => setOpenNewInspection(false)}
          data={transactionData}
          refetchData={() => {
            refetch();
            reset({
              next_action_id: null,
              inspection_id: null,
            });
          }}
        />
      )}
      {openItemPlacement && (
        <TransactionItemPlacement
          action={getValues("next_action_id")}
          data={transactionData}
          refetchData={() => {
            refetch();
            reset({
              next_action_id: null,
              inspection_id: null,
            });
          }}
          onClose={() => setOpenItemPlacement(false)}
        />
      )}

      <Dialog
        open={openCollect}
        onClose={() => setopenCollect(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "#ff0000",
            justifyContent: "center",
            textAlign: "center",
            boxShadow: "0px 0px 4px rgb(222 228 237 / 80%) !important",
          }}
        >
          <ErrorIcon style={{ fontSize: "50px" }} />
        </DialogTitle>
        <DialogContent style={{ minWidth: "500px" }}>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center" }}
          >
            {`Are you sure you want to Collect This Device`}

            <p style={{ fontWeight: "500", color: "#000", margin: "5px 0px" }}>
              {transactionData?.barcode}
            </p>
          </DialogContentText>
          <div>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormInput
                  required
                  type="text"
                  placeholder="Please Enter your Name"
                  name="collected_by"
                  value={value}
                  onChange={(e) => setCollectedName(e.target.value)}
                  helperText={error?.message}
                  hideVariant={true}
                />
              )}
              name={`collected_by`}
              control={control}
            />
          </div>
        </DialogContent>
        <Divider style={{ color: "#4ba4f0" }} />
        <DialogActions style={{ justifyContent: "center", display: "flex" }}>
          <Button
            name={"Collect"}
            selected
            loading={collectLoading}
            onClick={() => handleCollect()}
          />

          <Button
            name={"Cancel"}
            selected
            style={{ backgroundColor: "#ff0000" }}
            onClick={() => setopenCollect(false)}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TransactionSellDetails;
