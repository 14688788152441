import React from "react";
import { SearchSVG } from "../../assests/icons/SVG";
import "./Search.scss";
const Search = (props) => {
  const { placeholder, onChange, submit, className, curved, value, name } =
    props;

  return (
    <div
      className={`searchComponent ${className || ""} ${
        curved ? "curvedSearchComponent" : ""
      }`}
    >
      <input
        type="text"
        {...props}
        curved={null}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      <SearchSVG className="searchIcon" onClick={submit} />
    </div>
  );
};
Search.defaultProps = {
  placeholder: "Search here...",
  curved: false,
};
export default Search;
