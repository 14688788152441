//UTILITIES
import React, { useState, useEffect, useContext } from "react";

//COMPONENT
import Button from "../../../components/button/Button";
import {
  getAllCategories,
  getCategoryDetails,
  moveCategory,
} from "../../../core/apis/category";
import {
  Card,
  Breadcrumbs,
  ButtonBase,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
} from "@mui/material";

import { useGet } from "../../../hooks/useFetch";
import { CloseSVG } from "../../../assests/icons/SVG";

//CSS
import "./Categories.scss";
import { useNavigate } from "react-router";

export function MoveCategoryHandle(props) {
  const { data, onClose, refetchData } = props;
  let navigate = useNavigate();
  const [loadingCircle, setLoadingCircle] = useState(false);
  const [loadingMove, setLoadingMove] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [selectedParentId, setSelectedParentId] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  // const handleBreadcrumbs = (id) => {
  //   setLoadingCircle(true);
  //   setSelectedParentId(id || null);
  //   (id ? getCategoryDetails(id) : getAllCategories()).then((res) => {
  //     let ctg = res?.data?.data?.category;
  //     setAllCategories(id ? ctg?.child || [] : res?.data?.data?.categories);
  //     id
  //       ? setBreadcrumbs([
  //           { id: ctg?.id, name: ctg?.name.en },
  //           ...res?.data?.data?.ascendants,
  //         ])
  //       : setBreadcrumbs([]);
  //     setLoadingCircle(false);
  //   });
  // };

  const handleBreadcrumbs = (id) => {
    setLoadingCircle(true);
    setSelectedParentId(id || null);

    if (id) {
      getCategoryDetails(id).then((res) => {
        if (res?.data?.success) {
          let ctg = res?.data?.data?.category;
          setBreadcrumbs([
            { id: ctg?.id, name: ctg?.name.en },
            ...res?.data?.data?.ascendants,
          ]);

          getAllCategories({
            parent_category_id: id,
          }).then((res) => {
            setAllCategories(res?.data?.data?.categories || []);
            setLoadingCircle(false);
          });
        }
      });
    } else {
      getAllCategories().then((res) => {
        if (res?.data?.success) {
          setBreadcrumbs([]);
          setAllCategories(res?.data?.data?.categories || []);
        }

        setLoadingCircle(false);
      });
    }
  };

  const handleMove = (e) => {
    setLoadingMove(true);

    moveCategory(data?.id, selectedParentId).then((res) => {
      setLoadingMove(false);
      if (res?.data?.success) {
        onClose();
        if (!selectedParentId) {
          navigate(`/categories`);
        } else {
          navigate(`/categories?sub=${selectedParentId}`);
        }
      }
    });
  };

  useEffect(() => {
    if (data?.id) {
      setLoadingData(true);
      getCategoryDetails(data?.id).then((res) => {
        if (res?.data?.success) {
          setBreadcrumbs([...res?.data?.data?.ascendants]);
          setSelectedParentId(null);
          getAllCategories({
            parent_category_id: res?.data?.data?.ascendants?.[0]?.id,
          }).then((res) => {
            setAllCategories(res?.data?.data?.categories || []);
            setLoadingData(false);
          });
        }
      });
    }
  }, [data]);

  return (
    <Dialog disablebackdropclick="true" fullWidth open={true}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {`Move ${data?.name?.en} to...`}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseSVG />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {!loadingData ? (
          <div className={`parentIdChange `}>
            <div className="top">
              <Breadcrumbs separator=">">
                <ButtonBase onClick={() => handleBreadcrumbs()}>
                  Categories
                </ButtonBase>

                {[...breadcrumbs]?.reverse()?.map((item, index) => (
                  <ButtonBase
                    key={item.id}
                    onClick={() => handleBreadcrumbs(item?.id)}
                    disabled={index === breadcrumbs.length - 1}
                    style={{
                      color: index === breadcrumbs.length - 1 ? "#4ba4f0" : "",
                    }}
                  >
                    {item.name}
                  </ButtonBase>
                ))}
              </Breadcrumbs>
              {loadingCircle && <CircularProgress size={20} />}
            </div>
            {!loadingCircle ? (
              allCategories?.length !== 0 && (
                <div className="categoryList">
                  {allCategories?.map((item) => (
                    <ButtonBase
                      className="category-list-item"
                      key={item.id}
                      onClick={() => handleBreadcrumbs(item?.id)}
                      disabled={
                        +item.id === +data.id || item?.product_count !== 0
                      }
                    >
                      {item?.name?.en}
                      <span className="category-product-count">
                        {item?.product_count !== 0
                          ? ` ${item?.product_count} products`
                          : ""}
                      </span>
                    </ButtonBase>
                  ))}
                </div>
              )
            ) : (
              <Skeleton count={10} />
            )}
            <div className="action-footer">
              <Button
                name={"Move"}
                selected
                // in all kind of loading the button should be disabled
                // if the category is already a parent , it could not be parent in the new state again
                // if we are moving the category to it;s original category
                //if it's the same category ; no need to move (error backend maybe not handled check anthony)
                //we have to add here later that if && breadcrumb length !==0
                //to let the user move to a parent category
                disabled={
                  loadingMove ||
                  loadingCircle ||
                  // breadcrumbs?.length !== 0 ||
                  (data?.parent_id && data?.parent_id === breadcrumbs[0]?.id) ||
                  (!data?.parent_id && !selectedParentId)
                }
                loading={loadingMove}
                width={"30%"}
                onClick={() => handleMove()}
              />
            </div>
          </div>
        ) : (
          <Skeleton count={10} />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default MoveCategoryHandle;
