// EXTERNAL LIBRARIES
import { Card, CardContent } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { AlertContext } from "../../../context/AlertContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { api } from "../../../core/apis/main";
// STYLING
import "./DiscountDetails.scss";
import Spinner from "../../../components/MUI-skeleton/Spinner";

// FORM
import {
  FormInput,
  FormMultiSelect,
  FormSwitch,
  FormCheckBox,
} from "../../../components/formComponents/FormComponents";
import Button from "../../../components/button/Button";

// CUSTOM HOOKS
import { useAxios, useGet } from "../../../hooks/useFetch";
import { createDiscount, updateDiscount } from "../../../core/apis/discount";

const HandleSchema = yup.object({
  name: yup.string().required("Field is required"),
  value: yup.number().nullable().required("Field is required"),
  activate: yup.bool(),
  discount_type_ids: yup.array().required("Field is required"),
  discount_models: yup.array().of(
    yup.object().shape({
      discount_type_id: yup.object(),
      discount_model_id: yup.array(),
      activate: yup.bool(),
    })
  ),
  discount_sub_categories_models: yup.array().of(
    yup.object().shape({
      activate: yup.bool(),
      discount_type_id: yup.object(),
      discount_model_id: yup.object(),
      discount_subs_id: yup.array(),
    })
  ),
  discount_sub_attributes_models: yup.array().of(
    yup.object().shape({
      activate: yup.bool(),
      discount_type_id: yup.object(),
      discount_model_id: yup.object(),
      discount_subs_id: yup
        .array()
        .min(1, "Field is required")
        .required("Field is required"),
    })
  ),
});

const DiscountDetails = (props) => {
  // DATA AND HOOKS
  const { create } = props;
  const params = useParams();
  let navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(HandleSchema),
    reValidateMode: "onChange" | "onBlur",
  });

  const {
    fields: discountModelsFields,
    remove: discountModelsRemove,
    append: discountModelsAppend,
  } = useFieldArray({ control, name: "discount_models" });

  const {
    fields: discountSubCategoriesFields,
    remove: discountSubCategoriesRemove,
    append: discountSubCategoriesAppend,
  } = useFieldArray({ control, name: "discount_sub_categories_models" });

  const {
    fields: discountSubAttributesFields,
    remove: discountSubAttributesRemove,
    append: discountSubAttributesAppend,
  } = useFieldArray({ control, name: "discount_sub_attributes_models" });

  //API

  const { data: attributes, attributesLoading } = useGet({
    url: "/admin/get-all-attributes",
  });
  const { data: brands, brandsLoading } = useGet({
    url: "/admin/get-all-brands",
  });
  const { data: categories, categoriesLoading } = useGet({
    url: "/admin/get-all-categories",
  });
  const { data: condition, conditionsLoading } = useGet({
    url: "/user/get-all-conditions",
  });

  const { data: productSku, productSkuLoading } = useGet({
    url: "/admin/get-product-skus",
  });
  const { data: products, productsLoading } = useGet({
    url: "/admin/get-products",
  });

  const { data: usageType, usageTypeLoading } = useGet({
    url: "/admin/get-all-product-usage-types",
  });

  const { data: discountTypes, discountTypesLoading } = useGet({
    url: "/admin/get-discount-types",
  });

  const { data: discountById, loading } = useGet({
    url: !create ? `admin/get-discount-by-id/${params.id}` : null,
  });

  let discountTypesArray = discountTypes?.data?.data;
  let attributesArray = attributes?.data?.data;
  let brandsArray = brands?.data?.data;
  let categoriesArray = categories?.data?.data;
  let productsArray = products?.data?.data;
  let conditionArray = condition?.data?.data;
  let productSkuArray = productSku?.data?.data;
  let usageTypeArray = usageType?.data?.data;

  //FUNCTIONS
  const GetTypeData = (name) => {
    switch (name) {
      case "Brands":
        return brandsArray || [];
      case "Products":
        return productsArray || [];
      case "Categories":
        return categoriesArray?.categories || [];
      case "Condition":
        return conditionArray || [];
      case "Usage Type":
        return usageTypeArray || [];
      case "Product SKU":
        return productSkuArray || [];
      case "Attribute Values":
        return attributesArray || [];
    }
  };

  const handleSubmitForm = (formData) => {
    let discount_models = [];
    formData?.discount_models?.map((element) => {
      if (element?.discount_type_id?.name === "Categories") {
        if (formData?.discount_sub_categories_models?.length === 0) {
          element?.discount_model_id?.map((models) => {
            discount_models.push({
              discount_type_id: element?.discount_type_id?.id,
              discount_model_id: models?.id,
              activate: element?.activate,
            });
          });
        }
      } else if (element?.discount_type_id?.name === "Attribute Values") {
        formData?.discount_sub_attributes_models?.map((val) =>
          val.discount_subs_id.map((el) =>
            discount_models.push({
              discount_type_id: val?.discount_type_id?.id,
              discount_model_id: el?.id,
              activate: val?.activate,
            })
          )
        );
      } else {
        element?.discount_model_id?.map((models) => {
          discount_models.push({
            discount_type_id: element?.discount_type_id?.id,
            discount_model_id: models?.id,
            activate: element?.activate,
          });
        });
      }
    });
    if (formData?.discount_sub_categories_models?.length !== 0) {
      formData?.discount_sub_categories_models?.slice(-1).map((val) =>
        val.discount_subs_id?.length !== 0
          ? val.discount_subs_id?.map((el) =>
              discount_models.push({
                discount_type_id: val?.discount_type_id?.id,
                discount_model_id: el?.id,
                activate: val?.activate,
              })
            )
          : discount_models.push({
              discount_type_id: val?.discount_type_id?.id,
              discount_model_id: val?.discount_model_id?.id,
              activate: val?.activate,
            })
      );
    }

    setSaveLoading(true);
    if (create) {
      createDiscount({
        name: formData?.name,
        value: formData?.value,
        activate: formData?.activate,
        discount_models: discount_models,
      }).then((res) => {
        if (res?.data?.success) {
          navigate("/discounts");
        }
        setAlert({
          visible: true,
          text: res.data ? res.data?.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
      });
    } else {
      updateDiscount({
        id: params?.id,
        name: formData?.name,
        value: formData?.value,
        activate: formData?.activate,
      }).then((res) => {
        if (res?.data?.success) {
          navigate("/discounts");
        }
        setAlert({
          visible: true,
          text: res.data ? res.data?.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
      });
    }
  };

  //FUNCTIONS
  const appendNewObject = async (array) => {
    let result = await discountModelsRemove();
    array?.map((val) => {
      discountModelsAppend(val);
    });
  };

  const appendNewSubObject = async (array) => {
    let result = await discountSubAttributesRemove();
    array?.map((val) => {
      discountSubAttributesAppend(val);
    });
  };

  const handleAddDiscountModels = (valuesArray) => {
    let array = [];
    let checkCategory = valuesArray.find((el) => el?.name === "Categories");
    let checkAttributes = valuesArray.find(
      (el) => el?.name === "Attribute Values"
    );

    if (!checkCategory) {
      discountSubCategoriesRemove();
    } else if (!checkAttributes) {
      discountSubAttributesRemove();
    }
    valuesArray?.map((item) => {
      let checkAvailable = getValues("discount_models")?.find(
        (el) => el?.discount_type_id?.id === item?.id
      );

      if (checkAvailable) {
        array.push(checkAvailable);
      } else {
        array.push({
          discount_type_id: item,
          discount_model_id: [],
          activate: false,
        });
      }
    });

    appendNewObject(array);
  };

  const getDataCategory = async (type, categoryValue, categoryIndex) => {
    setLoadingData(true);
    return await api
      .get(`admin/get-category-by-id/${categoryValue?.id}`)
      .then((res) => {
        if (res?.data?.success) {
          discountSubCategoriesAppend({
            activate: type?.activate,
            discount_type_id: type?.discount_type_id,
            discount_model_id: res?.data?.data?.category,
            discount_subs_id: [],
          });
        }

        setLoadingData(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAddSubModelFromSub = (
    typeElement,
    subModelElement,
    value,
    index
  ) => {
    //remove all subs under this category where the changes where made
    getValues("discount_sub_categories_models")?.map((el, subIndex) => {
      if (subIndex > index) {
        discountSubCategoriesRemove(subIndex);
      }
    });
    // recheck if the value is one then we have to pass to the child
    if (value?.length === 1 && value?.[0]?.has_child) {
      getDataCategory(typeElement, value[0], index);
    }
  };

  const handleAddSubModelsFromMain = (discountModelElement, value) => {
    if (discountModelElement?.discount_type_id?.name === "Attribute Values") {
      let array = [];

      value.map((item) => {
        let checkAvailable = getValues("discount_sub_attributes_models").find(
          (val) => val?.discount_model_id?.id === item.id
        );

        if (checkAvailable) {
          array.push(checkAvailable);
        } else {
          array.push({
            activate: discountModelElement?.activate,
            discount_type_id: discountModelElement?.discount_type_id,
            discount_model_id: item,
            discount_subs_id: [],
          });
        }
      });

      appendNewSubObject(array);
    } else if (discountModelElement?.discount_type_id?.name === "Categories") {
      discountSubCategoriesRemove();
      if (value?.length === 1 && value?.[0]?.has_child) {
        getDataCategory(discountModelElement, value[0], null);
      }
    }
  };

  const handleActivateSubs = (discountElement, value) => {
    if (discountElement?.discount_type_id?.name === "Categories") {
      getValues("discount_sub_categories_models")?.map((el, index) => {
        setValue(`discount_sub_categories_models.${index}.activate`, value, {
          shouldValidate: true,
        });
      });
    } else {
      getValues("discount_sub_attributes_models")?.map((el, index) => {
        setValue(`discount_sub_attributes_models.${index}.activate`, value, {
          shouldValidate: true,
        });
      });
    }
  };

  function groupBy(objectArray, property) {
    let array = [];
    objectArray?.map((el) => {
      let findIndex = array?.findIndex(
        (item) => item?.model_id.id === el?.[property]?.id
      );
      if (findIndex !== -1) {
        let checkValue = array[findIndex]["value"]?.find(
          (val) => val.id === el?.id
        );
        if (!checkValue) {
          array[findIndex]["value"].push(el);
        }
      } else {
        array.push({
          model_id: el[property],
          value: [el],
        });
      }
    });

    return array;
  }

  const test = [];
  const GetLastTreeElement = (object) => {
    if (object?.model_type?.parents) {
      GetLastTreeElement(object?.model_type?.parents);
    } else {
      let findItem = test?.find(
        (item) => item?.model_type?.id === object?.model_type?.parent_id
      );
      if (!findItem) {
        test.push(object);
      }
    }

    return test;
  };

  let test2 = [];
  const DistructTree = (discounType, discountActivate, objectArray) => {
    objectArray?.map((el) => {
      if (el?.parents) {
        let findIndex = test2?.findIndex(
          (val) => val?.discount_model_id?.id === el?.parent_id
        );

        if (findIndex !== -1) {
          let checkValue = test2[findIndex]["discount_subs_id"]?.find(
            (val) => val.id === el?.id
          );
          if (!checkValue) {
            test2[findIndex]["discount_subs_id"].push(el);
          }
        } else {
          test2.push({
            discount_type_id: discounType,
            discount_model_id: el?.parents,
            discount_subs_id: [el],
            activate: discountActivate,
          });
        }
        DistructTree([el?.parents]);
      }
    });

    return test2;
  };

  const DistructDiscountModel = (values) => {
    let existing = groupBy(values?.model_discount, "discount_type");

    let discount_type_ids = [];
    let discount_models = [];
    let discount_sub_categories_models = [];
    let discount_sub_attributes_models = [];

    existing?.map((el) => {
      discount_type_ids.push(el?.model_id);
      if (
        el?.model_id?.name !== "Categories" &&
        el?.model_id?.name !== "Attribute Values"
      ) {
        discount_models.push({
          discount_type_id: el?.model_id,
          discount_model_id: el?.value?.map((val) => val?.model_type),
          activate: el?.value?.[0]?.is_active,
        });
      } else if (el?.model_id?.name === "Attribute Values") {
        let existing_att = groupBy(el?.value, "attribute_details");
        discount_models.push({
          discount_type_id: el?.model_id,
          discount_model_id: existing_att?.map((ex) => ex.model_id),
          activate: el?.value?.[0]?.is_active,
        });
        existing_att?.map((att) => {
          discount_sub_attributes_models.push({
            activate: att?.value?.[0]?.is_active,
            discount_type_id: el?.model_id,
            discount_model_id: att?.model_id,
            discount_subs_id: att.value?.map((val) => val?.model_type),
          });
        });
      } else if (el?.model_id?.name === "Categories") {
        let last = GetLastTreeElement(el?.value[0]);

        discount_models.push({
          discount_type_id: el?.model_id,
          discount_model_id: last?.map((el) =>
            el?.model_type ? el?.model_type : el
          ),
          activate: el?.value?.[0]?.is_active,
        });
        discount_sub_categories_models = DistructTree(
          el?.model_id,
          el?.value?.[0]?.is_active,
          [...el?.value?.map((el) => el.model_type)]
        ).reverse();
      }
    });

    reset({
      name: values?.name?.en,
      value: values?.value,
      activate: values?.is_active,
      discount_type_ids: discount_type_ids || [],
      discount_models: discount_models || [],
      discount_sub_categories_models: discount_sub_categories_models || [],
      discount_sub_attributes_models: discount_sub_attributes_models || [],
    });
    setLoadingData(false);
  };

  useEffect(() => {
    if (!create) {
      setLoadingData(true);
      DistructDiscountModel(discountById?.data?.data);
    } else {
      reset({
        name: "",
        value: null,
        activate: true,
        discount_type_ids: [],
        discount_models: [],
        discount_sub_categories_models: [],
        discount_sub_attributes_models: [],
      });
    }
  }, [create, discountById]);

  return !create && loading ? (
    <Spinner />
  ) : (
    <div className="discountDetails">
      <h1>
        {create ? "Add Discount" : "Edit " + discountById?.data?.data?.name?.en}
      </h1>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Card className="discountDetailsCard">
          <CardContent>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormInput
                  required
                  label="Title"
                  placeholder="Enter title"
                  name="name"
                  value={value}
                  onChange={(e) => onChange(e?.target.value)}
                  helperText={error?.message}
                />
              )}
              name="name"
              control={control}
            />
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormSwitch
                  label="Active"
                  name="activate"
                  value={value}
                  onChange={(e) => onChange(e?.target.checked)}
                />
              )}
              name="activate"
              control={control}
            />
          </CardContent>
        </Card>
        <Card className="discountDetailsCard">
          <CardContent>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormInput
                  required
                  type="number"
                  label="Discount"
                  name="value"
                  placeholder={"Enter value"}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value === "" ? null : e.target.value);
                    clearErrors("value");
                  }}
                  helperText={error?.message}
                />
              )}
              name="value"
              control={control}
            />
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormMultiSelect
                  required
                  data={discountTypesArray || []}
                  loading={discountTypesLoading}
                  label="Discount Type"
                  fixedOptions={!create ? value : []}
                  disabled={!create}
                  name="discount_type_ids"
                  placeholder={"Select types"}
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    handleAddDiscountModels(value);
                  }}
                  helperText={error?.message}
                />
              )}
              name="discount_type_ids"
              control={control}
            />
            {discountModelsFields?.length !== 0 &&
              discountModelsFields?.map((el, discountModelIndex) => (
                <>
                  <div
                    key={`${discountModelIndex}_${discountModelsFields?.length}`}
                    className="discount_model_dynamic"
                  >
                    <div className="active-checkbox">
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormCheckBox
                            label="Active"
                            disabled={!create}
                            name={`discount_models.${discountModelIndex}.activate`}
                            value={value}
                            onChange={(e) => {
                              onChange(e?.target.checked);
                              handleActivateSubs(el, e?.target.checked);
                            }}
                          />
                        )}
                        name={`discount_models.${discountModelIndex}.activate`}
                        control={control}
                      />
                    </div>
                    <div className="choose-model">
                      <div>
                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormMultiSelect
                              data={GetTypeData(el?.discount_type_id?.name)}
                              value={value}
                              fixedOptions={!create ? value : []}
                              disabled={!create}
                              onChange={(value) => {
                                onChange(value);

                                handleAddSubModelsFromMain(
                                  getValues(
                                    `discount_models.${discountModelIndex}`
                                  ),
                                  value
                                );
                              }}
                              accessValue={
                                el?.discount_type_id?.name === "Product SKU"
                                  ? "sku_code"
                                  : "name"
                              }
                              name={`discount_models.${discountModelIndex}.discount_model_id`}
                              loading={false}
                              label={el?.discount_type_id?.name}
                              helperText={error?.message}
                            />
                          )}
                          name={`discount_models.${discountModelIndex}.discount_model_id`}
                          control={control}
                        />
                      </div>
                      {el?.discount_type_id?.name === "Attribute Values" &&
                        discountSubAttributesFields?.map(
                          (el, discountSubModelIndex) => (
                            <div>
                              <Controller
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <FormMultiSelect
                                    value={value}
                                    fixedOptions={!create ? value : []}
                                    disabled={!create}
                                    onChange={(value) => {
                                      onChange(value);
                                      clearErrors(
                                        `discount_sub_attributes_models.${discountSubModelIndex}.discount_subs_id`
                                      );
                                    }}
                                    name={`discount_sub_attributes_models.${discountSubModelIndex}.discount_subs_id`}
                                    label={el?.discount_model_id?.name?.en}
                                    data={
                                      el?.discount_model_id?.attribute_value ||
                                      []
                                    }
                                    helperText={error?.message}
                                  />
                                )}
                                name={`discount_sub_attributes_models.${discountSubModelIndex}.discount_subs_id`}
                                control={control}
                              />
                            </div>
                          )
                        )}

                      {el?.discount_type_id?.name === "Categories" &&
                        discountSubCategoriesFields?.map(
                          (subModel, discountSubModelIndex) => (
                            <div>
                              <Controller
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <FormMultiSelect
                                    value={value}
                                    fixedOptions={!create ? value : []}
                                    disabled={!create}
                                    onChange={(value) => {
                                      onChange(value);
                                      handleAddSubModelFromSub(
                                        getValues(
                                          `discount_models.${discountModelIndex}`
                                        ),
                                        subModel,
                                        value,
                                        discountSubModelIndex
                                      );
                                      clearErrors(
                                        `discount_sub_categories_models.${discountSubModelIndex}.discount_subs_id`
                                      );
                                    }}
                                    name={`discount_sub_categories_models.${discountSubModelIndex}.discount_subs_id`}
                                    label={
                                      subModel?.discount_model_id?.name?.en
                                    }
                                    data={
                                      subModel?.discount_model_id?.child || []
                                    }
                                    helperText={error?.message}
                                  />
                                )}
                                name={`discount_sub_categories_models.${discountSubModelIndex}.discount_subs_id`}
                                control={control}
                              />
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </>
              ))}

            <div className="action-footer">
              <Button
                name="Save"
                selected
                type="submit"
                loading={saveLoading}
                disabled={loadingData || saveLoading}
              />
            </div>
          </CardContent>
        </Card>
      </form>
    </div>
  );
};
export default DiscountDetails;
