import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid, Skeleton, Switch } from "@mui/material";
import Button from "../../../components/button/Button";
import {
  FormInput,
  FormTextArea,
} from "../../../components/formComponents/FormComponents";
import { useGet } from "../../../hooks/useFetch";
import { useGlobalValues } from "../../../context/GlobalContext";
import { updateSectionone } from "../../../core/apis/store";
import { AlertContext } from "../../../context/AlertContext";
import "./blogs.scss";
import { ColorPicker } from "../../../components/colorPicker/colorpicker";

const BlogsSection = () => {
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();
  const [titleColor, setTitleColor] = useState("#041C3F");
  const [paragraphColor, setParagraphColor] = useState("#041C3F");
  const [isFetchingData, setIsFetchingData] = useState(true);

  const blogssectionSchema = yup.object({
    title: yup.string().nullable().required("Field is Required"),
    description: yup.string().nullable().required("Field is Required"),
    active: yup.bool().nullable().required("Field is Required"),
    buttonText: yup.string().nullable().required("Field is Required"),
    buttonLink: yup.string().nullable().url("Please enter a valid URL"),
  });

  const defaults = {
    title: "",
    description: "",
    active: true,
    titleColor: "#041C3F",
    paragraphColor: "#041C3F",
    buttonText: "",
    buttonLink: "",
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(blogssectionSchema),
    defaultValues: defaults,
  });

  // Get Section Details by Slug
  const {
    data: sectionDetails,
    loading: loadingDetails,
    refetch,
  } = useGet({
    url: "admin/section/BLOGS_AD_SECTION",
  });

  const blogsadsectionArray = sectionDetails?.data?.data;

  const handleCancel = () => {
    refetch();
  };

  useEffect(() => {
    if (blogsadsectionArray) {
      // Check if either array exists
      setIsFetchingData(true);

      const titleObject = blogsadsectionArray?.columns?.find(
        (item) => item.title && item.language === storeLanguage
      );
      const descriptionObject = blogsadsectionArray?.columns?.find(
        (item) => item.description && item.language === storeLanguage
      );
      const titleColorObject = blogsadsectionArray?.columns?.find(
        (item) => item.button_color && item.language === storeLanguage
      );
      const paragraphColorObject = blogsadsectionArray?.columns?.find(
        (item) => item.button_text_color && item.language === storeLanguage
      );
      const activeObject = blogsadsectionArray?.columns?.find(
        (item) => item.is_enable && item.language === storeLanguage
      );
      const buttontextObject = blogsadsectionArray?.columns?.find(
        (item) => item.button_text && item.language === storeLanguage
      );
      const buttonlinkObject = blogsadsectionArray?.columns?.find(
        (item) => item.button_link && item.language === storeLanguage
      );

      const populatedFormData = {
        title: titleObject?.title,
        description: descriptionObject?.description,
        titleColor: titleColorObject?.button_color,
        paragraphColor: paragraphColorObject?.button_text_color,
        active: activeObject?.is_enable === "1",
        buttonLink: buttonlinkObject?.button_link,
        buttonText: buttontextObject?.button_text,
      };

      // Setting form values using setValue
      Object.keys(populatedFormData).forEach((key) => {
        setValue(key, populatedFormData[key]);
      });

      setTitleColor(populatedFormData.titleColor || "#041C3F");
      setParagraphColor(populatedFormData.paragraphColor || "#041C3F");

      setIsFetchingData(false);
    } else {
      setIsFetchingData(false); // Ensure to set fetching data to false if both arrays are undefined or null
    }
  }, [blogsadsectionArray, storeLanguage, setValue]);

  const handleSubmitForm = async (values) => {
    setLoading(true);

    const payload = {
      language: storeLanguage,
      title: values.title,
      description: values.description,
      is_enable: values.active ? 1 : 0,
      button_color: titleColor,
      button_text_color: paragraphColor,
      button_text: values.buttonText,
      button_link: values.buttonLink,
    };

    updateSectionone("BLOGS_AD_SECTION", payload).then((res) => {
      setAlert({
        visible: true,
        text: res?.data.success ? "Successfully Updated" : res?.data?.message,
        type: res?.data?.success ? "success" : "error",
      });
      refetch();
      setLoading(false);
    });
  };

  return (
    <div className="adSection">
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} className="displayEnd">
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width={100} height={40} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <div className="switch">
                        <label>Enable this block</label>
                        <Switch
                          checked={value}
                          onChange={(e) => {
                            onChange(e.target.checked);
                          }}
                        />
                      </div>
                    )}
                    name="active"
                    control={control}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={40} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="title"
                        label="Ad Title"
                        hideVariant
                        placeholder={"Enter Title"}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="title"
                    control={control}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width="100%" height={120} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormTextArea
                        required
                        hideVariant
                        name="description"
                        label="Ad Description"
                        placeholder={"Enter Description"}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="description"
                    control={control}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={40} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="buttonText"
                        label="Button Text"
                        hideVariant
                        placeholder="Button Text"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="buttonText"
                    control={control}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={40} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="buttonLink"
                        label="Button Link"
                        hideVariant
                        placeholder={"Button Link"}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="buttonLink"
                    control={control}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width="100%" height={40} />
                ) : (
                  <ColorPicker
                    label="Button Color"
                    selectedColor={titleColor}
                    onColorChange={(color) => {
                      setTitleColor(color);
                      setValue("titleColor", color); // Ensure form value is updated
                    }}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width="100%" height={40} />
                ) : (
                  <ColorPicker
                    label="Button Text Color"
                    selectedColor={paragraphColor}
                    onColorChange={(color) => {
                      setParagraphColor(color);
                      setValue("paragraphColor", color); // Ensure form value is updated
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="flex-end"
            spacing={2}
            style={{ marginTop: "10px" }}
          >
            <Grid item>
              {isFetchingData ? (
                <Skeleton variant="rectangular" width={100} height={40} />
              ) : (
                <Button
                  onClick={() => handleCancel()}
                  name={"Cancel"}
                  type="button"
                  className="mx-1"
                />
              )}
            </Grid>
            <Grid item>
              {isFetchingData ? (
                <Skeleton variant="rectangular" width={100} height={40} />
              ) : (
                <Button
                  selected
                  name={"Save"}
                  type="submit"
                  loading={loading}
                  className="mx-1"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default BlogsSection;
