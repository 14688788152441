import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Divider, Grid, Skeleton, Typography } from "@mui/material";
import Button from "../../../components/button/Button";
import {
  FormInput,
  FormTextArea,
} from "../../../components/formComponents/FormComponents";
import { PhoneInput } from "../../../components/phoneInput/PhoneInput";
import { useGet } from "../../../hooks/useFetch";
import { useGlobalValues } from "../../../context/GlobalContext";
import { updateSectionone } from "../../../core/apis/store";
import { AlertContext } from "../../../context/AlertContext";
import "./contactUs.scss";
import FindusSection from "./findusSection";

const ContactusSection = () => {
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [phoneNumber_error, setPhone_number_error] = useState("");

  // Yup schema validation
  const informationsectionSchema = yup.object({
    title: yup.string().nullable(),
    paragraph: yup.string().nullable(),
    email: yup.string().nullable(),
    address: yup.string().nullable(),
    mapLat: yup.string().nullable(),
    mapLong: yup.string().nullable(),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .min(10, "Phone number must be at least 10 digits"),
  });

  const defaults = {
    title: "",
    paragraph: "",
    address: "",
    email: "",
    mapLong: "",
    mapLat: "",
    phoneNumber: "",
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(informationsectionSchema),
    defaultValues: defaults,
  });

  const {
    data: sectionDetails,
    loading: loadingDetails,
    refetch,
  } = useGet({
    url: "admin/section/CONTACT_US_SECTION",
  });

  const contactusArray = sectionDetails?.data?.data;

  const handleCancel = () => {
    refetch();
  };

  const handleSubmitForm = async (values) => {
    console.log(values, "values");
    setLoading(true);

    const payload = {
      language: storeLanguage,
      title: values.title,
      paragraph: values.paragraph,
      email: values.email,
      address: values.address,
      map_long: values.mapLong,
      map_lat: values.mapLat,
      phone: values.phoneNumber,
    };

    updateSectionone("CONTACT_US_SECTION", payload).then((res) => {
      setAlert({
        visible: true,
        text: res?.data.success ? "Successfully Updated" : res?.data?.message,
        type: res?.data?.success ? "success" : "error",
      });
      refetch();
      setLoading(false);
    });
  };

  useEffect(() => {
    if (contactusArray) {
      setIsFetchingData(true);

      // Helper function to find and return the value for a given key
      const findValue = (key) => {
        // Try to find the object using the storeLanguage
        const foundObject = contactusArray?.columns?.find(
          (item) => item.language === storeLanguage && item[key] !== undefined
        );

        // If found, return the value
        if (foundObject) {
          return foundObject[key];
        }

        // Fallback: Try to find the object using the default language "Arabic"
        const defaultObject = contactusArray?.columns?.find(
          (item) => item.language === "en" && item[key] !== undefined
        );

        // Return the value from the default object, or an empty string if not found
        return defaultObject ? defaultObject[key] : "";
      };

      const populatedFormData = {
        title: findValue("title"),
        paragraph: findValue("paragraph"),
        email: findValue("email"),
        phoneNumber: findValue("phone"),
        address: findValue("address"),
        mapLong: findValue("map_long"),
        mapLat: findValue("map_lat"),
      };

      setValue("title", populatedFormData.title);
      setValue("paragraph", populatedFormData.paragraph);
      setValue("email", populatedFormData.email);
      setValue("phoneNumber", populatedFormData.phoneNumber);
      setValue("address", populatedFormData.address);
      setValue("mapLong", populatedFormData.mapLong);
      setValue("mapLat", populatedFormData.mapLat);

      setIsFetchingData(false);
    }
  }, [contactusArray, storeLanguage, setValue]);

  return (
    <div className="contactusSection">
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={40} />
                ) : (
                  <Controller
                    name="title"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="title"
                        label="Form Title"
                        hideVariant
                        placeholder="Enter Contact Form Title"
                        value={value || ""}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width="100%" height={120} />
                ) : (
                  <Controller
                    name="paragraph"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormTextArea
                        required
                        hideVariant
                        name="paragraph"
                        label="Form sub-Paragraph"
                        placeholder="Enter Form sub-paragraph"
                        value={value || ""}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={40} />
                ) : (
                  <Controller
                    name="email"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        label="Email"
                        hideVariant
                        fullWidth
                        className="inputform"
                        name="email"
                        id="email"
                        placeholder="Enter Email"
                        value={value || ""}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={40} />
                ) : (
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <PhoneInput
                        required
                        hideVariant
                        value={value || ""}
                        className="phone-input-mg-form"
                        onChange={() => (number, info) => {
                          onChange(number);
                          setPhone_number_error("");
                        }}
                        helperText={
                          phoneNumber_error !== ""
                            ? phoneNumber_error
                            : error?.message
                        }
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width="100%" height={120} />
                ) : (
                  <Controller
                    name="address"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormTextArea
                        required
                        hideVariant
                        name="address"
                        label="Address"
                        placeholder="Enter Address"
                        value={value || ""}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={12}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={40} />
                ) : (
                  <Controller
                    name="mapLong"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="mapLong"
                        label="Longitude"
                        hideVariant
                        placeholder="Long"
                        value={value || ""}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={40} />
                ) : (
                  <Controller
                    name="mapLat"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="mapLat"
                        label="Latitude"
                        hideVariant
                        placeholder="Lat"
                        value={value || ""}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            {isFetchingData ? (
              <Skeleton variant="rectangular" width={100} height={40} />
            ) : (
              <Button
                onClick={() => handleCancel()}
                name={"Cancel"}
                type="button"
                className="mx-1"
              />
            )}
          </Grid>
          <Grid item>
            {isFetchingData ? (
              <Skeleton variant="rectangular" width={100} height={40} />
            ) : (
              <Button
                selected
                name={"Save"}
                type="submit"
                className="mx-1"
                loading={loading}
              />
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ContactusSection;
