import React, { useContext, useState } from "react";
import "./FAQs.scss";
import { TableCell, Typography } from "@mui/material";
import Paginator from "../../../components/paginator/Paginator";
import TableBodyComponent from "../../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../../components/table/tableBody/TableRowComponent";
import { useGet } from "../../../hooks/useFetch";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { AlertContext } from "../../../context/AlertContext";
import { useSearchParams } from "react-router-dom";
import { useGlobalValues } from "../../../context/GlobalContext";
import Button from "../../../components/button/Button";
import AddQuestions from "./addQuestions"; // Updated import
import { deleteFAQ } from "../../../core/apis/store";

const FAQs = () => {
  const { setAlert } = useContext(AlertContext);
  const [showAddQuestion, setShowAddQuestion] = useState(false);
  const [questionID, setQuestionID] = useState("");
  const { storeLanguage } = useGlobalValues();
  const [searchParams] = useSearchParams();
  const [FAQSDetail, setFAQSDetail] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  let url = `admin/faq`;
  const { data: FAQs, loading, refetch } = useGet({ url: url });
  let data = FAQs?.data?.data;

  const handleToggleAddQuestion = () => {
    setShowAddQuestion((prevState) => !prevState); // Toggle state
  };

  const handleToggleEditQuestion = (id) => {
    setShowAddQuestion(true);
    setQuestionID(id);
  };

  const handleTogglefalse = () => {
    setQuestionID(null);
    setShowAddQuestion(false);
    refetch();
  };

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      const faqId = parseInt(responseData?.id, 10);
      console.log("Deleting FAQ with ID:", faqId);

      deleteFAQ(faqId).then((res) => {
        if (res?.data?.success) {
          setFAQSDetail(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res?.data?.success
            ? "Deleted Successfully"
            : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  return (
    <div className="FAQS">
      {showAddQuestion ? (
        <AddQuestions
          onCancel={handleTogglefalse}
          onSave={handleTogglefalse}
          ID={questionID}
        />
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop:"15px",
              padding:"5px"
            }}
          >
            <Typography variant="h6" gutterBottom>
              FAQs{" "}
            </Typography>
            <Button
              selected
              width={"150px"}
              name="Add Question"
              onClick={handleToggleAddQuestion}
            />
          </div>
          <div className="exportList">
            <TableBodyComponent loading={loading} total={data?.total}>
              {data?.map((element, index) => (
                <TableRowComponent
                  internal
                  key={element?.id}
                  handleEdit={() => {
                    handleToggleEditQuestion(element.id);
                  }}
                  handleDelete={() => {
                    setFAQSDetail(element);
                    setOpenDelete(true);
                  }}
                >
                  <TableCell>{element?.question?.[storeLanguage]}</TableCell>
                </TableRowComponent>
              ))}
            </TableBodyComponent>
            <Paginator count={data?.last_page} disabled={loading} />
          </div>
        </div>
      )}
      {openDelete && (
        <NoticeConfirmation
          data={FAQSDetail}
          handleResponse={handleDeleteResponse}
        />
      )}
    </div>
  );
};

export default FAQs;
