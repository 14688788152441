//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../context/AlertContext";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGet } from "../../hooks/useFetch";
import Button from "../../components/button/Button";
import { CardContent, Card, Skeleton } from "@mui/material";
import {
  FormInput,
  FormMultiSelect,
  FormSwitch,
  FormCalendarInput,
  FormCheckBox,
  FormRadioButton,
} from "../../components/formComponents/FormComponents";

import "./HomeGroup.scss";
import {
  CreateHomegroup,
  UpdateHomeGroup,
} from "../../core/apis/homePageGroup";
import { api } from "../../core/apis/main";
import { USAFlagIcon } from "../../assests/icons/SVG";
import KSAFlag from "../../assests/Images/united-ara.png";
import CustomTab from "../../components/Tabs/customTab";
import { useGlobalValues } from "../../context/GlobalContext";
import { translateByGoogleApi } from "../../core/apis/newlanguage";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
const HandleSchema = yup.object({
  name: yup.string().required("Field is required"),
  expiry_date: yup.date().nullable().required("Field is required"),
  is_active: yup.bool(),
  top_selling_option: yup.bool(),
  discount_option: yup.bool(),
  date_option: yup.bool(),
  top_selling_value: yup
    .number()
    .nullable()
    .when("top_selling_option", {
      is: (value) => value === true,
      then: yup.number().nullable().required("Field is required"),
      otherwise: yup.number().nullable(),
    }),
  discount_min_value: yup
    .number()
    .nullable()
    .when("discount_option", {
      is: (value) => value === true,
      then: yup
        .number()
        .nullable()
        .lessThan(
          yup.ref("discount_max_value"),
          "Field should be less than the max"
        )
        .required("Field is required"),
      otherwise: yup.number().nullable(),
    }),
  discount_max_value: yup
    .number()
    .nullable()
    .when("discount_option", {
      is: (value) => value === true,
      then: yup
        .number()
        .nullable()
        .moreThan(
          yup.ref("discount_min_value"),
          "Field should be greater than the min"
        )
        .required("Field is required"),
      otherwise: yup.number().nullable(),
    }),
  date_min_value: yup
    .number()
    .nullable()
    .when("date_option", {
      is: (value) => value === true,
      then: yup.number().nullable().required("Field is required"),
      otherwise: yup.number().nullable(),
    }),
  date_max_value: yup
    .number()
    .nullable()
    .when("date_option", {
      is: (value) => value === true,
      then: yup.number().nullable().required("Field is required"),
      otherwise: yup.number().nullable(),
    }),
  days_value: yup
    .number()
    .nullable()
    .when("date_option", {
      is: (value) => value === true,
      then: yup.number().nullable().required("Field is required"),
      otherwise: yup.number().nullable(),
    }),
  home_page_group_type_id: yup
    .object()
    .nullable()
    .required("Field is required"),
  home_page_group_model_ids: yup
    .array()
    .min(1, "Field is required")
    .required("Field is required"),
  home_page_group_sub_model_ids: yup.array().of(
    yup.object().shape({
      model_id: yup.object(),
      value: yup.array(),
    })
  ),
  select_all_countries: yup.bool(),
  country_ids: yup.array().required("Field is required"),
});

export function HomeGroupHandle(props) {
  //VARIABLES
  const { id } = useParams();
  let navigate = useNavigate();
  const { type } = props;
  const { setAlert } = useContext(AlertContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [tabvalue, setTabValue] = useState("en");

  const { enabledLanguages } = useGlobalValues();

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(HandleSchema),
    reValidateMode: "onChange" | "onBlur",
  });

  const {
    fields: modelIdsFields,
    remove: modelIdsRemove,
    append: modelIdsAppend,
  } = useFieldArray({ control, name: "home_page_group_sub_model_ids" });

  //API
  const { data: homePageGroupById, loading } = useGet({
    url: type === "edit" ? `admin/get-home-page-group-by-id/${id}` : null,
  });

  const { data: homegroupTypes, homegroupTypesloading } = useGet({
    url: "admin/get-home-page-group-types",
    payload: null,
  });

  const { data: countries, countryloading } = useGet({
    url: "get-all-countries",
    payload: null,
  });
  const { data: attributes } = useGet({
    url: "admin/get-all-attributes",
    payload: null,
  });
  const { data: products } = useGet({
    url: "admin/get-products",
    payload: null,
  });
  const { data: categories } = useGet({
    url: "admin/get-all-categories",
    payload: null,
  });
  const { data: conditions } = useGet({
    url: "user/get-all-conditions",
    payload: null,
  });

  const { data: brands } = useGet({
    url: "admin/get-all-brands",
    payload: null,
  });

  let brandsArray = brands?.data?.data;
  let productsArray = products?.data?.data;
  let attributesArray = attributes?.data?.data;
  let countriesArray = countries?.data?.data;
  let categoriesArray = categories?.data?.data?.categories;
  let conditionsArray = conditions?.data?.data;
  let homegroupTypesArray = homegroupTypes?.data?.data?.filter((item) => item.id == 1 || item.id == 5).map((item) => item);


  //FUNCTIONS
  const handleSubmitForm = (formData) => {
    let home_page_group_model_ids = [];
    if (formData.home_page_group_type_id?.name === "Attribute Values") {
      formData.home_page_group_sub_model_ids.map((val) =>
        val.value.map((el) => home_page_group_model_ids.push(el.id))
      );
    } else if (formData.home_page_group_type_id?.name === "Categories") {
      if (formData.home_page_group_sub_model_ids?.length !== 0) {
        formData.home_page_group_sub_model_ids
          .slice(-1)
          .map((val) =>
            val.value?.length !== 0
              ? val.value?.map((el) => home_page_group_model_ids.push(el.id))
              : home_page_group_model_ids.push(val?.model_id?.id)
          );
      } else {
        formData?.home_page_group_model_ids.map((val) =>
          home_page_group_model_ids.push(val.id)
        );
      }
    } else {
      formData.home_page_group_model_ids?.map((val) =>
        home_page_group_model_ids.push(val.id)
      );
    }

    if (formData.discount_option === false) {
      delete formData.discount_max_value;
      delete formData.discount_min_value;
    }

    setSaveLoading(true);
    if (type === "edit") {
      UpdateHomeGroup({
        ...formData,
        language: tabvalue,
        group_id: id,
        country_ids: formData.country_ids?.map((val) => val.id),
        expiry_date: moment(formData.expiry_date).format("YYYY-MM-DD"),
      }).then((res) => {
        setAlert({
          visible: true,
          text: res.data ? res.data?.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
        if (res.data?.success) {
          navigate(`/homepage-groups`);
        }
        setSaveLoading(false);
      });
    } else {
      let CountriesId = formData.country_ids?.map((val) => val.id);
      CreateHomegroup({
        ...formData,
        language: tabvalue,
        home_page_group_type_id: formData.home_page_group_type_id?.id,
        home_page_group_model_ids: home_page_group_model_ids,
        country_ids: formData.country_ids?.map((val) => val.id),
        expiry_date: moment(formData.expiry_date).format("YYYY-MM-DD"),
      }).then((res) => {
        setAlert({
          visible: true,
          text: res.data ? res.data?.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
        if (res.data?.success) {
          enabledLanguages.map((item, index) => {
            if (item.code != "en") {
              handleTranslateByGoogle(res.data.data, item.code, CountriesId);
            }
            navigate(`/homepage-groups`);
          });
        }
        setSaveLoading(false);
      });
    }
  };

  const getDataCategory = async (categoryValue) => {
    setLoadingData(true);
    return await api
      .get(`admin/get-category-by-id/${categoryValue?.id}`)
      .then((res) => {
        if (res?.data?.success) {
          modelIdsAppend({
            model_id: res?.data?.data?.category,
            value: [],
          });
        }

        setLoadingData(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAddSubCategoryFromSub = (value, index) => {
    if (getValues("home_page_group_type_id")?.name === "Categories") {
      //remove all subs under this category where the changes where made
      getValues("home_page_group_sub_model_ids")?.map((el, subIndex) => {
        if (subIndex > index) {
          modelIdsRemove(subIndex);
        }
      });
      // recheck if the value is one then we have to pass to the child
      if (value?.length === 1 && value?.[0]?.has_child) {
        getDataCategory(value[0], index);
      }
    }
  };

  const appendNewObject = async (array) => {
    let result = await modelIdsRemove();
    array?.map((val) => {
      modelIdsAppend(val);
    });
  };
  const handleAddSubModelIdsFromMain = (value) => {
    if (getValues("home_page_group_type_id")?.name === "Attribute Values") {
      let array = [];

      value.map((item) => {
        let checkAvailable = getValues("home_page_group_sub_model_ids").find(
          (val) => val?.model_id?.id === item.id
        );

        if (checkAvailable) {
          array.push(checkAvailable);
        } else {
          array.push({
            model_id: item,
            value: [],
          });
        }
      });

      // modelIdsRemove();
      appendNewObject(array);
    } else if (getValues("home_page_group_type_id")?.name === "Categories") {
      //if categories we should reset the subs ; and if length equal to 1 we get the data of new child
      modelIdsRemove();
      if (value?.length === 1 && value?.[0]?.has_child) {
        getDataCategory(value[0], null);
      }
    }
  };

  const GetTypeData = (typeName) => {
    switch (typeName) {
      case "Categories":
        return categoriesArray;
      case "Products":
        return productsArray;
      case "Attribute Values":
        return attributesArray;
      case "Condition":
        return conditionsArray;
      case "Brand":
        return brandsArray;
      default:
        return [];
    }
  };

  const test = [];
  const GetLastTreeElement = (object) => {
    if (object?.parents) {
      GetLastTreeElement(object?.parents);
    } else {
      let findItem = test?.find((item) => item?.id === object?.parent_id);
      if (!findItem) {
        test.push(object);
      }
    }

    return test;
  };

  let test2 = [];
  const DistructTree = (objectArray) => {
    objectArray?.map((el) => {
      if (el?.parents) {
        let findIndex = test2?.findIndex(
          (val) => val?.model_id?.id === el?.parent_id
        );

        if (findIndex !== -1) {
          let checkValue = test2[findIndex]["value"]?.find(
            (val) => val.id === el?.id
          );
          if (!checkValue) {
            test2[findIndex]["value"].push(el);
          }
        } else {
          test2.push({
            model_id: el.parents,
            value: [el],
          });
        }
        DistructTree([el.parents]);
      }
    });

    return test2;
  };
  function groupBy(objectArray, property) {
    let array = [];
    objectArray.map((el) => {
      let findIndex = array?.findIndex(
        (item) => item?.model_id.id === el?.attribute_id
      );
      if (findIndex !== -1) {
        let checkValue = array[findIndex]["value"]?.find(
          (val) => val.id === el?.id
        );
        if (!checkValue) {
          array[findIndex]["value"].push(el);
        }
      } else {
        array.push({
          model_id: el[property],
          value: [el],
        });
      }
    });

    return array;
  }

  useEffect(() => {
    reset({
      name:
        homePageGroupById?.data?.data?.name?.[tabvalue] ||
        homePageGroupById?.data?.data?.name?.en ||
        "",
      expiry_date: homePageGroupById?.data?.data?.expiry_date || null,
      is_active: homePageGroupById?.data?.data?.data
        ? homePageGroupById?.data?.data?.data.home_page_group?.is_active
        : true,
      top_selling_option:
        homePageGroupById?.data?.data?.top_selling_option || false,
      discount_option: homePageGroupById?.data?.data?.discount_option || false,
      date_option: homePageGroupById?.data?.data?.date_option || false,
      top_selling_value: 0,
      discount_min_value:
        homePageGroupById?.data?.data?.discount_min_value || 0,
      discount_max_value:
        homePageGroupById?.data?.data?.discount_max_value || 0,
      date_min_value: null,
      date_max_value: null,
      days_value: 0,
      home_page_group_type_id:
        homePageGroupById?.data?.data?.home_page_group_model_type
          ?.home_page_group_type || null,
      home_page_group_model_ids:
        homePageGroupById?.data?.data?.home_page_group_model_type
          ?.home_page_group_type?.id === 4
          ? homePageGroupById?.data?.data?.condition
          : homePageGroupById?.data?.data?.home_page_group_model_type
              ?.home_page_group_type?.id === 5
          ? homePageGroupById?.data?.data?.brand
          : homePageGroupById?.data?.data?.home_page_group_model_type
              ?.home_page_group_type?.id === 2
          ? homePageGroupById?.data?.data?.product
          : homePageGroupById?.data?.data?.home_page_group_model_type
              ?.home_page_group_type?.id === 3
          ? [
              ...new Map(
                homePageGroupById?.data?.data?.attribute_value?.map((item) => [
                  item.attribute_id,
                  item.attribute,
                ])
              ).values(),
            ]
          : homePageGroupById?.data?.data?.home_page_group_model_type
              ?.home_page_group_type?.id === 1
          ? GetLastTreeElement(homePageGroupById?.data?.data?.category[0])
          : [],
      home_page_group_sub_model_ids:
        homePageGroupById?.data?.data?.home_page_group_model_type
          ?.home_page_group_type?.id === 3
          ? groupBy(homePageGroupById?.data?.data?.attribute_value, "attribute")
          : homePageGroupById?.data?.data?.home_page_group_model_type
              ?.home_page_group_type?.id === 1
          ? DistructTree(homePageGroupById?.data?.data?.category).reverse()
          : [],
      country_ids: homePageGroupById?.data?.data?.country || [],
      select_all_countries: homePageGroupById?.data?.data?.country
        ? homePageGroupById?.data?.data?.country?.length ==
          countriesArray?.length
          ? true
          : false
        : false,
    });
  }, [homePageGroupById, homegroupTypes, countries, tabvalue]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTranslateByGoogle = (values, targetLanguage, country_ids) => {
    let data = [values.name.en];
    translateByGoogleApi(data, targetLanguage).then((res) => {
      values["name"] = res[0]?.translatedText;
      values["country_ids"] = country_ids;
      values["group_id"] = values.id;
      values["language"] = targetLanguage;
      for (let key in values) {
        if (values[key] === null) {
          delete values[key];
        }
      }
      UpdateHomeGroup(values).then((res) => {
        setAlert({
          visible: true,
          text: res.data ? res.data?.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
      });
    });
  };

  return (
    <>
      <h1>
        {type === "edit"
          ? homePageGroupById?.data?.data?.name?.[tabvalue] ||
            homePageGroupById?.data?.data?.name?.en
          : "New Group"}
      </h1>

      <div className="home-group-component">
        <div className="form-handle">
          <form
            onSubmit={handleSubmit(handleSubmitForm)}
            className="form-section"
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          >
            <TabContext value={tabvalue}>
              <Tabs
                value={tabvalue}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                {enabledLanguages.map((item, index) =>
                  type != "edit" && item.code === "en" ? (
                    <CustomTab
                      index={index}
                      value={item.code}
                      label={item.name}
                      icon={<USAFlagIcon />}
                    />
                  ) : (
                    type === "edit" && (
                      <CustomTab
                        index={index}
                        value={item.code}
                        label={item.name}
                        icon={
                          item.code == "en" ? (
                            <USAFlagIcon />
                          ) : item.code == "ar" ? (
                            <img src={KSAFlag} width="30" />
                          ) : (
                            ""
                          )
                        }
                      />
                    )
                  )
                )}
              </Tabs>
              <Card>
                <CardContent>
                  {!loading ? (
                    <>
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormInput
                            required
                            name="name"
                            label="Homepage Group Name"
                            placeholder={"Enter group name"}
                            value={value}
                            onChange={(e) => {
                              onChange(e.target.value);
                              clearErrors("name");
                            }}
                            helperText={error?.message}
                          />
                        )}
                        name="name"
                        control={control}
                      />
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormCalendarInput
                            required
                            name="expiry_date"
                            label="Expiry Date"
                            placeholder={"Select date"}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("expiry_date");
                            }}
                            helperText={error?.message}
                          />
                        )}
                        name="expiry_date"
                        control={control}
                      />
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <FormSwitch
                            label="Active"
                            name="is_active"
                            value={value}
                            onChange={(e) => {
                              onChange(e.target.checked);
                            }}
                          />
                        )}
                        name="is_active"
                        control={control}
                      />
                    </>
                  ) : (
                    <Skeleton count={5} />
                  )}
                </CardContent>
              </Card>
            </TabContext>
            <div className="form-second-card">
              <Card>
                <CardContent>
                  {!loading ? (
                    <>
                      {/* i hide it  */}
                      <div
                        className="home-page-group-checkboxes "
                        style={{ display: "none" }}
                      >
                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormCheckBox
                              required
                              disabled={true}
                              name="top_selling_option"
                              label="Top Selling"
                              value={value}
                              onChange={(e) => {
                                onChange(e.target.checked);
                              }}
                              helperText={error?.message}
                            />
                          )}
                          name={"top_selling_option"}
                          control={control}
                        />
                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormCheckBox
                              required
                              name="discount_option"
                              label="Discount"
                              value={value}
                              onChange={(e) => {
                                setValue("discount_min_value", 0, {
                                  shouldValidate: true,
                                });
                                setValue("discount_max_value", 0, {
                                  shouldValidate: true,
                                });
                                onChange(e.target.checked);
                              }}
                              helperText={error?.message}
                            />
                          )}
                          name={"discount_option"}
                          control={control}
                        />
                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormCheckBox
                              required
                              name="date_option"
                              label="Date Range"
                              disabled={true}
                              value={value}
                              onChange={(e) => onChange(e.target.checked)}
                              helperText={error?.message}
                            />
                          )}
                          name={"date_option"}
                          control={control}
                        />
                      </div>
                      {getValues("discount_option") === true && (
                        <div className="form-double-children">
                          <Controller
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <FormInput
                                required
                                type="number"
                                label="Min"
                                name="discount_min_value"
                                placeholder={"Enter min"}
                                value={value}
                                onChange={(e) => {
                                  onChange(
                                    e.target.value === ""
                                      ? null
                                      : e.target.value
                                  );
                                  clearErrors([
                                    "discount_max_value",
                                    "discount_min_value",
                                  ]);
                                }}
                                helperText={error?.message}
                              />
                            )}
                            name="discount_min_value"
                            control={control}
                          />
                          <Controller
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <FormInput
                                required
                                type="number"
                                label="Max"
                                name="discount_max_value"
                                placeholder={"Enter max"}
                                value={value}
                                onChange={(e) => {
                                  onChange(
                                    e.target.value === ""
                                      ? null
                                      : e.target.value
                                  );
                                  clearErrors([
                                    "discount_max_value",
                                    "discount_min_value",
                                  ]);
                                }}
                                helperText={error?.message}
                              />
                            )}
                            name="discount_max_value"
                            control={control}
                          />
                        </div>
                      )}
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormRadioButton
                            required
                            disabled={type === "edit" ? true : false}
                            loading={homegroupTypesloading}
                            data={homegroupTypesArray || []}
                            label="Type"
                            name="home_page_group_type_id"
                            value={value}
                            onChange={(value) => {
                              onChange(value);
                              setValue("home_page_group_model_ids", [], {
                                shouldValidate: true,
                              });
                              setValue("home_page_group_sub_model_ids", [], {
                                shouldValidate: true,
                              });
                            }}
                            helperText={error?.message}
                          />
                        )}
                        name={"home_page_group_type_id"}
                        control={control}
                      />
                      {/* THIS IS THE INITIAL MULTISELECT THAT WILL APPEAR IN ALL CASES HOLDING THE HOME_PAGE_GROUP_TYPE_ID */}
                      {getValues("home_page_group_type_id") !== null && (
                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormMultiSelect
                              required
                              fixedOptions={type === "edit" ? value : []}
                              disabled={type === "edit" ? true : false}
                              loading={false}
                              clearErrors={clearErrors}
                              dependencyError={[
                                "home_page_group_model_ids",
                                "home_page_group_sub_model_ids",
                              ]}
                              data={
                                GetTypeData(
                                  getValues("home_page_group_type_id")?.name
                                ) || []
                              }
                              label={getValues("home_page_group_type_id")?.name}
                              name="home_page_group_model_ids"
                              placeholder={`Select ${
                                getValues("home_page_group_type_id")?.name
                              }`}
                              value={value}
                              onChange={(value) => {
                                onChange(value);

                                handleAddSubModelIdsFromMain(value);
                              }}
                              helperText={error?.message}
                            />
                          )}
                          name={"home_page_group_model_ids"}
                          control={control}
                        />
                      )}

                      {/* //IN CASE IT'S ATTRIBUTE VALUE , ATTRIBUTE VALUE HAVE 1 LEVEL DATA
                      //SO WE LOOP INTO THE SELECTED ONES TO TAKE THEIR DESCENDANTS  */}
                      {getValues("home_page_group_type_id")?.name ===
                        "Attribute Values" &&
                        getValues("home_page_group_sub_model_ids")?.map(
                          (val, index) => (
                            <Controller
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <FormMultiSelect
                                  required
                                  disabled={type === "edit" ? true : false}
                                  fixedOptions={type === "edit" ? value : []}
                                  loading={false}
                                  data={val?.model_id?.attribute_value || []}
                                  label={val?.model_id?.name?.en}
                                  name={`home_page_group_sub_model_ids.${index}.value`}
                                  placeholder={`Select ${val?.model_id?.name?.en}`}
                                  value={value}
                                  onChange={(value) => {
                                    onChange(value);
                                  }}
                                  helperText={error?.message}
                                />
                              )}
                              name={`home_page_group_sub_model_ids.${index}.value`}
                              control={control}
                            />
                          )
                        )}
                      {getValues("home_page_group_type_id")?.name ===
                        "Categories" &&
                        getValues("home_page_group_sub_model_ids")?.map(
                          (val, index) => (
                            <Controller
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <FormMultiSelect
                                  required
                                  disabled={type === "edit" ? true : false}
                                  fixedOptions={type === "edit" ? value : []}
                                  loading={false}
                                  data={val?.model_id?.child || []}
                                  label={val?.model_id?.name?.en}
                                  name={`home_page_group_sub_model_ids.${index}.value`}
                                  placeholder={`Select ${val?.model_id?.name?.en}`}
                                  value={value}
                                  onChange={(value) => {
                                    onChange(value);
                                    handleAddSubCategoryFromSub(value, index);
                                  }}
                                  helperText={error?.message}
                                />
                              )}
                              name={`home_page_group_sub_model_ids.${index}.value`}
                              control={control}
                            />
                          )
                        )}
                      <div className="countries-select-all">
                        <div className="countries-select">
                          <Controller
                            render={({ field: { onChange, value } }) => (
                              <FormMultiSelect
                                required
                                fixedOptions={[]}
                                loading={countryloading}
                                data={countriesArray || []}
                                label="Country"
                                name="country_ids"
                                disabled={
                                  getValues("select_all_countries")
                                    ? true
                                    : false
                                }
                                placeholder={"Select country"}
                                value={value}
                                onChange={(value) => {
                                  onChange(value);
                                }}
                                helperText={errors?.country_ids?.message}
                              />
                            )}
                            name={"country_ids"}
                            control={control}
                          />{" "}
                        </div>
                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormCheckBox
                              required
                              name="select_all_countries"
                              label="Select All Countries"
                              value={value}
                              onChange={(e) => {
                                onChange(e.target.checked);
                                if (e?.target?.checked) {
                                  setValue("country_ids", [...countriesArray], {
                                    shouldValidate: true,
                                  });
                                } else {
                                  setValue("country_ids", [], {
                                    shouldValidate: true,
                                  });
                                }
                              }}
                              helperText={error?.message}
                            />
                          )}
                          name={"select_all_countries"}
                          control={control}
                        />
                      </div>

                      <div className="action-footer">
                        <Button
                          name={type === "edit" ? "Save" : "Create"}
                          selected
                          disabled={saveLoading || loadingData}
                          loading={saveLoading} //when opening new sub category , to not let the user submit before choosing a sub
                          width={"30%"}
                          type="submit"
                        />
                      </div>
                    </>
                  ) : (
                    <Skeleton count={5} />
                  )}
                </CardContent>
              </Card>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default HomeGroupHandle;
