import React, { useContext } from "react";
import { Card, CardContent } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/button/Button";
import {
  FormCheckboxDropdown,
  FormInput,
  UploadComponent,
} from "../../../components/formComponents/FormComponents";
import { getFormData } from "../../../core/functions/Functions";
import { useAxios, useGet } from "../../../hooks/useFetch";
import "./LogisticCompaniesDetails.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { CountryContext } from "../../../context/CountryContext";
const brandSchema = yup.object({
  name: yup.string("Enter your email").required("Email is required"),
});
const removeBlob = (obj) => {
  let newobj = { ...obj };
  typeof newobj?.logo === "string" && delete newobj?.logo;
  return newobj;
};
const LogisticCompaniesDetails = () => {
  const { countries, loadingCountries } = useContext(CountryContext);

  let navigate = useNavigate();
  const params = useParams();
  const { data, loading, refetch } = useGet({
    url: `admin/get-logistic-company-by-id/${params.id}`,
  });
  const logisticCompany = data?.data?.data || {};
  const formik = useFormik({
    initialValues: {
      name: logisticCompany?.name || "",
      description: logisticCompany?.description || "",
      logo: logisticCompany?.logo_path,
      countries:
        logisticCompany?.logistic_company_countries?.map((i) => ({
          ...i,
          id: i?.country_id,
        })) || [],
      active: true,
    },

    enableReinitialize: true,
    validationSchema: brandSchema,
    onSubmit: () => {
      callAPI();
      refetch();
    },
  });
  const {
    data: res,
    loading: loadingUpdate,
    callAPI,
  } = useAxios(
    `admin/update-logistic-company/${params.id}`,
    "post",
    getFormData(removeBlob(formik?.values))
  );
  res?.data?.success && navigate(-1);

  const handleCountries = (_, value) => {
    formik.setFieldValue(
      "countries",
      value.find((option) => option.value === "select-all")
        ? countries?.map((i) => ({
            ...i,
            ...formik?.values?.countries?.[
              formik?.values?.countries.findIndex(
                (el) => el?.country_id === i?.id
              )
            ],
            country_id: i?.id,
            currency_id: 4,
          }))
        : value.map((i) => ({
            ...i,
            country_id: i?.id,
            currency_id: 4,
            ...formik?.values?.countries?.[
              formik?.values?.countries.findIndex(
                (el) => el?.country_id === i?.id
              )
            ],
          })) || formik.initialValues.countries?.country_id
    );
  };
  console.log(countries);
  return loading || loadingCountries ? (
    "loading..."
  ) : (
    <>
      <h1>{logisticCompany?.name?.en}</h1>
      <form className="BrandDetails" onSubmit={formik.handleSubmit}>
        <div className="form-section">
          <Card>
            <CardContent>
              <FormInput
                label="Logistic Company Name"
                placeholder="Enter Name"
                name="name"
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <FormInput
                label="Description"
                placeholder="Enter Description"
                name="description"
                id="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
              <FormCheckboxDropdown
                label="Countries"
                name="countries"
                id="countries"
                multiple
                loading={loadingCountries}
                options={countries || []}
                value={
                  countries?.filter((i) =>
                    formik?.values?.countries
                      ?.map((el) => el?.country_id)
                      .includes(+i?.id)
                  ) || {}
                }
                onChange={handleCountries}
              />
              {formik?.values?.countries.map((item, index) => (
                <FormInput
                  label={`Shipping fees in ${
                    item?.country?.name || item?.name
                  }`}
                  placeholder={`Enter Shipping fees in ${
                    item?.country?.name || item?.name
                  }`}
                  name={`countries.[${formik?.values?.countries.findIndex(
                    (i) => i?.country_id === item?.country_id
                  )}].shipping_price`}
                  id="description"
                  value={
                    formik?.values?.countries?.[
                      formik?.values?.countries.findIndex(
                        (i) => i?.country_id === item?.country_id
                      )
                    ]?.shipping_price
                  }
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              ))}
              <div className="action-footer">
                <Button
                  name={"Save"}
                  selected
                  loading={loadingUpdate}
                  disabled={!formik.dirty || loading}
                  type="submit"
                />
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="upload-section">
          <UploadComponent
            name="logo"
            value={formik.values.logo}
            setFieldValue={formik.setFieldValue}
          />
        </div>
      </form>
    </>
  );
};

export default LogisticCompaniesDetails;
