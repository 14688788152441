import React, { useContext, useState } from "react";
import { AlertContext } from "../../../context/AlertContext";
import "./Categories.scss";
import {
  Breadcrumbs,
  ButtonBase,
  CircularProgress,
  TableCell,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Paginator from "../../../components/paginator/Paginator";
import TableBodyComponent from "../../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../../components/table/tableBody/TableRowComponent";
import TableHeader from "../../../components/table/tableHeader/TableHeader";
import { useGet } from "../../../hooks/useFetch";
import DialogComponent from "../../../components/dialogs/dialog/Dialog";
import {
  deleteCategory,
  DeleteCategory,
  getAllCategories,
  getCategoryDetails,
  moveCategory,
} from "../../../core/apis/category";
import MoveCategoryHandle from "./MoveCategoryHandle";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import withH1 from "../../../assests/HOC/withH1";

const Categories = () => {
  const [open, setOpen] = useState(false);
  const [itemInfo, setItemInfo] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const tableHeaders = [
    { title: "Name" },
    { title: "Ar" },
    { title: "Type" },
    { title: "Status" },
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  let id = searchParams.get("sub");
  let navigate = useNavigate();
  let payload = {
    per_page: id ? 20 : 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
    parent_category_id: searchParams.get("sub"),
  };
  let url = `admin/get-all-categories`;

  const {
    data: categories,
    loading,
    refetch,
  } = useGet({
    url: url,
    payload: payload,
    reloading: true,
  });
  let data = categories?.data?.data || [];

  //FUNCTIONS
  const handleEdit = (id) => (e) => {
    e.stopPropagation();
    navigate(`/categories/category/${id}`);
  };
  const handleDialog = (item) => (e) => {
    setItemInfo(item);
    setOpen(true);
  };

  //FUNCTIONS
  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteCategory(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setItemInfo(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  return (
    <>
      <TableHeader
        placeholder="Search by category name"
        to={"/categories/new-category"}
        filterButton={false}
        state={{ parent_id: searchParams.get("sub") }}
      />
      <TableBodyComponent
        header={tableHeaders}
        loading={loading}
        total={data?.categories?.total}
      >
        {data?.categories?.data?.map((element) => (
          <TableRowComponent
            className="BrandDetails"
            handleEdit={handleEdit(element?.id)}
            key={element?.id}
            handleEye={() => {
              setSearchParams({ sub: element?.id });
            }}
            handleDelete={() => {
              setItemInfo(element);
              setOpenDelete(true);
            }}
            handleMove={handleDialog(element)}
          >
            <TableCell> {element?.name?.en}</TableCell>
            <TableCell> {element?.name?.ar}</TableCell>
            <TableCell>
              {element?.is_buy && "Buy"}
              {element?.is_buy && element?.is_sell && " & "}
              {!element?.is_buy && !element?.is_sell && (
                <hr style={{ width: "5vmax" }} />
              )}
              {element?.is_sell && "Sell"}
            </TableCell>
            <TableCell>
              {element?.is_coming_soon && "Coming Soon"}
              {element?.is_coming_soon && element?.is_active && " & "}
              {!element?.is_coming_soon && !element?.is_active && (
                <hr style={{ width: "5vmax" }} />
              )}
              {element?.is_active && "Active"}
            </TableCell>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
      {!id && (
        <Paginator count={data?.categories?.last_page} disabled={loading} />
      )}{" "}
      {open && (
        <MoveCategoryHandle
          data={itemInfo}
          onClose={() => {
            setItemInfo(null);
            setOpen(false);
          }}
        />
      )}
      {openDelete && (
        <NoticeConfirmation
          data={itemInfo}
          handleResponse={handleDeleteResponse}
        />
      )}
    </>
  );
};

export default withH1(Categories, "Categories");
