import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Grid, Skeleton } from "@mui/material";
import Button from "../../../components/button/Button";
import {
  FormInput,
  FormTextArea,
} from "../../../components/formComponents/FormComponents";
import { useGet } from "../../../hooks/useFetch";
import { useGlobalValues } from "../../../context/GlobalContext";
import { updateSectionone } from "../../../core/apis/store";
import { AlertContext } from "../../../context/AlertContext";
import { Switch } from "@mui/material";
import Cropper from "../../../components/singleUpload/imageCropper";
import "./about.scss";
import { ColorPicker } from "../../../components/colorPicker/colorpicker";

const BannerSection = () => {
  const [loading, setLoading] = useState(false);
  const [bannerimageVal, setBannerImageValue] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();
  const [titleColor, setTitleColor] = useState("#041C3F");
  const [paragraphColor, setParagraphColor] = useState("#041C3F");
  const [isFetchingData, setIsFetchingData] = useState(true);

  const sectiontwoSchema = yup.object({
    title: yup.string().nullable().required("Title is required"),
    description: yup.string().nullable().required("Description is required"),
    bannerImage: yup.mixed().nullable().required("Header Image is required"),
    buttonText: yup.string().nullable().required("Button Text is required"),
    buttonLink: yup
      .string()
      .nullable()
      .required("Button Link is required")
      .url("Please enter a valid URL"), // Validation for a valid URL
  });

  const handleCancel = () => {
    refetch();
  };

  const handleBannerImage = (croppedImage) => {
    setBannerImageValue(croppedImage);
    setValue("bannerImage", croppedImage);
  };

  const defaults = {
    title: "",
    description: "",
    active: true,
    bannerImage: null,
    titleColor: "#041C3F",
    paragraphColor: "#041C3F",
    buttonText: "",
    buttonLink: "",
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sectiontwoSchema),
    defaultValues: defaults, // Set default values here
  });

  // Get Section Details by Slug
  const {
    data: sectionDetails,
    loading: loadingDetails,
    refetch,
  } = useGet({
    url: "admin/section/ABOUT_US_BANNER_SECTION",
  });
  const aboutusbannersectionArray = sectionDetails?.data?.data;

  useEffect(() => {
    if (aboutusbannersectionArray) {
      setIsFetchingData(true);

      const titleObject = aboutusbannersectionArray?.columns?.find(
        (item) => item.title && item.language === storeLanguage
      );
      const descriptionObject = aboutusbannersectionArray?.columns?.find(
        (item) => item.description && item.language === storeLanguage
      );
      const titleColorObject = aboutusbannersectionArray?.columns?.find(
        (item) => item.button_color && item.language === storeLanguage
      );
      const paragraphColorObject = aboutusbannersectionArray?.columns?.find(
        (item) => item.button_text_color && item.language === storeLanguage
      );
      const activeObject = aboutusbannersectionArray?.columns?.find(
        (item) => item.is_enable && item.language === storeLanguage
      );
      const imageObject = aboutusbannersectionArray?.columns?.find(
        (item) => item.image && item.language === storeLanguage
      );
      const buttontextObject = aboutusbannersectionArray?.columns?.find(
        (item) => item.button_text && item.language === storeLanguage
      );
      const buttonlinkObject = aboutusbannersectionArray?.columns?.find(
        (item) => item.button_link && item.language === storeLanguage
      );

      const populatedFormData = {
        title: titleObject?.title,
        description: descriptionObject?.description,
        titleColor: titleColorObject?.button_color,
        paragraphColor: paragraphColorObject?.button_text_color,
        active: activeObject?.is_enable,
        bannerImage: imageObject?.image,
        buttonLink: buttonlinkObject?.button_link,
        buttonText: buttontextObject?.button_text,
      };

      // Setting form values using setValue
      Object.keys(populatedFormData).forEach((key) => {
        setValue(key, populatedFormData[key]);
      });

      setTitleColor(populatedFormData.titleColor);
      setParagraphColor(populatedFormData.paragraphColor);

      setIsFetchingData(false);
    }
  }, [aboutusbannersectionArray, storeLanguage, setValue]);

  const handleSubmitForm = async (values) => {
    let formData = new FormData();
    setLoading(true);

    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("image", values.bannerImage);
    formData.append("is_enable", values.active ? 1 : 0);
    formData.append("language", storeLanguage);
    formData.append("button_color", titleColor);
    formData.append("button_text_color", paragraphColor);
    formData.append("button_text", values.buttonText);
    formData.append("button_link", values.buttonLink);

    if (values.bannerImage instanceof File === false) {
      formData.delete("image");
    }

    updateSectionone("ABOUT_US_BANNER_SECTION", formData).then((res) => {
      setAlert({
        visible: true,
        text: res?.data.success ? "Successfully Updated" : res?.data?.message,
        type: res?.data?.success ? "success" : "error",
      });
      refetch();
      setLoading(false);
    });
  };

  return (
    <div className="bannerSection">
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {isFetchingData ? (
              <Skeleton variant="rectangular" width={100} height={40} />
            ) : (
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="switch">
                    <label>Enable this block</label>
                    <Switch
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                    />
                  </div>
                )}
                name="active"
                control={control}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {isFetchingData ? (
              <Skeleton variant="text" width="100%" height={80} />
            ) : (
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Box>
                    <Cropper
                      required
                      sendCroppedFile={handleBannerImage}
                      MAX_IMAGE_WIDTH={1500}
                      MAX_IMAGE_HEIGHT={400}
                      title={"Drag your Banner Image here only webp"}
                      className="width100 height100"
                      isTrue={true}
                      iwebp={true}
                      imageSrc={getValues("bannerImage")}
                    />
                    {error && value == null ? (
                      <p className="error">{error.message}</p>
                    ) : null}
                  </Box>
                )}
                name="bannerImage"
                control={control}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={40} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="title"
                        label="Title"
                        hideVariant
                        placeholder={"Enter Title"}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="title"
                    control={control}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width="100%" height={120} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormTextArea
                        required
                        hideVariant
                        name="description"
                        label="Sub Paragraph"
                        placeholder={"Enter sub paragraph"}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="description"
                    control={control}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={40} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="buttonText"
                        label="Button Text"
                        hideVariant
                        placeholder={"Shop Now"}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="buttonText"
                    control={control}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={40} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="buttonLink"
                        label="Button Link"
                        hideVariant
                        placeholder={"Button Link"}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="buttonLink"
                    control={control}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width="100%" height={40} />
                ) : (
                  <ColorPicker
                    label="Button Text Color"
                    selectedColor={titleColor}
                    onColorChange={setTitleColor}
                    isLoading={isFetchingData}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width="100%" height={40} />
                ) : (
                  <ColorPicker
                    label="Text Color"
                    selectedColor={paragraphColor}
                    onColorChange={setParagraphColor}
                    isLoading={isFetchingData}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                onClick={() => handleCancel()}
                name={"Cancel"}
                type="button"
                className="mx-1"
              />
            </Grid>
            <Grid item>
              {isFetchingData ? (
                <Skeleton variant="rectangular" width={100} height={40} />
              ) : (
                <Button
                  selected
                  name={"Save"}
                  type="submit"
                  loading={loading}
                  className="mx-1"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default BannerSection;
