import { api } from "./main";

export const CreateCollectionPoint = async (payload) => {
  return await api
    .post("admin/create-collection-point", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const EditCollectionPoint = async (payload) => {
  return await api
    .put(`admin/update-collection-point/${payload?.cp_id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deleteCollectionPoint = async (payload) => {
  return await api
    .delete(`admin/delete-collection-point/${payload}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getAllCollectionPoints = async (payload) => {
  return await api
    .get(`admin/get-all-collection-point`, { params: { ...payload } })
    .then((res) => res)
    .catch((err) => console.error(err));
};
