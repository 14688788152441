import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid, Skeleton } from "@mui/material";
import Button from "../../../components/button/Button";
import FormMultiSelect, {
  FormInput,
  FormTextArea,
} from "../../../components/formComponents/FormComponents";
import { useGet } from "../../../hooks/useFetch";
import { useGlobalValues } from "../../../context/GlobalContext";
import { updateSectionone } from "../../../core/apis/store";
import { AlertContext } from "../../../context/AlertContext";
import { ColorPicker } from "../../../components/colorPicker/colorpicker";
import "./sellmydevice.scss";

const Sectionone = () => {
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();
  const [titleColor, setTitleColor] = useState("");
  const [paragraphColor, setParagraphColor] = useState("");
  const [isFetchingData, setIsFetchingData] = useState(true);

  const sectiononeSchema = yup.object({
    title: yup.string().nullable().required("Field is required"),
    description: yup.string().nullable().required("Field is required"),
    categories_id: yup
      .array()
      .min(1, "At least one category is required")
      .required("Field is required"),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sectiononeSchema),
  });

  const handleCancel = () => {
    refetch();
  };

  // Get Section Details by Slug
  const {
    data: sectionDetails,
    loading: loadingDetails,
    refetch,
  } = useGet({
    url: "admin/section/SELL_MY_DEVICE_SECTION_ONE",
  });
  const sectionDetailsArray = sectionDetails?.data?.data;

  // CategoriesMultiSelect API
  const {
    data: categories,
    loading: loadingCategories,
    error,
  } = useGet({
    url: "admin/get-all-categories",
  });
  const categoriesArray = categories?.data?.data?.categories;

  const handleSubmitForm = async (values) => {
    setLoading(true);
    const categoriesID = values.categories_id.map((element) => element.id);
    const payload = {
      language: storeLanguage,
      categories: categoriesID,
      title: values.title,
      description: values.description,
      paragraph_color: paragraphColor,
      title_color: titleColor,
    };

    updateSectionone("SELL_MY_DEVICE_SECTION_ONE", payload).then((res) => {
      setAlert({
        visible: true,
        text: res?.data.success ? "Successfully Updated" : res?.data?.message,
        type: res?.data?.success ? "success" : "error",
      });
      refetch();
      setLoading(false);
    });
  };

  useEffect(() => {
    if (sectionDetailsArray) {
      setIsFetchingData(true);
      const titleObject = sectionDetailsArray?.columns?.find(
        (item) => item.title && item.language === storeLanguage
      );
      const descriptionObject = sectionDetailsArray?.columns?.find(
        (item) => item.description && item.language === storeLanguage
      );
      const titleColorObject = sectionDetailsArray?.columns?.find(
        (item) => item.title_color && item.language === storeLanguage
      );
      const paragraphColorObject = sectionDetailsArray?.columns?.find(
        (item) => item.paragraph_color && item.language === storeLanguage
      );

      const populatedFormData = {
        title: titleObject?.title || "",
        description: descriptionObject?.description || "",
        title_color: titleColorObject?.title_color || "#041C3F",
        paragraph_color: paragraphColorObject?.paragraph_color || "#041C3F",
      };

      setValue("title", populatedFormData.title);
      setValue("description", populatedFormData?.description);
      setValue("categories_id", sectionDetailsArray?.categories);
      setValue("title_color", populatedFormData?.title_color);
      setValue("paragraph_color", populatedFormData?.paragraph_color);

      setTitleColor(populatedFormData?.title_color);
      setParagraphColor(populatedFormData?.paragraph_color);

      setIsFetchingData(false);
    }
  }, [sectionDetailsArray, storeLanguage, setValue]);

  return (
    <div className="sectionone">
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={40} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="title"
                        label="Title"
                        hideVariant
                        placeholder={"Enter Title"}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="title"
                    control={control}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width="100%" height={120} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormTextArea
                        required
                        hideVariant
                        name="description"
                        label="Description"
                        placeholder={"Enter Description"}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="description"
                    control={control}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width="100%" height={40} />
                ) : (
                  <Controller
                    name="categories_id"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormMultiSelect
                        required
                        hideVariant
                        label="Category"
                        placeholder="Select Category"
                        name="categories_id"
                        id="categories_id"
                        loading={loadingCategories}
                        value={value || []}
                        data={categoriesArray || []}
                        onChange={(e) => onChange(e)}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <ColorPicker
                  label="Title Color"
                  selectedColor={titleColor}
                  onColorChange={setTitleColor}
                  isLoading={isFetchingData}
                />
              </Grid>

              <Grid item xs={12}>
                <ColorPicker
                  label="Paragraph Color"
                  selectedColor={paragraphColor}
                  onColorChange={setParagraphColor}
                  isLoading={isFetchingData}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  onClick={() => handleCancel()}
                  name={"Cancel"}
                  type="button"
                  className="mx-1"
                />
              </Grid>
              <Grid item>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width={100} height={40} />
                ) : (
                  <Button
                    selected
                    name={"Save"}
                    type="submit"
                    loading={loading}
                    className="mx-1"
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Sectionone;
