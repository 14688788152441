import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./Login.scss";
import Button from "../../components/button/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { IconButton, InputAdornment } from "@mui/material";
import { Input } from "../../components/input/Input";
import { useAuth } from "../../hooks/useAuth";
import { LockOutlined, MailOutline } from "@mui/icons-material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LogoSVG } from "../../assests/icons/SVG";
import { NotificationContext } from "../../context/NotificationContext";

const loginSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
});

const LoginComponent = () => {
  const { updateNotificationCount } = useContext(NotificationContext);
  const { login } = useAuth();

  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      const data = {
        user_type_id: 1,
        email: values.email,
        password: values.password,
      };
      setLoading(true);

      login(data).then((res) => {
        if (res?.data?.success) {
          updateNotificationCount();
        }
        setLoading(false);
      });
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <div className="LoginPage">
      <div className="wrap1">
        <h1>Admin Log In</h1>
        <form className="loginForm form" onSubmit={formik.handleSubmit}>
          <Input
            className="curvedInput"
            variant="outlined"
            type="email"
            placeholder="Enter Email"
            name="email"
            id="email"
            ref={inputRef}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutline style={{ color: "#4BA4F0" }} />
                </InputAdornment>
              ),
            }}
          />
          <Input
            className="curvedInput"
            type={showPassword ? "text" : "password"}
            placeholder="password"
            name="password"
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined style={{ color: "#4BA4F0" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Link className="forgotPassword" to="/forgot-password">
            Forgot password
          </Link>
          <Button
            name="Log In"
            width="100%"
            selected
            loading={loading}
            disabled={!formik.dirty || loading}
            type="submit"
          />
        </form>
      </div>
      <div className="wrap2">
        <LogoSVG className="logo" />
      </div>
    </div>
  );
};
export default LoginComponent;
