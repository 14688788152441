import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid, Skeleton } from "@mui/material";
import Button from "../../../components/button/Button";
import {
  FormInput,
  FormTextArea,
} from "../../../components/formComponents/FormComponents";
import { PhoneInput } from "../../../components/phoneInput/PhoneInput";
import { useGet } from "../../../hooks/useFetch";
import { useGlobalValues } from "../../../context/GlobalContext";
import { updateSectionone } from "../../../core/apis/store";
import { AlertContext } from "../../../context/AlertContext";
import "./footer.scss";
import { Switch } from "@mui/material";
import Cropper from "../../../components/singleUpload/imageCropper";

const PaymentMethod = () => {
  const [loading, setLoading] = useState(false);
  const [headerimageVal, setHeaderImageValue] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [phoneNumber_error, setPhone_number_error] = useState("");

  // Yup schema validation
  const paymentmethodSchema = yup.object({
    title: yup.string().nullable().required("Field is required"),
    headerImage: yup.mixed().nullable().required("Field is required"),
    active: yup.bool().nullable().required("Field is required"),
  });

  const defaults = {
    title: "",
    headerImage: "",
    active: true,
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(paymentmethodSchema),
    defaultValues: defaults,
  });

  const {
    data: sectionDetails,
    loading: loadingDetails,
    refetch,
  } = useGet({
    url: "admin/section/PAYMENT_METHOD_SECTION",
  });

  const paymentmethodArray = sectionDetails?.data?.data;

  const handleCancel = () => {
    refetch();
  };

  const handleHeaderImage = (croppedImage) => {
    setHeaderImageValue(croppedImage);
    setValue("headerImage", croppedImage);
  };

  const handleSubmitForm = async (values) => {
    console.log(values);
    let formData = new FormData();
    setLoading(true);

    formData.append("title", values?.title);
    formData.append("image", values?.headerImage);
    formData.append("is_enable", values?.active ? 1 : 0);
    formData.append("language", storeLanguage);

    if (values.headerImage instanceof File === false) {
      formData.delete("image");
    }

    updateSectionone("PAYMENT_METHOD_SECTION", formData).then((res) => {
      setAlert({
        visible: true,
        text: res?.data.success ? "Successfully Updated" : res?.data?.message,
        type: res?.data?.success ? "success" : "error",
      });
      refetch();
      setLoading(false);
    });
  };

  useEffect(() => {
    if (paymentmethodArray) {
      setIsFetchingData(true);

      // Helper function to find and return the value for a given key
      const findValue = (key) => {
        const foundObject = paymentmethodArray?.columns?.find(
          (item) => item.language === storeLanguage && item[key] !== undefined
        );
        return foundObject ? foundObject[key] : "";
      };

      const populatedFormData = {
        title: findValue("title"),
        headerImage: findValue("image"),
        active: findValue("is_enable"),
      };

      setValue("title", populatedFormData.title);
      setValue("headerImage", populatedFormData.headerImage);
      setValue("active", populatedFormData.active);

      setIsFetchingData(false);
    }
  }, [paymentmethodArray, storeLanguage, setValue]);

  return (
    <div className="paymentmethodSection">
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Grid item className="displayEnd">
              {isFetchingData ? (
                <Skeleton variant="text" width="100%" height={40} />
              ) : (
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div className="switch">
                      <label>Enable This Block</label>
                      <Switch
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                      />
                    </div>
                  )}
                  name="active"
                  control={control}
                />
              )}
            </Grid>
            <Grid item>
              {isFetchingData ? (
                <Skeleton variant="text" width="100%" height={40} />
              ) : (
                <Controller
                  name="title"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      required
                      name="title"
                      label="Title"
                      hideVariant
                      placeholder="Contact Info"
                      value={value || ""}
                      onChange={(e) => onChange(e.target.value)}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {isFetchingData ? (
                <Skeleton variant="text" width="100%" height={40} />
              ) : (
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div>
                      <Cropper
                        required
                        sendCroppedFile={handleHeaderImage}
                        MAX_IMAGE_WIDTH={500}
                        MAX_IMAGE_HEIGHT={500}
                        title={"Drag your payments logo here only webp"}
                        className="width100 height100"
                        isTrue={true}
                        iwebp={true}
                        imageSrc={getValues("headerImage")}
                      />
                      {error && headerimageVal == null ? (
                        <p className="error">{error.message}</p>
                      ) : null}
                    </div>
                  )}
                  name="headerImage"
                  control={control}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            spacing={2}
            style={{ marginTop: "20px" }}
          >
            <Grid item>
              {isFetchingData ? (
                <Skeleton variant="rectangular" width={100} height={40} />
              ) : (
                <Button
                  onClick={() => handleCancel()}
                  name={"Cancel"}
                  type="button"
                  className="mx-1"
                />
              )}
            </Grid>
            <Grid item>
              {isFetchingData ? (
                <Skeleton variant="rectangular" width={100} height={40} />
              ) : (
                <Button
                  selected
                  name={"Save"}
                  type="submit"
                  className="mx-1"
                  loading={loading}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default PaymentMethod;
