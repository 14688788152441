import React, { useState, useEffect, useContext } from "react";
import "./BannerDetails.scss";
import { useNavigate } from "react-router";
import { Card, Skeleton } from "@mui/material";
import {
  FormCalendarInput,
  FormCheckBox,
  FormCheckboxDropdown,
  FormInput,
  FormSingleUpload,
  FormSwitch,
} from "../../../components/formComponents/FormComponents";
import Button from "../../../components/button/Button";
import { useFormik } from "formik";
import { getBannerById } from "../../../core/apis/banner";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useGet } from "../../../hooks/useFetch";
import dayjs from "dayjs";
import { createBanner, updateBanner } from "../../../core/apis/banner";
import { getFormData, removeBlob } from "../../../core/functions/Functions";
import { AlertContext } from "../../../context/AlertContext";
import NewEditor from "../../../components/editor/NewEditor";

const bannerSchema = yup.object({
  name: yup.string("Field is invalid").nullable().required("Field is required"),
  url: yup
    .string("Field is invalid")
    .url("Field is invalid")
    .nullable()
    .required("Field is required"),
  start_date: yup
    .string("Field is invalid")
    .nullable()
    .required("Field is required"),
  end_date: yup
    .string("Field is invalid")
    .nullable()
    .required("Field is required"),
  is_new_tab: yup.boolean(),
  country_ids: yup.array().nullable().min(1, "Field is required"),
  image: yup.mixed().nullable().required("Field is required"),
  mobile_image: yup.mixed().nullable().required("Field is required"),
  description: yup.string().nullable(),
});

const BannerDetails = (props) => {
  let { id } = useParams() || {};
  const { create } = props;
  let navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const { data: countries, loading: loadingCountries } = useGet({
    url: `get-all-countries`,
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      image: "",
      mobile_image: "",
      country_ids: [],
      start_date: "",
      end_date: "",
      url: "",
      banner_position_id: 1,
      is_active: true,
      is_new_tab: false,
      description: "",
    },

    enableReinitialize: true,
    validationSchema: bannerSchema,
    onSubmit: () => {
      callAPI();
    },
  });

  const callAPI = () => {
    setSaveLoading(true);
    if (create) {
      createBanner(
        getFormData(removeBlob(formik?.values, ["image", "mobile_image"])),
      ).then((res) => {
        setAlert({
          visible: true,
          text: res.data ? res.data.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
        if (res.data?.success) {
          navigate("/banners");
        }
        setSaveLoading(false);
      });
    } else {
      updateBanner({
        payload: getFormData(
          removeBlob(formik?.values, ["image", "mobile_image"]),
        ),
        id: id,
      }).then((res) => {
        setAlert({
          visible: true,
          text: res.data ? res.data.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
        if (res.data?.success) {
          navigate("/banners");
        }
        setSaveLoading(false);
      });
    }
  };
  useEffect(() => {
    !create &&
      getBannerById(id).then((res) => {
        formik.setFieldValue("is_active", res?.data?.data?.is_active);
        formik.setFieldValue("name", res?.data?.data?.name);

        formik.setFieldValue("image", res?.data?.data?.image_path);
        formik.setFieldValue(
          "mobile_image",
          res?.data?.data?.mobile_image_path,
        );
        formik.setFieldValue("url", res?.data?.data?.url);
        formik.setFieldValue("is_new_tab", res?.data?.data?.is_new_tab);

        formik?.setFieldValue(
          "country_ids",
          res?.data?.data?.country?.map((i) => i?.id),
        );
        formik.setFieldValue("start_date", res?.data?.data?.start_date);
        formik.setFieldValue("end_date", res?.data?.data?.end_date);
        formik.setFieldValue("description", res?.data?.data?.description);

        setLoading(false);
      });
  }, []);

  return (!create && loading) || loadingCountries ? (
    <Skeleton count={10} />
  ) : (
    <div className="bannerPage">
      <h1>{create ? "New Banner" : formik?.values?.title}</h1>
      <form className="bannerDetails" onSubmit={formik.handleSubmit}>
        <Card className="bannerDetailsCard " style={{ padding: "16px 24px" }}>
          <FormInput
            label="Banner Name"
            placeholder="Enter Banner Name"
            name="name"
            id="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            required
          />
          <div className="form-double-children">
            <FormCalendarInput
              label="Start Date"
              required
              minDate={new Date()}
              value={formik?.values?.start_date}
              helperText={formik.touched.start_date && formik.errors.start_date}
              onChange={(value) => {
                formik.setFieldValue(
                  "start_date",
                  dayjs(value).format("YYYY-MM-DD"),
                );
                formik.setFieldValue("end_date", null);
              }}
            />
            <FormCalendarInput
              label="End Date"
              required
              minDate={formik.values.start_date}
              value={formik?.values?.end_date}
              helperText={formik.touched.end_date && formik.errors.end_date}
              onChange={(value) => {
                formik.setFieldValue(
                  "end_date",
                  dayjs(value).format("YYYY-MM-DD"),
                );
              }}
            />
          </div>

          <div className="url-link">
            <FormInput
              label="URL Link"
              placeholder="URL Link"
              name="url"
              id="url"
              value={formik?.values?.url}
              onChange={(e) => {
                formik.setFieldValue("url", e?.target?.value);
              }}
              error={formik.touched.url && Boolean(formik.errors.url)}
              helperText={formik.touched.url && formik.errors.url}
              required
            />
            <FormCheckBox
              label="Open link in a new tab"
              value={formik?.values?.is_new_tab}
              name="is_new_tab"
              onChange={(e) => {
                formik.setFieldValue("is_new_tab", e?.target?.checked);
              }}
            />
          </div>

          <FormCheckboxDropdown
            label="Countries"
            name="country_ids"
            id="country_ids"
            multiple
            helperText={formik.touched.country_ids && formik.errors.country_ids}
            loading={loadingCountries}
            options={countries?.data?.data || []}
            required
            value={countries?.data?.data.filter((i) =>
              formik?.values?.country_ids.includes(+i?.id),
            )}
            onChange={(_, value) => {
              formik.setFieldValue(
                "country_ids",
                value.some((option) => option.value === "select-all")
                  ? countries?.data?.data.map((i) => i.id)
                  : value.map((i) => i.id) || formik.initialValues.country_ids,
              );
            }}
          />
          {!create && (
            <FormSwitch
              label="active"
              value={formik.values.is_active}
              onChange={(_, checked) =>
                formik.setFieldValue("is_active", checked)
              }
            />
          )}
          <br />
          <NewEditor
            label={"Description"}
            value={formik?.values?.description}
            onChange={(e) => {
              formik.setFieldValue("description", e);
            }}
          />
          <div className="action-footer">
            <Button
              name={create ? "Create" : "Update"}
              selected
              loading={saveLoading}
              disabled={(!create && loading) || saveLoading}
              type="submit"
            />
          </div>
        </Card>

        <div className="upload-images">
          <FormSingleUpload
            name="image"
            label="Upload Web"
            value={formik.values.image}
            helperText={formik.touched.image && formik.errors.image}
            onChange={(file) => {
              formik.setFieldValue("image", file);
            }}
            required
          ></FormSingleUpload>
          <FormSingleUpload
            required
            name="mobile_image"
            label="Upload Mobile"
            value={formik.values.mobile_image}
            helperText={
              formik.touched.mobile_image && formik.errors.mobile_image
            }
            onChange={(file) => {
              formik.setFieldValue("mobile_image", file);
            }}
          ></FormSingleUpload>
        </div>
      </form>
    </div>
  );
};

export default BannerDetails;
