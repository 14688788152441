import React, { forwardRef } from "react";
import { TextField } from "@mui/material";
import "./Input.scss";
export const Input = forwardRef((props, ref) => {
  const { className } = props;
  return (
    <TextField
      ref={ref}
      {...props}
      fullWidth
      margin="none"
      className={className ? `inputControl ${className}` : ""}
    />
  );
});
