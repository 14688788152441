import React, { useContext, useState } from "react";
import { TableCell, IconButton } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Paginator from "../../components/paginator/Paginator";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import { useGet } from "../../hooks/useFetch";
import "./reports.scss";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { AlertContext } from "../../context/AlertContext";
import { deleteReport } from "../../core/apis/reports";
import withH1 from "../../assests/HOC/withH1";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { Paper } from "@mui/material";
import moment from "moment";

const Reports = () => {
  const { setAlert } = useContext(AlertContext);
  const tableHeaders = [
    { title: "ID" },
    { title: "NAME" },
    { title: "DATE" },
    { title: "TYPE" },
    { title: "EXPORTED BY" },
  ];
  const [searchParams] = useSearchParams();
  const [reportDetail, setReportDetail] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [downloading, setDownloading] = useState(false);
  let navigate = useNavigate();
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };
  let url = `admin/report/get-all-reports`;
  const {
    data: reports,
    loading,
    refetch,
  } = useGet({ url: url, payload: payload });
  let data = reports?.data.data;

  const getReportTypeClass = (type) => {
    switch (type) {
      case "Organization Report":
        return "organizationReport";
      case "Branch Report":
        return "branchReport";
      case "User Report":
        return "userReport";
      case " Transaction Report":
        return "transactionReport";
      case " Items Report":
        return "itemReport";
    }
  };

  // Handle Download PriceList
  const handleDownload = (fileUrl, fileName) => {
    setDownloading(true);
    console.log(fileUrl);

    // Creating a new object for the file
    let alink = document.createElement("a");
    alink.href = process.env.REACT_APP_IMAGE_URL + "/" + fileUrl;
    alink.download = fileName;
    alink.click();

    setDownloading(false);
  };

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteReport(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setReportDetail(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res?.data?.success
            ? "Deleted SuccessFully"
            : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  return (
    <>
      <div className="exportList">
        <Paper elevation={24} className="paper-div">
          <TableBodyComponent
            header={tableHeaders}
            loading={loading}
            total={data?.total}
          >
            {data?.data?.map((element) => (
              <TableRowComponent
                internal
                key={element?.id} // Add a unique key for each row
              >
                <TableCell>{element?.id}</TableCell>
                <TableCell>{element?.report_name}</TableCell>
                <TableCell>
                  {element?.created_at
                    ? moment(element?.created_at).calendar()
                    : "---"}
                </TableCell>
                <TableCell>
                  <div
                    // className={`${"reportType"} ${getReportTypeClass(
                    //   element?.report_type
                    // )}`}
                    className="reportType"
                  >
                    {element?.report_type}
                  </div>
                </TableCell>

                <TableCell>{element?.user?.full_name}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() =>
                      handleDownload(element?.report_path, "report.xlsx")
                    }
                    disabled={downloading}
                  >
                    <DownloadIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setReportDetail(element);
                      setOpenDelete(true);
                    }}
                    sx={{ color: "red" }} // Set the color to red
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRowComponent>
            ))}
          </TableBodyComponent>
        </Paper>
        <Paginator count={data?.last_page} disabled={loading} />
        {openDelete && (
          <NoticeConfirmation
            data={reportDetail}
            handleResponse={handleDeleteResponse}
          />
        )}
      </div>
    </>
  );
};

export default withH1(Reports, "Reports");
