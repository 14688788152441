// EXTERNAL LIBRARIES
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TableCell } from "@mui/material";

// STYLING
import "./Countries.scss";

// TABLE
import TableHeader from "../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import Paginator from "../../components/paginator/Paginator";

// CUSTOM HOOKS
import { useGet } from "../../hooks/useFetch";

// DATA NOT FOUND
import DataNotFound from "../../components/notFound/DataNotFound";
import withH1 from "../../assests/HOC/withH1";

const Countries = () => {
  // DATA AND HOOKS
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const tableHeaders = [
    { title: "Name" },
    { title: "Short Code" },
    { title: "Country Factor" },
  ];

  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };

  let url = `admin/get-all-countries`;

  // API CALL
  const { data: countries, loading } = useGet({ url: url, payload: payload });
  let data = countries?.data.data;

  return (
    <>
      <TableHeader
        placeholder="Search by name"
        to="/countries/new-country"
        filterButton={false}
      />
      <TableBodyComponent
        header={tableHeaders}
        loading={loading}
        total={data?.total}
      >
        {data?.data ? (
          data?.data?.map((element) => (
            <TableRowComponent
              handleEdit={() => navigate(`/countries/country/${element?.id}`)}
              row={element}
              key={element?.id}
            >
              <TableCell>{element?.name}</TableCell>
              <TableCell>{element?.country_code}</TableCell>
              <TableCell>{element?.factor}</TableCell>
              {/* <TableCell>{element?.category.name.en}</TableCell> */}
            </TableRowComponent>
          ))
        ) : (
          <DataNotFound />
        )}
      </TableBodyComponent>
      {data?.data && <Paginator count={data?.last_page} disabled={loading} />}
    </>
  );
};

export default withH1(Countries, "Countries");
