//UTILITIES
import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

//COMPONENT
import { TableCell } from "@mui/material";
import Paginator from "../../components/paginator/Paginator";
import TableHeader from "../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";

//API
import { useGet } from "../../hooks/useFetch";
//CSS
import "./Organizations.scss";
import withH1 from "../../assests/HOC/withH1";

export function Organizations(props) {
  //VARIABLES
  let navigate = useNavigate();
  const tableHeaders = [
    { title: "Organization", sorted: false },
    { title: "Country", sorted: false },
    { title: "Factor", sorted: false },
  ];

  const [searchParams] = useSearchParams(); //pagination and filter
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
    country_ids: searchParams.getAll("country_ids"),
  };

  const { data: organizations, loading } = useGet({
    url: "admin/get-all-organization",
    payload: payload,
  });
  let organizationsArray = organizations?.data.data;

  return (
    <div className="organizations">
      <TableHeader
        placeholder="Search by organization name"
        to="/organizations/new-organization"
        filterByCountry
        checkbox
      />
      <TableBodyComponent
        header={tableHeaders}
        loading={loading}
        total={organizationsArray?.total}
      >
        {organizationsArray?.data?.map((element) => (
          <TableRowComponent
            row={element}
            handleEdit={() =>
              navigate(`/organizations/edit-organization/${element?.id}`)
            }
          >
            <TableCell>{element.name?.en}</TableCell>
            <TableCell>{element.address?.[0]?.country?.name}</TableCell>
            <TableCell>{element.factor}</TableCell>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
      <Paginator count={organizationsArray?.last_page} disabled={loading} />
    </div>
  );
}

export default withH1(Organizations, "Organizations");
