import React, { useState } from "react";
import { Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./footer.scss";
import PaymentMethod from "./paymentMethod";
import QuickLinks from "./quickLinks";
import SocialLinks from "./socialLinks";
import ContactInfo from "./contactInfo";
import FooterOpenText from "./footeropentext"

const Footer = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="footer">
      <TabPanel value="11">
        <TabContext value={tabValue}>
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label="CONTACT INFO" value="0" />
            <Tab label="SOCIAL LINKS" value="1" />
            <Tab label="PAYMENT METHOD" value="2" />
            <Tab label="QUICK LINKS " value="3" />
            <Tab label="Footer Open Text " value="4" />
          </TabList>

          <TabPanel value="0" style={{ minHeight: "600px", padding: "0px" }}>
            <ContactInfo></ContactInfo>
          </TabPanel>
          <TabPanel value="1" style={{ minHeight: "600px", padding: "0px" }}>
            <SocialLinks></SocialLinks>
          </TabPanel>
          <TabPanel value="2" style={{ minHeight: "600px", padding: "0px" }}>
            <PaymentMethod></PaymentMethod>
          </TabPanel>
          <TabPanel value="3" style={{ minHeight: "600px", padding: "0px" }}>
            <QuickLinks></QuickLinks>
          </TabPanel>
          <TabPanel value="4" style={{ minHeight: "600px", padding: "0px" }}>
            <FooterOpenText></FooterOpenText>
          </TabPanel>
        </TabContext>
      </TabPanel>
    </div>
  );
};

export default Footer;
