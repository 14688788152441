import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import RootLayout from "./rootLayout/RootLayout";

export const LoginLayout = () => {
  const { user } = useAuth();
  if (user) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <RootLayout auth>
      <Outlet />
    </RootLayout>
  );
};
