import * as React from "react";
import { CircularProgress, Box } from "@mui/material";
import "./Spinner.scss";

export default function CircularIndeterminate(props) {
  return (
    <Box sx={{ display: "flex" }}>
      <div
        className="spinner"
        style={{
          width: props.width,
          height: props.height,
          margin: props.margin,
        }}
      >
        <CircularProgress />
      </div>
    </Box>
  );
}
