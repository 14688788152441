import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../../pages/login/Login";
import Dashboard from "../../pages/dashboard/Dashboard";
import Banners from "../../pages/banners/Banners";
import EmailsNotifications from "../../pages/notification/emailsNotification/EmailsNotifications";
import AddNotification from "../../pages/notification/emailsNotification/addNotification/AddNotification";
import ViewNotification from "../../pages/notification/emailsNotification/viewNotification/ViewNotification";
import UserGroups from "../../pages/notification/userGroups/UserGroups";
import UserGroupsDetails from "../../pages/notification/userGroups/userGroupsDetails/UserGroupsDetails";
import Roles from "../../pages/roles/roles/Roles";
import RoleName from "../../pages/roles/role-name/RoleName";
import ProductSKU from "../../pages/productSKU/ProductSKU";
import ProductSKUDetails from "../../pages/productSKU/productDetails/ProductSKUDetails";
import QuestionsHandle from "../../pages/setOfQuestions/addQuestion/QuestionsHandle";
import Questions from "../../pages/setOfQuestions/Questions";
import CategoryQuestions from "../../pages/setOfQuestions/categoryQuestions/CategoryQuestions";
import ItemDetails from "../../pages/items/itemDetails/ItemDetails";
import OpenTransaction from "../../pages/transactions/OpenTransactions";
import HistoricalTransactions from "../../pages/transactions/HistoricalTransactions";
import TransactionSellDetails from "../../pages/transactionDetails/sell/TransactionSellDetails";
import TransactionBuyDetails from "../../pages/transactionDetails/buy/TransactionBuyDetails";
import PaymentInformation from "../../pages/paymentInformation/PaymentInformation";
import CurrencySetting from "../../pages/currencySetting/CurrencySetting";
import CreateRole from "../../pages/roles/create-new-role/CreateRole";
import AccountDetails from "../../pages/accountDetails/AccountDetails";
import ChangePassword from "../../pages/accountDetails/AccountDetailsContent/ChangePassword/ChangePassword";
import PersonalInfo from "../../pages/accountDetails/AccountDetailsContent/PersonalInfo/PersonalInfo";
import Brands from "../../pages/brand-product-type/brands/Brands";
import UsageTypes from "../../pages/brand-product-type/usageTypes/UsageTypes";
import ItemIdentities from "../../pages/brand-product-type/itemIdentities/ItemIdentities";
import Categories from "../../pages/brand-product-type/categories/Categories";
import Products from "../../pages/brand-product-type/products/Products";
import ProductDetails from "../../pages/brand-product-type/products/productDetails/ProductDetails";
import Items from "../../pages/items/items/Items";
import NewItem from "../../pages/items/newItem/NewItem";
import Vat from "../../pages/vat/Vat";
import Attributes from "../../pages/attributes/Attributes";
import AttributeDetails from "../../pages/attributes/attributeDetails/AttributeDetails";
import AttributeEdit from "../../pages/attributes/attributeDetails/AttributeEdit";
import Discounts from "../../pages/discounts/Discounts";
import DiscountDetails from "../../pages/discounts/discountdetails/DiscountDetails";
import Countries from "../../pages/countries/Countries";
import CountryDetails from "../../pages/countries/countryDetails/CountryDetails";
import Languagesetting from "../../pages/languages/languagesetting.js";

//FORGOT PASSWORD
import ResetComponent from "../../pages/login/ResetPassword/reset.jsx";
import ResetFormComponent from "../../pages/login/ResetPassword/resetform.jsx";
import OTPComponent from "../../pages/login/ResetPassword/otp.jsx";
import ResetByComponent from "../../pages/login/ResetPassword/select.jsx";

//USERS COMPONENT
import UsersHandle from "../../pages/users-management/UsersHandle";
import Users from "../../pages/users-management/Users";
//CP COMPONENT
import CollectionPoints from "../../pages/collectionPoints/CollectionPoints";
import CollectionPointsHandle from "../../pages/collectionPoints/CollectionPointsHandle";
//ORGANIZATION ROUTES
import Organizations from "../../pages/organizations/Organizations";
import OrganizationsHandle from "../../pages/organizations/OrganizationsHandle";
//BRANCHES ROUTES
import Branches from "../../pages/branches/Branches";
import BranchesHandle from "../../pages/branches/BranchesHandle";
import BrandDetails from "../../pages/brand-product-type/brands/brandDetails/BrandDetails";
import { AuthProvider } from "../../hooks/useAuth";
import { ProtectedLayout } from "../../layout/ProtectedLayout";
import { LoginLayout } from "../../layout/LoginLayout";
// NOT FOUND
import PageNotFound from "../../pages/pageNotFound/PageNotFound";
import ManageRules from "../../pages/manageRules/ManageRules";
import HomeGroup from "../../pages/homePageGroup/HomeGroup";
import HomeGroupHandle from "../../pages/homePageGroup/HomeGroupHandle";
import BannerDetails from "../../pages/banners/bannerDetails/BannerDetails";
import TransferProductSku from "../../pages/transfer/TransferProductSku";
import UserNotifications from "../../pages/userNotification/UserNotifications";
import CategoryHandle from "../../pages/brand-product-type/categories/categoryDetails/CategoryHandle";
import LogisticCompanies from "../../pages/logisticCompanies/LogisticCompanies";
import LogisticCompaniesDetails from "../../pages/logisticCompanies/logisticCompaniesDetails/LogisticCompaniesDetails";
import Ribbons from "../../pages/ribbons/Ribbons";
import CountrySetting from "../../pages/countrySetting/CountrySetting";
import PiceaUsers from "../../pages/Picea/PiceaUsers/PiceaUsers.js";
import PiceaWorkFlow from "../../pages/Picea/PiceaWorkFlow/PiceaWorkFlow.js";
import ManageReailer from "../../pages/ManageRetailer/ManageRetailer.js";
import RetailerInfoPageData from "../../pages/ManageRetailer/RetailerInfoPageData.js";
import ViewProductDetails from "../../pages/brand-product-type/products/productDetails/ProductDetailsViewMode.js";
import ItemNewDetails from "../../pages/itemsList/itemNewDetails.js";
import ItemsList from "../../pages/itemsList/itemsList.js";
import ViewProductSKUDetails from "../../pages/productSKU/viewproductskudetails/viewproductskudetails.js";
import Reports from "../../pages/reports/reports.js";
import ReportDetails from "../../pages/reports/reportdetails/reportdetails.js";
import Exceptions from "../../pages/Exceptions/Exceptions.js";
import ViewExceptions from "../../pages/Exceptions/viewExceptions.js";
import HomePage from "../../pages/StoreCustomization/homePage.js";
import EditProductSKUDetails from "../../pages/productSKU/editproductskudetails/editproductskudetails.js";
import AddProductSku from "../../pages/productSKU/addProductSku/addProductSku.js";

const MainRoute = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<LoginLayout />}>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ResetComponent />} />
            <Route path="/reset-password" element={<ResetByComponent />} />
            <Route path="/reset-password/by-OTP" element={<OTPComponent />} />
            <Route
              path="/new-password/:token"
              element={<ResetFormComponent />}
            />
            <Route path="/new-password" element={<ResetFormComponent />} />
          </Route>

          <Route element={<ProtectedLayout />}>
            <Route path="/account-details/" element={<AccountDetails />}>
              <Route index element={<Navigate to="personal-info" replace />} />
              <Route path="personal-info" element={<PersonalInfo />} />
              <Route path="change-password" element={<ChangePassword />} />
            </Route>
            <Route path="/dashboard" element={<Dashboard />} />
            {/* USERS ROUTES */}
            <Route path="/users" element={<Users />} />
            <Route
              path="/users/new-user"
              element={<UsersHandle type="new" />}
            />
            <Route
              path="/users/edit-user/:id"
              element={<UsersHandle type="edit" />}
            />
            {/* COLLECTION POINTS ROUTES */}
            <Route path="/collection-points" element={<CollectionPoints />} />
            <Route
              path="/collection-points/new-collection-point"
              element={<CollectionPointsHandle type="new" />}
            />
            <Route
              path="/collection-points/edit-collection-point/:id"
              element={<CollectionPointsHandle type="edit" />}
            />
            {/* ORGANIZATION ROUTES */}
            <Route path="/organizations" element={<Organizations />} />
            <Route
              path="/organizations/new-organization"
              element={<OrganizationsHandle type="new" />}
            />
            <Route
              path="/organizations/edit-organization/:id"
              element={<OrganizationsHandle type="edit" />}
            />
            {/* BRANCHES ROUTES */}
            <Route path="/branches" element={<Branches />} />
            <Route
              path="/branches/new-branch"
              element={<BranchesHandle type="new" />}
            />
            <Route
              path="/branches/edit-branch/:id"
              element={<BranchesHandle type="edit" />}
            />
            {/* MANAGE RULES ROUTES */}
            <Route path="/manage-rules" element={<ManageRules />} />
            {/* HOME PAGE GROUP ROUTES */}
            <Route path="/homepage-groups" element={<HomeGroup />} />
            <Route
              path="/homepage-groups/new-group"
              element={<HomeGroupHandle type="new" />}
            />
            <Route
              path="/homepage-groups/edit-group/:id"
              element={<HomeGroupHandle type="edit" />}
            />
            <Route path="/banners" element={<Banners />} />
            <Route
              path="/banners/new-banner"
              element={<BannerDetails create />}
            />
            <Route path="/banners/banner/:id" element={<BannerDetails />} />
            <Route path="/ribbons" element={<Ribbons />} />
            <Route
              path="/emails-&-notifications"
              element={<EmailsNotifications />}
            />
            <Route
              path="/emails-&-notifications/add-notification"
              element={<AddNotification create />}
            />
            <Route
              path="/emails-&-notifications/notification/:id"
              element={<ViewNotification />}
            />
            <Route path="/notifications-list" element={<UserNotifications />} />
            <Route path="/user-groups" element={<UserGroups />} />
            <Route
              path="/user-groups/edit-user-group/:id"
              element={<UserGroupsDetails />}
            />
            <Route
              path="/user-groups/new-user-groups"
              element={<UserGroupsDetails create />}
            />
            <Route path="/transfer" element={<TransferProductSku />} />
            <Route path="/product-sku" element={<ProductSKU />} />
            <Route
              path="/new-product-sku-edit/:id"
              element={<ItemNewDetails />}
            />
            <Route path="items-list" element={<ItemsList />} />
            <Route
              path="/product-sku/new-product-sku"
              element={<AddProductSku create />}
            />

            <Route
              path="/product-sku/edit-item/:id"
              element={<ItemNewDetails create={false} />}
            />
            <Route
              path="/product-sku/view-product-sku/:id"
              element={<ViewProductSKUDetails create={false} />}
            />

            <Route
              path="/product-sku/edit-product-sku/:id"
              element={<EditProductSKUDetails create={false} />}
            />
            <Route path="/roles" element={<Roles />} />
            <Route path="/create-role" element={<CreateRole />} />
            <Route path="/role-name/:id" element={<RoleName />} />
            <Route
              path="/set-of-questions/new-question"
              element={<QuestionsHandle />}
              create
            />
            <Route
              path="/set-of-questions/question/:id"
              element={<QuestionsHandle />}
            />
            <Route path="/set-of-questions" element={<Questions />} />
            <Route
              path="/set-of-questions/:id"
              element={<CategoryQuestions />}
            />
            {/* new */}
            <Route path="/brands" element={<Brands />} />
            <Route path="/brands/new-brand" element={<BrandDetails create />} />
            <Route path="/brands/brand/:id" element={<BrandDetails />} />
            <Route path="/usage-types" element={<UsageTypes />} />
            <Route path="/item-identities" element={<ItemIdentities />} />
            <Route path="/language-settings" element={<Languagesetting />} />
            <Route path="/categories" element={<Categories />} />
            <Route
              path="/categories/new-category"
              element={<CategoryHandle create />}
            />
            <Route
              path="/categories/category/:id"
              element={<CategoryHandle />}
            />
            <Route path="/products" element={<Products />} />
            <Route
              path="/products/new-product"
              element={<ProductDetails create />}
            />
            <Route path="/products/product/:id" element={<ProductDetails />} />
            <Route
              path="/products/view-details/:id"
              element={<ViewProductDetails />}
            />
            {/* /new */}
            <Route path="/items" element={<Items />} />
            <Route path="/new-item" element={<NewItem />} />
            <Route path="/variants" element={<Attributes />} />
            <Route
              path="/variants/new-variant"
              element={<AttributeDetails create />}
            />
            <Route path="/variants/variant/:id" element={<AttributeEdit />} />
            <Route path="/vat" element={<Vat />} />
            <Route path="/items-details/:id" element={<ItemDetails />} />
            <Route path="/discounts" element={<Discounts />} />
            <Route
              path="/discounts/add-new-discount"
              element={<DiscountDetails create />}
            />
            <Route path="/discounts/:id" element={<DiscountDetails />} />
            <Route path="/countries" element={<Countries />} />
            <Route
              path="/countries/new-country"
              element={<CountryDetails create />}
            />
            <Route path="/countries/country/:id" element={<CountryDetails />} />
            <Route path="/open-transactions" element={<OpenTransaction />} />
            <Route
              path="/open-selling-transaction/:id"
              element={<TransactionSellDetails historical={false} />}
            />
            <Route
              path="/open-buying-transaction/:id"
              element={<TransactionBuyDetails historical={false} />}
            />
            <Route
              path="/historical-transactions"
              element={<HistoricalTransactions />}
            />
            <Route
              path="/historical-selling-transaction/:id"
              element={<TransactionSellDetails historical={true} />}
            />
            <Route
              path="/historical-buying-transaction/:id"
              element={<TransactionBuyDetails historical={true} />}
            />
            <Route
              path="/payment-information"
              element={<PaymentInformation />}
            />

            <Route path="/currency-setting" element={<CurrencySetting />} />
            <Route path="/country-setting" element={<CountrySetting />} />
            <Route path="/manage-rules" element={<ManageRules />} />
            <Route path="/logistic-companies" element={<LogisticCompanies />} />
            {/* <Route
              path="/logistic-companies/new-logistic-company"
              element={<LogisticCompaniesDetails create />}
            /> */}
            <Route
              path="/logistic-companies/logistic-company/:id"
              element={<LogisticCompaniesDetails />}
            />

            {/* NEW ROUTES*/}
            <Route path="/picea-workflows" element={<PiceaWorkFlow />} />
            <Route path="/picea-users" element={<PiceaUsers />} />
            <Route path="/Manage-retailer" element={<ManageReailer />} />
            <Route
              path="/Retailer-info-page-data"
              element={<RetailerInfoPageData />}
            />

            {/* Page Not Found */}
            <Route path="*" element={<PageNotFound />} />

            <Route path="/exports" element={<Reports />} />
            <Route path="/create-reports" element={<ReportDetails create />} />
            <Route
              path="/store-customizations/:section"
              element={<HomePage />}
            />
            <Route path="/exceptions" element={<Exceptions />} />
            <Route path="/view-exceptions/:id" element={<ViewExceptions />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default MainRoute;
