// STYLING
import "./PageNotFound.scss";
import Image from "../../assests/Images/pageNotFound.png";

const PageNotFound = () => {
  return (
    <div className="wrapper">
      <img src={Image} width="440px" />
      <p>No Page Found!</p>
    </div>
  );
};

export default PageNotFound;
