//UTILITIES
import React from "react";
import PropTypes from "prop-types";
import { FormSwitch } from "../../formComponents/FormComponents";
//COMPONENT
import {
  Button,
  ButtonBase,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import {
  DeleteSVG,
  EditSVG,
  EyeSVG,
  MoveSVG,
} from "../../../assests/icons/SVG";

//CSS
import "./TableBody.scss";

const TableRowComponent = (props) => {
  const { handleDelete, handleEdit, children, row, internal, actions } = props;
  const {
    handleClick,
    handleMove,
    handleEye,
    handleSwitch,
    isSwitchOn,
    isEnglish,
  } = props;

  return (
    <TableRow
      className={
        internal
          ? ""
          : `table-component-body-row ${handleClick ? "detailed-row" : ""}`
      }
      onClick={handleClick}
    >
      {children}
      {actions && (
        <TableCell align="right" className="body-action-cell">
          {handleEye && (
            <IconButton color="primary" component="span" onClick={handleEye}>
              <EyeSVG />
            </IconButton>
          )}

          {handleEdit && (
            <IconButton color="primary" component="span" onClick={handleEdit}>
              <EditSVG />
            </IconButton>
          )}

          {handleDelete && (
            <IconButton
              color="primary"
              component="span"
              onClick={() => handleDelete(row)}
            >
              <DeleteSVG fill="#EE1818" />
            </IconButton>
          )}

          {handleMove && (
            <IconButton color="primary" component="span" onClick={handleMove}>
              <MoveSVG />
            </IconButton>
          )}
          {handleSwitch && (
            <FormSwitch
              label="Enable"
              value={isSwitchOn}
              disabled={isEnglish}
              onChange={(e) => {
                handleSwitch(e);
              }}
            />
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

TableRowComponent.defaultProps = {
  internal: false,
  actions: true,
  prefixActions: false,
};

TableRowComponent.propTypes = {
  internal: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  row: PropTypes.any,
  internal: PropTypes.bool,
  prefixActions: PropTypes.bool,
};

export default TableRowComponent;
