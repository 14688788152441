import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { Skeleton } from "@mui/material";

const CustomCard = () => {
  return (
    <Card>
      <Skeleton variant="rectangular" width="100%" height={200} />
      <CardContent>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="90%" />
      </CardContent>
      <CardActions>
        <Button size="small" disabled={true}>
          Edit
        </Button>
        <Button size="small" disabled={true}>
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

export default CustomCard;
