import { api } from "./main";

export const CreateOrganization = async (payload) => {
  return await api
    .post("admin/create-organization", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const EditOrganization = async (id, payload) => {
  return await api
    .post(`admin/update-organization/${id}?_method=PUT`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateOrganizationCountryFactor = async (payload) => {
  return await api
    .put(`admin/update-organization-country-factor/${payload?.id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deleteOrganizationCountry = async (payload) => {
  return await api
    .delete(`admin/delete-organization-country/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getOrganizationById = async (id) => {
  return await api
    .get(`admin/get-organization-by-id/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
