import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid, Skeleton } from "@mui/material";
import Button from "../../../components/button/Button";
import { FormInput } from "../../../components/formComponents/FormComponents";
import { useGet } from "../../../hooks/useFetch";
import { useGlobalValues } from "../../../context/GlobalContext";
import { updateSectionone } from "../../../core/apis/store";
import { AlertContext } from "../../../context/AlertContext";
import "./footer.scss";

const SocialLinks = () => {
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();
  const [isFetchingData, setIsFetchingData] = useState(true);

  // Yup schema validation
  const sociallinksSchema = yup.object({
    facebook: yup.string().nullable().url("Please enter a valid URL"),
    linkedIn: yup.string().nullable().url("Please enter a valid URL"),
    instagram: yup.string().nullable().url("Please enter a valid URL"),
    whatsapp: yup.string().nullable().url("Please enter a valid URL"),
  });

  const defaults = {
    facebook: "",
    linkedIn: "",
    instagram: "",
    whatsapp: "",
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sociallinksSchema),
    defaultValues: defaults,
  });

  const {
    data: sectionDetails,
    loading: loadingDetails,
    refetch,
  } = useGet({
    url: "admin/section/SOCIAL_LINKS_SECTION",
  });

  const sociallinksArray = sectionDetails?.data?.data;

  const handleCancel = () => {
    refetch();
  };

  const handleSubmitForm = async (values) => {
    setLoading(true);

    const payload = {
      language: storeLanguage,
      facebook: values.facebook,
      instagram: values.instagram,
      linkedin: values.linkedIn,
      whatsapp: values.whatsapp,
    };

    updateSectionone("SOCIAL_LINKS_SECTION", payload).then((res) => {
      setAlert({
        visible: true,
        text: res?.data.success ? "Successfully Updated" : res?.data?.message,
        type: res?.data?.success ? "success" : "error",
      });
      refetch();
      setLoading(false);
    });
  };

  useEffect(() => {
    if (sociallinksArray) {
      setIsFetchingData(true);

      // Helper function to find and return the value for a given key
      const findValue = (key) => {
        const foundObject = sociallinksArray?.columns?.find(
          (item) => item.language === storeLanguage && item[key] !== undefined
        );
        return foundObject ? foundObject[key] : "";
      };

      const populatedFormData = {
        facebook: findValue("facebook"),
        instagram: findValue("instagram"),
        whatsapp: findValue("whatsapp"),
        linkedin: findValue("linkedin"),
      };

      setValue("linkedIn", populatedFormData.linkedin);
      setValue("whatsapp", populatedFormData.whatsapp);
      setValue("instagram", populatedFormData.instagram);
      setValue("facebook", populatedFormData.facebook);

      setIsFetchingData(false);
    }
  }, [sociallinksArray, storeLanguage, setValue]);

  return (
    <div className="contactinfoSection">
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={2}>
          {/* Facebook and LinkedIn on the same line */}
          <Grid item xs={12} sm={6}>
            {isFetchingData ? (
              <Skeleton variant="text" width="100%" height={40} />
            ) : (
              <Controller
                name="facebook"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormInput
                    name="facebook"
                    label="Facebook"
                    hideVariant
                    placeholder="#"
                    value={value || ""}
                    onChange={(e) => onChange(e.target.value)}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {isFetchingData ? (
              <Skeleton variant="text" width="100%" height={40} />
            ) : (
              <Controller
                name="linkedIn"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormInput
                    label="LinkedIn"
                    hideVariant
                    fullWidth
                    className="inputform"
                    name="linkedIn"
                    id="linkedIn"
                    placeholder="#"
                    value={value || ""}
                    onChange={(e) => onChange(e.target.value)}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          </Grid>

          {/* Address and Phone Number on the same line */}
          <Grid item xs={12} sm={6}>
            {isFetchingData ? (
              <Skeleton variant="text" width="100%" height={40} />
            ) : (
              <Controller
                name="instagram"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormInput
                    name="instagram"
                    label="Instagram"
                    hideVariant
                    placeholder="#"
                    value={value || ""}
                    onChange={(e) => onChange(e.target.value)}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {isFetchingData ? (
              <Skeleton variant="text" width="100%" height={40} />
            ) : (
              <Controller
                name="whatsapp"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormInput
                    name="whatsapp"
                    label="Whatsapp"
                    hideVariant
                    placeholder="#"
                    value={value || ""}
                    onChange={(e) => onChange(e.target.value)}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          </Grid>

          {/* Buttons */}
          <Grid
            container
            justifyContent="flex-end"
            spacing={2}
            style={{ marginTop: "20px" }}
          >
            <Grid item>
              {isFetchingData ? (
                <Skeleton variant="rectangular" width={100} height={40} />
              ) : (
                <Button
                  onClick={() => handleCancel()}
                  name={"Cancel"}
                  type="button"
                  className="mx-1"
                />
              )}
            </Grid>
            <Grid item>
              {isFetchingData ? (
                <Skeleton variant="rectangular" width={100} height={40} />
              ) : (
                <Button
                  selected
                  name={"Save"}
                  type="submit"
                  className="mx-1"
                  loading={loading}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default SocialLinks;
