import Banners from "../../pages/StoreCustomization/Banners/banners";
import { api } from "./main";

///Partners
export const getAllPartners = () => {
  return api
    .get("admin/partners")
    .then((res) => res.data.data)
    .catch((err) => console.error(err));
};

export const createPartner = async (data) => {
  return await api
    .post("admin/partners", data)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getPartnersByID = async (id) => {
  return await api
    .get(`admin/partners/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updatePartner = async (id, payload) => {
  return await api
    .post(`admin/partners/${id}?_method=PUT`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};

export const deletePartner = async (id) => {
  return await api
    .delete(`admin/partners/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

//// laws and orders

export const updateExistingDocument = async (type, payload) => {
  return await api
    .post(`admin/document/${type}?_method=PUT`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};

//Menu Editors

export const isActiveMenuEditor = async (id, payload) => {
  return await api
    .put(`admin/menu-editors/${id}`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};

///FAQs

export const deleteFAQ = async (payload) => {
  return await api
    .delete(`admin/faq/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

//ADD QUESTION FAQs

export const createQuestion = async (data) => {
  return await api
    .post("admin/faq", data)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getQuestionsByID = async (id) => {
  return await api
    .get(`admin/faq/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateQuestion = async (id, payload) => {
  return await api
    .put(`admin/faq/${id}`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err)); //required when updating form data
};

///Blogs

export const deleteBlog = async (payload) => {
  return await api
    .delete(`admin/blog/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

///Add Blogs

export const createBlog = async (data) => {
  return await api
    .post("admin/blog", data)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateBlog = async (id, payload) => {
  return await api
    .post(`admin/blog/${id}?_method=PUT`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err)); //required when updating form data
};

export const getBlogsByID = async (id) => {
  return await api
    .get(`admin/blog/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

///////////////////////////////////////////////

export const getSectionbySlug = async (slug) => {
  return await api
    .get(`admin/section/${slug}`)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};

export const updateSectionone = async (slug, payload) => {
  return await api
    .post(`admin/section/${slug}`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err)); //required when updating form data
};

export const updateDynamicSection = async (slug, payload) => {
  return await api
    .post(`admin/section/add/${slug}`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err)); //required when updating form data
};

export const deleteDynamicSection = async (slug) => {
  return await api
    .delete(`admin/section/${slug}`)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};

// Footer Block

export const createFooterBlock = async (payload) => {
  return await api
    .post(`admin/footer`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateFooterBlock = async (id, payload) => {
  return await api
    .post(`admin/footer/${id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deleteFooterBlock = async (id) => {
  return await api
    .delete(`admin/footer/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deleteFooterLink = async (id) => {
  return await api
    .delete(`admin/footer/link/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

///Blogs

export const deleteBanner = async (payload) => {
  return await api
    .delete(`admin/delete-banner/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

///Add Blogs

export const createBanner = async (data) => {
  return await api
    .post("admin/create-banner", data)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateBanner = async (id, payload) => {
  return await api
    .post(`admin/update-banner/${id}`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err)); //required when updating form data
};

export const getBannerByID = async (id) => {
  return await api
    .get(`admin/get-banner-by-id/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
