import React, { useContext } from "react";
import { isUserLoggedIn } from "../../core/apis/auth";
import { Outlet, useLocation } from "react-router-dom";
import SideMenu from "../sideMenu/SideMenu";
import Notification from "../../firebase/Notification";
import Header from "../header/Header";
import "./RootLayout.scss";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import { SideMenuContext } from "../../context/SideMenuContext";
import * as Sentry from "@sentry/react";

function FallbackComponent() {
  return <div>an error has occurred please try again later</div>;
}
const RootLayout = () => {
  let location = useLocation().pathname.split("/");
  const { sideMenu } = useContext(SideMenuContext);

  return (
    <>
      {isUserLoggedIn() && <Notification />}
      {isUserLoggedIn() && <SideMenu />}
      <Header />
      <main className={`main-body ${sideMenu ? "sideopen" : "sideclose"}`}>
        <Breadcrumbs />
        {/* <h1 className="page-header">
          {location?.length < 3 &&
            location?.[location.length - 1].split("-").join(" ")}
        </h1> */}
        <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
          <Outlet />
        </Sentry.ErrorBoundary>
      </main>
    </>
  );
};

export default RootLayout;
