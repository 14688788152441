//UTILITIES
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router";
import { AlertContext } from "../../context/AlertContext";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
//COMPONENT
import Button from "../../components/button/Button";
import { Card, CardContent, Skeleton } from "@mui/material";
import {
  FormDropdownList,
  FormInput,
  FormSwitch,
} from "../../components/formComponents/FormComponents";
//CSS
import "./Users.scss";
import { PhoneInput } from "../../components/phoneInput/PhoneInput";

import { useGet } from "../../hooks/useFetch";
import { CreateUser, EditUser } from "../../core/apis/user";
import { checkPhoneValidity } from "../../core/apis/userManagement";

//PS:
//USER TYPES:
// 1 ADMIN , 2 RETAILER , 3 BUYER , 4 COLLECTION POINT, 5 END USER

const UsersHandle = (props) => {
  const HandleSchema = yup.object({
    full_name: yup.string().required("Field is required"),
    email: yup
      .string("Invalid email format")
      .email("Invalid email format")
      .required("Field is required"),
    country_id: yup.number(),
    phone_number: yup.number().nullable().required("Field is required"),

    role_id: yup.object().nullable().required("Field is required"),
    organization_id: yup
      .object()
      .nullable()
      .when("role_id", {
        is: (value) =>
          value && (value.user_type_id === 2 || value.user_type_id === 3),
        then: yup.object().nullable().required("Field is required"),
        otherwise: yup.object().nullable().notRequired(),
      }),

    collection_point_id: yup
      .object()
      .nullable()
      .when("role_id", {
        is: (value) => value && value.user_type_id === 4,
        then: yup.object().nullable().required("Field is required"),
        otherwise: yup.object().nullable().notRequired(),
      }),
    branch_id: yup
      .object()
      .nullable()
      .when("role_id", {
        is: (value) =>
          value && (value.user_type_id === 2 || value.user_type_id === 3),
        then: yup.object().nullable().required("Field is required"),
        otherwise: yup.object().nullable().notRequired(),
      }),
    currency_id: yup
      .object()
      .nullable()
      .when("role_id", {
        is: (value) => value && value.user_type_id === 2,
        then: yup.object().nullable().required("Field is required"),
        otherwise: yup.object().nullable().notRequired(),
      }),

    third_party_id: yup.object().nullable(),
  });

  const { id } = useParams();
  const { type } = props;
  let navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(HandleSchema),
    reValidateMode: "onChange" | "onBlur",
  });

  //API
  const { data: roles, rolesLoading } = useGet({
    url: "admin/get-all-roles",
    payload: {
      user_type_id:
        type === "edit"
          ? [2]
          : [1, 2, 4] /*because currently only retailer's roles are editable*/,
    },
  });
  let rolesArray = roles?.data?.data;

  const { data: organizations, organizationLoading } = useGet({
    url: "admin/get-all-organization",
    payload: null,
  });
  const { data: currencies, currenciesLoading } = useGet({
    url: "admin/get-all-currencies",
    payload: { is_hidden: false },
  });

  const { data: userById, loading } = useGet({
    url: type === "edit" ? `admin/get-user-by-id/${id}` : null,
  });

  const {
    data: branches,
    branchesLoading,
    refetch: branchesRefetch,
  } = useGet({
    url: "admin/get-all-branches",
    payload: {
      organization_id: getValues("organization_id")?.id,
    },
  });

  const { data: thirdParties, thirdPartiesLoading } = useGet({
    url: "admin/get-all-third-parties",
    payload: {
      user_type_id: getValues("role_id")?.user_type_id,
    },
  });

  let organizationsArray = organizations?.data?.data;
  let branchesArray = branches?.data?.data;
  let thirdPartiesArray = thirdParties?.data?.data;
  let currenciesArray = currencies?.data?.data;

  const { data: cps, cpLoading } = useGet({
    url: "admin/get-all-collection-point",
    payload: {
      is_active: true,
    },
  });
  let cpsArray = cps?.data.data;
  //VARIABLES
  const [saveLoading, setSaveLoading] = useState(false);
  const [phone_number_error, setPhone_number_error] = useState("");

  //FUNCTIONS
  const checkPhone = async (phone) => {
    let boolean = false;
    await checkPhoneValidity(phone).then((res) => {
      if (!res?.data?.data) {
        boolean = false;
        setPhone_number_error(res?.data?.message);
      } else {
        boolean = true;
        setPhone_number_error("");
      }
    });

    return boolean;
  };

  const handleSubmitForm = async (formData) => {
    setSaveLoading(true);
    let phoneCheck = await checkPhone(formData.phone_number);
    if (phoneCheck) {
      if (formData.role_id?.user_type_id !== 2) {
        delete formData.third_party_id;
      }

      if (type === "edit") {
        EditUser({
          ...formData,
          user_id: id,
          collection_point_id: formData.collection_point_id?.id,
          branch_id: formData.branch_id?.id,
          role_id: formData.role_id?.id,
          phone_number: formData.phone_number?.toString(),
          language_id: 40, //english
          currency_id:
            formData.role_id?.user_type_id === 2 ||
            formData.role_id?.user_type_id === 4
              ? formData?.currency_id?.id
              : 1, //usd
          ...(formData.role_id?.user_type_id === 2 && {
            third_party_id: formData.third_party_id?.id,
          }),
        }).then((res) => {
          setAlert({
            visible: true,
            text: res.data ? res.data?.message : res.message,
            type: res.data?.success ? "success" : "error",
          });
          if (res.data?.success) {
            navigate("/users");
          }
          setSaveLoading(false);
        });
      } else {
        CreateUser({
          ...formData,
          collection_point_id: formData.collection_point_id?.id,
          branch_id: formData.branch_id?.id,
          role_id: formData.role_id?.id,
          language_id: 40, //english
          currency_id:
            formData.role_id?.user_type_id === 2 ||
            formData.role_id?.user_type_id === 4
              ? formData?.currency_id?.id
              : 1, //usd
          phone_number: formData.phone_number?.toString(),
          ...(formData.role_id?.user_type_id === 2 && {
            third_party_id: formData.third_party_id?.id,
          }),
        }).then((res) => {
          setAlert({
            visible: true,
            text: res.data ? res.data?.message : res.message,
            type: res.data?.success ? "success" : "error",
          });
          if (res.data?.success) {
            navigate("/users");
          }
          setSaveLoading(false);
        });
      }
    } else {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    reset({
      enable_notification: userById?.data?.data?.enable_notification || true,
      full_name: userById?.data?.data?.full_name || "",
      email: userById?.data?.data?.email || "",
      phone_number: userById?.data?.data?.phone_number || "961",
      country_id: userById?.data?.data?.country_id || 118,
      role_id: userById?.data?.data?.role || null,
      branch_id: userById?.data?.data?.branch || null,
      currency_id: userById?.data?.data?.currency || null,
      organization_id:
        userById?.data?.data?.branch?.organization?.organization || null,

      collection_point_id: userById?.data?.data?.collection_point || null,
      third_party_id: userById?.data?.data?.third_party?.[0] || null,
    });
  }, [userById]);

  return (
    <>
      <h1>
        {type === "new" ? "New User" : userById?.data?.data?.full_name || ""}
      </h1>
      <div className="user-handle-component">
        <div className="user-handle-form-section">
          <Card>
            <CardContent>
              {!loading ? (
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="full_name"
                        label="Full Name"
                        placeholder={"Enter name"}
                        disabled={
                          type === "edit" &&
                          getValues("role_id")?.user_type_id === 5
                        }
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                          clearErrors("full_name");
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name="full_name"
                    control={control}
                  />
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="email"
                        label="Email"
                        placeholder={"Enter email"}
                        value={value}
                        disabled={type === "edit"}
                        onChange={(e) => {
                          onChange(e.target.value);
                          clearErrors("email");
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name="email"
                    control={control}
                  />
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <PhoneInput
                        required
                        className="phone-input-mg-form"
                        value={value}
                        disabled={
                          type === "edit" &&
                          getValues("role_id")?.user_type_id === 5
                        }
                        clearErrors={clearErrors}
                        onChange={(countries) => (number, info) => {
                          setValue(
                            "country_id",
                            countries?.find(
                              (item) =>
                                item.country_code?.toLowerCase() ===
                                info?.countryCode?.toLowerCase(),
                            )?.id,
                            {
                              shouldValidate: true,
                            },
                          );
                          setPhone_number_error("");
                          onChange(number);
                          clearErrors("phone_number");
                        }}
                        helperText={
                          phone_number_error !== ""
                            ? phone_number_error
                            : error?.message
                        }
                      />
                    )}
                    name="phone_number"
                    control={control}
                  />
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormDropdownList
                        required
                        helperText={error?.message}
                        data={rolesArray || []}
                        loading={rolesLoading}
                        name="role_id"
                        label="Role"
                        disabled={
                          type === "edit" &&
                          getValues("role_id")?.user_type_id !== 2
                            ? true
                            : false
                        }
                        placeholder={"Select role"}
                        clearErrors={clearErrors}
                        dependencyError={[
                          "third_party_id",
                          "collection_point_id",
                          "branch_id",
                          "organization_id",
                          "role_id",
                        ]}
                        value={value}
                        onChange={(value) => {
                          if (type !== "edit") {
                            /*we are checking on the edit state only because only retailer's role is editable*/
                            setValue("third_party_id", null, {
                              shouldValidate: true,
                            });
                            setValue("collection_point_id", null, {
                              shouldValidate: true,
                            });
                            setValue("branch_id", null, {
                              shouldValidate: true,
                            });
                            setValue("organization_id", null, {
                              shouldValidate: true,
                            });
                            setValue("currency_id", null, {
                              shouldValidate: true,
                            });
                          }

                          onChange(value);
                        }}
                      />
                    )}
                    name="role_id"
                    control={control}
                  />
                  {getValues("role_id")?.user_type_id === 4 && (
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormDropdownList
                          required
                          clearErrors={clearErrors}
                          name="collection_point_id"
                          data={cpsArray || []}
                          loading={cpLoading}
                          disabled={type === "edit" ? true : false}
                          label="Collection Point"
                          placeholder={"Select collection point"}
                          value={value}
                          onChange={(e) => onChange(e)}
                          helperText={error?.message}
                        />
                      )}
                      name="collection_point_id"
                      control={control}
                    />
                  )}
                  {(getValues("role_id")?.user_type_id === 2 ||
                    getValues("role_id")?.user_type_id === 3) && (
                    <>
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormDropdownList
                            required
                            data={organizationsArray || []}
                            loading={organizationLoading}
                            label="Organization"
                            name="organization_id"
                            disabled={type === "edit" ? true : false}
                            placeholder={"Select organization"}
                            value={value}
                            helperText={error?.message}
                            clearErrors={clearErrors}
                            dependencyError={["branch_id", "organization_id"]}
                            onChange={(e) => {
                              setValue("branch_id", null, {
                                shouldValidate: true,
                              });
                              onChange(e);
                              branchesRefetch();
                            }}
                          />
                        )}
                        name="organization_id"
                        control={control}
                      />

                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormDropdownList
                            required
                            data={branchesArray || []}
                            name="branch_id"
                            loading={branchesLoading}
                            label="Branch"
                            disabled={type === "edit" ? true : false}
                            clearErrors={clearErrors}
                            placeholder={"Select branch"}
                            value={value}
                            onChange={(e) => onChange(e)}
                            helperText={error?.message}
                          />
                        )}
                        name="branch_id"
                        control={control}
                      />
                    </>
                  )}
                  {getValues("role_id")?.user_type_id === 2 && (
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormDropdownList
                          helperText={error?.message}
                          data={thirdPartiesArray || []}
                          disabled={type === "edit" ? true : false}
                          loading={thirdPartiesLoading}
                          name="third_party_id"
                          clearErrors={clearErrors}
                          label="Third Party"
                          placeholder={"Select third party"}
                          value={value}
                          onChange={(value) => {
                            onChange(value);
                            if (value?.name?.en === "DU") {
                              setValue(
                                "currency_id",
                                currenciesArray?.find(
                                  (el) => el?.code === "AED",
                                ),
                                {
                                  shouldValidate: true,
                                },
                              );
                            }
                          }}
                        />
                      )}
                      name="third_party_id"
                      control={control}
                    />
                  )}
                  {(getValues("role_id")?.user_type_id === 4 ||
                    getValues("role_id")?.user_type_id === 2) && (
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormDropdownList
                          required
                          clearErrors={clearErrors}
                          name="currency_id"
                          disabled={
                            getValues("third_party_id")?.name?.en === "DU"
                              ? true
                              : false
                          }
                          data={currenciesArray || []}
                          loading={currenciesLoading}
                          label="Currency"
                          placeholder={"Select currency"}
                          value={value}
                          onChange={(e) => onChange(e)}
                          helperText={error?.message}
                        />
                      )}
                      name="currency_id"
                      control={control}
                    />
                  )}
                  {type === "edit" && (
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <FormSwitch
                          label="Enable Notification"
                          name="enable_notification"
                          value={value}
                          disabled={
                            type === "edit" &&
                            getValues("role_id")?.user_type_id === 5
                          }
                          onChange={(e) => onChange(e.target.checked)}
                        />
                      )}
                      name="enable_notification"
                      control={control}
                    />
                  )}
                  <div className="action-footer">
                    <Button
                      name={type === "edit" ? "Save" : "Create"}
                      selected
                      width={"30%"}
                      loading={saveLoading}
                      type="submit"
                    ></Button>
                  </div>
                </form>
              ) : (
                <Skeleton count={5} />
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default UsersHandle;
