import { api } from "./main";

export const tamaraOrderDetails = async (transaction_id) => {
  return await api
    .get(`admin/order-details/${transaction_id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const AuthorizeOrder = async (transaction_id) => {
  return await api
    .patch(`admin/authorize-order/${transaction_id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const CapturePayment = async (transaction_id) => {
  return await api
    .patch(`admin/capture-payment/${transaction_id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const RefundPayment = async (transaction_id, payload) => {
  return await api
    .patch(`admin/refund-payment/${transaction_id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
