//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

//COMPONENT
import Button from "../../components/button/Button";
import { useForm, Controller } from "react-hook-form";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import {
  FormInput,
  FormTextArea,
} from "../../components/formComponents/FormComponents";
import { CloseSVG } from "../../assests/icons/SVG";
import { AlertContext } from "../../context/AlertContext";
import { UpdateheadlineinfoById } from "../../core/apis/retailerinfopagedata";

//CSS
import "./ManageRetailer.scss";

const HandleSchema = yup.object({
  information: yup.string("Invalid").nullable().required("Field is required"),
});

export function RetailerinfoHandle(props) {
  const { data, onClose, languageValue } = props;

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  const { setAlert } = useContext(AlertContext);
  const [saveLoading, setSaveLoading] = useState(false);

  const handleSubmitForm = (formData) => {
    setSaveLoading(true);
    let payload = {
      information: formData.information,
      language: languageValue,
      headline_info_type_id: formData.type_id,
    };

    UpdateheadlineinfoById(formData.id, payload).then((res) => {
      if (res?.data.success) {
        onClose();
        reset();
      }

      setAlert({
        visible: true,
        text: res.data ? res.data?.message : res.message,
        type: res.data?.success ? "success" : "error",
      });
      setSaveLoading(false);
    });
  };

  useEffect(() => {
    if (data) {
      reset({
        id: data?.id,
        information: data?.information?.[languageValue]
          ? data?.information?.[languageValue]
          : data?.information?.en,
        type_id: data.headline_info_type_id,
      });
    }
  }, [data]);

  return (
    <Dialog disablebackdropclick="true" fullWidth open={true}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Update
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            "&:hover": {
              backgroundColor: "transparent", // Set the hover background color
            },
          }}
          style={{ justifyContent: "end" }}
        >
          <CloseSVG />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <form
          className={
            languageValue != "ar"
              ? "ManageRhandle"
              : " ManageRhandle arabic-component"
          }
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <div>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormTextArea
                  required
                  rows="2"
                  label="Information"
                  placeholder={""}
                  name="information"
                  value={value}
                  onChange={(e) => onChange(e)}
                  helperText={error?.message}
                />
              )}
              name={`information`}
              control={control}
            />

            <div className="action-footer">
              <Button
                title="Save"
                selected
                loading={saveLoading}
                width={"30%"}
                type="submit"
              />
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default RetailerinfoHandle;
