// EXTERNAL LIBRARIES
import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

// STYLING
import "./CountryDetails.scss";

import Spinner from "../../../components/MUI-skeleton/Spinner";

// CUSTOM HOOKS
import { useGet } from "../../../hooks/useFetch";
import { AlertContext } from "../../../context/AlertContext";

// FORM
import {
  FormDropdownList,
  FormInput,
} from "../../../components/formComponents/FormComponents";
import Button from "../../../components/button/Button";
import { api } from "../../../core/apis/main";
import SimpleMap from "../../../components/maps/Maps";

// Formik schema
const countrySchema = yup.object({
  name: yup.string("Field is invalid").required("Field is required"),
  country_code: yup.string("Field is invalid").required("Field is required"),
  dial_code: yup.string("Field is invalid").required("Field is required"),
  factor: yup.number("Field is invalid").required("Field is required"),
  currency_id: yup
    .object("Field is invalid")
    .nullable()
    .required("Field is required"),
  longitude: yup.number().nullable(),
  latitude: yup.number().nullable(),
  location_message: yup
    .string()
    .nullable()
    .when(["latitude", "longitude"], {
      is: (latitudeValue, longitudeValue) =>
        latitudeValue === null || longitudeValue === null,
      then: yup.string().nullable().required("Field is required"),
      otherwise: yup.string().nullable(),
    }),
});

const CountryDetails = (props) => {
  // DATA AND HOOKS
  const { create } = props;
  const params = useParams();
  let navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);

  const [loadingForm, setLoadingForm] = useState(false);

  // API REQUESTS
  const {
    data: country,
    loading,
    refetch: getCountry,
  } = useGet({
    url: `admin/get-country-by-id/${params.id}`,
  });

  const {
    data: currencies,
    loading: currencyLoading,
    refetch: getCurrencies,
  } = useGet({
    url: `admin/get-all-currencies`,
  });

  let countryData = country?.data?.data;
  let currencyData = currencies?.data?.data;

  // Formik values
  const formik = useFormik({
    initialValues: {
      name: countryData?.name || "",
      country_code: countryData?.country_code || "",
      dial_code: countryData?.dial_code || "",
      factor: countryData?.factor || "",
      currency_id: countryData?.currency || null,
      longitude: countryData?.longitude || null,
      latitude: countryData?.latitude || null,
      location_message: "",
    },
    enableReinitialize: true,
    validationSchema: countrySchema,
    onSubmit: () => {
      if (create) {
        addData();
      } else {
        updateData();
      }
    },
  });

  // Adds data
  const addData = async () => {
    setLoadingForm(true);
    return await api
      .post(`admin/create-country`, {
        ...formik?.values,
        currency_id: formik.values?.currency_id?.id,
      })
      .then((res) => {
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
        if (res?.data?.success) {
          navigate("/countries");
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingForm(false);
      });
  };

  // Updates data
  const updateData = async () => {
    setLoadingForm(true);
    return await api
      .put(`admin/update-country/${params.id}`, {
        ...formik.values,
        currency_id: formik.values?.currency_id?.id,
      })
      .then((res) => {
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
        if (res?.data?.success) {
          navigate("/countries");
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingForm(false);
      });
  };

  // Gets country on edit
  useEffect(() => {
    if (!create) getCountry();
  }, [create]);

  return !create && loading ? (
    <Spinner />
  ) : (
    <div className="countryDiv">
      <h1>{create ? "New Country" : countryData?.name}</h1>
      <form className="CountryDetails" onSubmit={formik.handleSubmit}>
        <Card className="CountryDetailsCard ">
          <CardContent>
            <FormInput
              required
              label="Country Name"
              placeholder="Enter name"
              name="name"
              id="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <FormInput
              required
              label="Country Code"
              placeholder="Enter code"
              name="country_code"
              id="country_code"
              value={formik.values.country_code}
              onChange={formik.handleChange}
              error={
                formik.touched.country_code &&
                Boolean(formik.errors.country_code)
              }
              helperText={
                formik.touched.country_code && formik.errors.country_code
              }
            />
            <FormInput
              required
              label="Dial Code"
              placeholder="Enter code"
              name="dial_code"
              id="dial_code"
              value={formik.values.dial_code}
              onChange={formik.handleChange}
              error={
                formik.touched.dial_code && Boolean(formik.errors.dial_code)
              }
              helperText={formik.touched.dial_code && formik.errors.dial_code}
            />
            <FormInput
              required
              label="Country Factor"
              placeholder="Enter country factor"
              type="number"
              name="factor"
              id="factor"
              value={formik.values.factor}
              onChange={formik.handleChange}
              error={formik.touched.factor && Boolean(formik.errors.factor)}
              helperText={formik.touched.factor && formik.errors.factor}
            />
            <FormDropdownList
              required
              label="Currency"
              placeholder={"Enter currency"}
              name="currency_id"
              data={currencyData || []}
              loading={currencyLoading}
              disabled={!create}
              value={formik.values.currency_id}
              onChange={formik.handleChange}
              error={
                formik.touched.currency_id && Boolean(formik.errors.currency_id)
              }
              helperText={
                formik.touched.currency_id && formik.errors.currency_id
              }
            />
            <div className={"map-height"}>
              <SimpleMap
                coordination={{
                  lat: formik.values.latitude,
                  lng: formik.values.longitude,
                }}
                draggable={true}
                zoom={8}
                onClick={(e) => {
                  formik.setFieldValue("latitude", e.lat);
                  formik.setFieldValue("longitude", e.lng);
                  formik.setFieldTouched("location_message", false, false);
                }}
                helperText={
                  formik.touched.location_message &&
                  formik.errors.location_message
                }
              />
            </div>
            <div className="action-footer">
              <Button
                name={create ? "Create" : "Save"}
                selected
                loading={loadingForm}
                disabled={loadingForm}
                type="submit"
                width={"30%"}
              />
            </div>
          </CardContent>
        </Card>
      </form>
    </div>
  );
};

export default CountryDetails;
