import { useContext, useState } from "react";
import "./ResetPassword.scss";
import * as AuthApi from "../../../core/apis/auth";
import Button from "../../../components/button/Button";
import { AlertContext } from "../../../context/AlertContext";
import { useFormik } from "formik";
import * as yup from "yup";
import { IconButton, InputAdornment } from "@mui/material";
import { Input } from "../../../components/input/Input";
import { useLocation, useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const changePasswordSchema = yup.object({
  password: yup
    .string("Enter your password")
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
const EnterNewPassword = () => {
  const { setAlert } = useContext(AlertContext);
  const [loading, setloading] = useState(false);
  const inputs = [
    { label: "New Password", name: "password" },
    { label: "Confirm New Password", name: "password_confirmation" },
  ];
  let location = useLocation();
  let navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const [pass, setPass] = useState({ 0: false, 1: false });
  const formiks = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
      token: params.get("token"),
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values) => {
      setloading(true);
      AuthApi.resetPassword(values).then((res) => {
        setAlert({
          visible: true,
          type: res.data.success ? "success" : "error",
          text: res.data.message,
        });
        setloading(false);
        res.data.success && navigate("/login");
      });
    },
  });

  const handleShow = (index) => setPass({ ...pass, [index]: !pass[index] });
  const handleMouseDownPassword = (event) => event.preventDefault();

  return (
    <form onSubmit={formiks.handleSubmit} className="forgot-password">
      <h2>Reset Password</h2>
      {inputs.map((item, index) => (
        <Input
          className="curvedInput"
          key={index}
          type={pass?.[index] ? "text" : "password"}
          placeholder={item.label}
          name={item.name}
          id={item.name}
          value={formiks.values[item.name]}
          onChange={formiks.handleChange}
          error={
            formiks.touched[item.name] && Boolean(formiks.errors[item.name])
          }
          helperText={formiks.touched[item.name] && formiks.errors[item.name]}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon style={{ color: "#4BA4F0" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleShow(index)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {pass[index] ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ))}
      <Button
        width="100%"
        selected
        name="Reset Password"
        loading={loading}
        disabled={!formiks.dirty}
        type="submit"
      />
    </form>
  );
};

export default EnterNewPassword;
