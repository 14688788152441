import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AlertContext } from "../context/AlertContext";
import { api } from "../core/apis/main";
import { useLocalStorage } from "./useLocalStorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const [user, setUser] = useLocalStorage("SESSION", null);

  // call this function when you want to authenticate the user
  const login = async (data) => {
    return await api.post("auth/login", data).then((res) => {
      !Boolean(res.data.data) &&
        setAlert({
          visible: true,
          type: "error",
          text: res.data.message,
        });

      setUser(Boolean(res.data.data));
      return res;
    });
  };

  const logout = async () => {
    return await api.get("auth/logout").then((res) => {
      setUser(null);
      navigate("/", { replace: true });
      localStorage.clear();

      return res;
    });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
