import { api } from "./main";

export const getAllPiceaUsers = async (payload) => {
  return await api
    .get(`admin/picea/get-all-picea-users`, { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const createPiceaUser = async (payload) => {
  return await api
    .post(`admin/picea/create-picea-user`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deletePiceaUser = async (id) => {
  return await api
    .delete(`admin/picea/delete-picea-user/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getAllPiceaWorkFlowTypes = async (payload) => {
  return await api
    .get(`admin/picea/get-all-picea-workflow-types`, { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getAllPiceaWorkFlow = async (payload) => {
  return await api
    .get(`admin/picea/get-all-picea-workflows`, { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const createPiceaWorkFlow = async (payload) => {
  return await api
    .post(`admin/picea/create-picea-workflow`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deletePiceaWorkFlow = async (id) => {
  return await api
    .delete(`admin/picea/delete-picea-workflow/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updatePiceaOraganization = async (id, payload) => {
  return await api
    .put(`admin/update-organization-picea-workflows/${id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
