//UTILITIES
import React, { useContext, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useGet } from "../../hooks/useFetch";
//COMPONENT
import { AlertContext } from "../../context/AlertContext";
import Paginator from "../../components/paginator/Paginator";
import TableHeader from "../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import { TableCell } from "@mui/material";

//CSS
import "./Users.scss";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { deleteUser } from "../../core/apis/userManagement";
import withH1 from "../../assests/HOC/withH1";

//PS:
//USER TYPES:
// 1 ADMIN , 2 RETAILER , 3 BUYER , 4 COLLECTION POINT, 5 END USER

export function Users() {
  //VARIABLES
  const { setAlert } = useContext(AlertContext);
  const [userDetail, setUserDetail] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const tableHeaders = [
    { title: "Username" },
    { title: "Email" },
    { title: "Type" },
    { title: "Organization" },
    { title: "Branch" },
  ];

  let navigate = useNavigate();
  const [searchParams] = useSearchParams(); //pagination and filter
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
    user_type_ids: searchParams.getAll("user_type_ids"),
  };
  let url = `admin/get-all-users`;
  const {
    data: users,
    loading,
    refetch,
  } = useGet({ url: url, payload: payload });
  let data = users?.data?.data;

  //FUNCTIONS
  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteUser(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setUserDetail(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };
  return (
    <div className="users">
      <TableHeader
        placeholder="Search by username"
        to="/users/new-user"
        filterByUserType
        checkbox
      />
      <TableBodyComponent
        header={tableHeaders}
        loading={loading}
        total={data?.total}
      >
        {data?.data?.map((element) => (
          <TableRowComponent
            row={element}
            handleDelete={
              element?.user_type_id !== 5
                ? () => {
                    setUserDetail(element);
                    setOpenDelete(true);
                  }
                : null
            }
            handleEdit={() => navigate(`/users/edit-user/${element?.id}`)}
          >
            <TableCell>{element?.full_name}</TableCell>
            <TableCell>{element?.email}</TableCell>
            <TableCell>{element?.role?.name}</TableCell>
            <TableCell>
              {element?.branch?.organization?.organization?.name?.en}
            </TableCell>
            <TableCell>{element?.branch?.name}</TableCell>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
      <Paginator count={data?.last_page} disabled={loading} />
      {openDelete && (
        <NoticeConfirmation
          data={userDetail}
          handleResponse={handleDeleteResponse}
        />
      )}
    </div>
  );
}

export default withH1(Users, "Users");
