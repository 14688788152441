import { api } from "./main";

export const getallretailerhomepagecards = async () => {
  return await api
    .get("/get-all-retailerhomepage-cards")
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const UpdateRetailerhomepageCardById = async (id, payload) => {
  return await api
    .post(`/admin/update-retailerhomepage-card/${id}?_method=PUT`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};

export const reOrderManageRetailHomePage = (payload) => {
  return api
    .patch(`/admin/update-retailhomepage-card-order`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
