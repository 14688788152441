import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid, Skeleton, Typography } from "@mui/material";
import Button from "../../../components/button/Button";
import {
  FormInput,
  FormTextArea,
} from "../../../components/formComponents/FormComponents";
import { PhoneInput } from "../../../components/phoneInput/PhoneInput";
import { useGet } from "../../../hooks/useFetch";
import { useGlobalValues } from "../../../context/GlobalContext";
import { updateSectionone } from "../../../core/apis/store";
import { AlertContext } from "../../../context/AlertContext";
import "./footer.scss";
import { Switch } from "@mui/material";
import Cropper from "../../../components/singleUpload/imageCropper";

const ContactInfo = () => {
  const [loading, setLoading] = useState(false);
  const [headerimageVal, setHeaderImageValue] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [phoneNumber_error, setPhone_number_error] = useState("");

  // Yup schema validation
  const informationsectionSchema = yup.object({
    title: yup.string().nullable().required("Field is required"),
    email: yup.string().nullable().required("Field is required"),
    address: yup.string().nullable().required("Field is required"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .min(10, "Phone number must be at least 10 digits")
      .required("Field is required"),
    headerImage: yup.mixed().nullable().required("Field is required"),
    active: yup.bool().nullable().required("Field is required"),
  });

  const defaults = {
    title: "",
    address: "",
    email: "",
    phoneNumber: "",
    headerImage: "",
    active: true, // Assuming published is a boolean
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(informationsectionSchema),
    defaultValues: defaults,
  });

  const {
    data: sectionDetails,
    loading: loadingDetails,
    refetch,
  } = useGet({
    url: "admin/section/CONTACT_INFO_SECTION",
  });

  const contactInfoArray = sectionDetails?.data?.data;

  const handleCancel = () => {
    refetch();
  };

  const handleHeaderImage = (croppedImage) => {
    setHeaderImageValue(croppedImage);
    setValue("headerImage", croppedImage);
  };

  const handleSubmitForm = async (values) => {
    console.log(values);
    let formData = new FormData();
    setLoading(true);

    formData.append("title", values?.title);
    formData.append("address", values?.address);
    formData.append("phone", values?.phoneNumber);
    formData.append("image", values?.headerImage);
    formData.append("is_enable", values?.active ? 1 : 0);
    formData.append("email", values?.email);
    formData.append("language", storeLanguage);

    if (values.headerImage instanceof File === false) {
      formData.delete("image");
    }

    updateSectionone("CONTACT_INFO_SECTION", formData).then((res) => {
      setAlert({
        visible: true,
        text: res?.data.success ? "Successfully Updated" : res?.data?.message,
        type: res?.data?.success ? "success" : "error",
      });
      refetch();
      setLoading(false);
    });
  };

  useEffect(() => {
    if (contactInfoArray) {
      setIsFetchingData(true);
      // Helper function to find and return the value for a given key
      const findValue = (key) => {
        const foundObject = contactInfoArray?.columns?.find(
          (item) => item.language === storeLanguage && item[key] !== undefined
        );
        return foundObject ? foundObject[key] : "";
      };

      const populatedFormData = {
        title: findValue("title"),
        email: findValue("email"),
        phoneNumber: findValue("phone"),
        address: findValue("address"),
        headerImage: findValue("image"),
        active: findValue("is_enable"),
      };

      setValue("title", populatedFormData.title);
      setValue("email", populatedFormData.email);
      setValue("phoneNumber", populatedFormData.phoneNumber);
      setValue("address", populatedFormData.address);
      setValue("headerImage", populatedFormData.headerImage);
      setValue("active", populatedFormData.active);

      setIsFetchingData(false);
    }
  }, [contactInfoArray, storeLanguage, setValue]);

  return (
    <div className="contactinfoSection">
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              {isFetchingData ? (
                <Skeleton variant="text" width="100%" height={40} />
              ) : (
                <Controller
                  name="title"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      required
                      name="title"
                      label="Title"
                      hideVariant
                      placeholder="Contact Info"
                      value={value || ""}
                      onChange={(e) => onChange(e.target.value)}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {isFetchingData ? (
                <Skeleton variant="text" width="100%" height={40} />
              ) : (
                <Controller
                  name="email"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      label="Email"
                      hideVariant
                      fullWidth
                      className="inputform"
                      name="email"
                      id="email"
                      placeholder="Enter Email"
                      value={value || ""}
                      onChange={(e) => onChange(e.target.value)}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {isFetchingData ? (
                <Skeleton variant="text" width="100%" height={40} />
              ) : (
                <Controller
                  name="address"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      required
                      name="address"
                      label="Address"
                      hideVariant
                      placeholder="Address"
                      value={value || ""}
                      onChange={(e) => onChange(e.target.value)}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {isFetchingData ? (
                <Skeleton variant="text" width="100%" height={40} />
              ) : (
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <PhoneInput
                      required
                      hideVariant
                      value={value || ""}
                      className="phone-input-mg-form"
                      onChange={() => (number, info) => {
                        onChange(number);
                        setPhone_number_error("");
                      }}
                      helperText={
                        phoneNumber_error !== ""
                          ? phoneNumber_error
                          : error?.message
                      }
                    />
                  )}
                />
              )}
              {phoneNumber_error && (
                <p className="error-text">{phoneNumber_error}</p>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginTop: "20px" }}>
            <Grid item xs={12} className="displayEnd">
              {isFetchingData ? (
                <Skeleton variant="rectangular" width={100} height={40} />
              ) : (
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div className="switch">
                      <label>Enable This Block</label>
                      <Switch
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                      />
                    </div>
                  )}
                  name="active"
                  control={control}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {isFetchingData ? (
                <Skeleton variant="text" width="100%" height={100} />
              ) : (
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div>
                      <Cropper
                        required
                        sendCroppedFile={handleHeaderImage}
                        MAX_IMAGE_WIDTH={500}
                        MAX_IMAGE_HEIGHT={500}
                        title={"upload 2use logo only webp"}
                        className="width100"
                        isTrue={true}
                        iwebp={true}
                        imageSrc={getValues("headerImage")}
                      />
                      {error && headerimageVal == null ? (
                        <p className="error">{error.message}</p>
                      ) : null}
                    </div>
                  )}
                  name="headerImage"
                  control={control}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          spacing={2}
          style={{ marginTop: "10px" }}
        >
          <Grid item>
            {isFetchingData ? (
              <Skeleton variant="rectangular" width={100} height={40} />
            ) : (
              <Button
                onClick={() => handleCancel()}
                name={"Cancel"}
                type="button"
                className="mx-1"
              />
            )}
          </Grid>
          <Grid item>
            {isFetchingData ? (
              <Skeleton variant="rectangular" width={100} height={40} />
            ) : (
              <Button
                selected
                name={"Save"}
                type="submit"
                className="mx-1"
                loading={loading}
              />
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ContactInfo;
