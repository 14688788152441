//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AlertContext } from "../../context/AlertContext";
//COMPONENT
import { useGet } from "../../hooks/useFetch";
import { useForm, Controller } from "react-hook-form";
import DialogComponent from "../../components/dialogs/dialog/Dialog";
import { FormInput } from "../../components/formComponents/FormComponents";
import { updateConditionFactor } from "../../core/apis/product";

export function ManageConditionsHandle(props) {
  //VARIABLES
  const { onClose, data } = props;
  const { setAlert } = useContext(AlertContext);
  const [errorMessage, setErrorMessage] = useState("");
  const HandleSchema = yup.object({
    conditions_factor: yup.array().of(
      yup.object().shape({
        condition_id: yup.object().nullable().required("Field is required"),
        factor: yup
          .number()
          .nullable()
          .required("Field is required")
          .positive("Field should be positive"),
      })
    ),
  });

  //API
  const { data: conditionByProductId, loading } = useGet({
    url: `admin/get-condition-factor-by-product-id/${data?.id}`,
  });
  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  //FUNCTIONS
  const handleSubmitForm = (formData) => {
    updateConditionFactor({
      product_id: data?.id,
      conditions_factor: formData.conditions_factor.map((val) => ({
        ...val,
        condition_id: val.condition_id?.id,
      })),
    }).then((res) => {
      if (res.data?.success) {
        onClose();
        setAlert({
          visible: true,
          text: res.data ? res.data.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
      } else {
        setErrorMessage(res.data ? res.data.message : res.message);
      }
    });
  };
  useEffect(() => {
    if (conditionByProductId) {
      let array = [];
      conditionByProductId?.data?.data?.map((val) => {
        array.push({
          factor: val?.factor,
          condition_id: val?.product_factorable,
        });
      });
      reset({
        conditions_factor: array,
      });
    }
  }, [conditionByProductId]);
  return (
    <DialogComponent
      open={true}
      className="cardDialog addressDialog"
      handleOpenClose={onClose}
      buttonLabel={"Save"}
      title={`Edit Condition`}
      loading={loading}
      //   disabled={!formik.dirty || loading}
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <>
        <div className="conditions-factor-handle">
          {getValues("conditions_factor")?.map((element, index) => (
            <div className="form-handle">
              <div className="label">{element?.condition_id?.name?.en}</div>
              <div>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <FormInput
                      required
                      type="number"
                      name={`conditions_factor.${index}.factor`}
                      placeholder={"Enter factor"}
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value === "" ? null : e.target.value);
                        setErrorMessage("");
                      }}
                      helperText={
                        errors?.conditions_factor?.[index]?.factor?.message
                      }
                    />
                  )}
                  name={`conditions_factor.${index}.factor`}
                  control={control}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="form-error-message">{errorMessage}</div>
      </>
    </DialogComponent>
  );
}
export default ManageConditionsHandle;
