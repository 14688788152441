// EXTERNAL LIBRARIES
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TableCell } from "@mui/material";

// STYLING
import "./UserGroups.scss";

// TABLE
import TableHeader from "../../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../../components/table/tableBody/TableRowComponent";
import Paginator from "../../../components/paginator/Paginator";
import Button from "../../../components/button/Button";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { api } from "../../../core/apis/main";

// CUSTOM HOOKS
import { useGet } from "../../../hooks/useFetch";
import { AlertContext } from "../../../context/AlertContext";

// DATA NOT FOUND
import DataNotFound from "../../../components/notFound/DataNotFound";
import withH1 from "../../../assests/HOC/withH1";

const UserGroups = () => {
  // DATA AND HOOKS
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);

  const [openDelete, setOpenDelete] = useState(false); //bool delete dialog
  const [selectedData, setSelectedData] = useState(null); //selected data when opening delete dialog

  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };
  const tableHeaders = [{ title: "User Groups" }];

  // API REQUESTS
  let url = `/admin/get-all-notification-groups`;
  const {
    data: groups,
    loading,
    refetch,
  } = useGet({ url: url, payload: payload });
  let data = groups?.data?.data;

  // FUNCTIONALITY
  // Deleting notifiation group api
  const handleDeleteResponse = async (type, element) => {
    if (type === "yes") {
      return await api
        .delete(`admin/delete-notification-group/${element}`)
        .then((res) => {
          setAlert({
            text: res.data.message,
            type: res.data.success ? "success" : "error",
          });
          setOpenDelete(false);
          setSelectedData(null);
          refetch();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setOpenDelete(false);
      setSelectedData(null);
    }
  };

  // Opening delete modal and setting values
  const handleDelete = (element) => {
    setOpenDelete(true);
    setSelectedData(element);
  };

  return (
    <>
      <TableHeader
        placeholder="Search by group name"
        to="/user-groups/new-user-groups"
        filterButton={false}
      />
      <TableBodyComponent
        header={tableHeaders}
        loading={loading}
        total={data?.total}
      >
        {data?.data?.length ? (
          data?.data?.map((element) => (
            <TableRowComponent
              handleEdit={() =>
                navigate(`/user-groups/edit-user-group/${element?.id}`)
              }
              handleDelete={() => handleDelete(element?.id)}
              row={element}
              key={element?.id}
            >
              <TableCell>{element?.name}</TableCell>
            </TableRowComponent>
          ))
        ) : (
          <DataNotFound />
        )}
      </TableBodyComponent>
      {data && <Paginator count={data?.last_page} disabled={loading} />}
      {openDelete && (
        <NoticeConfirmation
          data={selectedData}
          handleResponse={(e) => handleDeleteResponse(e, selectedData)}
        />
      )}
    </>
  );
};
export default withH1(UserGroups, "User Groups");
