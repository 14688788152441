import React, { useState } from "react";
import { Tab, Typography } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import StoresectionOne from "./storesectionOne";
import "./storesection.scss";
import BannerSection from "./bannerSection";

const Storesection = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="storesection">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
          padding: "5px",
        }}
      >
      </div>
      <TabPanel value="1" style={{ padding: "0px" }}>
        <TabContext value={tabValue}>
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label="Section one" value="0" />
            <Tab label="Banner Section" value="1" />
          </TabList>

          <TabPanel
            value="0"
            style={{ minHeight: "600px", padding: "0px", marginTop: "10px" }}
          >
            <StoresectionOne></StoresectionOne>
          </TabPanel>
          <TabPanel
            value="1"
            style={{ minHeight: "600px", padding: "0px", marginTop: "10px" }}
          >
            <BannerSection></BannerSection>
          </TabPanel>
        </TabContext>
      </TabPanel>
    </div>
  );
};

export default Storesection;
