import { api } from "./main";

export const getAllBanners = async (payload) => {
  return await api
    .get("admin/get-all-banners", { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const getBannerById = async (id) => {
  return await api
    .get(`admin/get-banner-by-id/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const createBanner = async (payload) => {
  return await api
    .post("admin/create-banner", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const updateBanner = async (payload) => {
  return await api
    .post(`admin/update-banner/${payload?.id}`, payload?.payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const deleteBanner = async (id) => {
  return await api
    .delete(`admin/delete-banner/${id}`, { params: { id: id } })
    .then((res) => res)
    .catch((err) => console.error(err));
};
