//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { AlertContext } from "../../context/AlertContext";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

//COMPONENT
import BranchesUsers from "./BranchesUsers";
import Button from "../../components/button/Button";
import { Card, CardContent, Skeleton } from "@mui/material";
import SimpleMap from "../../components/maps/Maps";
import {
  FormDropdownList,
  FormInput,
} from "../../components/formComponents/FormComponents";
//CSS
import "./Branches.scss";

//API
import { useGet } from "../../hooks/useFetch";

import {
  CreateBranch,
  EditBranch,
  getBranchById,
} from "../../core/apis/branch";
import { defaultLat, defaultLong } from "../../core/constants/constant";

const HandleSchema = yup.object({
  name: yup.string("Field is invalid").required("Field is required"),
  description: yup.string("Field is invalid"),
  factor: yup
    .number("Field is invalid")
    .nullable()
    .required("Field is required")
    .positive("Field should be positive"),
  organization_id: yup.object().nullable().required("Field is required"),
  address: yup.object().shape({
    address_line: yup.string("Field is invalid").required("Field is required"),
    country_id: yup.object().nullable().required("Field is required"),
    city_name: yup.string("Field is invalid").required("Field is required"),
    latitude: yup.number("Field is invalid"),
    longitude: yup.number("Field is invalid"),
    postal_code: yup.string("Field is invalid"),
  }),
});

const BranchesHandle = (props) => {
  const { id } = useParams();
  let navigate = useNavigate();
  const { type } = props;
  const [saveLoading, setSaveLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });
  //API

  const { data: organizations, organizationLoading } = useGet({
    url: "admin/get-all-organization",
    payload: null,
  });

  let organizationArray = organizations?.data?.data;
  const { setAlert } = useContext(AlertContext);
  //VARIABLES

  const handleSubmitForm = async (formData) => {
    setSaveLoading(true);
    if (type === "edit") {
      EditBranch({
        branch_id: id,
        ...formData,
        organization_id: formData.organization_id?.id,
        address: {
          ...formData.address,
          country_id: formData.address.country_id?.id,
        },
      }).then((res) => {
        setAlert({
          visible: true,
          text: res.data ? res.data?.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
        if (res.data?.success) {
          navigate("/branches");
        }
        setSaveLoading(false);
      });
    } else {
      CreateBranch({
        ...formData,
        organization_id: formData.organization_id?.id,
        address: {
          ...formData.address,
          country_id: formData.address.country_id?.id,
        },
      }).then((res) => {
        setAlert({
          visible: true,
          text: res.data ? res.data?.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
        if (res.data?.success) {
          navigate("/branches");
        }
        setSaveLoading(false);
      });
    }
  };

  const getBranchData = () => {
    setLoadingData(true);
    getBranchById(id).then((res) => {
      if (res?.data?.success) {
        reset({
          display_name: res?.data?.data?.name || "",
          name: res?.data?.data?.name || "",
          description: res?.data?.data?.description || "",
          organization_id: res?.data?.data?.organization?.organization || null,
          factor: res?.data?.data?.factor || null,
          address: {
            id: res?.data?.data?.address?.[0]?.id || null,
            country_id: res?.data?.data?.address?.[0]?.country || null,
            address_line: res?.data?.data?.address?.[0]?.address_line || "",
            city_name: res?.data?.data?.address?.[0]?.city_name || "",
            latitude: res?.data?.data?.address?.[0]?.latitude || defaultLat,
            longitude: res?.data?.data?.address?.[0]?.longitude || defaultLong,
            postal_code: res?.data?.data?.address?.[0]?.postal_code || "",
          },
        });
      } else {
        setAlert({
          visible: true,
          text: res.data ? res.data.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
      }
      setLoadingData(false);
    });
  };
  useEffect(() => {
    if (type === "edit") {
      getBranchData();
    } else {
      reset({
        name: "",
        description: "",
        organization_id: null,
        factor: null,
        address: {
          id: null,
          country_id: null,
          address_line: "",
          city_name: "",
          latitude: defaultLat,
          longitude: defaultLong,
          postal_code: "",
        },
      });
    }
  }, []);

  return (
    <>
      <h1>{type === "edit" ? getValues("display_name") : "New Branch"}</h1>
      <div className="branches-handle-component">
        <div className="branches-handle-form-section">
          <Card>
            <CardContent>
              {!loadingData ? (
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="name"
                        label="Branch"
                        placeholder={"Enter name"}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name="name"
                    control={control}
                  />
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        name="description"
                        label="Description"
                        placeholder={"Enter description"}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name="description"
                    control={control}
                  />
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        type="number"
                        name="factor"
                        label="Factor"
                        placeholder={"Enter factor"}
                        value={value}
                        onChange={(e) => {
                          onChange(
                            e.target.value === "" ? null : e.target.value
                          );
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name="factor"
                    control={control}
                  />
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormDropdownList
                        required
                        data={organizationArray || []}
                        loading={organizationLoading}
                        label="Organization"
                        name="organization_id"
                        clearErrors={clearErrors}
                        dependencyError={[
                          "address.country_id",
                          "organization_id",
                        ]}
                        placeholder={"Select organization"}
                        value={value}
                        onChange={(e) => {
                          setValue("address.country_id", null, {
                            shouldValidate: true,
                          }); // ✅ performant

                          onChange(e);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name="organization_id"
                    control={control}
                  />

                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        label="Address"
                        name="address.address_line"
                        placeholder={"Enter address"}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name="address.address_line"
                    control={control}
                  />
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormDropdownList
                        required
                        data={getValues("organization_id")?.country || []}
                        loading={false}
                        label="Country"
                        clearErrors={clearErrors}
                        name="address.country_id"
                        placeholder={"Select country"}
                        noOptionsMessage={
                          getValues("organization_id")
                            ? "No Options"
                            : "Please Select Organization First"
                        }
                        value={value || null}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name="address.country_id"
                    control={control}
                  />

                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        label="City"
                        placeholder={"Enter city"}
                        name="address.city_name"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name="address.city_name"
                    control={control}
                  />
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        label="Postal Code"
                        placeholder={"Enter postal code"}
                        name="address.postal_code"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name="address.postal_code"
                    control={control}
                  />
                  <div className="map-height">
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <SimpleMap
                          coordination={{
                            lat: getValues("address.latitude"),
                            lng: getValues("address.longitude"),
                          }}
                          draggable={true}
                          zoom={8}
                          onClick={(e) => {
                            setValue("address.latitude", e.lat, {
                              shouldValidate: true,
                            });
                            setValue("address.longitude", e.lng, {
                              shouldValidate: true,
                            });
                          }}
                          helperText={error?.message}
                        />
                      )}
                      name="address.latitude"
                      control={control}
                    />
                  </div>

                  <div className="action-footer">
                    <Button
                      name={type === "edit" ? "Save" : "Create"}
                      selected
                      loading={saveLoading}
                      width={"30%"}
                      type="submit"
                    ></Button>
                  </div>
                </form>
              ) : (
                <Skeleton count={5} />
              )}
            </CardContent>
          </Card>
        </div>
        {type === "edit" && (
          <div className="branches-handle-side-section">
            <BranchesUsers branchId={id} />
          </div>
        )}
      </div>
    </>
  );
};

export default BranchesHandle;
