//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

//COMPONENT
import SimpleMap from "../../components/maps/Maps";
import Button from "../../components/button/Button";
import { Card, CardContent, Skeleton } from "@mui/material";
import {
  FormDropdownList,
  FormInput,
  FormSwitch,
} from "../../components/formComponents/FormComponents";
//CSS
import "./CollectionPoints.scss";
import CPOpenHours from "./CPOpenHours";
import CPUsers from "./CPUsers";
import { useGet } from "../../hooks/useFetch";
import {
  CreateCollectionPoint,
  EditCollectionPoint,
} from "../../core/apis/collectionPoint";
import { AlertContext } from "../../context/AlertContext";
import { defaultLat, defaultLong } from "../../core/constants/constant";
import moment from "moment";
const CollectionPointsHandle = (props) => {
  const { id } = useParams();
  const { type } = props;
  let navigate = useNavigate();
  const [saveLoading, setSaveLoading] = useState(false);
  //API
  const { data: cp, loading } = useGet({
    url: type === "edit" ? `admin/get-collection-point-by-id/${id}` : null,
  });

  const { data: countries, countryloading } = useGet({
    url: "get-all-countries",
    payload: null,
  });
  let countryArray = countries?.data.data;

  //VARIABLES
  const { setAlert } = useContext(AlertContext);

  const HandleSchema = yup.object({
    name: yup.string().required("Field is required"),
    address: yup.object().shape({
      address_line: yup.string().required("Field is required"),
      country_id: yup.object().nullable().required("Field is required"),
      city_name: yup.string().required("Field is required"),
      latitude: yup.number(),
      longitude: yup.number(),
      postal_code: yup.string(),
    }),
    opening_hours: yup.array().of(
      yup.object().shape({
        is_active: yup.boolean(),
        day_id: yup.number(),
        hour_from: yup.string().when("is_active", {
          is: true,
          then: yup.string().required("Field is required"),
          otherwise: yup.string().notRequired(),
        }),
        hour_to: yup.string().when("is_active", {
          is: true,
          then: yup.string().required("Field is required"),
          otherwise: yup.string().notRequired(),
        }),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    register,
    setValue,
    getValues,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });
  const {
    fields: openingHoursFields,
    remove: openingHoursRemove,
    append: openingHoursAppend,
  } = useFieldArray({ control, name: "opening_hours" });

  //FUNCTIONS

  const handleSubmitForm = (formData, e) => {
    // e?.preventDefault();
    // e?.stopPropagation();
    setSaveLoading(true);
    if (type === "edit") {
      EditCollectionPoint({
        ...formData,
        cp_id: id,
        address: {
          ...formData.address,
          country_id: formData.address.country_id?.id,
        },
        opening_hours: formData.opening_hours.map((val) => ({
          ...val,
          hour_from: val.hour_from
            ? moment(new Date(`2022-02-01T${val.hour_from}`)).format("HH:mm")
            : "",
          hour_to: val.hour_from
            ? moment(new Date(`2022-02-01T${val.hour_to}`)).format("HH:mm")
            : "",
        })),
      }).then((res) => {
        setAlert({
          visible: true,
          text: res.data ? res.data.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
        if (res.data?.success) {
          navigate("/collection-points");
        }
        setSaveLoading(false);
      });
    } else {
      CreateCollectionPoint({
        ...formData,
        address: {
          ...formData.address,
          country_id: formData.address.country_id?.id,
        },
        opening_hours: formData.opening_hours.map((val) => ({
          ...val,
          hour_from: val.hour_from
            ? moment(new Date(`2022-02-01T${val.hour_from}`)).format("HH:mm")
            : "",
          hour_to: val.hour_from
            ? moment(new Date(`2022-02-01T${val.hour_to}`)).format("HH:mm")
            : "",
        })),
      }).then((res) => {
        setAlert({
          visible: true,
          text: res.data ? res.data.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
        if (res.data?.success) {
          navigate("/collection-points");
        }
        setSaveLoading(false);
      });
    }
  };

  useEffect(() => {
    if (type === "new") {
      reset({
        name: "",
        address: {
          address_line: "",
          country_id: null,
          city_name: "",
          latitude: defaultLat,
          longitude: defaultLong,
          postal_code: "",
        },

        opening_hours: Array(7)
          .fill()
          .map((val, index) => ({
            day_id: index + 1,
            is_active: false,
            hour_from: "",
            hour_to: "",
          })),
      });
    } else {
      if (cp) {
        let data = cp?.data?.data;

        reset({
          is_active: data?.is_active,
          name: data?.name?.en,
          address: {
            id: data?.address?.[0]?.id,
            address_line: data?.address?.[0]?.address_line,
            country_id: data?.address?.[0]?.country,
            city_name: data?.address?.[0]?.city_name,
            latitude: data?.address?.[0]?.latitude,
            longitude: data?.address?.[0]?.longitude,
            postal_code: data?.address?.[0]?.postal_code,
          },
          opening_hours: data?.week_days?.map((val) => ({
            day_id: val?.pivot?.id,
            is_active: val?.pivot?.is_active,
            hour_from: val?.pivot?.hour_from ? val?.pivot?.hour_from : "",
            hour_to: val?.pivot?.hour_to ? val?.pivot?.hour_to : "",
          })),
        });
      }
    }
  }, [cp]);

  return (
    <>
      <h1>
        {type === "new"
          ? "New Collection Point"
          : cp?.data?.data?.name?.en || ""}
      </h1>
      <form
        onSubmit={handleSubmit(handleSubmitForm)}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <div className="cp-handle-component">
          <div className="cp-handle-form-section">
            <Card>
              <CardContent>
                {!loading ? (
                  <>
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormInput
                          name="name"
                          label="Name"
                          placeholder={"Enter name"}
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          helperText={error?.message}
                        />
                      )}
                      name="name"
                      control={control}
                    />
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormInput
                          name="address.address_line"
                          label="Address"
                          placeholder={"Enter address"}
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          helperText={error?.message}
                        />
                      )}
                      name="address.address_line"
                      control={control}
                    />
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormDropdownList
                          name="address.country_id"
                          data={countryArray || []}
                          loading={countryloading}
                          label="Country"
                          placeholder={"Select country"}
                          value={value}
                          onChange={(value) => onChange(value)}
                          helperText={error?.message}
                        />
                      )}
                      name="address.country_id"
                      control={control}
                    />
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormInput
                          name="city_name"
                          label="City"
                          placeholder={"Enter city"}
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          helperText={error?.message}
                        />
                      )}
                      name="address.city_name"
                      control={control}
                    />
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormInput
                          name="postal_code"
                          label="Postal Code"
                          placeholder={"Enter postal code"}
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          helperText={error?.message}
                        />
                      )}
                      name="address.postal_code"
                      control={control}
                    />
                    <div className="map-height">
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <SimpleMap
                            coordination={{
                              lat: getValues("address.latitude"),
                              lng: getValues("address.longitude"),
                            }}
                            draggable={true}
                            zoom={8}
                            onClick={(e) => {
                              setValue("address.latitude", e.lat, {
                                shouldValidate: true,
                              });
                              setValue("address.longitude", e.lng, {
                                shouldValidate: true,
                              });
                            }}
                            helperText={error?.message}
                          />
                        )}
                        name="address.latitude"
                        control={control}
                      />
                    </div>
                    {type === "edit" && (
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <FormSwitch
                            label="Active"
                            name="is_active"
                            value={value}
                            onChange={(_, checked) => onChange(checked)}
                          />
                        )}
                        name="is_active"
                        control={control}
                      />
                    )}
                    <div className="action-footer">
                      <Button
                        type="submit"
                        name={type === "edit" ? "Save" : "Create"}
                        selected
                        loading={saveLoading}
                        width={"30%"}
                      ></Button>
                    </div>
                  </>
                ) : (
                  <Skeleton count={5} />
                )}
              </CardContent>
            </Card>
          </div>
          <div className="cp-handle-side-section">
            <CPOpenHours
              clearErrors={clearErrors}
              errors={errors}
              loading={loading}
              control={control}
              setValue={setValue}
              getValues={getValues}
              type={type}
            />
            {type === "edit" && <CPUsers cpId={id} />}
          </div>
        </div>
      </form>
    </>
  );
};

export default CollectionPointsHandle;
