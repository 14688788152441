import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid, Skeleton } from "@mui/material";
import Button from "../../../components/button/Button";
import {
  FormInput,
  FormTextArea,
} from "../../../components/formComponents/FormComponents";
import { useGet } from "../../../hooks/useFetch";
import { useGlobalValues } from "../../../context/GlobalContext";
import { updateSectionone } from "../../../core/apis/store";
import { AlertContext } from "../../../context/AlertContext";
import "./becomeaPartner.scss";
import { Switch } from "@mui/material";

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();
  const [isFetchingData, setIsFetchingData] = useState(true);

  // Schema validation
  const informationsectionSchema = yup.object({
    title: yup.string().nullable(),
    subTitle: yup.string().nullable(),
    active: yup.boolean().nullable().required(""),
  });

  const handleCancel = () => {
    refetch();
  };

  const defaults = {
    title: "",
    subTitle: "",
    active: true,
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(informationsectionSchema),
    defaultValues: defaults,
  });

  // Fetch section details by slug
  const {
    data: sectionDetails,
    loading: loadingDetails,
    refetch,
  } = useGet({
    url: "admin/section/CONTACT_FORM_SECTION",
  });

  const contactFormArray = sectionDetails?.data?.data;
  //console.log(contactFormArray);

  const handleSubmitForm = async (values) => {
    console.log(values);
    setLoading(true);

    const payload = {
      language: storeLanguage,
      title: values.title,
      sub_title: values.subTitle,
      is_enable: values.active,
    };

    updateSectionone("CONTACT_FORM_SECTION", payload).then((res) => {
      setAlert({
        visible: true,
        text: res?.data.success ? "Successfully Updated" : res?.data?.message,
        type: res?.data?.success ? "success" : "error",
      });
      refetch();
      setLoading(false);
    });
  };

  useEffect(() => {
    if (contactFormArray) {
      setIsFetchingData(true);

      const titleObject = contactFormArray?.columns?.find(
        (item) => item.language === storeLanguage && item.title
      );
      const subTitleObject = contactFormArray?.columns?.find(
        (item) => item.language === storeLanguage && item.sub_title
      );
      const activeObject = contactFormArray?.columns?.find(
        (item) => item.language === "en" && item.is_enable
      );

      const populatedFormData = {
        title: titleObject?.title,
        subTitle: subTitleObject?.sub_title,
        active: activeObject?.is_enable,
      };

      setValue("title", populatedFormData.title);
      setValue("subTitle", populatedFormData.subTitle);
      setValue("active", populatedFormData.active);

      setIsFetchingData(false);
    }
  }, [contactFormArray, storeLanguage, setValue]);

  return (
    <div className="informationSection">
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} className="displayEnd">
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width={100} height={40} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <div className="switch">
                        <label>Enable this block</label>
                        <Switch
                          checked={value}
                          onChange={(e) => {
                            onChange(e.target.checked);
                          }}
                        />
                      </div>
                    )}
                    name="active"
                    control={control}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="text" width="100%" height={40} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name="title"
                        label="Form Title"
                        hideVariant
                        placeholder={""}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="title"
                    control={control}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width="100%" height={120} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormTextArea
                        required
                        hideVariant
                        name="subTitle"
                        label="Form Sub Description"
                        placeholder={""}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        helperText={error?.message}
                      />
                    )}
                    name="subTitle"
                    control={control}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              {isFetchingData ? (
                <Skeleton variant="rectangular" width={100} height={40} />
              ) : (
                <Button
                  onClick={() => handleCancel()}
                  name={"Cancel"}
                  type="button"
                  className="mx-1"
                />
              )}
            </Grid>
            <Grid item>
              {isFetchingData ? (
                <Skeleton variant="rectangular" width={100} height={40} />
              ) : (
                <Button
                  selected
                  name={"Save"}
                  type="submit"
                  className="mx-1"
                  loading={loading}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ContactForm;
