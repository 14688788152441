import React, { useState } from "react";
import { useGet } from "../../../hooks/useFetch";
import { Tab,Typography } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./lawandordersHomepage.scss";
import PrivacyPolicy from "./PrivacyPolicy/privacyPolicy";
import Skeleton from "@mui/material/Skeleton";

const LawandOrdersHomePage = () => {
  const [tabValue, setTabValue] = useState("PRIVACY_POLICY");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  //Get All Documents
  const { data: allDocuments, loading: loadingALLDocuments } = useGet({
    url: "admin/document-types",
  });

  let AllDocumentsArray = allDocuments?.data?.data?.filter((item) => item != "FOOTER_OPEN_TEXT")

  const updateName = (str) => {
    return str?.toLowerCase()?.replace(/_/g, " ")?.toUpperCase();
  };

  return (
    <div className="lawandorder">

      <TabContext value={tabValue}>
        <TabList
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {loadingALLDocuments
            ? Array(5)
                .fill(0)
                .map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    width={200}
                    height={50}
                    style={{ borderRadius: "8px", margin: "10px" }}
                  />
                ))
            : AllDocumentsArray?.map((tab) => (
                <Tab
                  key={tab}
                  label={updateName(tab)}
                  value={tab}
                  iconPosition="start"
                  style={{ marginTop: "10px" }}
                />
              ))}
        </TabList>
        {AllDocumentsArray?.map((tab) => (
          <TabPanel value={tab} style={{ padding: "0px" }}>
            <PrivacyPolicy doctype={tab} />
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
};

export default LawandOrdersHomePage;
