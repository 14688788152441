import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AlertContext } from "../../../context/AlertContext";
import { separateValuesByAttributeId } from "../../../core/functions/Functions";
import "./Products.scss";
import { Paper, TableCell } from "@mui/material";
import TableHeader from "../../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../../components/table/tableBody/TableBodyComponent";
import EditableRow from "../../../components/editableRow/editableRow";
import Paginator from "../../../components/paginator/Paginator";
import CollapsedTableRow from "../../../components/collapsedTable/CollapsedTableRow";
import { useGet } from "../../../hooks/useFetch";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import withH1 from "../../../assests/HOC/withH1";
import "./Products.scss";
import {
  updateproductfactorsbyid,
  DownloadProductsPrices,
  UploadProductsPrices,
} from "../../../core/apis/product";

import {
  UpdateAllProductsConditionFactor,
  deleteProduct,
  DownloadProductsPricesBasedOnCategory,
} from "../../../core/apis/product";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm, Controller } from "react-hook-form";
import { FormDropdownList } from "../../../components/formComponents/FormComponents";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "../../../components/button/Button";
import DownloadIcon from "@mui/icons-material/Download";
import UploadFile from "@mui/icons-material/UploadFile";

const HandleSchema = yup.object({
  category_id: yup.object().nullable().required("Field is required"),
});

const Products = () => {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { setAlert } = useContext(AlertContext);
  const [productDetail, setProductDetail] = useState(null);

  // Modals
  const [openDelete, setOpenDelete] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPriceDialog, setOpenPriceDialog] = useState(false);

  const [conditionfactorsArray, setconditionfactorsArray] = useState([]);
  const [conditionfactorsList, setconditionfactorsList] = useState({});
  const [attributeListValues, setAttributeListValues] = useState({});

  const [processedData, setProcessedData] = useState({});
  const [attributesprocessedData, setattributesProcessedData] = useState({});

  const [error, setError] = useState(false);
  const [conditioneditMode, setconditioneditMode] = useState(false);
  const [editModes, setEditModes] = useState({});

  const [downloading, setDownloading] = useState(false);

  // PriceList
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [downloadPriceList, setDownloadPriceList] = useState(false);

  // Upload Price List
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(HandleSchema),
    reValidateMode: "onChange" | "onBlur",
  });

  const tableHeaders = [
    { title: "Product" },
    { title: "Base Price" },
    { title: "Brand" },
    { title: "Category" },
  ];
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
    brand_ids: searchParams.get("brand_ids")
      ? [searchParams.get("brand_ids")]
      : null,
    category_ids: searchParams.getAll("category_ids"),
  };

  const {
    data: products,
    loading,
    refetch,
  } = useGet({ url: "admin/get-all-products", payload: payload });
  let data = products?.data.data;

  const { data: categories, categoriesLoading } = useGet({
    url: "admin/get-all-categories",
  });

  const { data: conditions, conditionsLoading } = useGet({
    url: "/user/get-all-conditions",
  });

  const handlecreateConditionsVal = () => {
    const conditionarray = conditions.data.data
      .filter((item) => item.id < 8)
      .map((item) => ({
        attributeName: "Condition",
        attribute_id: null,
        outterid: null,
        id: item.id,
        factor: null,
        name: item?.name,
      }));
    setconditionfactorsArray(conditionarray);
  };

  useEffect(() => {
    if (conditions) {
      handlecreateConditionsVal();
    }
  }, [conditions]);

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteProduct(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setProductDetail(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  const handlesavedata = (id) => {
    if (conditionfactorsList[id] && attributeListValues[id]) {
      // Extract conditions from the savedata object for the specified id
      const conditionList = conditionfactorsList[id];
      const attributesValues = attributeListValues[id];

      const newconditionlist = transformArrayToConditionsFactor(conditionList);
      const attribute_values_factor = [];

      attributesValues.map((item) => {
        attribute_values_factor.push({
          attribute_value_id: parseInt(item.id),
          factor: parseInt(item.factor),
        });
      });

      let payload = {
        conditions_factor: newconditionlist.conditions_factor,
        attribute_values_factor: attribute_values_factor,
      };

      updateproductfactorsbyid(payload, id).then((res) => {
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
        refetch();
      });
    } else {
      setAlert({
        visible: true,
        text: "Please Save Conditions and Attributes Factors ",
        type: "error",
      });
    }
  };

  // handleSubmit For dialog
  const handleSubmitForm = (formvalues) => {
    const conditions_factor = conditionfactorsArray.map((item) => ({
      condition_id: item.id,
      factor: item.factor,
    }));
    let id = formvalues.category_id.id;
    UpdateAllProductsConditionFactor(id, { conditions_factor }).then((res) => {
      if (res?.data?.success) {
        reset({
          category_id: null,
        });
        refetch();
        setconditionfactorsArray([])
        setOpenDialog(false);
      }
      setAlert({
        visible: true,
        text: res.data.message,
        type: res.data.success ? "success" : "error",
      });
    });
  };

  // Download Price List Based On Categor
  const handleDownloadPriceList = async () => {
    try {
      setDownloadPriceList(true);
      setOpenPriceDialog(false);

      let payload = {
        category_id: selectedCategory?.id,
        category_name: selectedCategory?.name?.en,
      };

      // Fetch product prices data with responseType: 'blob'
      const response = await DownloadProductsPricesBasedOnCategory(payload);

      // Ensure response contains data
      if (!response || !response.data) {
        throw new Error("No data received");
      }

      if (response.status === 200) {
        // Creating new object of the file to be downloaded
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        // Creating a link element and setting its attributes for download
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = `${selectedCategory?.name?.en}-List.xlsx`;
        link.click();

        setAlert({
          visible: true,
          text: "Successfully Downloaded",
          type: "success",
        });
      } else {
        throw new Error("Failed to download file");
      }
    } catch (error) {
      console.error("Error downloading product prices:", error);
      setAlert({
        visible: true,
        text: "Failed to download file",
        type: "error",
      });
    } finally {
      setDownloadPriceList(false);
      setOpenPriceDialog(false);
    }
  };

  const handleValues = (id, values) => {
    setAttributeListValues((prevState) => {
      const updatedData = { ...prevState };

      if (updatedData[id]) {
        // Remove existing items with the same ids as the new values
        const newIds = values.map((item) => item.id);
        updatedData[id] = updatedData[id].filter(
          (item) => !newIds.includes(item.id)
        );

        // Add new values
        updatedData[id] = [...updatedData[id], ...values];
      } else {
        // If the id doesn't exist, add the new values
        updatedData[id] = values;
      }

      return updatedData;
    });
    setattributesProcessedData((prevState) => {
      const updatedData = { ...prevState };

      if (updatedData[id]) {
        // Remove existing items with the same ids as the new values
        const newIds = values.map((item) => item.id);
        updatedData[id] = updatedData[id].filter(
          (item) => !newIds.includes(item.id)
        );

        // Add new values
        updatedData[id] = [...updatedData[id], ...values];
      } else {
        // If the id doesn't exist, add the new values
        updatedData[id] = values;
      }

      return updatedData;
    });
  };

  const handlenewConditionValues = (id, values) => {
    setconditionfactorsList((prevState) => {
      const updatedData = { ...prevState };

      if (updatedData[id]) {
        // Remove existing items with the same ids as the new values
        const newIds = values.map((item) => item.id);
        updatedData[id] = updatedData[id].filter(
          (item) => !newIds.includes(item.id)
        );

        // Add new values
        updatedData[id] = [...updatedData[id], ...values];
      } else {
        // If the id doesn't exist, add the new values
        updatedData[id] = values;
      }

      return updatedData;
    });
    setProcessedData((prevState) => {
      const updatedData = { ...prevState };

      if (updatedData[id]) {
        // Remove existing items with the same ids as the new values
        const newIds = values.map((item) => item.id);
        updatedData[id] = updatedData[id].filter(
          (item) => !newIds.includes(item.id)
        );

        // Add new values
        updatedData[id] = [...updatedData[id], ...values];
      } else {
        // If the id doesn't exist, add the new values
        updatedData[id] = values;
      }

      return updatedData;
    });
  };

  const handleClick = (id, mode) => {
    const state = { viewMode: mode };
    const path = `/products/product/${id}`;
    navigate(path, { state });
  };

  const transformArrayToConditionsFactor = (array) => {
    return {
      conditions_factor: array.map((item) => ({
        condition_id: item?.id,
        factor: item?.factor,
      })),
    };
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  // Dialog Values
  const handleConditionValues = (val, name) => {
    setconditionfactorsArray(val);
  };

  const handleapiConditionsValue = async (data) => {
    const values = separateValuesByAttributeId(data).filter(
      (item) => item?.attribute_id == null
    );

    // Process each item and return the conditionarray
    const conditionarrays = values.map((item) => {
      return item?.items.map((innerItem) => ({
        outterid: innerItem?.id,
        id: innerItem?.product_factorable?.id,
        factor: innerItem?.factor,
        name: innerItem?.product_factorable?.name,
      }));
    });

    return conditionarrays.flat(); // Flatten the arrays
  };

  const handleRecievedAttValues = async (data, attribute) => {
    const values = separateValuesByAttributeId(data).filter(
      (item) => item?.attribute_id != null
    );
    // Map attributes to set 'attributeName' for matching values
    attribute.forEach((item) => {
      const matchedValue = values.find(
        (KeyValue) => KeyValue?.attribute_id === item.id
      );
      if (matchedValue) {
        // Set 'attributeName' if a match is found
        matchedValue.attributeName = item.name.en;
      }
    });
    // Extract and map items
    const storageArray = values
      .sort((a, b) => a.id - b.id)
      .flatMap((item) =>
        item.items.map((innerItem) => ({
          outterid: innerItem?.id,
          id: innerItem?.product_factorable?.id,
          attribute_id: item?.attribute_id,
          factor: innerItem?.factor,
          name: innerItem?.product_factorable?.name,
          abbreviation: innerItem?.product_factorable?.abbreviation,
          attributeName: item?.attributeName,
          // Add more properties from 'product_factorable' as needed
        }))
      );
    return storageArray;
  };

  useEffect(() => {
    const preprocessData = async () => {
      const newProcessedData = {};
      const newattributesProcessedData = {};

      for (const item of products?.data?.data?.data || []) {
        const processedItems = await handleapiConditionsValue(
          item.product_factor
        );
        newProcessedData[item.id] = processedItems;
        const attributeprocessedItems = await handleRecievedAttValues(
          item.product_factor,
          item.attribute
        );
        newattributesProcessedData[item.id] = attributeprocessedItems;
      }

      setProcessedData(newProcessedData);
      setattributesProcessedData(newattributesProcessedData);
    };

    preprocessData();
  }, [products]);

  const groupByAttributeId = (data) => {
    return data?.reduce((result, item) => {
      if (!result[item.attribute_id]) {
        result[item.attribute_id] = [];
      }
      result[item.attribute_id].push(item);
      return result;
    }, {});
  };

  const toggleEditMode = (rowId, attributeName) => {
    setEditModes((prevEditModes) => ({
      ...prevEditModes,
      [rowId]: {
        ...prevEditModes[rowId],
        [attributeName]: !prevEditModes[rowId]?.[attributeName],
      },
    }));
  };

  // Handle Download PriceList
  const handleDownload = async () => {
    setDownloading(true);
    // Fetch product prices data with responseType: 'arraybuffer'
    const response = await DownloadProductsPrices();
    // Ensure response contains data
    if (!response || !response.data) {
      throw new Error("Empty response or missing data");
    }
    if (response?.status === 200) {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(new Blob([response?.data]));
      // Setting various property values
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = "transaction.xlsx";
      alink.click();
      setDownloading(false);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    handleUpload(event?.target?.files[0]);
  };
  const handleUpload = (selectedFile) => {
    setUploading(true);
    if (!selectedFile) {
      console.error("No file selected for upload");
      return;
    }
    let valuesData = new FormData();
    valuesData.append("file", selectedFile);
    // Call handleUpload function with selectedFile
    UploadProductsPrices(valuesData).then((response) => {
      setAlert({
        visible: true,
        text: response?.data?.message,
        type: response?.data?.success ? "success" : "error",
      });
      refetch();
      setUploading(false);
      console.log("File uploaded successfully:", response);
      // Handle successful upload response as needed
    });
  };

  const handleClosePriceDialog = () => {
    setOpenPriceDialog(false);
    setSelectedCategory(null);
  };

  return (
    <div className="productsList">
      <Dialog fullWidth maxWidth={"lg"} open={openDialog} onClose={handleClose}>
        <DialogTitle>Apply Condition</DialogTitle>
        <DialogContent style={{ minWidth: "800px" }}>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormDropdownList
                  required
                  data={categories?.data?.data?.categories || []}
                  loading={categoriesLoading}
                  label="Categories"
                  name="category_id"
                  clearErrors={clearErrors}
                  mainClassName="mx-2 width-100"
                  dependencyError={["category_id"]}
                  placeholder={"Select Categories"}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error?.message}
                  hideVariant={true}
                />
              )}
              name="category_id"
              control={control}
            />
            <div className="factors-div">
              {conditions && (
                <div className="factorsDiv">
                  <div className="mt-1">
                    <h5>{"Condition Factors"}</h5>
                    <EditableRow
                       data={conditionfactorsArray?.sort(
                        (a, b) => a.id - b.id
                      )}
                      create={true}
                      title={"Condition"}
                      editMode={conditioneditMode}
                      setEditMode={() =>
                        setconditioneditMode(!conditioneditMode)
                      }
                      // add onclear
                      onClear={(val) =>
                        setconditionfactorsArray(attributesprocessedData)
                      }
                      onsave={handleConditionValues}
                      hide={false}
                    />
                    {error && (
                      <p className="error-message">All fields are required!</p>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="button-group">
              <Button
                selected
                name={"Save"}
                type="submit"
                className="mx-1"
                startIcon={<CheckCircleIcon />}
              />
              <Button
                name={"Cancel"}
                type="button"
                className="mx-1"
                onClick={handleClose}
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={openPriceDialog}
        onClose={handleClosePriceDialog}
      >
        <DialogTitle>Download Price List Based On Category</DialogTitle>
        <DialogContent style={{ minWidth: "800px" }}>
          <FormDropdownList
            required
            data={categories?.data?.data?.categories || []}
            loading={categoriesLoading}
            label="Categories"
            name="category_id"
            mainClassName="mx-2 width-100"
            dependencyError={["category_id"]}
            placeholder={"Select Categories"}
            hideVariant={true}
            onChange={(e) => setSelectedCategory(e)}
          />
          <div className="button-group">
            <Button
              selected
              name={"Save"}
              onClick={() => handleDownloadPriceList()}
              className="mx-1"
              startIcon={<CheckCircleIcon />}
            />
            <Button
              name={"Cancel"}
              type="button"
              className="mx-1"
              onClick={handleClosePriceDialog}
            />
          </div>
        </DialogContent>
      </Dialog>

      <Paper elevation={24} className="paper-div">
        <TableHeader
          placeholder="Search by name"
          to="/products/new-product"
          filterByManageRulesCategory
          filterByBrands
          checkbox={true}
        />

        <div className="condition-button-container">
          <Button
            selected
            name={"Apply Condition"}
            onClick={() => setOpenDialog(true)}
          ></Button>
          <div className="allbuttons">
            <Button
              name={"Price List "}
              type="button"
              className="mx-1"
              startIcon={<DownloadIcon />}
              onClick={() => setOpenPriceDialog(true)}
              loading={downloadPriceList}
            />
            <Button
              selected
              name={"Price Template"}
              type="button"
              className="mx-1"
              startIcon={<DownloadIcon />}
              onClick={handleDownload}
              loading={downloading}
            />
            <>
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileChange}
                style={{ display: "none" }}
                ref={fileInputRef} // Use fileInputRef here
              />
              <Button
                selected
                name={"Bulk Pricing"}
                type="button"
                className="mx-1"
                startIcon={<UploadFile />}
                loading={uploading}
                onClick={() => fileInputRef.current.click()} // Call click method on fileInputRef
              />
            </>
          </div>
        </div>
        <TableBodyComponent
          header={tableHeaders}
          loading={loading}
          total={data?.total}
        >
          {data?.data
            ?.sort((a, b) => a.id - b.id)
            .map((element) => (
              <CollapsedTableRow
                handleDelete={() => {
                  setProductDetail(element);
                  setOpenDelete(true);
                }}
                handleEye={() =>
                  navigate(`/products/view-details/${element?.id}`)
                }
                handleEdit={() => handleClick(element?.id, false)}
                handlesavedata={() => handlesavedata(element?.id)}
                row={element}
                key={element?.id}
                colSpan={5}
                collapseComponent={
                  <div>
                    <div className="factors-div">
                      {processedData &&
                        Array.isArray(processedData[element.id]) &&
                        processedData[element.id].length > 0 && (
                          <div className="factorsDiv">
                            <div className="mt-1">
                              <h5>{"Condition Factors"}</h5>
                              <EditableRow
                                data={processedData[element.id]?.sort(
                                  (a, b) => a.id - b.id
                                )}
                                create={false}
                                title={"Condition"}
                                onsave={(values) =>
                                  handlenewConditionValues(element.id, values)
                                }
                                editMode={
                                  editModes[element.id]?.Condition || false
                                }
                                setEditMode={() =>
                                  toggleEditMode(element.id, "Condition")
                                }
                                // add onclear
                                onClear={(val) => setconditionfactorsArray(val)}
                                hide={false}
                              />
                              {error && (
                                <p className="error-message">
                                  All fields are required!
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="factors-div">
                      {attributesprocessedData[element.id] && (
                        <div className="factorsDiv">
                          <div className="mt-1">
                            {Object.entries(
                              groupByAttributeId(
                                attributesprocessedData[element.id]
                              )
                            ).map(([attributeId, items]) => (
                              <div key={attributeId}>
                                <h6>{items[0].attributeName}</h6>
                                <EditableRow
                                  data={items?.sort((a, b) => a.id - b.id)}
                                  create={false}
                                  editMode={
                                    editModes[element.id]?.[
                                      items[0].attributeName
                                    ] || false
                                  }
                                  setEditMode={() =>
                                    toggleEditMode(
                                      element.id,
                                      items[0].attributeName
                                    )
                                  }
                                  title={items[0].attributeName}
                                  // add onclear
                                  onClear={(val) =>
                                    setconditionfactorsArray(
                                      attributesprocessedData
                                    )
                                  }
                                  onsave={(values) => (
                                    handleValues(element.id, values),
                                    handlenewConditionValues(
                                      element?.id,
                                      processedData[element.id]
                                    )
                                  )}
                                  hide={false}
                                />
                              </div>
                            ))}
                            {error && (
                              <p className="error-message">
                                All fields are required!
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                }
              >
                <TableCell>{element?.name?.en}</TableCell>
                <TableCell>{element?.base_price}</TableCell>
                <TableCell>{element?.brand?.name?.en}</TableCell>
                <TableCell>{element?.category?.name?.en}</TableCell>
              </CollapsedTableRow>
            ))}
        </TableBodyComponent>
      </Paper>
      <Paginator count={data?.last_page} disabled={loading} />
      {openDelete && (
        <NoticeConfirmation
          data={productDetail}
          handleResponse={handleDeleteResponse}
        />
      )}
    </div>
  );
};
export default withH1(Products, "Products");
