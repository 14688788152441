//UTILITIES
import React, { useContext, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { AlertContext } from "../../context/AlertContext";
//COMPONENT
import { TableCell } from "@mui/material";
import Paginator from "../../components/paginator/Paginator";
import TableHeader from "../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
//CSS
import "./CollectionPoints.scss";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { useGet } from "../../hooks/useFetch";
import { deleteCollectionPoint } from "../../core/apis/collectionPoint";
import withH1 from "../../assests/HOC/withH1";

function CollectionPoints(props) {
  //VARIABLES
  const { setAlert } = useContext(AlertContext);
  let navigate = useNavigate();
  const tableHeaders = [
    { title: "Collection Point", sorted: false },
    { title: "Country", sorted: false },
    { title: "Number of users", sorted: false },
  ];

  const [searchParams] = useSearchParams(); //pagination and filter
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
    country_ids: searchParams.getAll("country_ids"),
  };

  const {
    data: cps,
    loading,
    refetch,
  } = useGet({
    url: "admin/get-all-collection-point",
    payload: payload,
  });

  let data = cps?.data.data;
  const [openDelete, setOpenDelete] = useState(false); //bool delete dialog
  const [selectedData, setSelectedData] = useState(null); //selected data when opening delete dialog

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteCollectionPoint(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setSelectedData(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  return (
    <div className="collection-points">
      <TableHeader
        placeholder="Search by collection point name"
        to="/collection-points/new-collection-point"
        filterByCountry
        checkbox
      />
      <TableBodyComponent
        header={tableHeaders}
        loading={loading}
        total={data?.total}
        // handleExport={handleExport}
      >
        {data?.data.map((element) => (
          <TableRowComponent
            row={element}
            handleDelete={() => {
              setSelectedData(element);
              setOpenDelete(true);
            }}
            handleEdit={() =>
              navigate(
                `/collection-points/edit-collection-point/${element?.id}`
              )
            }
          >
            <TableCell>{element.name?.en}</TableCell>
            <TableCell>{element.address?.[0]?.country?.name}</TableCell>
            <TableCell>{element.user_count}</TableCell>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
      <Paginator count={data?.last_page} disabled={loading} />

      {openDelete && (
        <NoticeConfirmation
          data={selectedData}
          handleResponse={handleDeleteResponse}
        />
      )}
    </div>
  );
}

export default withH1(CollectionPoints, "Collection Points");
