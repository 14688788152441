import { api } from "./main";

export const getAllReports = (payload) => {
  return api
    .get("admin/report/get-all-reports", { params: payload })
    .then((res) => res.data.data)
    .catch((err) => console.error(err));
};

export const createReport = async (data) => {
  return await api
    .post("admin/report/export-organizations", data)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deleteReport = async (payload) => {
  return await api
    .delete(`admin/report/delete-report-history/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

/////////////////////////////////////////

export const branchesExport = async (payload) => {
  return await api
    .post("admin/report/export-branches", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const organizationsExport = async (payload) => {
  return await api
    .post("admin/report/export-organizations", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const transactionsExport = async (payload) => {
  return await api
    .post("admin/report/export-transactions", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const usersExport = async (payload) => {
  return await api
    .post("admin/report/export-users", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const itemsExport = async (payload) => {
  return await api
    .post("admin/report/export-items", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
