//UTILITIES
import React from "react";
import PropTypes from "prop-types";

//COMPONENT
import palette from "../../../theme/color.scss";
import Skeleton from "../../MUI-skeleton/Skeleton";
import { Table, TableBody, TableContainer, Paper } from "@mui/material";
import TableCustomHeader from "./TableCustomHeader";
import DataNotFound from "../../../components/notFound/DataNotFound";

//CSS
import "./TableBody.scss";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
});

const TableBodyComponent = (props) => {
  const {
    header,
    children,
    handleExport,
    loading,
    internal,
    total,
    TableStyle,
    isArabic
  } = props;
  const { actions, prefixActions, handleAdd, selectAllValue, handleSelectAll } =
    props;

  return (
    <TableContainer
      className="table-component-container "
      {...TableStyle}
      sx={{
        "&::-webkit-scrollbar":
          total !== 0
            ? {
                width: 4,
                height: 5,
              }
            : { display: "none" },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: palette.primaryColorOpacity,
          borderRadius: 25,
        },
      }}
    >
      {!loading ? (
        <Table
          className={`table-component-table  ${!internal && "external-table"}`}
        >
          {header.length > 0 && (
            <TableCustomHeader
              handleSelectAll={handleSelectAll}
              selectAllValue={selectAllValue}
              actions={actions}
              handleAdd={handleAdd}
              header={header}
              prefixActions={prefixActions}
              handleExport={handleExport}
              internal={internal}
              isArabic={isArabic ? true : false}
            />
          )}
          <TableBody>{total !== 0 ? children : <DataNotFound />}</TableBody>
        </Table>
      ) : (
        <Skeleton count={10} />
      )}
    </TableContainer>
  );
};

TableBodyComponent.defaultProps = {
  actions: true,
  prefixActions: false,
  internal: false,
  header: [],
};
TableBodyComponent.propTypes = {
  actions: PropTypes.bool,
  prefixActions: PropTypes.bool,
  requestSort: PropTypes.func,
  headers: PropTypes.any,
  handleExport: PropTypes.func,
  loading: PropTypes.bool,
  internal: PropTypes.bool,
  total: PropTypes.number,
};

export default TableBodyComponent;
