//UTILITIES
import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router";
import Paginator from "../../components/paginator/Paginator";
import { useSearchParams } from "react-router-dom";
import TableHeader from "../../components/table/tableHeader/TableHeader";
import { useGet } from "../../hooks/useFetch";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import {
  TableCell,
  Paper,
  Button,
  Chip,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import CountUp from "react-countup";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { AlertContext } from "../../context/AlertContext";
import { deleteTransaction } from "../../core/apis/transactions";
import BrowseGalleryIcon from "@mui/icons-material/BrowseGallery";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import ListAltIcon from "@mui/icons-material/ListAlt";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ErrorIcon from "@mui/icons-material/Error";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { changeItemAction, BulkCollect } from "../../core/apis/item";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// FORM
import { FormInput } from "../../components/formComponents/FormComponents";

//css
import "./Transactions.scss";
import withH1 from "../../assests/HOC/withH1";

const OpenTransaction = () => {
  const tableHeaders = [
    { title: "Barcode" },
    { title: "User" },
    { title: "Price" },
    { title: "Date" },
    { title: "Status" },
    { title: "Type" },
  ];

  const PendingtableHeaders = [
    { title: "" },
    { title: "Barcode" },
    { title: "User" },
    { title: "Price" },
    { title: "Date" },
    { title: "Status" },
    { title: "Type" },
  ];

  //VARIABLES
  const [searchParams] = useSearchParams();
  const [TransactionDetails, setTransactionDetails] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [openCollect, setopenCollect] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const [value, setTabValue] = useState("0");
  const [collectLoading, setCollectLoading] = useState(false);
  const [collectAll, setcollectAll] = useState(false);

  const HandleSchema = yup.object({
    collected_by: yup.string("Field is invalid").required("Field is required"),
    select_all_items: yup.boolean(),
  });

  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem.id !== item.id)
      );
    }
  };

  console.log("selectedItems", selectedItems);

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  let payload = {
    per_page: 10,
    barcode: searchParams.get("search"),
    is_buy: searchParams.get("is_buy")
      ? searchParams.get("is_buy") == "true"
        ? true
        : false
      : null,
    action_ids: searchParams.getAll("action_ids"),
    transaction_status_ids: value > 0 ? [value] : [],
  };

  let navigate = useNavigate();
  let page = searchParams.get("page") || 1;
  let url = `admin/get-all-open-transactions?page=${page}`;

  //API CALL
  const {
    data: transaction,
    loading,
    refetch,
  } = useGet({ url: url, payload: payload });

  let data = transaction?.data.data;

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteTransaction(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setTransactionDetails(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  const handleChange = (event, newValue) => {
    setValue("select_all_items", false);
    setTabValue(newValue);
  };

  const handleCollectDevice = (element) => {
    setopenCollect(true);
    setTransactionDetails(element);
  };

  const handleCollectAlll = (element) => {
    setopenCollect(true);
    setcollectAll(true);
  };

  const handleSubmitDecisionForm = (value) => {
    setCollectLoading(true);
    changeItemAction({
      id: TransactionDetails?.inspection[0]?.item_id,
      action_taken_by: value.collected_by,
      next_action_id: 2,
    }).then((response) => {
      if (response?.data.success) {
        refetch();
        setCollectLoading(false);
        setopenCollect(false);
      } else {
        setCollectLoading(false);
        setopenCollect(false);
      }
      setAlert({
        visible: true,
        text: response.data.message,
        type: response.data.success ? "success" : "error",
      });
    });
  };

  const handleBulkCollect = (value) => {
    let ids = selectedItems.map((item) => item?.inspection[0]?.item_id);
    setCollectLoading(true);
    BulkCollect({
      item_ids: ids,
      action_taken_by: value.collected_by,
    }).then((response) => {
      if (response?.data.success) {
        refetch();
        setCollectLoading(false);
        setopenCollect(false);
        setSelectedItems([]);
        reset();
      } else {
        setCollectLoading(false);
        setopenCollect(false);
      }
      setAlert({
        visible: true,
        text: response.data.message,
        type: response.data.success ? "success" : "error",
      });
    });
  };

  const handleSelectAll = (newValue) => {
    if (newValue === false) {
      setSelectedItems([]);
    } else if (newValue === true) {
      setSelectedItems(data?.data);
    }
    setValue("select_all_items", newValue);
  };

  useEffect(() => {
    setValue("select_all_items", false);
    setSelectedItems([]);
    console.log(page)
  }, [page]);

  return (
    <div className="open-transaction-component">
      <Paper elevation={24} className="paper-div">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              <Tab
                label="All"
                value="0"
                icon={<ListAltIcon />}
                iconPosition="start"
              />
              <Tab
                label="Pending"
                value="1"
                icon={<BrowseGalleryIcon />}
                iconPosition="start"
              />
              <Tab
                label="Under Going"
                value="2"
                icon={<LibraryAddCheckIcon />}
                iconPosition="start"
              />
            </TabList>
          </Box>
          <TableHeader
            placeholder="Search by barcode"
            filterByTransactionType
          />
          {value == 1 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                marginTop: "10px",
              }}
            >
              <Button
                variant="outlined"
                color="warning"
                onClick={() => handleCollectAlll()}
                disabled={selectedItems.length > 0 ? false : true}
                endIcon={<WidgetsIcon />}
              >
                Collect All
              </Button>
            </div>
          )}

          <TableBodyComponent
            header={tableHeaders}
            loading={loading}
            total={data?.total}
            actions={false}
            selectAllValue={getValues("select_all_items") ? true : false}
            prefixActions={value != 1 ? false : true}
            handleSelectAll={handleSelectAll}
          >
            {data?.data?.map((element) => (
              <TableRowComponent
                internal
                row={element}
                actions={true}
                handleEye={() =>
                  navigate(
                    `/open-${
                      element?.is_buy ? "buying" : "selling"
                    }-transaction/${element?.id}`
                  )
                }
                handleDelete={() => {
                  setTransactionDetails(element);
                  setOpenDelete(true);
                }}
              >
                {value == 1 && (
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedItems.some(
                            (selectedItem) => selectedItem?.id === element?.id
                          )}
                          onChange={(event) =>
                            handleCheckboxChange(event, element)
                          }
                        />
                      }
                    />
                  </TableCell>
                )}
                <TableCell>{element?.barcode}</TableCell>
                <TableCell>{element?.user?.full_name}</TableCell>
                <TableCell>
                  <CountUp
                    end={element?.transaction_amount}
                    duration={0.9}
                    decimals={2}
                  />
                  {element?.currency?.code}
                </TableCell>
                <TableCell>
                  {element?.created_at
                    ? moment(element?.created_at).calendar()
                    : "---"}
                </TableCell>
                <TableCell>
                  <Chip
                    label={element?.transaction_status?.name?.en}
                    className={
                      element?.transaction_status_id != 1
                        ? "chipGreen"
                        : "chipRed"
                    }
                  />
                </TableCell>

                <TableCell>{element?.is_buy ? "Buying" : "Selling"}</TableCell>
                {!element?.is_buy && element?.transaction_status_id == 1 ? (
                  <TableCell
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleCollectDevice(element)}
                      endIcon={<WidgetsIcon />}
                    >
                      Collect
                    </Button>
                  </TableCell>
                ) : (
                  <TableCell
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button color="success" endIcon={<WidgetsIcon />}>
                      Collected
                    </Button>
                  </TableCell>
                )}
              </TableRowComponent>
            ))}
          </TableBodyComponent>
        </TabContext>
      </Paper>
      <Paginator count={data?.last_page} disabled={loading} />
      {openDelete && TransactionDetails?.transaction_status?.id === 1 ? (
        <NoticeConfirmation
          data={TransactionDetails}
          handleResponse={handleDeleteResponse}
          cannotBeDeleted={false}
          message={
            `Are you sure you want to delete This Transaction` +
            " " +
            TransactionDetails.barcode
          }
        />
      ) : (
        openDelete && (
          <NoticeConfirmation
            data={TransactionDetails}
            handleResponse={handleDeleteResponse}
            msg={TransactionDetails?.transaction_status.name.en}
            cannotBeDeleted={true}
          />
        )
      )}

      <Dialog
        open={openCollect}
        onClose={() => setopenCollect(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "#ff0000",
            justifyContent: "center",
            textAlign: "center",
            boxShadow: "0px 0px 4px rgb(222 228 237 / 80%) !important",
          }}
        >
          <ErrorIcon style={{ fontSize: "50px" }} />
        </DialogTitle>
        <DialogContent style={{ minWidth: "500px" }}>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center" }}
          >
            {`Are you sure you want to Collect This Device`}
            <p style={{ fontWeight: "500", color: "#000", margin: "5px 0px" }}>
              {TransactionDetails?.barcode}
            </p>
          </DialogContentText>
          <div>
            <form>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormInput
                    required
                    type="text"
                    placeholder="Please Enter your Name"
                    name="collected_by"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    helperText={error?.message}
                    hideVariant={true}
                  />
                )}
                name={`collected_by`}
                control={control}
              />
            </form>
          </div>
        </DialogContent>
        <Divider style={{ color: "#4ba4f0" }} />
        <DialogActions style={{ justifyContent: "center", display: "flex" }}>
          <Button
            type="submit"
            color="primary"
            onClick={
              collectAll
                ? handleSubmit(handleBulkCollect)
                : handleSubmit(handleSubmitDecisionForm)
            }
            style={{ color: "#fff" }}
            variant="contained"
            disabled={collectLoading ? true : false}
          >
            Collect
          </Button>
          <Button
            onClick={() => setopenCollect(false)}
            color="error"
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withH1(OpenTransaction, "Open Transactions");
