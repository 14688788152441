// EXTERNAL LIBRARIES
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card } from "@mui/material";

import { useForm } from "react-hook-form";
// STYLING
import "./ProductDetails.scss";

// CUSTOM HOOKS
import { useGet } from "../../../../hooks/useFetch";

import { getProductByID } from "../../../../core/apis/product";
import EditableRow from "../../../../components/editableRow/editableRow";

import { useGlobalValues } from "../../../../context/GlobalContext";
import Paper from "@mui/material/Paper";
import TitleComponent from "../../../../components/titleComponent/TitleComponent";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import WorkIcon from "@mui/icons-material/Work";
import DescriptionIcon from "@mui/icons-material/Description";
import CategoryIcon from "@mui/icons-material/Category";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Grid from "@mui/material/Grid";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { separateValuesByAttributeId } from "../../../../core/functions/Functions";

// Formik schema

const ViewProductDetails = (props) => {
  const params = useParams();
  const [loadingForm, setLoadingForm] = useState(false);
  const [productName, setProductName] = useState("");
  const [existingImages, setExistingImages] = useState([]);
  const [tabvalue, setTabValue] = useState("en");
  const [attributeList, setAttributeList] = useState([]);

  const [receivedConditionValues, setreceivedConditionValues] = useState([]);

  const [error, setError] = useState(false);

  const { enabledLanguages } = useGlobalValues();

  const { data: conditions, conditionsLoading } = useGet({
    url: "/user/get-all-conditions",
  });

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange" | "onBlur",
  });

  const handleapiConditionsValue = async (data, attribute) => {
    const values = separateValuesByAttributeId(data).filter(
      (item) => item?.attribute_id == null
    );

    // Iterate over the 'values' array
    await values.forEach((item) => {
      // Extract items and map them to include 'product_factorable' contents
      const conditionarray = item.items.map((innerItem) => ({
        outterid: innerItem.id,
        id: innerItem.product_factorable.id,
        factor: innerItem.factor,
        name: innerItem.product_factorable.name,
      }));
      setreceivedConditionValues(conditionarray);
    });
  };

  // HANDLE ATTRIBUTES
  const handleRecievedAttValues = async (data, attribute) => {
    const values = separateValuesByAttributeId(data).filter(
      (item) => item.attribute_id != null
    );

    // Map attributes to set 'attributeName' for matching values
    attribute.forEach((item) => {
      const matchedValue = values.find(
        (KeyValue) => KeyValue.attribute_id === item.id
      );
      if (matchedValue) {
        // Set 'attributeName' if a match is found
        matchedValue.attributeName = item.name.en;
      }
    });
    // Initialize an empty array to hold the extracted items
    let storageArray = [];

    // Iterate over the 'values' array
    await values.forEach((item) => {
      // Extract items and map them to include 'product_factorable' contents
      const storageItems = item.items.map((innerItem) => ({
        outterid: innerItem.id,
        id: innerItem.product_factorable.id,
        attribute_id: item.attribute_id,
        factor: innerItem.factor,
        name: innerItem.product_factorable.name,
        abbreviation: innerItem.product_factorable.abbreviation,
        // Add more properties from 'product_factorable' as needed
      }));

      const itemObject = {
        [item.attributeName]: storageItems,
      };

      // Push the filtered object into the 'storageArray'
      storageArray.push(itemObject);
    });

    return storageArray;
  };

  // Calling fetch data api on edit
  useEffect(() => {
    getProductByID(params?.id).then(async (res) => {
      if (res?.data?.success) {
        let responseData = res?.data?.data;

        let attValues = await handleRecievedAttValues(
          responseData?.product_factor,
          responseData?.attribute
        );

        handleapiConditionsValue(
          responseData?.product_factor,
          responseData?.attribute
        );
        reset({
          description: responseData?.description?.[tabvalue]
            ? responseData?.description?.[tabvalue]
            : responseData?.description?.en,
          name: responseData?.name?.[tabvalue]
            ? responseData?.name?.[tabvalue]
            : responseData?.name?.en,
          base_price: responseData?.base_price,
          brand_id: responseData?.brand,
          category_id: responseData?.category?.name?.[tabvalue]
            ? responseData?.category?.name?.[tabvalue]
            : responseData?.category?.name?.en,
          attribute_id: responseData?.attribute || [],
          attribute_values_id: attValues || [],
          category_ids: responseData?.category?.id,
          enablefactors:responseData.is_buy_sell,
          abbreviation: responseData?.abbreviation,
        });
        setAttributeList(responseData?.attribute);
        setProductName(
          responseData?.name?.[tabvalue]
            ? responseData?.name?.[tabvalue]
            : responseData?.name?.en
        );
        setExistingImages(responseData?.product_image);
      }
      setLoadingForm(false);
    });
  }, [tabvalue]);

  const handleChange = (event) => {
    setTabValue(event);
  };

  const enableBlock = watch("enablefactors", true);


  return (
    <div className="products-details">
      {!loadingForm ? (
        <Paper elevation={24} className="paper-div">
          <div className="header">
            <TitleComponent className="title-details" title={productName} />
            <div className="chip-wrapper">
              {enabledLanguages.map((item, index) => (
                <Chip
                  key={index}
                  label={item.name}
                  value={item.code}
                  className={
                    tabvalue != item.code ? "en-chip" : "en-chip color-white"
                  }
                  color="primary"
                  variant={tabvalue != item.code ? "outlined" : ""}
                  onClick={() => handleChange(item.code)}
                />
              ))}
            </div>
          </div>

          <Card variant="elevation">
            <Box sx={{ p: 2 }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} md={4}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className="avatarBg">
                        <TurnedInIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Product Name"
                      secondary={getValues("name")}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className="avatarBg">
                        <DescriptionIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Description"
                      secondary={getValues("description")}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className="avatarBg">
                        <RequestQuoteIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Base Price"
                      secondary={getValues("base_price")}
                    />
                  </ListItem>
                </Grid>
                <Grid item xs={3}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className="avatarBg">
                        <WorkIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Product Abbreviation"
                      secondary={getValues("abbreviation")}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className="avatarBg">
                        <AttachFileIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Brand"
                      secondary={getValues("brand_id.name.en")}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className="avatarBg">
                        <CategoryIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Category"
                      secondary={getValues("category_id")}
                    />
                  </ListItem>
                </Grid>
                <Grid item xs={12} md={5}>
                  <div className="viewModeImages">
                    {existingImages &&
                      existingImages.map((image) => (
                        <div className="image " key={image.id}>
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_URL + image.image_path
                            }
                            style={{ height: "150px", width: "150px" }}
                          />
                        </div>
                      ))}
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography gutterBottom variant="h6">
                Attributes
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  py: 2,
                  gap: 1,
                }}
              >
                {attributeList &&
                  attributeList.map((item) => (
                    <Chip
                      label={
                        item?.name?.[tabvalue]
                          ? item?.name?.[tabvalue]
                          : item?.name?.en
                      }
                      color="primary"
                      variant={"outlined"}
                    />
                  ))}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  py: 2,
                  gap: 1,
                }}
              >
                {attributeList &&
                  attributeList.map((item) =>
                    item.values.map((itemKey) => (
                      <Chip
                        label={
                          itemKey?.name?.[tabvalue]
                            ? itemKey?.name?.[tabvalue]
                            : itemKey?.name?.en
                        }
                      />
                    ))
                  )}
              </Box>
            </Box>
          </Card>

          {tabvalue != "ar" && (
            <div className="factors-div">
              {conditions && enableBlock && (
                <div className="factorsDiv">
                  <div className="mt-1">
                    <h5>{"Condition Factors"}</h5>
                    <EditableRow
                      data={receivedConditionValues?.sort(
                        (a, b) => a.id - b.id
                      )}
                      create={false}
                      title={"Condition"}
                      hide={true}
                    />
                    {error && (
                      <p className="error-message">All fields are required!</p>
                    )}
                  </div>

                  {watch("attribute_values_id")?.map((selectedValue, index) => {
                    return (
                      <div key={index}>
                        {Object.keys(selectedValue)?.map((key) => (
                          <div
                            key={key}
                            className={
                              selectedValue[key]?.length > 0 &&
                              selectedValue[key]?.length < 3
                                ? "width-25 mt-1"
                                : selectedValue[key]?.length > 2 &&
                                  selectedValue[key]?.length < 4
                                ? "width-50 mt-1"
                                : "width-100 mt-1"
                            }
                          >
                            <h5>{key + " " + "Factors"}</h5>
                            <EditableRow
                              data={selectedValue[key]}
                              create={false}
                              title={key}
                              hide={true}
                            />
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </Paper>
      ) : (
        <Paper elevation={24} className="paper-div"></Paper>
      )}
    </div>
  );
};

export default ViewProductDetails;
