//UTILITIES
import React, { useState } from "react";
import PropTypes from "prop-types";
//COMPONENT
import {
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";

import { DeleteSVG, EditSVG, EyeSVG, MoveSVG } from "../../assests/icons/SVG";
import { FormSwitch } from "../formComponents/FormComponents";

import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";

//CSS
import "./CollapsedTable.scss";
import { DownSVG, UpSVG } from "../../assests/icons/SVG";

export function CollapsedTableRow(props) {
  const { children, row, collapseComponent, colSpan } = props;
  const { handleDelete, handleEdit, actions } = props;
  const {
    handleMove,
    handleEye,
    handlesavedata,
    handleSwitch,
    isSwitchOn,
    isEnglish,
  } = props;

  //VARIABLES
  const [open, setOpen] = useState(false);

  //FUNCTIONS
  const handleGetCollapseData = () => {};

  return (
    <>
      <TableRow className="collpase-table-component-row">
        {children}
        {actions && (
          <TableCell align="right" className="body-action-cell">
            <IconButton
              size="small"
              aria-label="expand row"
              onClick={() => {
                if (!open) {
                  handleGetCollapseData(row);
                }
                setOpen(!open);
              }}
            >
              {open ? <UpSVG /> : <DownSVG />}
            </IconButton>

            {handleEye && (
              <IconButton color="primary" component="span" onClick={handleEye}>
                <EyeSVG />
              </IconButton>
            )}
            {handlesavedata && (
              <IconButton
                color="primary"
                component="span"
                onClick={handlesavedata}
              >
                <CollectionsBookmarkIcon />
              </IconButton>
            )}
            {handleEdit && (
              <IconButton color="primary" component="span" onClick={handleEdit}>
                <EditSVG />
              </IconButton>
            )}

            {handleDelete && (
              <IconButton
                color="primary"
                component="span"
                onClick={() => handleDelete(row)}
              >
                <DeleteSVG fill="#EE1818" />
              </IconButton>
            )}

            {handleMove && (
              <IconButton color="primary" component="span" onClick={handleMove}>
                <MoveSVG />
              </IconButton>
            )}
            {handleSwitch && (
              <FormSwitch
                label="Enable"
                value={isSwitchOn}
                disabled={isEnglish}
                onChange={(e) => {
                  handleSwitch(e);
                }}
              />
            )}
          </TableCell>
        )}
      </TableRow>
      {open && (
        <TableRow className="collapse-row">
          <TableCell colSpan={colSpan}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Card className="collapse-detail">
                <CardContent>{collapseComponent}</CardContent>
              </Card>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

CollapsedTableRow.defaultProps = {
  internal: false,
  actions: true,
};

CollapsedTableRow.propTypes = {
  internal: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  row: PropTypes.any,
  internal: PropTypes.bool,
};

export default CollapsedTableRow;
