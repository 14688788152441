import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { USAFlagIcon } from "../../assests/icons/SVG";
import KSAFlag from "../../assests/Images/united-ara.png";

export default function BasicTabs(props) {
  const {
    labelone,
    labeltwo,
    componentone,
    componentarabic,
    handleChange,
    value,
    tabsList,
    children,
  } = props;

  const CustomTab = ({ label, icon, ...props }) => {
    return (
      <Tab
        {...props}
        label={
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <span style={{ marginleft: "5px", marginRight: "5px" }}>
              {label}
            </span>
            {icon}
          </Box>
        }
      />
    );
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value ? value : "ar"}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabsList &&
            tabsList.map((item, index) => (
              <CustomTab
                index={item.code}
                label={item.name}
                icon={
                  item.code == "en" ? (
                    <USAFlagIcon />
                  ) : item.code == "ar" ? (
                    <img src={KSAFlag} width="30" />
                  ) : (
                    ""
                  )
                }
                value={item.code}
              />
            ))}
          {!tabsList ? (
            <CustomTab label={labelone} icon={<USAFlagIcon />} />
          ) : (
            ""
          )}
          {!tabsList ? (
            <CustomTab
              label={labeltwo}
              icon={<img src={KSAFlag} width="30" />}
            />
          ) : (
            ""
          )}
        </Tabs>
      </Box>

      {children}

      {/* //STATIC */}
      <CustomTabPanel value={value} index={0}>
        {componentone}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {componentarabic}
      </CustomTabPanel>
    </Box>
  );
}
