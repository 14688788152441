import React, { useEffect } from "react";
import { FormInput } from "../../components/formComponents/FormComponents";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IconButton, Divider } from "@mui/material";
import { DeleteSVG, PlusCircleSVG } from "../../assests/icons/SVG";
import Button from "../../components/button/Button";
import { useGet } from "../../hooks/useFetch";
import { FormDropdownList } from "../../components/formComponents/FormComponents";

import "./Transfer.scss";

const HandleAddInputs = ({ onSuccess, onCancel }) => {
  const HandleSchema = yup.object({
    country_id_to: yup.object().nullable().required("Field is required"),
    transfer_cost: yup.array().of(
      yup.object().shape({
        description: yup
          .string("Field is invalid")
          .required("Field is required"),
        cost: yup.string("Field is invalid").required("Field is required"),
      })
    ),
  });

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(HandleSchema),
    reValidateMode: "onChange" | "onBlur",
  });

  const { data: countries, loading: loadingCountries } = useGet({
    url: `get-all-countries`,
  });

  let countriesData = countries?.data?.data;

  const {
    fields: feesFields,
    remove: feesRemove,
    append: feesAppend,
  } = useFieldArray({ control, name: "transfer_cost" });

  const handleSubmitForm = (formData) => {
    onSuccess(formData);
  };

  const handleCancel = (formData) => {
    reset();
    onCancel("Canceled");
  };

  const handleAddValue = () => {
    feesAppend({
      description: "",
      cost: "",
    });
  };

  useEffect(() => {
    reset({
      country_id_to: null,
      transfer_cost: [
        {
          description: "",
          cost: "",
        },
      ],
    });
  }, []);

  return (
    <div className="feesPOPUP">
      <form onSubmit={handleSubmit(handleSubmitForm)} className="formfees">
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormDropdownList
              required
              mainClassName="width25"
              hideVariant={true}
              clearErrors={clearErrors}
              name="country_id_to"
              data={
                getValues("country_id_from")
                  ? countriesData.filter(
                      (el) => el?.id !== getValues("country_id_from")?.id
                    )
                  : countriesData || []
              }
              loading={loadingCountries}
              dependencyError={["country_id_from", "country_id_to"]}
              label="To Country"
              placeholder={"Select Location"}
              value={value}
              onChange={(e) => onChange(e)}
              helperText={error?.message}
            />
          )}
          name="country_id_to"
          control={control}
        />
        <div className="feesRow">
          {feesFields?.length !== 0 &&
            feesFields?.map((el, attributeIndex) => {
              const isLastIndex = attributeIndex === feesFields.length - 1;
              return (
                <div
                  key={`${attributeIndex}_${feesFields.length}`}
                  className="dynamic-fees-add"
                >
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        label={`Description ${attributeIndex + 1}`}
                        placeholder="Enter Description"
                        name={`transfer_cost.${attributeIndex}.description`}
                        value={value}
                        onChange={(e) => onChange(e?.target.value)}
                        helperText={error?.message}
                        hideVariant={true}
                      />
                    )}
                    name={`transfer_cost.${attributeIndex}.description`}
                    control={control}
                  />
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        label={`Cost ${attributeIndex + 1}`}
                        placeholder="Enter Cost"
                        name={`transfer_cost.${attributeIndex}.cost`}
                        value={value}
                        onChange={(e) => onChange(e?.target.value)}
                        helperText={error?.message}
                        hideVariant={true}
                      />
                    )}
                    name={`transfer_cost.${attributeIndex}.cost`}
                    control={control}
                  />
                  {feesFields.length > 1 && (
                    <IconButton
                      onClick={() => feesRemove(attributeIndex)}
                      className="feesIconButton"
                    >
                      <DeleteSVG />
                    </IconButton>
                  )}
                  {isLastIndex && (
                    <IconButton
                      onClick={() => handleAddValue()}
                      className="feesIconButton"
                    >
                      <PlusCircleSVG />
                    </IconButton>
                  )}
                </div>
              );
            })}
        </div>
        <div className="buttons-row">
          <Button name={"Create"} selected type="submit" />
          <Button name={"Cancel"} onClick={() => handleCancel()} />
        </div>
      </form>
    </div>
  );
};

export default HandleAddInputs;
