import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "@mui/lab/TabPanel";
import CircularProgress from "@mui/material/CircularProgress";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import { Button } from "@mui/material";
import * as XLSX from "xlsx";

import "./pricesTable.scss";

const ProductTable = ({ productData }) => {
  const [progress, setProgress] = useState(0);
  const [loading, setloading] = useState(true);

  const [value, setValue] = useState(0);
  const [filteredData, setfilteredData] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
      setloading(false);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const groupedData = productData?.reduce((acc, obj) => {
      const { condition } = obj;
      if (!acc[condition]) {
        acc[condition] = [];
      }
      acc[condition].push(obj);
      return acc;
    }, {});

    setfilteredData(groupedData);
  }, [productData]);

  // Extract the keys from the first product object
  let keys = [];
  if (productData && productData.length > 0) {
    keys = Object.keys(productData[0]);
  }
  const sortedKeys = keys.filter(
    (key) => key !== "Price" && key !== "condition"
  );
  sortedKeys.push("Price"); // Append "Price" at the end

  const StyledTableCell = styled(TableCell)(({ lastColumn }) => ({
    [`&.${tableCellClasses.head}`]: {
      borderRadius: "0px",
      color: "#000000DE",
      backgroundColor: "#4ba4f066",
      height: "50px",
      border: "1px solid #eee",
      textTransform: "capitalize",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: lastColumn ? "#ff0000" : "inherit",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#47aaff24",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const ExportToExcel = ({ data, sortedKeys }) => {
    const handleExport = () => {
      const workbook = XLSX.utils.book_new();

      const headerStyle = {
        font: { bold: true },
        backgroundColor: "#ff0000",
      };

      Object.keys(data).forEach((key) => {
        const sheetData = [
          [...sortedKeys], // Add headers
          ...data[key].map((item) => [...sortedKeys.map((k) => item[k])]), // Add rows
        ];

        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

        // Set header style
        const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
        for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({ c: C, r: 0 });
          if (!worksheet[cellAddress].s) worksheet[cellAddress].s = {};
          worksheet[cellAddress].s = headerStyle;
        }

        // Set column widths
        const wscols = new Array(sheetData[0].length).fill({ wch: 15 });
        worksheet["!cols"] = wscols;

        XLSX.utils.book_append_sheet(workbook, worksheet, key);
      });

      XLSX.writeFile(workbook, "exported_data.xlsx");
    };

    return (
      <Button variant="outlined" color="primary" onClick={handleExport}>
        Export to Excel
      </Button>
    );
  };

  return (
    <div className="product-table-container" style={{ minWidth: "800px" }}>
      {!loading && productData && (
        <ExportToExcel data={filteredData} sortedKeys={sortedKeys} />
      )}

      {!productData || productData.length === 0 ? (
        <p>No product data available</p>
      ) : loading ? (
        <div className="spinnerSection">
          <CircularProgress variant="determinate" />
        </div>
      ) : (
        filteredData && (
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {Object.keys(filteredData)?.map((key, originalindex) => (
                  <Tab label={[key]} value={originalindex} />
                ))}
              </TabList>
            </Box>
            {Object.keys(filteredData)?.map((key, originalindex) => (
              <TabPanel value={originalindex}>
                <TableContainer
                  sx={{ minWidth: 800, maxWidth: 2000, overflow: "auto" }}
                >
                  <Table aria-label="simple table" stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        {sortedKeys.map((header, index) => (
                          <StyledTableCell key={index}>
                            {header}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData[key].map((item, itemIndex) => (
                        <StyledTableRow key={itemIndex}>
                          {sortedKeys.map((sortedKey) => (
                            <StyledTableCell
                              key={sortedKey}
                              className={sortedKey == "Price" ? "redColor" : ""}
                            >
                              {item[sortedKey]}
                            </StyledTableCell>
                          ))}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            ))}
          </TabContext>
        )
      )}
    </div>
  );
};

export default ProductTable;
