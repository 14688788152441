// EXTERNAL LIBRARIES
import React, { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TableCell } from "@mui/material";
import { AlertContext } from "../../context/AlertContext";
// TABLE
import TableHeader from "../../components/table/tableHeader/TableHeader";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import Paginator from "../../components/paginator/Paginator";

// CUSTOM HOOKS
import { useGet } from "../../hooks/useFetch";

// DATA NOT FOUND
import DataNotFound from "../../components/notFound/DataNotFound";

// STYLING
import "./CurrencySetting.scss";

// MODAL COMPONENT

import { FormDropdownList } from "../../components/formComponents/FormComponents";
import { changeCurrencyStatus } from "../../core/apis/Currency";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import CurrencySettingHandle from "./CurrencyDetails/CurrencySettingHandle";
import withH1 from "../../assests/HOC/withH1";
const tableHeaders = [
  { title: "Currency Name" },
  { title: "Currency Code" },
  { title: "Currency Symbol" },
  { title: "Currency Status" },
];

const CurrencySetting = () => {
  // DATA AND HOOKS
  const [searchParams] = useSearchParams();
  const { setAlert } = useContext(AlertContext);

  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
    is_hidden: searchParams.get("is_hidden")
      ? searchParams.get("is_hidden") == "true"
        ? true
        : false
      : false,
  };

  const hiddenStatusOption = [
    { name: "Hidden", id: 1 },
    { name: "Not Hidden", id: 2 },
  ];
  const [currencyDetail, setCurrencyDetail] = useState(null);
  const [openStatusToggle, setOpenStatusToggle] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  let url = `admin/get-all-currencies`;

  // API CALL (fetching data to be displayed)
  const {
    data: currencies,
    loading,
    refetch,
  } = useGet({ url: url, payload: payload });
  let data = currencies?.data.data;

  const handleChangeStatus = (e, element) => {
    setCurrencyDetail(element);
    setOpenStatusToggle(true);
  };

  const handleResponse = (type, data) => {
    if (type === "yes") {
      changeCurrencyStatus({ id: data?.id }).then((res) => {
        if (res?.data?.success) {
          refetch();
          setAlert({
            text: res.data.message,
            type: res.data.success ? "success" : "error",
          });
        }
      });
    }
    setOpenStatusToggle(false);
  };

  return (
    <div className="currencyComponent">
      <TableHeader
        placeholder="Search by currency name"
        filterByHideStatus
        withAll={false}
      />
      <TableBodyComponent
        header={tableHeaders}
        loading={loading}
        total={data?.total}
      >
        {data?.data?.length ? (
          data?.data?.map((element) => (
            <TableRowComponent
              handleEdit={() => {
                setCurrencyDetail(element);
                setOpenUpdate(true);
              }}
              row={element}
              key={element?.id}
            >
              <TableCell>{element?.name}</TableCell>
              <TableCell>{element?.code}</TableCell>
              <TableCell>{element?.symbol}</TableCell>
              <TableCell>
                <FormDropdownList
                  required
                  formComponent={false}
                  data={hiddenStatusOption}
                  value={
                    element?.is_hidden
                      ? { name: "Hidden", id: 1 }
                      : { name: "Not Hidden", id: 2 }
                  }
                  onChange={(e) => handleChangeStatus(e, element)}
                />
              </TableCell>
            </TableRowComponent>
          ))
        ) : (
          <DataNotFound />
        )}
      </TableBodyComponent>
      {data?.data && <Paginator count={data?.last_page} disabled={loading} />}

      {openStatusToggle && (
        <NoticeConfirmation
          message={`Are you sure you want to ${
            currencyDetail?.is_hidden ? " unhide " : " hide "
          }this currency`}
          handleResponse={handleResponse}
          data={currencyDetail}
        />
      )}
      {openUpdate && (
        <CurrencySettingHandle
          data={currencyDetail}
          refetchData={refetch}
          onClose={() => {
            setCurrencyDetail(null);
            setOpenUpdate(false);
          }}
        />
      )}
    </div>
  );
};
export default withH1(CurrencySetting, "Currency Setting");
