//UTILITIES
import React from "react";
import PropTypes from "prop-types";
//COMPONENT
import palette from "../../../theme/color.scss";
import {
  TableSortLabel,
  TableRow,
  TableCell,
  TableHead,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { PlusVectorSVG, TableExportSVG } from "../../../assests/icons/SVG";
//CSS
import "./TableBody.scss";

export function TableCustomHeader(props) {
  //PROPS
  const {
    header,
    actions,
    requestSort,
    handleExport,
    internal,
    handleAdd,
    selectAllValue,
    handleSelectAll,
    isArabic
  } = props;
  const { prefixActions } = props;

  const handleRequestSort = (_, item) => requestSort(item);

  return (
    <TableHead>
      <TableRow className="table-component-header-row">
        {prefixActions && (
          <TableCell
            className={
              internal
                ? "internal-action-header-cell"
                : "external-action-header-cell"
            }
            align={"left"}
          >
            {handleSelectAll && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={selectAllValue ? true : false}
                      checked={selectAllValue ? true : false}
                      onChange={(e) => handleSelectAll(e.target.checked)}
                    />
                  }
                />
              </FormGroup>
            )}
          </TableCell>
        )}
        {header.map((item, index) => (
          <TableCell
            key={index}
            className={
              internal ? "internal-header-cell" : "external-header-cell"
            }
          >
            {item.sorted === true ? (
              <TableSortLabel
                direction={props.orderBy}
                active={props.sortedBy === item.value}
                onClick={(e) => handleRequestSort(e, item.value)}
              >
                {item.title}
              </TableSortLabel>
            ) : (
              <div className={isArabic ? "textAlighRight" : ""}>
                {item.title}
              </div>
            )}
          </TableCell>
        ))}

        {actions && (
          <TableCell
            align={"right"}
            className={
              internal
                ? "internal-action-header-cell"
                : "external-action-header-cell"
            }
          >
            {handleExport && (
              <IconButton
                color="primary"
                component="span"
                onClick={() => handleExport()}
              >
                <TableExportSVG />
              </IconButton>
            )}
            {handleAdd && (
              <IconButton
                color="primary"
                component="span"
                onClick={() => handleAdd()}
              >
                <PlusVectorSVG fill={palette.primaryColor} />
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}
TableCustomHeader.defaultProps = {
  actions: true,
};
TableCustomHeader.propTypes = {
  actions: PropTypes.bool,
  requestSort: PropTypes.func,
  handleExport: PropTypes.func,
  headers: PropTypes.any,
  internal: PropTypes.bool,
};

export default TableCustomHeader;
