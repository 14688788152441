//UTILITIES
import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useContext,
} from "react";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

//COMPONENT
import Button from "../../components/button/Button";
import { useForm, Controller } from "react-hook-form";
import Search from "../../components/search/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  IconButton,
  CardContent,
  TableCell,
  DialogContent,
  Accordion,
  Typography,
  AccordionSummary,
  Dialog,
  DialogTitle,
  AccordionDetails,
} from "@mui/material";
import { CloseSVG } from "../../assests/icons/SVG";
import { FormInput } from "../../components/formComponents/FormComponents";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import {
  groupParamsByKey,
  objectCleaner,
} from "../../core/functions/Functions";

//CSS
import "./Organizations.scss";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import {
  deleteOrganizationCountry,
  updateOrganizationCountryFactor,
} from "../../core/apis/organization";
import { AlertContext } from "../../context/AlertContext";

const HandleSchema = yup.object({
  exception_factor: yup
    .number()
    .positive("Field should be positive")
    .nullable()
    .required("Field is required"),
});

export function OrganizationCountries(props) {
  //VARIABLES
  const { setAlert } = useContext(AlertContext);
  const { data, refetchData } = props;
  const setTimerRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("country_name") || "");
  const [countriesData, setCountriesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [countryDetail, setCountryDetail] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const tableHeaders = [
    { title: "Country", sorted: false },
    { title: "Factor", sorted: false },
    // { title: "Total Branches", sorted: false },
  ];

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  //FUNCTIONS
  const handleSubmitForm = (formData) => {
    updateOrganizationCountryFactor({
      ...formData,
      id: countryDetail?.pivot?.id,
    }).then((res) => {
      if (res?.data?.success) {
        setCountryDetail(null);
        setOpenEdit(false);
        refetchData();
      }
      setAlert({
        text: res.data ? res.data.message : res.message,
        type: res.data?.success ? "success" : "error",
      });
    });
  };
  const handleSearch = useCallback(
    (e) => {
      setSearch(e.target.value);
      if (setTimerRef.current) {
        clearTimeout(setTimerRef.current);
        setTimerRef.current = null;
      }

      setTimerRef.current = setTimeout(() => {
        let url = {
          ...groupParamsByKey(searchParams),

          country_name: e.target.value,
        };
        setSearchParams(objectCleaner(url));
        clearTimeout(setTimerRef.current);
        setTimerRef.current = null;
      }, 1000);
    },
    [search]
  );

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleRemoveCountryFactor = (type, data) => {
    if (type === "yes") {
      deleteOrganizationCountry(data?.pivot?.id).then((res) => {
        if (res?.data?.success) {
          setCountryDetail(null);
          setOpenDelete(false);
          refetchData();
        }
        setAlert({
          visible: true,
          text: res.data ? res.data.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
      });
    } else {
      setOpenDelete(false);
    }
  };

  useEffect(() => {
    setCountriesData(data || []);
  }, [data]);

  useEffect(() => {
    if (searchParams.get("country_name")) {
      setCountriesData(
        countriesData?.filter((el) =>
          el?.name
            ?.toLowerCase()
            .includes(searchParams.get("country_name")?.toLowerCase())
        )
      );
    } else {
      setCountriesData(data || []);
    }
  }, [searchParams, data]);

  return (
    <>
      <Accordion expanded={expanded} onChange={handleExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="organization-factor-header">
            <Typography>Countries Factors</Typography>
            <div>{`${data ? data?.length : 0} total countries`}</div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <CardContent className="no-padding-top">
            <div className="organizations-branches">
              <div className="search-section">
                <Search
                  className="search"
                  placeholder={"Search by country name"}
                  onChange={handleSearch}
                  value={search}
                  curved
                />
              </div>

              <TableBodyComponent
                header={tableHeaders}
                loading={loading}
                internal={true}
                TableStyle={{
                  style: { maxHeight: "400px", overscrollY: "auto" },
                }}
              >
                {countriesData?.map((element) => (
                  <TableRowComponent
                    row={element}
                    internal={true}
                    handleDelete={() => {
                      setCountryDetail(element);
                      setOpenDelete(true);
                    }}
                    handleEdit={() => {
                      reset({
                        exception_factor: element?.pivot?.exception_factor,
                      });
                      setCountryDetail(element);
                      setOpenEdit(true);
                    }}
                  >
                    <TableCell>{element.name}</TableCell>
                    <TableCell>{element?.pivot?.exception_factor}</TableCell>
                    {/* <TableCell>{element.branch_count || 0}</TableCell> */}
                  </TableRowComponent>
                ))}
              </TableBodyComponent>
            </div>
          </CardContent>
        </AccordionDetails>
      </Accordion>

      {openEdit && (
        <Dialog disablebackdropclick="true" fullWidth open={true}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            {"Edit Factor"}
            <IconButton
              onClick={() => {
                setCountryDetail(null);
                setOpenEdit(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseSVG />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <div className="handle-country-org-factor">
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      required
                      type="number"
                      label="Factor"
                      placeholder="Enter factor"
                      name="exception_factor"
                      value={value}
                      onChange={(e) =>
                        onChange(e.target.value === "" ? null : e.target.value)
                      }
                      helperText={error?.message}
                    />
                  )}
                  name={`exception_factor`}
                  control={control}
                />
                <div className="action-footer">
                  <Button
                    name={"Save"}
                    selected
                    loading={saveLoading}
                    width={"30%"}
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      )}

      {openDelete && (
        <NoticeConfirmation
          data={countryDetail}
          handleResponse={handleRemoveCountryFactor}
        />
      )}
    </>
  );
}

export default OrganizationCountries;
