import { api } from "./main";

export const getPersonalDetails = () => {
  return api
    .get(`admin/get-admin-account-details`)
    .then((res) => res.data.data[0])
    .catch((err) => console.error(err));
};

export const changePassword = (payload) => {
  return api.put("auth/change-password", payload).then((res) => {
    return res;
  });
};
