import React from "react";
import {
  Grid,
  Switch,
  FormControlLabel,
  Skeleton,
  Typography,
} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import { useContext } from "react";
import Button from "../../../components/button/Button";
import { AlertContext } from "../../../context/AlertContext";
import "./menuEditor.scss";
import { useGet } from "../../../hooks/useFetch";
import { isActiveMenuEditor } from "../../../core/apis/store";
import { useForm, Controller } from "react-hook-form";

const MenuEditor = () => {
  const { control } = useForm({});
  const { setAlert } = useContext(AlertContext);

  // Get All Menu Editors
  const {
    data: allEditors,
    loading: loadingEditors,
    refetch,
  } = useGet({
    url: "admin/menu-editors",
  });

  let AllEditorsArray = allEditors?.data?.data
    ? Object.values(allEditors?.data?.data)
    : [];

  const handleSwitchChange = async (id, isActive) => {
    try {
      const payload = { is_active: isActive };
      const res = await isActiveMenuEditor(id, payload);
      if (res.data.success) {
        setAlert({
          visible: true,
          text: res?.data?.success ? "Successfully updated" : res.data.message,
          type: res?.data?.success ? "success" : "error",
        });
        refetch();
      } else {
        console.error("Error:", res.message);
        //setalert
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  const updateName = (str) => {
    return str?.toLowerCase()?.replace(/_/g, " ")?.toUpperCase();
  };

  return (
    <div>
      <TabPanel value="12">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            padding: "5px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Menu Editor
          </Typography>
        </div>
        <div className="menu-editor">
          <Grid container spacing={2}>
            {loadingEditors
              ? Array?.from(new Array(9))?.map((_, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <Skeleton variant="rectangular" height={40} />
                  </Grid>
                ))
              : AllEditorsArray?.sort((a, b) => a.id - b.id)?.map((element) => (
                  <Grid item xs={12} sm={4} key={element.id}>
                    <Controller
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              defaultChecked={element.is_active}
                              onChange={(e) =>
                                handleSwitchChange(
                                  element?.id,
                                  e?.target?.checked
                                )
                              }
                            />
                          }
                          label={updateName(element?.slug)}
                        />
                      )}
                      name={`Switch-${element.id}`}
                      control={control}
                    />
                  </Grid>
                ))}
          </Grid>
        </div>
      </TabPanel>
      {/* <div className="action-footer">
        {loadingEditors ? (
          <>
            <Skeleton variant="rectangular" width={100} height={36} />
            <Skeleton
              variant="rectangular"
              width={100}
              height={36}
              style={{ marginLeft: "8px" }}
            />
          </>
        ) : (
          <>
            <Button selected name={"Save"} type="submit" className="mx-1" />
            <Button name={"Cancel"} type="button" className="mx-1" />
          </>
        )}
      </div> */}
    </div>
  );
};

export default MenuEditor;
