//UTILITIES
import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

//COMPONENT
import { TableCell, Button } from "@mui/material";
import Paginator from "../../components/paginator/Paginator";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";

//API
import { useGet } from "../../hooks/useFetch";
//CSS
import "./ManageRules.scss";
import ManageConditionsHandle from "./ManageConditionsHandle";
import ManageAttributesHandle from "./ManageAttributesHandle";
import TableHeader from "../../components/table/tableHeader/TableHeader";
import withH1 from "../../assests/HOC/withH1";

export function ManageRules() {
  //VARIABLES
  let navigate = useNavigate();
  const tableHeaders = [
    { title: "Product", sorted: false },
    { title: "Type", sorted: false },
    { title: "Condition", sorted: false },
  ];
  const [openEditAttribute, setOpenEditAttribute] = useState(false);
  const [openViewCondition, setOpenViewCondition] = useState(false);
  const [productDetail, setProductDetail] = useState(null);
  const [searchParams] = useSearchParams(); //pagination and filter
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
    category_ids: searchParams.getAll("category_ids"),
  };

  const { data: manageRules, loading } = useGet({
    url: "admin/get-all-products",
    payload: payload,
  });
  let manageRulesArray = manageRules?.data.data;

  return (
    <div className="manage-rules-component">
      <TableHeader
        placeholder="Search by product name"
        filterByManageRulesCategory
        checkbox
      />
      <TableBodyComponent header={tableHeaders} loading={loading}>
        {manageRulesArray?.data?.map((element) => (
          <TableRowComponent
            row={element}
            handleEdit={() => {
              setProductDetail(element);
              setOpenEditAttribute(true);
            }}
          >
            <TableCell>{element.name?.en}</TableCell>
            <TableCell>{element.category?.name?.en}</TableCell>
            <TableCell>
              <Button
                className="condition-link"
                size="small"
                onClick={() => {
                  setProductDetail(element);
                  setOpenViewCondition(true);
                }}
              >
                View Condition
              </Button>
            </TableCell>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
      <Paginator count={manageRulesArray?.last_page} disabled={loading} />

      {openViewCondition && (
        <ManageConditionsHandle
          data={productDetail}
          onClose={() => {
            setProductDetail(null);
            setOpenViewCondition(false);
          }}
        />
      )}
      {openEditAttribute && (
        <ManageAttributesHandle
          data={productDetail}
          onClose={() => {
            setProductDetail(null);
            setOpenEditAttribute(false);
          }}
        />
      )}
    </div>
  );
}

export default withH1(ManageRules, "Manage Rules");
