import { Skeleton } from "@mui/material";
import "./Skeletons.scss";
export function CircularDashboardSkeleton(props) {
  const { count } = props;
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
        margin: "10px 0px",
      }}
    >
      <Skeleton variant="circular" height="200px" width="200px" />
    </div>
  );
}

export function BarSkeleton(props) {
  const { count } = props;
  return (
    <div className="bar-dashboard-skeleton">
      {" "}
      {[...Array(7)]?.map((el) => (
        <Skeleton />
      ))}
    </div>
  );
}
