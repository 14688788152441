import { api } from "./main";
export const getAllItems = async (payload) => {
  return await api
    .get(`admin/get-all-items`, { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const getItemById = async (id) => {
  return await api
    .get(`admin/get-item-by-id/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const createItemIdentityValue = async (payload) => {
  return await api
    .post(`admin/create-item-identity-value`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const updateItemOrder = async (payload) => {
  return await api
    .put(`admin/update-item`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const updateItemValueOrder = async (id) => {
  return await api
    .patch(`admin/update-item${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const getAllItemIdentities = async (payload) => {
  return await api
    .get(`admin/get-all-item-identities`, { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const getItemIdentityById = async (id) => {
  return await api
    .get(`admin/get-item-identity-by-id/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const createItemIdentity = async (payload) => {
  return await api
    .post(`admin/create-item-identity`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const updateItemIdentity = async (payload) => {
  return await api
    .put(`admin/update-item-identity/${payload?.id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const changeItemAction = async (payload) => {
  return await api
    .patch(`admin/change-item-action/${payload?.id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateItem = async (payload) => {
  return await api
    .post(`admin/update-item/${payload?.id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const transferItem = async (payload) => {
  return await api
    .post(`admin/transfer-item-to-country`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const addItemToProductSku = async (payload) => {
  return await api
    .post(`admin/add-item-to-product-sku`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deleteItemIdentity = async (payload) => {
  return await api
    .delete(`admin/delete-item-identity/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const delteItemImages = async (itemId, payload) => {
  return await api
    .delete(`admin/delete-item-images/${itemId}`, { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const uploadFeaturedItemImage = async (payload) => {
  return await api
    .post(`admin/upload-featured-item-image`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const uploadItemImages = async (payload) => {
  return await api
    .post(`admin/upload-item-images`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const BulkCollect = async (payload) => {
  return await api
    .post(`admin/item-bulk-collect`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

// Product sku

export const uploadProductSkuFeaturedItemImage = async (payload) => {
  return await api
    .post(`admin/upload-product-sku-featured-image`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const uploadProductSkuImages = async (payload) => {
  return await api
    .post(`admin/upload-product-sku-images`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const delteProductSkuImages = async (itemId, payload) => {
  return await api
    .delete(`admin/delete-product-sku-images/${itemId}`, { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const createProductSku = async (payload) => {
  return await api
    .post(`admin/create-product-sku`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const updateProductSku = async (id, payload) => {
  return await api
    .put(`admin/update-product-sku/${id}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getproductskuImages = async (id) => {
  return await api
    .get(`admin/get-product-sku-images/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
