import { api } from "./main";

export const login = async (payload) => {
  return await api
    .post("auth/login", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const logout = async () => {
  return await api
    .get("auth/logout")
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const refreshToken = async () => {
  return await api
    .get("auth/refresh-token")
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const isUserLoggedIn = () => {
  let token = localStorage.getItem("SESSION");
  return Boolean(JSON.parse(token));
};

export const forgotPassword = async (data) => {
  return await api.post("auth/check-email", data).then((res) => {
    if (res.data.success);
    return res;
  });
};

export const resetPassword = async (data) => {
  return await api.post("auth/reset-password", data).then((res) => {
    if (res.data.success);
    return res;
  });
};
export const checkOtp = async (data) => {
  return await api.post("auth/check-otp", data).then((res) => {
    if (res.data.success);
    return res;
  });
};
export const resetPasswordByEmail = async (data) => {
  let payload = {
    ...data,
  };
  return await api.post("auth/reset-password-by-email", payload).then((res) => {
    if (res.data.success);
    return res;
  });
};

export const resetPasswordByOTP = (data) => {
  return api.post("auth/reset-password-by-otp", data).then((res) => {
    if (res.data.success);
    return res;
  });
};
export const FCMToken = (bodyRequest) => {
  return api.post("save-fcm-token", bodyRequest).then((res) => {
    return res;
  });
};

export const updateAccount = (data) => {
  return api
    .post("user/update-account-details", data)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const checkEmail = async (payload) => {
  return await api
    .get("/check-email", { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const checkOTP = async (payload) => {
  return await api
    .get("/check-reset-password-otp", { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};
