import React, { useContext, useState } from "react";
import "./PiceaUsers.scss";
import { TableCell } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Paginator from "../../../components/paginator/Paginator";
import TableBodyComponent from "../../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../../components/table/tableBody/TableRowComponent";
import TableHeader from "../../../components/table/tableHeader/TableHeader";
import { useGet } from "../../../hooks/useFetch";
import DialogComponent from "../../../components/dialogs/dialog/Dialog";
import { FormInput } from "../../../components/formComponents/FormComponents";
import { useFormik } from "formik";
import * as yup from "yup";
import { createPiceaUser, deletePiceaUser } from "../../../core/apis/Picea";
import { AlertContext } from "../../../context/AlertContext";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import withH1 from "../../../assests/HOC/withH1";

const piceaUserSchema = yup.object({
  username: yup.string("Enter username").required("Field is required"),
  password: yup.string("Enter password").required("Field is required"),
});

const PiceaUsers = () => {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const tableHeaders = [{ title: "Id" }, { title: "username" }];
  const [searchParams] = useSearchParams();

  let url = {
    getAll: `admin/picea/get-all-picea-users`,
  };

  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    username: searchParams.get("search"),
  };

  const {
    data: piceaUsers,
    loading: loadingPage,
    refetch,
  } = useGet({ url: url.getAll, payload: payload });

  let data = piceaUsers?.data?.data;

  const toggleDialog = (value) => () => {
    setOpen(value);
    setId(null);
  };

  const formik = useFormik({
    initialValues: {
      username: data?.data?.find((i) => i?.id === id)?.username || "",
      id: data?.data?.find((i) => i?.id === id)?.id || null,
      password:""
    },
    enableReinitialize: true,
    validationSchema: piceaUserSchema,
    onSubmit: () => {
      handleSubmitForm();
    },
  });

  const handleSubmitForm = () => {
    setLoading(true);
    createPiceaUser({ ...formik.values }).then((res) => {
      console.log(formik.values);
      setAlert({
        visible: true,
        text: res.data ? res.data.message : res.message,
        type: res.data?.success ? "success" : "error",
      });
      if (res.data?.success) {
        refetch();
        formik.resetForm(); // Reset the form values
        toggleDialog(!open)();
      }
      setLoading(false);
    });
  };

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deletePiceaUser(responseData).then((res) => {
        if (res?.data?.success) {
          setId(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  return (
    <>
      <DialogComponent
        open={open.add || open.update || false}
        handleOpenClose={toggleDialog(!open)}
        buttonLabel="Create"
        title="Create User "
        loading={loading}
        disabled={!formik.dirty || loading}
        onSubmit={formik.handleSubmit}
      >
        <div className="w-500">
          <FormInput
            required
            label="User Name"
            placeholder="Enter User Name"
            name="username"
            id="username"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
          <FormInput
            required
            label="Password"
            placeholder="Enter Password"
            name="password"
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </div>
      </DialogComponent>

      <TableHeader
        placeholder="Search by name"
        add={() => setOpen({ ...open, add: true })}
        filterButton={false}
      />
      <TableBodyComponent
        header={tableHeaders}
        loading={loadingPage}
        total={data?.total}
      >
        {data?.data?.map((element) => (
          <TableRowComponent
            className="BrandDetails"
            handleDelete={() => {
              setId(element?.id);
              setOpenDelete(true);
            }}
            row={element}
            key={element?.id}
          >
            <TableCell>{element.id}</TableCell>
            <TableCell>{element?.username}</TableCell>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
      <Paginator count={data?.last_page} disabled={loadingPage} />
      {openDelete && (
        <NoticeConfirmation data={id} handleResponse={handleDeleteResponse} />
      )}
    </>
  );
};

export default withH1(PiceaUsers, "Users");
