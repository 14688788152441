import { api } from "./main";
export const getAllOpenTransactions = (page, payload) => {
  return api
    .post(`admin/get-all-open-transactions?page=${page}`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const TakeOrderActions = (bodyRequest) => {
  return api
    .patch(`admin/take-order-actions/${bodyRequest?.id}`, bodyRequest)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const rejectOffer = (bodyRequest) => {
  return api
    .patch(`accept-reject-offer/${bodyRequest?.id}`, bodyRequest)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const getShippingStatus = (id) => {
  return api
    .get("/admin/get-shipping-status/" + id)
    .then((res) => res?.data?.data)
    .catch((err) => console.error(err));
};


export const deleteTransaction = async (payload) => {
  return await api
    .delete(`admin/delete-transaction/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
