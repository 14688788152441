import { api } from "./main";

export const GetAllUsers = (payload) => {
  return api
    .get(`admin/get-all-users`, { params: { ...payload } })
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const getAllUsersTypes = async (data) => {
  return await api
    .get("get-all-user-types", data)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};

export const deleteUser = async (payload) => {
  return await api
    .delete(`admin/delete-user/${payload}`)
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};

export const checkPhoneValidity = async (payload) => {
  return await api
    .get(`validate-phone-number`, { params: { phone_number: payload } })
    .then((res) => {
      return res;
    })
    .catch((err) => console.error(err));
};
