//UTILITIES
import React, { useEffect, useContext } from "react";
import moment from "moment";
import { useGet } from "../../hooks/useFetch";
//COMPONENT
import { List, ListItem, Button, Skeleton } from "@mui/material";

//CSS
import "./UserNotifications.scss";
import {
  getNotificationById,
  markAllNotificationsRead,
} from "../../core/apis/notifications";
import { EllipsisSVG } from "../../assests/icons/SVG";
import DataNotFound from "../../components/notFound/DataNotFound";
import { AlertContext } from "../../context/AlertContext";
import withH1 from "../../assests/HOC/withH1";
import { NotificationContext } from "../../context/NotificationContext";

export function UserNotifications() {
  const { notificationCount, updateNotificationCount } =
    useContext(NotificationContext);
  const { setAlert } = useContext(AlertContext);
  //API
  const {
    data: notificationsList,
    loading,
    refetch,
  } = useGet({
    url: "/get-user-notifications",
  });

  let notificationsArray = notificationsList?.data?.data;

  //FUNCTIONS
  const handleReadAll = () => {
    markAllNotificationsRead().then((res) => {
      if (res?.data?.success) {
        refetch();
        updateNotificationCount();
      }
      setAlert({
        text: res.data.message,
        type: res.data.success ? "success" : "error",
      });
    });
  };

  useEffect(() => {
    refetch();
  }, [notificationCount]);
  //API TO MARK NOTIFICAITON AS READ(BACKEND IMPLEMENTED)

  const markNotificationAsRead = (value) => {
    getNotificationById(value?.id).then((res) => {
      if (res?.data?.success) {
        refetch();
        updateNotificationCount();
      }
    });
  };

  const showDate = (dateValue) => {
    let date = moment(dateValue);
    if (moment().diff(date, "days") >= 2) {
      return date?.fromNow(); // '2 days ago' etc.
    }
    return date?.calendar().split(" ")[0]; // 'Yesterday', 'Today', 'Tomorrow'
  };

  const showHour = (dateValue, hourValue) => {
    let date = moment(dateValue);
    let hourdate = moment(hourValue);

    if (moment().diff(date, "days") == 0) {
      return hourdate?.fromNow(); // 'one hour.
    }
    return date?.fromNow(); // '2 days ago '
  };
  return (
    <div className="user-notifications">
      {!loading ? (
        notificationsArray?.notifications &&
        Object.keys(notificationsArray?.notifications).length !== 0 ? (
          Object.keys(notificationsArray?.notifications)?.map(
            (element, index) => (
              <div className="user-notification-elements">
                <div className="grouped-date">
                  <div> {showDate(element)}</div>
                  {index === 0 && (
                    <Button
                      onClick={() => handleReadAll()}
                      disabled={
                        notificationsArray?.unread_notifications_count === 0
                          ? true
                          : false
                      }
                    >
                      Mark all as read
                    </Button>
                  )}
                </div>

                <div>
                  {notificationsArray?.notifications[element]?.map((value) => (
                    <List className="notify-list">
                      <ListItem>
                        <div className="notify-item-content">
                          <div className="notify-desc">
                            <div>
                              <span style={{ marginRight: "5px" }}>
                                <EllipsisSVG
                                  fill={
                                    value?.notification_details?.is_read
                                      ? "#A4D1F7"
                                      : "#4BA4F0"
                                  }
                                />
                              </span>
                              {value?.content}
                            </div>

                            {value?.notification_details?.is_read === false && (
                              <span>
                                <Button
                                  onClick={() => markNotificationAsRead(value)}
                                >
                                  mark as read
                                </Button>
                              </span>
                            )}
                          </div>
                          <div className="state-hour">
                            {showHour(element, value?.created_at)}
                          </div>
                        </div>
                      </ListItem>
                    </List>
                  ))}
                </div>
              </div>
            )
          )
        ) : (
          <DataNotFound />
        )
      ) : (
        <Skeleton count={10} />
      )}
    </div>
  );
}

export default withH1(UserNotifications, "Notifications List");
