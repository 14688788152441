import { api } from "./main";

export const CreateNewInspection = async (payload) => {
  return await api
    .post("collection-point/create-new-inspection", payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      alert("error while fetching role");
    });
};
