import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import "./editableRow.scss";

const EditableRow = ({
  data,
  create,
  values,
  onsave,
  onClear,
  title,
  hide,
  editMode,
  setEditMode,
}) => {
  const [editableData, setEditableData] = useState({ ...data });
  const [maineditableData, setMainEditableData] = useState({ ...data });

  const [error, setError] = useState(false);

  useEffect(() => {
    if (data) {
      setEditableData({ ...data });
      setMainEditableData({ ...data });
    }
  }, [data]);

  const handleInputChange = (key, value) => {
    setEditableData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleSave = () => {
    const updatedDataArray = Object.keys(editableData).map((key) => {
      const currentValue = editableData[key];
      const previousValue = maineditableData[key]?.factor || null; // Get previous factor value

      const factor = !isNaN(currentValue)
        ? parseFloat(currentValue)
        : previousValue;
      const id = maineditableData[key]?.id || null;
      const attribute_id = maineditableData[key]?.attribute_id || null;
      const outterid = maineditableData[key]?.outterid || null;
      const attributeName = title;
      const name = maineditableData[key]?.name || null;

      return { factor, id, attribute_id, outterid, name, attributeName };
    });

    console.log(updatedDataArray);
    setEditMode(false);
    checkValidation(updatedDataArray);
  };

  const checkValidation = (updatedDataArray) => {
    // Check if any "factor" field is empty
    const isEmptyField = updatedDataArray.some((item) => item.factor === "");

    if (isEmptyField) {
      setError(true); // Set error state to true if any "factor" field is empty
      setEditMode(true);
      return; // Exit early if any "factor" field is empty
    } else {
      onsave(updatedDataArray, title);
      setError(false);
    }
  };

  // add on clear function that send empty array
  const handleClear = () => {
    setEditableData({ ...maineditableData });
    // onsave({}, title);
    onClear([], title);
    setError(false);
  };

  const handleEdit = () => {
    setEditMode(true);
    setError(false);
  };

  return (
    <div className="table-Buttons">
      <table
        className="table table-bordered table-sm table-responsive"
        style={{
          color: "#000",
          borderColor: "#eee",
          backgroundColor: "#fff",
        }}
      >
        <thead class="thead-dark">
          <tr>
            {Object.keys(maineditableData)?.map((key) => (
              <th
                key={key}
                scope="col"
                style={{ width: "100px" }}
                className="responsive-header"
              >
                {maineditableData[key]?.name?.en ||
                  maineditableData[key]?.product_factorable?.name?.en ||
                  maineditableData[key]?.name}
              </th>
            ))}
            {!hide && (
              <th scope="col" style={{ width: "100px" }}>
                Actions
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            {Object.keys(editableData)?.map((key) => (
              <td key={key}>
                <input
                  type="number"
                  min="0"
                  max="10"
                  disabled={!editMode}
                  placeholder="add Value"
                  value={editableData[key]?.factor}
                  onChange={(e) => handleInputChange(key, e.target.value)}
                  className="inputStyle"
                  aria-disabled={!editMode}
                />
              </td>
            ))}
            {!hide && (
              <td style={{ width: "100px" }}>
                <div className="iconTableButton">
                  {editMode ? (
                    <>
                      <IconButton
                        size="small"
                        onClick={handleSave}
                        style={{ color: "#008000" }}
                      >
                        <CheckCircleIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={handleClear}
                        style={{ color: "#FF0000" }}
                      >
                        <NotInterestedIcon />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton size="small" onClick={handleEdit}>
                      <ModeEditOutlineIcon />
                    </IconButton>
                  )}
                </div>
              </td>
            )}
          </tr>
        </tbody>
      </table>
      {error && <p className="error-message">All fields are required!</p>}
    </div>
  );
};

export default EditableRow;
