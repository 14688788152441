import React, { useContext, useState } from "react";
import { TableCell, IconButton } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Paginator from "../../components/paginator/Paginator";
import TableBodyComponent from "../../components/table/tableBody/TableBodyComponent";
import TableRowComponent from "../../components/table/tableBody/TableRowComponent";
import { useGet } from "../../hooks/useFetch";
import "./Exceptions.scss";
import { deleteException } from "../../core/apis/exception";
import NoticeConfirmation from "../../components/dialogs/noticeConfirmation/NoticeConfirmation";
import { AlertContext } from "../../context/AlertContext";
import withH1 from "../../assests/HOC/withH1";
import { Paper } from "@mui/material";
import { Exception } from "sass";

const Exceptions = () => {
  const { setAlert } = useContext(AlertContext);
  const tableHeaders = [{ title: "Case NO" }, { title: "Function Name" }];
  const [searchParams] = useSearchParams();
  const [exceptionDetail, setExceptionDetail] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  let navigate = useNavigate();
  let payload = {
    per_page: 10,
    page: searchParams.get("page") || 1,
    name: searchParams.get("search"),
  };
  let url = `admin/exceptions`;
  const { data: exceptions, loading, refetch } = useGet({ url: url });
  useGet({ url: url, payload: payload });
  let data = exceptions?.data.data;

  const handleDeleteResponse = (responseType, responseData) => {
    if (responseType === "yes") {
      deleteException(responseData?.id).then((res) => {
        if (res?.data?.success) {
          setExceptionDetail(null);
          refetch();
        }
        setAlert({
          visible: true,
          text: res?.data?.success
            ? "Deleted SuccessFully"
            : res?.data?.message,
          type: res?.data?.success ? "success" : "error",
        });
      });
    }
    setOpenDelete(false);
  };

  return (
    <>
      <div className="exceptionList">
        <Paper elevation={24} className="paper-div">
          <TableBodyComponent
            header={tableHeaders}
            loading={loading}
            total={data?.total}
          >
            {data?.data?.map((element) => (
              <TableRowComponent
                key={element?.id} // Add a unique key for each row
                internal
                handleDelete={() => {
                  setExceptionDetail(element);
                  setOpenDelete(true);
                }}
                sx={{ color: "red" }} // Set the color to red}
                handleEye={() => navigate("/view-exceptions/" + element?.id)}
              >
                <TableCell>{element?.case_number}</TableCell>
                <TableCell>{element?.function_name}</TableCell>
              </TableRowComponent>
            ))}
          </TableBodyComponent>
        </Paper>
        <Paginator count={data?.last_page} disabled={loading} />
        {openDelete && (
          <NoticeConfirmation
            data={exceptionDetail}
            handleResponse={handleDeleteResponse}
          />
        )}
      </div>
    </>
  );
};

export default withH1(Exceptions, "Exceptions");
